import React, { Component, Fragment } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import { Provider } from "react-redux";
import { loadUser } from "./actions/auth";
import configureStore, { history } from "./configureStore";
import { ConnectedRouter } from "connected-react-router";
import Login from "./components/auth/Login";
import MainHeader from "./components/common/MainHeader";
import PrivateRoute from "./components/common/PrivateRoute";
import SimpleReactLightbox from "simple-react-lightbox";
import "react-datepicker/dist/react-datepicker.css";

const store = configureStore();
class App extends Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router>
            <SimpleReactLightbox>
              <Fragment>
                <PrivateRoute path="/" component={MainHeader} />
                <Route exact path="/login" component={Login} />
              </Fragment>
            </SimpleReactLightbox>
          </Router>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
