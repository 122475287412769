import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, Attachment } from "../common";
import swal from "sweetalert";
import {
  selectDocumentation,
  deleteDocumentation,
} from "../../actions/documentations";

export default function ViewCorrespondence(props) {
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );

  return (
    <div className="shadow-md pb-4">
      <div
        className="grid grid-cols-2 gap-4"
        style={{
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          //   paddingBottom: 30,
        }}
      >
        <div>
          <Label name="E-mail From" />
          <p className="text-sm mb-4">{selectedDocument.email_from}</p>
        </div>
        <div>
          <Label name="Date" />
          <p className="text-sm mb-4">{selectedDocument.date}</p>
        </div>
        <div>
          <Label name="Subject" />
          <p className="text-sm mb-4">{selectedDocument.subject}</p>
        </div>
        <div>
          <Label name="E-mail Attachment" />
          <p className="text-sm mb-4">
            {selectedDocument.files && selectedDocument.files[0] && (
              <Attachment path={selectedDocument.files[0].full_path} />
            )}
          </p>
        </div>
        <div>
          <Label name="Comments" />
          <p className="text-sm mb-4">{selectedDocument.comments}</p>
        </div>
        <div>
          <Label name="Attachments" />
          {selectedDocument && selectedDocument.files ? (
            selectedDocument.files.map((file, index) => (
              <div key={index}>
                <Attachment path={file.full_path} showFileName />
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-xs">No attachments.</p>
          )}
        </div>
      </div>

      <div className="flex ml-4 justify-between">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
        <Button
          text="Delete"
          danger="true"
          onClick={() => {
            swal({
              icon: "warning",
              title: "Are you sure?",
              text: `Once deleted, selected correspondence will be removed.`,
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                dispatch(deleteDocumentation(selectedDocument.id));
                props.onViewClose && props.onViewClose();
                selectDocumentation(null);
              }
            });
          }}
        />
      </div>
    </div>
  );
}
