import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import EquipmentCategoryForm from "./EquipmentCategoryForm";

import { Button, Fieldset, Pagination, Spinner } from "../common";
import {
  getEquipmentCategories,
  selectEquipmentCategory,
} from "../../actions/equipments";

export default function EquipmentCategoryPage(props) {
  const dispatch = useDispatch();
  const equipmentCategories = useSelector(
    (state) => state.equipments.equipmentCategories
  );
  const selectedEquipmentCategory = useSelector(
    (state) => state.equipments.selectedEquipmentCategory
  );
  const pagination = useSelector(
    (state) => state.equipments.paginationEquipmentCategories
  );
  const equipmentsLoading = useSelector(
    (state) => state.tools.equipmentsLoading
  );

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getEquipmentCategories(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className="flex">
      <div className="w-1/2">
        <Fieldset>
          <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
            Equipment Categories
          </h1>

          <Button
            text="+ add category"
            primary="true"
            onClick={() => {
              dispatch(selectEquipmentCategory(null));
            }}
          />

          <table className="table-auto ml-6 mt-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Name</th>
              </tr>
            </thead>

            <tbody>
              {!equipmentsLoading &&
                equipmentCategories.map((equipmentCategory) => (
                  <tr
                    key={equipmentCategory.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedEquipmentCategory
                          ? selectedEquipmentCategory.id
                          : 0) === equipmentCategory.id,
                    })}
                    onClick={() => {
                      dispatch(selectEquipmentCategory(equipmentCategory));
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {equipmentCategory.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {equipmentsLoading && <Spinner />}

          {pagination && !equipmentsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      <div className="w-1/2 px-4">
        <Fieldset>
          <EquipmentCategoryForm />
        </Fieldset>
      </div>
    </div>
  );
}
