import axios from "axios";
import { getRoles } from "./roles";
import { getCompanies } from "./companies";
import { subscribeUser } from "../subscription";
// import { returnErrors } from "./messages";
// import { setActiveSidebarNav } from "./general";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// LOGIN
export const login = (username, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ username, password });
  axios
    .post(BASE_URL + "/login", body, config)
    .then((res) => {
      if (res.data.message === "Failed") {
        dispatch({
          type: LOGIN_FAIL,
        });
      } else {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
        dispatch(loadUser());
      }
    })
    .catch((err) => {
      //   dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axios
    .post(BASE_URL + "/logout/", null, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      console.error(err);
      //   dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  axios
    .get(BASE_URL + "/user/credentials", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      dispatch(getRoles());
      dispatch(getCompanies());
      //Subscribe to web push notifications
      subscribeUser(getState);
    })
    .catch((err) => {
      //   dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

export const getUserInfo = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + "/user/credentials", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};
