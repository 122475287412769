import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Label } from "components/common";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import ImageUploader from "react-images-upload";
import ImageUtils from "../../helpers/ImageUtils";
import { updateDocumentation } from "actions/documentations";
import { SRLWrapper } from "simple-react-lightbox";

export default function EditToolboxMeeting(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const [date, setDate] = useState(
    selectedDocument.date ? new Date(selectedDocument.date) : new Date()
  );
  const [photos, setPhotos] = useState([]);
  const imageUploadRef = useRef(null);

  //Values to add
  const [safety, setSafety] = useState(selectedDocument.safety);
  const [supervisor, setSupervisor] = useState(selectedDocument.supervisor);
  const [worker, setWorker] = useState(selectedDocument.worker);
  const [subcon, setSubcon] = useState(selectedDocument.subcon);
  const [supplyLabour, setSupplyLabour] = useState(
    selectedDocument.supply_labour
  );

  useEffect(() => {
    register("date");
    setValue(
      "date",
      moment(selectedDocument.date).format("YYYY-MM-DD").toString()
    );
  }, [register, setValue, selectedDocument]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const onDrop = (photo) => {
    setPhotos(photo);
  };

  const onUpdate = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the Toolbox Meeting.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        if (photos.length > 0) {
          let tempImg = [];
          for (let i = 0; i < photos.length; i++) {
            tempImg.push(await ImageUtils.resizeImage(photos[i]));
          }
          if (tempImg.length > 0) {
            data.base64image = tempImg;
          }
        }

        dispatch(updateDocumentation(selectedDocument.id, data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>

        <div className="flex">
          <div className="w-1/3">
            <Label name="Safety" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="safety"
              type="number"
              value={safety}
              onChange={(e) => setSafety(e.target.value)}
              required
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="Supervisor" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="supervisor"
              type="number"
              value={supervisor}
              onChange={(e) => setSupervisor(e.target.value)}
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Worker" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="worker"
              type="number"
              value={worker}
              onChange={(e) => setWorker(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="Subcon" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subcon"
              type="number"
              value={subcon}
              onChange={(e) => setSubcon(e.target.value)}
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Supply Labour" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="supply_labour"
              type="number"
              value={supplyLabour}
              onChange={(e) => setSupplyLabour(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="flex">
          <div className="w-1/3 mt-4">
            <Label name="Total" />
            <p className="text-sm font-bold">
              {parseInt(safety || 0) +
                parseInt(supervisor || 0) +
                parseInt(worker || 0) +
                parseInt(subcon || 0) +
                parseInt(supplyLabour || 0)}
            </p>
          </div>
        </div>

        <div className="mt-4">
          <Label name="Photos" />
          {selectedDocument && selectedDocument.files && (
            <SRLWrapper>
              <div className="flex">
                {selectedDocument.files.map((attachment, index) => (
                  <img
                    alt={`attachment-${index}`}
                    className="border-none p-4"
                    src={attachment.full_path}
                    style={{
                      width: "256px",
                      height: "256px",
                    }}
                  />
                ))}
              </div>
            </SRLWrapper>
          )}
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>

      <Button text="Update" success="true" type="submit" />
      <Button
        text="Close"
        primary="true"
        // disabled={isFormLoading}
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
