import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Fieldset, Label, Pagination, Spinner, Tab } from "../common";
import Select from "react-select";
import classNames from "classnames";

import {
  getEquipments,
  selectEquipment,
  getEquipmentFilters,
} from "../../actions/equipments";

import EquipmentRequestPage from "./EquipmentRequestPage";
import EquipmentScrappedPage from "./EquipmentScrappedPage";
import EquipmentServicePage from "./EquipmentServicePage";
import EquipmentCategoryPage from "./EquipmentCategoryPage";
import EquipmentForm from "./EquipmentForm";

export default function EquipmentPage(props) {
  const dispatch = useDispatch();
  const equipments = useSelector((state) => state.equipments.equipments);
  const equipmentsLoading = useSelector(
    (state) => state.equipments.equipmentsLoading
  );
  const equipmentsPagination = useSelector(
    (state) => state.equipments.pagination
  );
  const selectedEquipment = useSelector(
    (state) => state.equipments.selectedEquipment
  );

  const projects = useSelector((state) => state.equipments.filters.projects);
  const companies = useSelector((state) => state.equipments.filters.companies);
  const categories = useSelector(
    (state) => state.equipments.filters.categories
  );

  const [activeTab, setActiveTab] = useState("List");

  //FILTERS
  const [equipmentSearchTerm, setEquipmentSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "All",
  });
  const [selectedProject, setSelectedProject] = useState({
    value: "",
    label: "All",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    value: "",
    label: "All",
  });
  const [selectedCondition, setSelectedCondition] = useState({
    value: "",
    label: "All",
  });

  const [pageNumber, setPageNumber] = useState(1);

  let projectsDropdown = [{ value: "", label: "All" }];
  let categoriesDropdown = [{ value: "", label: "All" }];
  let conditionsDropdown = [
    { value: "", label: "All" },
    { value: "Good", label: "Good" },
    { value: "Poor", label: "Poor" },
    { value: "For Repair", label: "For Repair" },
    { value: "Scrap", label: "Scrap" },
  ];
  let companiesDropdown = [{ value: "", label: "All" }];

  for (let i in projects) {
    let projectDropdownObj = {
      value: projects[i].id,
      label: projects[i].title,
    };

    projectsDropdown.push(projectDropdownObj);
  }

  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  useEffect(() => {
    dispatch(getEquipmentFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getEquipments(
        equipmentSearchTerm,
        pageNumber,
        selectedCompany.value,
        selectedProject.value,
        selectedCondition.value,
        selectedCategory.value
      )
    );
  }, [
    dispatch,
    equipmentSearchTerm,
    pageNumber,
    selectedCompany,
    selectedProject,
    selectedCondition,
    selectedCategory,
  ]);

  return (
    <div className="m-12">
      <Fieldset>
        <Tab>
          <Tab.Item
            name="List"
            isActive={activeTab === "List"}
            onTabClick={() => {
              setActiveTab("List");
            }}
          />
          <Tab.Item
            name="Requests"
            isActive={activeTab === "Requests"}
            onTabClick={() => {
              setActiveTab("Requests");
            }}
          />
          <Tab.Item
            name="Service"
            isActive={activeTab === "Service"}
            onTabClick={() => {
              setActiveTab("Service");
            }}
          />
          <Tab.Item
            name="Scrapped"
            isActive={activeTab === "Scrapped"}
            onTabClick={() => {
              setActiveTab("Scrapped");
            }}
          />
          <Tab.Item
            name="Categories"
            isActive={activeTab === "Categories"}
            onTabClick={() => {
              setActiveTab("Categories");
            }}
          />
        </Tab>
      </Fieldset>

      {activeTab === "List" && (
        <div className="flex">
          <div className="w-2/3">
            <Fieldset>
              <div className="mb-4">
                <div className="flex mt-4 px-4">
                  <div className="w-1/2 px-2">
                    <Label name="Category" />
                    <Select
                      options={categoriesDropdown}
                      value={selectedCategory}
                      onChange={setSelectedCategory}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label name="Company" />
                    <Select
                      options={companiesDropdown}
                      value={selectedCompany}
                      onChange={setSelectedCompany}
                    />
                  </div>
                </div>

                <div className="flex mt-4 px-4">
                  <div className="w-1/2 px-2">
                    <Label name="Project" />
                    <Select
                      options={projectsDropdown}
                      value={selectedProject}
                      onChange={setSelectedProject}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label name="Condition" />
                    <Select
                      options={conditionsDropdown}
                      value={selectedCondition}
                      onChange={setSelectedCondition}
                    />
                  </div>
                </div>

                <div className="flex mt-4 px-4">
                  <div className="w-1/2 px-2">
                    <Label name="Search" />
                    <input
                      className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="off"
                      value={equipmentSearchTerm}
                      onChange={(e) => setEquipmentSearchTerm(e.target.value)}
                      type="text"
                      placeholder="Search for Serial #, Description or Brand..."
                    />
                  </div>
                </div>
              </div>

              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Brand</th>
                    <th className="text-xs px-4 py-2">Description</th>
                    <th className="text-xs px-4 py-2">Company</th>
                    <th className="text-xs px-4 py-2">S/N</th>
                    <th className="text-xs px-4 py-2">Purchase Date</th>
                    <th className="text-xs px-4 py-2">Expiry Date</th>
                    <th className="text-xs px-4 py-2">Condition</th>
                    <th className="text-xs px-4 py-2">Project</th>
                    <th className="text-xs px-4 py-2">Issued To</th>
                  </tr>
                </thead>
                <tbody>
                  {!equipmentsLoading &&
                    equipments.map((equipment) => (
                      <tr
                        key={equipment.id}
                        className={classNames(
                          "hover:bg-gray-200 cursor-pointer",
                          {
                            "text-blue-600 bg-teal-200":
                              (selectedEquipment ? selectedEquipment.id : 0) ===
                              equipment.id,
                          }
                        )}
                        onClick={() => {
                          dispatch(selectEquipment(equipment));
                        }}
                      >
                        <td className="text-xs border px-2 py-2">
                          {equipment.brand}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.description}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.company && equipment.company.name}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.serial_no}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.purchase_date &&
                            moment(equipment.purchase_date)
                              .format("MMM DD Y")
                              .toString()}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.warranty_expiry_date &&
                            moment(equipment.warranty_expiry_date)
                              .format("MMM DD Y")
                              .toString()}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.condition}
                        </td>
                        <td className="text-xs border px-2 py-2">
                          {equipment.project && equipment.project.title}
                        </td>
                        <td className="text-xs border px-2 py-2"></td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {equipmentsLoading && <Spinner />}

              {equipmentsPagination && !equipmentsLoading && (
                <Pagination
                  from={equipmentsPagination.from}
                  to={equipmentsPagination.to}
                  total={equipmentsPagination.total}
                  lastPage={equipmentsPagination.last_page}
                  currentPage={equipmentsPagination.current_page}
                  onPageClick={(page) => {
                    setPageNumber(page);
                  }}
                />
              )}
            </Fieldset>
          </div>

          <div className="w-1/3 px-4">
            <Fieldset>
              <EquipmentForm />
            </Fieldset>
          </div>
        </div>
      )}

      {activeTab === "Requests" && <EquipmentRequestPage />}
      {activeTab === "Scrapped" && <EquipmentScrappedPage />}
      {activeTab === "Service" && <EquipmentServicePage />}
      {activeTab === "Categories" && <EquipmentCategoryPage />}
    </div>
  );
}
