import React, { useState, useRef } from "react";
import { Label, Button } from "../common";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function MyTasksUploadInspection(props) {
  const fileInputRef = useRef();
  const [inspectedDate, setInspectedDate] = useState(null);

  // Inspection Fields
  const [selectedFile, setSelectedFile] = useState(null);
  const [inspectedRefNo, setInspectedRefNo] = useState("");
  const [inspectedLocation, setInspectedLocation] = useState("");
  const [inspectedDescription, setInspectedDescription] = useState("");
  const [acceptable, setAcceptable] = useState("Yes");

  const handleInspectedDateChange = (date) => {
    setInspectedDate(date);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onSubmit = () => {
    //save
    let payload = {
      type: "elect",
      date: moment(inspectedDate).format("YYYY-MM-DD").toString(),
      ref_no: inspectedRefNo,
      location: inspectedLocation,
      acceptable,
      description: inspectedDescription,
      files: [selectedFile],
    };
    props.onInspectionSubmit && props.onInspectionSubmit(payload);
  };

  return (
    <div>
      <Label name="Inspection Date" />
      <DatePicker
        showYearDropdown
        selected={inspectedDate}
        dateFormat="yyyy-MM-dd"
        onChange={handleInspectedDateChange}
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
      />

      <Label name="Elect No." />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        autoComplete="off"
        name="ref_no"
        type="text"
        value={inspectedRefNo}
        onChange={(e) => setInspectedRefNo(e.target.value)}
      />

      <Label name="Location" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        autoComplete="off"
        name="location"
        type="text"
        value={inspectedLocation}
        onChange={(e) => setInspectedLocation(e.target.value)}
      />

      <Label name="Description" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
        autoComplete="off"
        name="description"
        type="text"
        value={inspectedDescription}
        onChange={(e) => setInspectedDescription(e.target.value)}
      />

      <Label name="Acceptable?" />

      <div className="mb-4">
        <input
          type="radio"
          name="acceptable"
          value="Yes"
          className="h-5 w-5 text-gray-600"
          defaultChecked
          onChange={() => setAcceptable("Yes")}
        />
        <span className="ml-2 mt-2 mr-2 text-gray-700 text-xs">Yes</span>

        <input
          type="radio"
          name="acceptable"
          value="No"
          className="h-5 w-5 text-gray-600"
          onChange={() => setAcceptable("No")}
        />
        <span className="ml-2 mt-2 mr-2 text-gray-700 text-xs">No</span>
      </div>

      <Label name="Add File" />
      <input
        ref={fileInputRef}
        type="file"
        onChange={(e) => {
          handleFileChange(e);
        }}
      />

      <Button
        text="Submit Inspection"
        success="true"
        type="button"
        onClick={() => onSubmit()}
      />
    </div>
  );
}
