import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ProfileCard, Label, Button } from "components/common";
import { updateUser, deleteUser } from "../../actions/users";
import swal from "sweetalert";

export default function UserDetail(props) {
  const { register, handleSubmit, watch, errors } = useForm();
  const dispatch = useDispatch();
  const selectedUser = useSelector((state) => state.users.selectedUser);

  const onSubmit = (data) => {
    const { id } = selectedUser;
    dispatch(updateUser(id, data));
  };

  const onDelete = () => {
    swal({
      title: "Are you sure?",
      text: "Confirm to delete this credential of employee??",
      dangerMode: true,
      icon: "error",
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteUser(selectedUser.id));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        User Details
      </h1>

      <div>
        {selectedUser.employee && (
          <ProfileCard
            image={
              selectedUser.employee.avatar
                ? selectedUser.employee.avatar.image
                : null
            }
            headerText={`${selectedUser.employee.first_name} ${selectedUser.employee.last_name}`}
            subHeaderText={`${selectedUser.username}`}
          />
        )}

        <div>
          <div className="flex mt-4 px-4 justify-center">
            <div className="w-1/2 px-2">
              <Label name="Username" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register({ required: true })}
                key={selectedUser.username}
                defaultValue={selectedUser.username}
                name="username"
                type="text"
              />
            </div>
          </div>

          {errors && errors.username && (
            <div className="flex mt-1 px-4 justify-center">
              <span className="text-center text-red-500 text-sm">
                This field is required
              </span>
            </div>
          )}

          <div className="flex mt-4 px-4 justify-center">
            <div className="w-1/2 px-2">
              <Label name="New Password" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register({
                  required:
                    watch("old_password") && watch("old_password").length > 0,
                })}
                name="password"
                type="password"
              />
            </div>
          </div>

          {errors && errors.password && (
            <div className="flex mt-1 px-4 justify-center">
              <span className="text-center text-red-500 text-sm">
                This field is required
              </span>
            </div>
          )}

          <div className="flex mt-4 px-4 justify-center">
            <div className="w-1/2 px-2">
              <Label name="Confirm New Password" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register({
                  validate: {
                    confirmPassword: (value) => {
                      if (
                        watch("old_password") &&
                        watch("old_password").length > 0
                      ) {
                        if (value === watch("password")) {
                          return true;
                        } else {
                          return false;
                        }
                      }
                      return true;
                    },
                  },
                })}
                name="password_confirmation"
                type="password"
              />
            </div>
          </div>
          {errors && errors.password_confirmation && (
            <div className="flex mt-1 px-4 justify-center">
              <span className="text-center text-red-500 text-sm">
                Passwords did not match.
              </span>
            </div>
          )}
          {/* <div className="flex mt-4 px-4 justify-center">
                <button
                  type="submit"
                  className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-green focus:border-green-700 active:bg-green-700 transition duration-150 ease-in-out"
                >
                  Save changes
                </button>
              </div>
              <div className="flex mt-4 px-4 justify-center">
                <button
                  disabled
                  type="button"
                  className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline-red focus:border-red-700 active:bg-red-700 transition duration-150 ease-in-out"
                >
                  Delete
                </button>
              </div> */}
          <div className="justify-center flex mt-4 px-4">
            <button
              className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save Changes
            </button>

            <Button
              text="Delete"
              danger="true"
              onClick={() => {
                onDelete();
              }}
            />

            <Button
              text="Close"
              primary="true"
              onClick={() => {
                props.onViewClose && props.onViewClose();
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
