import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, Pagination } from "../../common";
import swal from "sweetalert";
import {
  getIssuePoMaterials,
  deleteCommonIssuePoMaterial,
  selectIssuePoMaterial,
  generatePDF,
} from "../../../actions/commonIssuePoMaterials";
import { roles } from "../../../helpers/userRoles";
import CommonMaterialIssuePOAdd from "./CommonMaterialIssuePOAdd";
import CommonMaterialIssuePOEdit from "./CommonMaterialIssuePOEdit";

export default function CommonMaterialIssuePOPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const issuePoMaterials = useSelector(
    (state) => state.commonIssuePoMaterials.issuePoMaterials
  );
  const pagination = useSelector(
    (state) => state.commonIssuePoMaterials.pagination
  );
  const isLoading = useSelector(
    (state) => state.commonIssuePoMaterials.isLoading
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal] = useState(false);

  useEffect(() => {
    dispatch(getIssuePoMaterials(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className="mt-4">
      <div className="m-4">
        <Button
          text={`+ Create Issue Purchase Order`}
          primary="true"
          onClick={() => {
            setAddModal(true);
          }}
        />
      </div>

      {viewModal && (
        <Modal>
          {/* <MaterialRequestOrderView
            onFormClose={() => {
              setViewModal(false);
            }}
          /> */}
        </Modal>
      )}

      {addModal && (
        <Modal>
          <CommonMaterialIssuePOAdd
            onFormSubmit={() => {
              setAddModal(false);
            }}
            onFormClose={() => {
              setAddModal(false);
            }}
            view={props.view}
          />
        </Modal>
      )}

      {editModal && (
        <Modal>
          <CommonMaterialIssuePOEdit
            onFormClose={() => {
              setEditModal(false);
            }}
          />
        </Modal>
      )}

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Date</th>
            <th className="text-xs px-4 py-2">Company</th>
            <th className="text-xs px-4 py-2">Ref No.</th>
            <th className="text-xs px-4 py-2">
              Doc.
              <br />
              Reference
            </th>
            <th className="text-xs px-4 py-2">
              Doc.
              <br />
              Ref No.
            </th>
            <th className="text-xs px-4 py-2">Supplier</th>
            <th className="text-xs px-4 py-2">Ship To</th>
            <th className="text-xs px-4 py-2">Issued By</th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {issuePoMaterials.map((issuePoMaterial) => (
            <tr key={issuePoMaterial.id} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.date}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.company ? issuePoMaterial.company.name : "-"}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.po_no}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.ref}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.ref_no}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.common_supplier ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        <img
                          alt="Common Supplier"
                          className="rounded-full border border-gray-100 object-cover"
                          src={issuePoMaterial.common_supplier.photo_full_url}
                        />
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.common_supplier.name}
                        <br />
                        {issuePoMaterial.common_supplier.contact_person}
                        <br />
                        {issuePoMaterial.common_supplier.contact_number}
                        <br />
                        {issuePoMaterial.common_supplier.address}
                        <br />
                      </span>
                    </div>
                  </>
                ) : (
                  " - "
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.ship_to ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        {issuePoMaterial.ship_to.avatar && (
                          <img
                            alt="Supplier"
                            className="rounded-full border border-gray-100 object-cover"
                            src={issuePoMaterial.ship_to.avatar.image}
                          />
                        )}
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.ship_to.first_name}{" "}
                        {issuePoMaterial.ship_to.last_name}
                        <br />
                        {issuePoMaterial.ship_to.contact_number}
                        <br />
                        {issuePoMaterial.ship_to_address}
                        <br />
                      </span>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {issuePoMaterial.issued_by ? (
                  <>
                    <div className="flex items-center">
                      <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                        {issuePoMaterial.issued_by.avatar && (
                          <img
                            alt="Supplier"
                            className="rounded-full border border-gray-100 object-cover"
                            src={issuePoMaterial.issued_by.avatar.image}
                          />
                        )}
                      </span>
                      <span className="text-xs ml-2">
                        {issuePoMaterial.issued_by.first_name}{" "}
                        {issuePoMaterial.issued_by.last_name}
                        <br />
                        {issuePoMaterial.issued_by.contact_number}
                      </span>
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                <div className="flex">
                  <div className="flex-initial">
                    <Button
                      text={`Generate PDF`}
                      primary="true"
                      onClick={() => {
                        dispatch(generatePDF(issuePoMaterial.id));
                      }}
                    />
                  </div>
                  <div className="flex-initial">
                    {user.employee.role_id === roles.su && (
                      <Button
                        text={`Edit`}
                        warning="true"
                        onClick={() => {
                          dispatch(selectIssuePoMaterial(issuePoMaterial));
                          setEditModal(true);
                        }}
                      />
                    )}
                    {!issuePoMaterial.has_deliveries &&
                      user.employee.role_id === roles.su && (
                        <Button
                          text={`Delete`}
                          danger="true"
                          onClick={() => {
                            swal({
                              icon: "error",
                              title: "Are you sure?",
                              text: `This will delete this Issue Purchase Order.`,
                              buttons: true,
                            }).then(async (willDelete) => {
                              if (willDelete) {
                                dispatch(
                                  deleteCommonIssuePoMaterial(
                                    issuePoMaterial.id
                                  )
                                );
                              }
                            });
                          }}
                        />
                      )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
