import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_TASKS,
  GET_MY_TASKS,
  GET_TASKS_DROPDOWN,
  GET_UNACCEPTED_TASKS,
  ACCEPT_TASK,
  SELECT_TASK,
  SELECT_MAIN_TASK,
  SELECT_MY_TASK,
  GET_SUBTASKS,
  ADD_TASK,
  UPDATE_TASK,
  ASSIGN_TO_TASK,
  UNASSIGN_TO_TASK,
} from "./types";

import { getProjectDetail } from "./projects";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET MY TASKS
export const getMyTasks = () => (dispatch, getState) => {
  dispatch({ type: LOADING_TASKS });
  axios
    .get(BASE_URL + `/employee/assignments/all`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MY_TASKS,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

export const getTasksDropdown = (projectId) => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/project/${projectId}/tasks`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TASKS_DROPDOWN,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

export const addTask = (mainType, projectID, task) => (dispatch, getState) => {
  dispatch({ type: LOADING_TASKS });
  axios
    .post(BASE_URL + `/task/${projectID}`, task, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_TASK,
        payload: res.data,
      });
      swal({
        title: "Task Added",
        text: "Kindly proceed to assigning Supervisor or Worker on Manpower tab.",
        icon: "success",
      });
      if (mainType === "projects") {
        dispatch(getProjectDetail(projectID));
      } else {
        dispatch(getSubtasks(projectID));
      }
      dispatch(selectTask(res.data.data));
    })
    .catch((err) => console.error(err));
};

export const deleteTask = (id, task, mainType) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/task/${id}`, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Task Deleted",
        icon: "success",
      });
      if (mainType === "projects") {
        dispatch(getProjectDetail(task.id));
      } else {
        dispatch(getSubtasks(task.id));
      }
      dispatch(selectTask(task));
    })
    .catch((err) => console.error(err));
};

export const updateTask = (id, task, mainType) => (dispatch, getState) => {
  // dispatch({ type: LOADING_TASKS });
  axios
    .put(BASE_URL + `/task/${id}`, task, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_TASK,
        payload: {
          response: res.data.data,
          input: task,
        },
      });
      swal({
        title: "Task Updated!",
        icon: "success",
      });
      // if (mainType === "projects") {
      //   dispatch(getProjectDetail(task.project_id));
      // } else {
      //   dispatch(getSubtasks(id));
      // }
    });
};

export const getUnacceptedTasks = () => (dispatch, getState) => {
  dispatch({ type: LOADING_TASKS });
  axios
    .get(BASE_URL + `/employee/assignments/unaccepted`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_UNACCEPTED_TASKS,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

// GET SUBTASKS
export const getSubtasks = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_TASKS });
  axios
    .get(BASE_URL + `/task/${id}/subtasks`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUBTASKS,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

export const getSubtasksPromise = (id) => (dispatch, getState) => {
  return new Promise(function (resolve, reject) {
    console.log(BASE_URL + `/task/${id}/subtasks`);
    axios
      .get(BASE_URL + `/task/${id}/subtasks`, tokenConfig(getState))
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

// SELECT TASK
export const selectTask = (task) => (dispatch) => {
  dispatch({
    type: SELECT_TASK,
    payload: task,
  });
};

// SELECT MAIN TASK
export const selectMainTask = (task) => (dispatch) => {
  dispatch({
    type: SELECT_MAIN_TASK,
    payload: task,
  });
};

// SELECT MY TASK
export const selectMyTask = (task) => (dispatch) => {
  dispatch({
    type: SELECT_MY_TASK,
    payload: task,
  });
};

// ACCEPT TASK
export const acceptTask = (task_id) => (dispatch, getState) => {
  const data = {
    task_id,
    type: "task",
  };
  dispatch({ type: LOADING_TASKS });
  axios
    .post(
      BASE_URL + `/employee/assignments/accept`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: ACCEPT_TASK,
      });
      swal({
        title: "Accepted Task!",
        icon: "success",
      });
      dispatch(getMyTasks());
      dispatch(getUnacceptedTasks());
    });
};

// ASSIGN TO TASK
export const assignToTask =
  (employee, task_ids, selectedMyTask, assignee = null, pic = null) =>
  (dispatch, getState) => {
    const data = {
      task_ids,
      assignee,
      pic,
    };
    axios
      .post(
        BASE_URL + `/employee/assign/${employee.id}/tasks`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: ASSIGN_TO_TASK,
          payload: employee,
        });

        swal({
          title: "Employee Assigned!",
          icon: "success",
        });

        if (selectedMyTask.type === "projects") {
          dispatch(getProjectDetail(selectedMyTask.id));
        } else {
          dispatch(getSubtasks(selectedMyTask.id));
        }
      })
      .catch((err) => console.error(err));
  };

// UNASSIGN TO TASK
export const unassignToTask =
  (employee_id, task_ids, selectedMyTask) => (dispatch, getState) => {
    let data = { task_ids };
    axios
      .post(
        BASE_URL + `/employee/unassign/${employee_id}/tasks`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UNASSIGN_TO_TASK,
          payload: employee_id,
        });

        swal({
          title: "Employee Unassigned!",
          icon: "success",
        });

        if (selectedMyTask.type === "projects") {
          dispatch(getProjectDetail(selectedMyTask.id));
        } else {
          dispatch(getSubtasks(selectedMyTask.id));
        }
      })
      .catch((err) => console.error(err));
  };

export const deleteMapTask = (id, key) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/task/${id}/delete-map`, { key }, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_TASK,
        payload: {
          response: res.data,
          input: res.data,
        },
      });
      swal({
        title: "File Removed",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
