import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Button, EmptyPlaceholder, ProfileCard, Tab } from "../common";
import IdentificationForm from "components/employee/IdentificationForm";
import InformationForm from "components/employee/InformationForm";
import LeaveDetailsForm from "components/employee/LeaveDetailsForm";
import SalaryDetailsForm from "components/employee/SalaryDetailsForm";
import ChangeProfilePicture from "./ChangeProfilePicture";
import { editEmployee } from "../../actions/employees";
import swal from "sweetalert";

export default function EmployeeDetail(props) {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const roles = useSelector((state) => state.roles.roles);

  let companyValues = [];
  let companiesDropdown = [];
  let rolesDropdown = [];

  companiesDropdown.push({ value: 0, label: "All" });
  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  for (let i in companies) {
    let companyValuesObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companyValues.push(companyValuesObj);
  }

  for (let i in roles) {
    let roleDropdownObj = {
      value: roles[i].id,
      label: roles[i].name,
    };

    rolesDropdown.push(roleDropdownObj);
  }

  const [activeTab, setActiveTab] = useState("Information");
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee
  );
  const { register, handleSubmit, setValue, errors, control } = useForm({
    defaultValues: { ...selectedEmployee },
  });

  const onSubmitInformation = async (data) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `Update the Employee Information of ${selectedEmployee.first_name} ${selectedEmployee.last_name}?`,
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const { id, company_id } = selectedEmployee;
        if (activeTab === "Identification") {
          dispatch(
            editEmployee(
              id,
              { ...data },
              {
                employeeSearchTerm: props.employeeSearchTerm,
                pageNumber: props.pageNumber,
                active: "Active",
              }
            )
          );
        } else {
          dispatch(
            editEmployee(
              id,
              { ...data, company_id },
              {
                employeeSearchTerm: props.employeeSearchTerm,
                pageNumber: props.pageNumber,
                active: "Active",
              }
            )
          );
        }
      }
    });
  };

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Employee Details
      </h1>
      {!selectedEmployee ? (
        <EmptyPlaceholder
          text="Select an Employee"
          subText="then we will show the details here."
        />
      ) : (
        <div>
          <ProfileCard
            image={
              selectedEmployee.avatar ? selectedEmployee.avatar.image : null
            }
            headerText={`${selectedEmployee.first_name} ${selectedEmployee.last_name}`}
            subHeaderText={`${selectedEmployee.unique_id} - ${selectedEmployee.employee_role.name}`}
            status={selectedEmployee.employee_status}
          />

          <Tab>
            <Tab.Item
              name="Information"
              isActive={activeTab === "Information"}
              onTabClick={() => setActiveTab("Information")}
            />
            <Tab.Item
              name="Identification"
              isActive={activeTab === "Identification"}
              onTabClick={() => setActiveTab("Identification")}
            />
            <Tab.Item
              name="Salary Details"
              isActive={activeTab === "Salary Details"}
              onTabClick={() => setActiveTab("Salary Details")}
            />
            <Tab.Item
              name="Leave Details"
              isActive={activeTab === "Leave Details"}
              onTabClick={() => setActiveTab("Leave Details")}
            />
            <Tab.Item
              name="Change Profile Picture"
              isActive={activeTab === "Change Profile Picture"}
              onTabClick={() => setActiveTab("Change Profile Picture")}
            />
          </Tab>
          <form onSubmit={handleSubmit(onSubmitInformation)}>
            <InformationForm
              active={activeTab === "Information"}
              selectedEmployee={selectedEmployee}
              Controller={Controller}
              control={control}
              register={register}
              errors={errors}
              setValue={setValue}
            />
            <IdentificationForm
              active={activeTab === "Identification"}
              selectedEmployee={selectedEmployee}
              companiesDropdown={companyValues}
              rolesDropdown={rolesDropdown}
              Controller={Controller}
              control={control}
              register={register}
              setValue={setValue}
            />
            <SalaryDetailsForm
              active={activeTab === "Salary Details"}
              selectedEmployee={selectedEmployee}
              Controller={Controller}
              control={control}
              register={register}
              errors={errors}
            />
            <LeaveDetailsForm
              active={activeTab === "Leave Details"}
              selectedEmployee={selectedEmployee}
              Controller={Controller}
              control={control}
              register={register}
              errors={errors}
              onViewClose={() => {
                props.onViewClose && props.onViewClose();
              }}
            />
            {activeTab === "Change Profile Picture" && (
              <ChangeProfilePicture
                onViewClose={() => {
                  props.onViewClose && props.onViewClose();
                }}
              />
            )}

            {activeTab !== "Leave Details" &&
              activeTab !== "Change Profile Picture" && (
                <div className="justify-center flex mt-4 px-4">
                  <button
                    className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    Save Changes
                  </button>

                  <Button
                    text="Close"
                    primary="true"
                    onClick={() => {
                      props.onViewClose && props.onViewClose();
                    }}
                  />
                </div>
              )}
          </form>
        </div>
      )}
    </div>
  );
}
