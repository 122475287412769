import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Button, Label } from "../common";

import {
  addTaskCategory,
  updateTaskCategory,
  deleteTaskCategory,
  selectTaskCategory,
} from "../../actions/taskCategories";
import swal from "sweetalert";

export default function TaskCategoryForm(props) {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const selectedTaskCategory = useSelector(
    (state) => state.taskCategories.selectedTaskCategory
  );
  const onSubmit = (data, e) => {
    if (selectedTaskCategory) {
      dispatch(updateTaskCategory(selectedTaskCategory.id, data));
    } else {
      dispatch(addTaskCategory(data));
      e.target.reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedTaskCategory
          ? `Task Category - ${selectedTaskCategory.name}`
          : `Add Task Category`}
      </h1>

      <Label name="Name" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={selectedTaskCategory ? selectedTaskCategory.name : "name"}
        defaultValue={selectedTaskCategory ? selectedTaskCategory.name : ""}
        name="name"
        type="text"
      />

      <Label name="Level (0 being the highest)" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={selectedTaskCategory ? selectedTaskCategory.level : "level"}
        defaultValue={selectedTaskCategory ? selectedTaskCategory.level : ""}
        name="level"
        type="number"
      />

      {selectedTaskCategory ? (
        <div className="flex">
          <Button text="Update" success="true" type="submit" />
          <Button
            onClick={() => {
              swal({
                icon: "warning",
                title: "Are you sure?",
                text: `Once deleted, it will label equipments assigned as "Uncategorized"`,
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  dispatch(deleteTaskCategory(selectedTaskCategory.id));
                  selectTaskCategory(null);
                  reset();
                }
              });
            }}
            danger
            text="Delete"
          />
        </div>
      ) : (
        <Button text="Save" success="true" type="submit" />
      )}
    </form>
  );
}
