export const toolListColumns = [
  {
    name: "Brand",
  },
  {
    name: "Description",
  },
  {
    name: "Company",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Warranty Expiry Date",
  },
  {
    name: "Condition",
  },
  {
    name: "Project",
  },
  {
    name: "Issued To",
  },
];

export const toolMovementColumns = [
  {
    name: "No.",
  },
  {
    name: "Date",
  },
  {
    name: "Type",
  },
  {
    name: "Condition",
  },
];

export const toolListRequestColumns = [
  {
    name: "Date Requested",
  },
  {
    name: "Project",
  },
  {
    name: "Requested By",
  },
  {
    name: "Request Tools By",
  },
  {
    name: "Items",
  },
  {
    name: "Comments",
  },
];

export const toolListDeliveryColumns = [
  {
    name: "Date Issued",
  },
  {
    name: "To",
  },
  {
    name: "Project",
  },
  {
    name: "Driver",
  },
  {
    name: "Items",
  },
];

export const toolDeliveryRequestedToolsColumns = [
  {
    name: "Brand",
  },
  {
    name: "Description",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Warranty Expiry Date",
  },
  {
    name: "Condition",
  },
];

export const toolRequestRequestedToolsColumns = [
  {
    name: "Brand",
  },
  {
    name: "Description",
  },
  {
    name: "Qty",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Warranty Expiry Date",
  },
  {
    name: "Condition",
  },
];

export const toolRequestRequestedSNToolsColumns = [
  {
    name: "Brand",
  },
  {
    name: "Description",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Warranty Expiry Date",
  },
  {
    name: "Condition",
  },
];

export const toolListScrappedColumns = [
  {
    name: "Tool",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Company",
  },
  {
    name: "Date Scrapped",
  },
  {
    name: "Scrapped to",
  },
  {
    name: "Attachment",
  },
];

export const toolListServiceColumns = [
  {
    name: "Date",
  },
  {
    name: "Sent By",
  },
  {
    name: "Serviced To",
  },
  {
    name: "Tool Serviced",
  },
  {
    name: "S/N",
  },
  {
    name: "Issue",
  },
];

export const toolServiceServicedToolColumns = [
  {
    name: "Tool",
  },
  {
    name: "S/N",
  },
  {
    name: "Purchase Date",
  },
  {
    name: "Warranty Expiry Date",
  },
];

export const toolListCategoriesColumns = [
  {
    name: "Name",
  },
];
