import React, { useState } from "react";

import { Tab } from "../common";

import MyTasksSpecialisedMaterialList from "./MyTasksSpecialisedMaterialList";
import MaterialBulkPOPage from "../materials/MaterialBulkPOPage";
import MaterialRequestOrderPage from "../materials/MaterialRequestOrderPage";
import MaterialIssuePOPage from "../materials/MaterialIssuePOPage";
import MaterialWithdrawalPage from "components/materials/MaterialWithdrawalPage";

export default function MyTasksMaterials(props) {
  const selectedTask = props.selectedTask;
  const [activeTab, setActiveTab] = useState("List");

  // const [selectedMaterialType, setSelectedMaterialType] = useState({
  //   value: "common",
  //   label: "Common",
  // });

  // let reportTypesDropdown = [
  //   { value: "common", label: "Common Materials" },
  //   { value: "specialised", label: "Specialised Materials" },
  // ];

  return (
    <div>
      {/* <div className="float-right">
        <Button text="Create Bulk PO" success="true" type="submit" />
      </div> */}

      <h1 className="font-bold">Materials</h1>

      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      {/* <div className="mb-4">
        <div className="flex">
          <div className="flex-1">
            <Label name="Material Type" />
            <Select
              options={reportTypesDropdown}
              value={selectedMaterialType}
              onChange={setSelectedMaterialType}
              className="text-xs"
            />
          </div>
        </div>
      </div> */}

      <Tab>
        <Tab.Item
          name="List"
          isActive={activeTab === "List"}
          onTabClick={() => {
            setActiveTab("List");
          }}
        />
        <Tab.Item
          name="Withdrawals"
          isActive={activeTab === "Withdrawals"}
          onTabClick={() => {
            setActiveTab("Withdrawals");
          }}
        />
        {/* <Tab.Item
          name="Bulk POs"
          isActive={activeTab === "Bulk POs"}
          onTabClick={() => {
            setActiveTab("Bulk POs");
          }}
        /> */}

        <Tab.Item
          name="Request Orders"
          isActive={activeTab === "Request Orders"}
          onTabClick={() => {
            setActiveTab("Request Orders");
          }}
        />

        {/* <Tab.Item
          name="Issue POs"
          isActive={activeTab === "Issue POs"}
          onTabClick={() => {
            setActiveTab("Issue POs");
          }}
        />
        <Tab.Item
          name="Deliveries"
          isActive={activeTab === "Deliveries"}
          onTabClick={() => {
            setActiveTab("Deliveries");
          }}
        /> */}

        {/* {selectedMaterialType.value === "common" && (
          <Tab.Item
            name="Requests"
            isActive={activeTab === "Requests"}
            onTabClick={() => {
              setActiveTab("Requests");
            }}
          />
        )} */}
      </Tab>
      {activeTab === "List" && <MyTasksSpecialisedMaterialList />}
      {activeTab === "Withdrawals" && <MaterialWithdrawalPage />}
      {activeTab === "Bulk POs" && <MaterialBulkPOPage view="my-tasks" />}
      {activeTab === "Request Orders" && (
        <MaterialRequestOrderPage view="my-tasks" />
      )}
      {activeTab === "Issue POs" && <MaterialIssuePOPage view="my-tasks" />}
    </div>
  );
}
