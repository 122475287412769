import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: "400px",
  height: "400px",
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function Maps(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const [center, setCenter] = useState({
    lat: Number(1.3751590564618008),
    lng: Number(103.86125681179708),
  });

  useEffect(() => {
    setCenter({
      lat: Number(props.lat),
      lng: Number(props.lng),
    });
  }, [props.lat, props.lng]);

  return isLoaded ? (
    <GoogleMap center={center} mapContainerStyle={containerStyle} zoom={15}>
      <Marker position={center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Maps;
