export default function (employees) {
  for (let i in employees) {
    employees[i].in = null;
    employees[i].out = null;
    employees[i].otIn = null;
    employees[i].otOut = null;
    employees[i].hours = 0;

    for (let j in employees[i].attendance) {
      if (employees[i].attendance[j].mode === "in") {
        employees[i].in = employees[i].attendance[j];
      }
      if (employees[i].attendance[j].mode === "out") {
        employees[i].out = employees[i].attendance[j];
        employees[i].hours = employees[i].attendance[j].hours;
      }
    }
  }

  return employees;
}
