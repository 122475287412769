import React, { useState } from "react";
import Popper from "popper.js";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "../common";
import ChangeProfilePicture from "../employee/ChangeProfilePicture";
import { selectEmployee } from "actions/employees";
import { logout } from "../../actions/auth";

const UserDropdown = () => {
  // dropdown props
  const user = useSelector((state) => state.auth.user);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    new Popper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);

  return (
    <>
      {addModal && (
        <Modal>
          <ChangeProfilePicture
            onPictureSubmit={() => {
              setAddModal(false);
              dispatch(selectEmployee(null));
            }}
            onViewClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}
      <a
        className="text-gray-600 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            {user.employee.avatar ? (
              <img
                alt="..."
                className="w-full rounded-full align-middle border-none shadow-lg"
                src={user.employee.avatar.image}
              />
            ) : (
              <img
                src={require("../../assets/img/silhouette.png")}
                alt="..."
                className="w-full rounded-full align-middle border-none shadow-lg"
              />
            )}
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        <button
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={() => {
            dispatch(selectEmployee(user.employee));
            setAddModal(true);
          }}
        >
          Update Profile Picture
        </button>
        <button
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={() => {
            dispatch(logout());
          }}
        >
          Logout
        </button>
        {/* <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Another action
        </a>
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Something else here
        </a>
        <div className="h-0 my-2 border border-solid border-gray-200" />
        <a
          href="#pablo"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
          }
          onClick={(e) => e.preventDefault()}
        >
          Seprated link
        </a> */}
      </div>
    </>
  );
};

export default UserDropdown;
