import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, Spinner } from "../common";
import { SRLWrapper } from "simple-react-lightbox";
import { roles } from "../../helpers/userRoles";
import swal from "sweetalert";
import {
  generatePDF,
  selectDocumentation,
  approveDailyProgressReport,
  rejectDailyProgressReport,
  deletedDailyProgressReport,
} from "../../actions/documentations";
import MaterialIcon from "../../assets/img/material_icon.png";

export default function ViewDailyProgressReport(props) {
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const [comments, setComments] = useState("");
  const user = useSelector((state) => state.auth.user);
  const isFormLoading = useSelector(
    (state) => state.documentations.isFormLoading
  );

  return (
    <div>
      {isFormLoading ? (
        <div className="items-center">
          <Spinner />
          <p className="text-center text-sm m-4">
            Please wait while we generate the PDF...
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1" style={{ marginLeft: -10 }}>
          <div>
            <button
              onClick={() => {
                dispatch(generatePDF(selectedDocument, "daily_report"));
              }}
              className="bg-blue-500 hover:bg-blue-700 text-xs text-white m-2 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Generate PDF
            </button>
            <Button
              text="Edit"
              primary="true"
              onClick={() => {
                props.onEditClick && props.onEditClick();
              }}
            />
          </div>
        </div>
      )}
      <hr />
      <div className="shadow-md">
        <div
          className="grid grid-cols-2 gap-4"
          style={{
            paddingTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
          }}
        >
          <div>
            <Label name="Location" />
            <p className="text-sm mb-4">{selectedDocument.location}</p>
          </div>
          <div>
            <Label name="Activity" />
            <p className="text-sm mb-4">{selectedDocument.activity}</p>
          </div>
          <div>
            <Label name="Accomplishments" />
            <p className="text-sm mb-4">{selectedDocument.accomplishments}</p>
          </div>
          <div>
            <Label name="Drawing No." />
            <p className="text-sm mb-4">{selectedDocument.drawing_no}</p>
          </div>
          <div>
            <Label name="Gridline" />
            <p className="text-sm mb-4">{selectedDocument.gridline}</p>
          </div>
          <div>
            <Label name="Hours" />
            <p className="text-sm mb-4">{selectedDocument.hours}</p>
          </div>
          <div>
            <Label name="Manpower" />
            <p className="text-sm mb-4">{selectedDocument.manpower}</p>
          </div>
          <div>
            <Label name="Approved By" />
            <p className="text-sm mb-4">
              {selectedDocument && selectedDocument.approved_by
                ? `${selectedDocument.approved_by.first_name} ${selectedDocument.approved_by.last_name}`
                : ""}
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="shadow-md">
        <div style={{ padding: 20 }}>
          <div>
            <Label name="Task Hierarchy" />
            <p className="text-sm mb-4">{selectedDocument.task_heirarchy}</p>
          </div>
        </div>
      </div>
      <br />
      <Label name="Photos" />
      <p className="text-gray-600 text-xs mb-4">Click photo to enlarge.</p>

      {selectedDocument && selectedDocument.attachments ? (
        <SRLWrapper>
          <div className="flex">
            {selectedDocument.attachments.map((attachment, index) => (
              <img
                alt={`attachment-${index}`}
                className="border-none p-4"
                src={attachment.image_full_url}
                style={{
                  width: "256px",
                  height: "256px",
                }}
              />
            ))}
          </div>
        </SRLWrapper>
      ) : (
        <p className="text-gray-600 text-xs">No attachments.</p>
      )}

      <Label name="Materials" />
      <p className="text-gray-600 text-xs mb-4">
        Materials used for this report.
      </p>

      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Photo</th>
            <th className="text-xs px-4 py-2">Type</th>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
            <th className="text-xs px-4 py-2">Used Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectedDocument.materials &&
            selectedDocument.materials.map((material, index) => (
              <tr key={index} className="hover:bg-gray-200">
                <td className="text-xs border px-2 py-2">
                  <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                    {material.photo_full_url ? (
                      <img
                        alt="..."
                        className="w-full align-middle border-none"
                        src={material.photo_full_url}
                      />
                    ) : (
                      <img
                        alt="..."
                        className="w-full align-middle border-none"
                        src={MaterialIcon}
                      />
                    )}
                  </span>
                </td>
                <td className="text-xs border px-2 py-2">{material.type}</td>
                <td className="text-xs border px-2 py-2">
                  {material.category?.name}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                <td className="text-xs border px-2 py-2">
                  {material.pivot.report_qty}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <Label name="Materials Comments (other used materials)" />
      <textarea
        className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600 mb-4"
        style={{ border: "1px solid #ccc" }}
        rows={5}
        readOnly={true}
        key={selectedDocument.material_comments}
        defaultValue={selectedDocument.material_comments}
        name="material_comments"
      />

      <Label name="Actions" />

      <div className="h-64 box border rounded flex flex-col shadow bg-white mb-4">
        <div className="bg-gray-400 px-3 py-2 border-b">
          <h3 className="text-sm text-gray-900 font-medium">Comments</h3>
        </div>
        <textarea
          className="text-xs text-gray-900 flex-1 p-2 m-1 bg-transparent"
          key={`daily_progress_report_comments`}
          value={comments ? comments : ""}
          onChange={(e) => {
            setComments(e.target.value);
          }}
        />
      </div>
      <div className="float-right">
        <Button
          text="Delete"
          danger="true"
          onClick={() => {
            swal({
              icon: "warning",
              title: "Are you sure?",
              text: `Once deleted, selected daily progress report will be removed.`,
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                dispatch(deletedDailyProgressReport(selectedDocument.id));
                props.onViewClose && props.onViewClose();
                selectDocumentation(null);
              }
            });
          }}
        />
      </div>

      <div className="flex">
        {user.employee.role_id !== roles.sup &&
          selectedDocument.is_submitted === 0 && (
            <div>
              <Button
                text="Approve"
                success="true"
                disabled={isFormLoading}
                onClick={() => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `This will set the Daily Progress Report as "Submitted".`,
                    buttons: true,
                  }).then((willApprove) => {
                    if (willApprove) {
                      dispatch(
                        approveDailyProgressReport(
                          selectedDocument.id,
                          comments
                        )
                      );
                    }
                  });
                }}
              />
              <Button
                text="Reject"
                danger="true"
                disabled={isFormLoading}
                onClick={() => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `This will set the Daily Progress Report as "Rejected".`,
                    buttons: true,
                  }).then((willReject) => {
                    if (willReject) {
                      dispatch(
                        rejectDailyProgressReport(selectedDocument.id, comments)
                      );
                    }
                  });
                }}
              />

              {selectedDocument.is_submitted === 1 && (
                <p className="text-gray-600 text-xs mt-4">
                  Report already approved..
                </p>
              )}
            </div>
          )}
        <Button
          text="Close"
          primary="true"
          disabled={isFormLoading}
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
