import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_SUPPLIERS,
  ADD_SUPPLIER,
  UPDATE_SUPPLIER,
  SELECT_SUPPLIER,
  DELETE_SUPPLIER,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET SUPPLIERS
export const getSuppliers = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/material-suppliers`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUPPLIERS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// ADD SUPPLIER
export const addSupplier = (supplier) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/material-suppliers`, supplier, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_SUPPLIER,
        payload: res.data,
      });
      swal({
        title: "Supplier Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE SUPPLIER
export const updateSupplier = (id, supplier) => (dispatch, getState) => {
  axios
    .put(
      BASE_URL + `/material-suppliers/${id}`,
      supplier,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_SUPPLIER,
        payload: res.data,
      });
      swal({
        title: "Supplier Updated",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// SELECT SUPPLIER
export const selectSupplier = (supplier) => (dispatch) => {
  dispatch({
    type: SELECT_SUPPLIER,
    payload: supplier,
  });
};

// DELETE SUPPLIER
export const deleteSupplier = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/material-suppliers/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_SUPPLIER,
        payload: id,
      });
      swal({
        title: "Supplier Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
