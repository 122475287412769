import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button, Attachment, Avatar, Pagination } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { updateBulkPoMaterial } from "../../actions/bulkPoMaterials";
import {
  updateMaterial,
  deleteMaterial,
  searchMaterials,
} from "../../actions/materials";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import { getCompanies } from "../../actions/companies";
import { getSuppliers } from "../../actions/suppliers";
import { useTransformOption } from "../../helpers/useTransformData";
import "react-datepicker/dist/react-datepicker.css";

export default function MaterialBulkPOEdit(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const selectedBulkPO = useSelector((state) => state.bulkPoMaterials.selected);
  const companiesDropdown = useTransformOption(companies);
  const suppliersDropdown = useTransformOption(suppliers);
  const [contractDate, setContractDate] = useState(
    selectedBulkPO.contract_date
      ? new Date(selectedBulkPO.contract_date)
      : new Date()
  );
  const [materials, setMaterials] = useState(
    selectedBulkPO.materials ? selectedBulkPO.materials : []
  );
  const [addedMaterials, setAddedMaterials] = useState([]);

  const fileInputRef = useRef();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);

  // For paginated materials
  const [currentMaterials, setCurrentMaterials] = useState([]);

  // New Material Fields
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [bulk_qty, setBulkQty] = useState("");

  // Material fields
  //   const [selectedCategory, setSelectedCategory] = useState(null);
  //   const [code, setCode] = useState("");
  //   const [description, setDescription] = useState("");
  //   const [unit, setUnit] = useState("");
  //   const [price, setPrice] = useState("");
  //   const [bulk_qty, setBulkQty] = useState("");
  //   const [warehouse, setWarehouse] = useState("");

  //Register fields that can't be captured by react-hook-form.
  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getSuppliers());
    // Move material.pivot.bulk_qty
    // to material.bulk_qty for update.
    if (selectedBulkPO.materials) {
      let newMaterials = [];
      selectedBulkPO.materials.forEach((m) => {
        let material = {
          ...m,
          bulk_qty: m.pivot.bulk_qty,
        };
        newMaterials.push(material);
      });
      setMaterials(newMaterials);
    }

    register("contract_date");
    setValue(
      "contract_date",
      moment(selectedBulkPO.contract_date).format("YYYY-MM-DD").toString()
    );

    if (selectedBulkPO.company) {
      setSelectedCompany({
        value: selectedBulkPO.company.id,
        label: selectedBulkPO.company.name,
      });
      setValue("company_id", selectedBulkPO.company.id);
    }
  }, [register, setValue, selectedBulkPO, dispatch]);

  useEffect(() => {
    setCurrentMaterials(materials.slice(itemOffset, itemOffset + 5));
  }, [materials, itemOffset]);

  const handleContractDateChange = (date) => {
    setContractDate(date);
    setValue("contract_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setValue("company_id", option.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onUpdateBulkPO = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update Bulk Purchase Order for this Task.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        if (file) {
          data.contract_attachment = file;
        }
        dispatch(updateBulkPoMaterial(selectedBulkPO.id, data));
        props.onFormClose && props.onFormClose();
        e.target.reset();
      }
    });
  };

  /**
   * Existing materials actions
   */

  const handleMaterialChange = (index, event) => {
    const values = [...currentMaterials];
    values[index][event.target.name] = event.target.value;

    setCurrentMaterials(values);
  };

  const updateMaterialEntry = (material) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the material for this Bulk PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(updateMaterial(material.id, material));
      }
    });
  };

  const updateMaterialBulkQty = (material) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the Bulk Qty of this material.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let data = { material };
        dispatch(updateBulkPoMaterial(selectedBulkPO.id, data));
      }
    });
  };

  const addMaterialsToBulkQty = () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add the materials for this Bulk Purchase Order.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let data = { materials: addedMaterials };
        dispatch(updateBulkPoMaterial(selectedBulkPO.id, data));
      }
    });
  };

  const removeMaterialEntry = (index, material) => {
    swal({
      icon: "error",
      title: "Are you sure?",
      text: `This will delete the material for this Bulk PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(deleteMaterial(material.id));
        let values = [...materials];
        values.splice(index, 1);
        setMaterials(values);
      }
    });
  };

  /**
   * Add another materials actions
   */

  const addMaterial = () => {
    if (!selectedMaterial) {
      alert("Please select a material first.");
      return;
    }
    if (!bulk_qty || bulk_qty <= 0) {
      alert("Bulk Quantity Input is required");
      return;
    }
    let material = {
      id: selectedMaterial.material.id,
      type: selectedMaterial.material.type,
      category: selectedMaterial.material.category?.name,
      code: selectedMaterial.material.code,
      description: selectedMaterial.material.description,
      unit: selectedMaterial.material.unit,
      material_id: selectedMaterial.material.id,
      bulk_qty: bulk_qty,
      price: selectedMaterial.material.price,
      warehouse: selectedMaterial.material.warehouse?.name,
    };

    setAddedMaterials([...addedMaterials, material]);
    setSelectedMaterial(null);
    setSelectedSupplier(null);
  };

  const loadMaterialOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchMaterials(inputValue, null, selectedSupplier?.value)).then(
        (res) => {
          resolve(res);
        }
      );
    });

    let options = [];

    for (let i in search) {
      options.push({
        value: search[i].id,
        label: `${search[i].code} - ${search[i].description}`,
        material: search[i],
      });
    }
    callback(options);
  };

  const removeAddedMaterial = (material) => {
    setAddedMaterials(addedMaterials.filter((ma) => ma.id !== material.id));
  };

  const handleSelectedMaterialChange = (option) => {
    setSelectedMaterial(option);
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdateBulkPO)}>
        <h1 className="font-bold">Update Bulk Purchase Order</h1>
        <div className="grid">
          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Company (required)" />
              <Select
                className="text-xs"
                options={companiesDropdown}
                value={selectedCompany}
                onChange={(option) => {
                  handleCompanyChange(option);
                }}
              />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2">
              <Label name="Bulk PO Series No. (Required)" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="series_no"
                type="text"
                key={selectedBulkPO.series_no}
                defaultValue={selectedBulkPO.series_no}
                required
              />
            </div>

            {/* <div className="w-1/2 px-4">
              <Label name="Supplier" />
              <p className="text-xs">
                {" "}
                {selectedBulkPO.supplier ? (
                  <Avatar
                    name={selectedBulkPO.supplier.name}
                    image={selectedBulkPO.supplier.photo_full_url}
                  />
                ) : (
                  " - "
                )}
              </p>
            </div> */}
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Contract Date" />
              <DatePicker
                showYearDropdown
                selected={contractDate}
                dateFormat="yyyy-MM-dd"
                onChange={handleContractDateChange}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                style={{ zIndex: 10 }}
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Contract Number" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="contract_number"
                type="text"
                key={selectedBulkPO.contract_number}
                defaultValue={selectedBulkPO.contract_number}
                required
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Contract Subject" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="contract_subject"
                type="text"
                key={selectedBulkPO.contract_subject}
                defaultValue={selectedBulkPO.contract_subject}
                required
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Contract File" />
              {selectedBulkPO.contract_attachment ? (
                <Attachment
                  path={selectedBulkPO.contract_attachment_full_url}
                />
              ) : (
                <p className="text-gray-600 text-xs">No attachments.</p>
              )}
              <br />
              <p className="text-xs text-gray-600 mb-4">
                note that uploading will overwrite the existing attachment (if
                any).
              </p>
              <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
          </div>
        </div>

        <Button text="Update Purchase Order" success="true" type="submit" />
      </form>

      <div className="mt-4">
        <p className="font-bold text-gray-900 mb-4">
          Update Materials to Bulk Purchase Order
        </p>
        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">Price</th>
              <th className="text-xs px-4 py-2">Bulk Qty</th>
              {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
              <th className="text-xs px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentMaterials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">
                  {material.category ? material.category.name : "Uncategorized"}
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="code"
                    type="text"
                    value={material.code}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="description"
                    type="text"
                    value={material.description}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="unit"
                    type="text"
                    value={material.unit}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <div className="flex">
                    <p className="m-4">$</p>
                    <input
                      className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="off"
                      name="price"
                      type="text"
                      value={material.price}
                      onChange={(event) => handleMaterialChange(index, event)}
                    />
                  </div>
                </td>
                <td className="text-xs border px-2 py-2 flex">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full mb-2 py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="bulk_qty"
                    type="text"
                    value={material.bulk_qty}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-xs text-white py-2 px-2 ml-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => {
                      updateMaterialBulkQty(material);
                    }}
                  >
                    Update Bulk Qty
                  </button>
                </td>
                {/* <td className="text-xs border px-2 py-2">
                  {material.warehouse ? material.warehouse.name : "-"}
                </td> */}
                <td>
                  <div className="flex">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        removeMaterialEntry(index, material);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      className="bg-orange-500 hover:bg-orange-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        updateMaterialEntry(material);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          from={itemOffset + 1}
          to={
            itemOffset + 6 > materials.length
              ? itemOffset + 6
              : materials.length
          }
          total={materials.length}
          lastPage={Math.ceil(materials.length / 5)}
          currentPage={pageNumber}
          onPageClick={(page) => {
            setPageNumber(page);
            setItemOffset(((page - 1) * 5) % materials.length);
          }}
        />
      </div>

      <div className="mt-4 bg-gray-200 p-4 mb-2">
        <p className="text-sm font-bold text-gray-600 mb-4">
          + Add more Materials to Bulk Purchase Order
        </p>
        <div className="flex mb-4">
          <div className="w-1/4">
            <Label name="Supplier" />
            <Select
              className="text-xs"
              options={suppliersDropdown}
              value={selectedSupplier}
              onChange={handleSupplierChange}
            />
          </div>

          <div className="w-2/4 px-4">
            <Label name="Search Code/Description" />
            <AsyncSelect
              loadOptions={loadMaterialOptions}
              onChange={handleSelectedMaterialChange}
              value={selectedMaterial}
              className="text-xs shadow-sm"
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Bulk Qty." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="order_qty"
              type="number"
              value={bulk_qty}
              onChange={(e) => setBulkQty(e.target.value)}
              required
            />
          </div>

          <div className="w-1/4 m-4 px-4">
            <Button
              text={`+ Add Material`}
              primary="true"
              onClick={() => {
                addMaterial();
              }}
            />
          </div>
        </div>

        <table className="table-fixed w-100 mb-4">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Type</th>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
              <th className="text-xs px-4 py-2">Bulk Qty</th>
              <th className="text-xs px-4 py-2">
                <i className="far fa-trash text-gray-500 text-base"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {addedMaterials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">{material.type}</td>
                <td className="text-xs border px-2 py-2">
                  {material.category}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                {/* <td className="text-xs border px-2 py-2">
                  {material.warehouse}
                </td> */}
                <td className="text-xs border px-2 py-2">
                  {material.bulk_qty}
                </td>
                <td>
                  <Button
                    text="-"
                    danger="true"
                    onClick={() => {
                      removeAddedMaterial(material);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mb-2">
          <Button
            text="Submit Added Materials to Bulk PO"
            success="true"
            disabled={addedMaterials.length <= 0}
            onClick={() => {
              addMaterialsToBulkQty();
            }}
          />
        </div>
      </div>

      <Button
        text="Close"
        primary="true"
        onClick={() => {
          props.onFormClose && props.onFormClose();
        }}
      />
    </div>
  );
}
