import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "components/common";
import DatePicker from "react-datepicker";
import moment from "moment";

import { updateEmployeeScreen } from "../../actions/employees";

const Form = (props) => {
  const dispatch = useDispatch();
  const { active, register, selectedEmployee, errors, setValue } = props;
  const [status, setStatus] = useState(selectedEmployee.employee_status);
  const isUpdateEmployeeScreen = useSelector(
    (state) => state.employees.isUpdateEmployeeScreen
  );
  const [enableEditing, setEnableEditing] = useState(false);
  const [birthDate, setBirthDate] = useState(null);

  useEffect(() => {
    register("birthdate");

    if (isUpdateEmployeeScreen === true) {
      setEnableEditing(false);
      dispatch(updateEmployeeScreen());
    }

    selectedEmployee && selectedEmployee.birthdate
      ? setValue(
          "birthdate",
          moment(new Date(selectedEmployee.birthdate))
            .format("Y-MM-DD")
            .toString()
        )
      : setValue("birthdate", null);

    setBirthDate(
      selectedEmployee && selectedEmployee.birthdate
        ? new Date(selectedEmployee.birthdate)
        : null
    );
  }, [dispatch, register, isUpdateEmployeeScreen, selectedEmployee, setValue]);

  const handleBirthDateChange = (date) => {
    setBirthDate(date);
    setValue("birthdate", moment(date).format("Y-MM-DD").toString());
  };

  if (active) {
    const age = moment().diff(selectedEmployee.birthdate, "years");
    return (
      <div>
        <div className="w-1/2" style={{ marginLeft: 18, marginTop: 10 }}>
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name="enable_editing"
              id="toggle"
              checked={enableEditing}
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              onChange={(event) => {
                setEnableEditing(event.currentTarget.checked);
              }}
            />
            <label
              htmlFor="toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label htmlFor="toggle" className="text-xs text-gray-700">
            Enable Editing
          </label>
        </div>
        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="First Name" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.first_name}
              defaultValue={selectedEmployee.first_name}
              name="first_name"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.first_name && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
          <div className="w-1/2 px-2">
            <Label name="Last Name" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.last_name}
              defaultValue={selectedEmployee.last_name}
              name="last_name"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.last_name && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Gender" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.gender}
              defaultValue={selectedEmployee.gender}
              name="gender"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.gender && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
          <div className="w-1/2 px-2">
            <Label name="Civil Status" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.civil_status}
              defaultValue={selectedEmployee.civil_status}
              name="civil_status"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.civil_status && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Nationality" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.nationality}
              defaultValue={selectedEmployee.nationality}
              name="nationality"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.nationality && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
          <div className="w-1/2 px-2">
            <Label name="Employment status" />
            <div className="relative inline-block w-40 mr-2 align-middle select-none transition duration-200 ease-in">
              <select
                name="e_status"
                onChange={(event) => setStatus(event.target.value)}
                className="text-xs border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                disabled={!enableEditing}
              >
                <option
                  value="Active"
                  selected={selectedEmployee.employee_status === "Active"}
                >
                  Active
                </option>
                <option
                  value="Inactive"
                  selected={selectedEmployee.employee_status === "Inactive"}
                >
                  Inactive
                </option>
              </select>
              <input
                autoComplete="off"
                ref={register({ required: true })}
                key={selectedEmployee.employee_status}
                name="employee_status"
                type="hidden"
                value={status}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Contact Number" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.contact_number}
              defaultValue={selectedEmployee.contact_number}
              name="contact_number"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.contact_number && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>

          <div className="w-1/2 px-2">
            <Label name="Emergency Number" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register({ required: true })}
              key={selectedEmployee.emergency_number}
              defaultValue={selectedEmployee.emergency_number}
              name="emergency_number"
              type="text"
              disabled={!enableEditing}
            />
            {errors && errors.emergency_number && (
              <p className="mt-1 text-xs text-red-500">Field is required!</p>
            )}
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Birth Date" />
            <DatePicker
              showYearDropdown
              selected={birthDate}
              ref={register}
              name="birthdate"
              dateFormat="yyyy-MM-dd"
              onChange={handleBirthDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
              disabled={!enableEditing}
            />
          </div>
          <div className="w-1/2 px-2">
            <Label name="Age" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              key={age}
              defaultValue={age}
              name="age"
              type="text"
              readOnly
              disabled={!enableEditing}
            />
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default Form;
