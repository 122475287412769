import React from "react";
import EmptyHuman from "../../assets/img/empty_human.png";

const EmptyPlaceholder = (props) => (
  <div className="flex items-center justify-center">
    <div>
      <img src={EmptyHuman} alt="Empty" className="object-scale-down h-48" />
      <p className="font-bold text-gray-900 text-center">{props.text}</p>
      {props.subText && (
        <p className="text-gray-600 text-xs">{props.subText}</p>
      )}
    </div>
  </div>
);

export default EmptyPlaceholder;
