import {
  SELECT_ATTENDANCE,
  GET_ATTENDANCES,
  LOADING_ATTENDANCES,
  LOADING_ATTENDANCE_DETAILS,
  GET_ATTENDANCE_DETAILS,
} from "../actions/types";

import flattenAttendance from "../helpers/flattenAttendance";

const initialState = {
  attendances: [],
  selectedAttendance: null,
  selectedAttendanceDetails: null,
  pagination: null,
  attendancesLoading: false,
  attendanceDetailsLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ATTENDANCES:
      return {
        ...state,
        attendancesLoading: true,
      };
    case LOADING_ATTENDANCE_DETAILS:
      return {
        ...state,
        attendanceDetailsLoading: true,
      };
    case GET_ATTENDANCES:
      return {
        ...state,
        attendances: flattenAttendance(action.payload),
        pagination: action.pagination,
        attendancesLoading: false,
      };
    case GET_ATTENDANCE_DETAILS:
      return {
        ...state,
        selectedAttendanceDetails: action.payload,
        attendanceDetailsLoading: false,
      };
    case SELECT_ATTENDANCE:
      return {
        ...state,
        selectedAttendance: action.payload,
      };
    default:
      return state;
  }
}
