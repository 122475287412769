import {
  GET_REQUEST_PO_MATERIALS,
  GET_REQUEST_PO_RUNNING_NUMBER,
  LOADING_REQUEST_PO_MATERIALS,
  SELECT_REQUEST_PO_MATERIAL,
  ADD_REQUEST_PO_MATERIAL,
  UPDATE_REQUEST_PO_MATERIAL,
  UPDATE_REQUEST_PO_MATERIAL_STATUS,
  DELETE_REQUEST_PO_MATERIAL,
  SET_LOADING_REQUEST_PO_PDF,
} from "../actions/types";

const initialState = {
  requestPoMaterials: [],
  pagination: null,
  selected: null,
  isLoading: false,
  isPDFLoading: false,
  runningNumber: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_REQUEST_PO_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOADING_REQUEST_PO_PDF:
      return {
        ...state,
        isPDFLoading: action.payload,
      };
    case GET_REQUEST_PO_MATERIALS:
      return {
        ...state,
        requestPoMaterials: action.payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case GET_REQUEST_PO_RUNNING_NUMBER:
      return {
        ...state,
        runningNumber: action.payload,
      };
    case SELECT_REQUEST_PO_MATERIAL:
      return {
        ...state,
        selected: action.payload,
      };
    case ADD_REQUEST_PO_MATERIAL:
      return {
        ...state,
        requestPoMaterials: [...state.requestPoMaterials, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case UPDATE_REQUEST_PO_MATERIAL:
      return {
        ...state,
        selected: action.payload,
        requestPoMaterials: state.requestPoMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    case UPDATE_REQUEST_PO_MATERIAL_STATUS:
      //Same as update reducer
      return {
        ...state,
        requestPoMaterials: state.requestPoMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    case DELETE_REQUEST_PO_MATERIAL:
      return {
        ...state,
        requestPoMaterials: state.requestPoMaterials.filter(
          (material) => material.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
