import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Pagination,
  Tab,
  EmptyPlaceholder,
  Attachment,
} from "../../common";
import {
  getDeliveredMaterials,
  getForDeliveryMaterials,
  getPartialDeliveredMaterials,
  selectDeliveredMaterial,
  selectForDeliveryMaterial,
  selectPartialDeliveredMaterial,
  deleteDeliveryMaterial,
} from "../../../actions/commonDeliverMaterials.js";
import swal from "sweetalert";
import { deleteCommonIssuePoMaterial } from "../../../actions/commonIssuePoMaterials";
import { roles } from "../../../helpers/userRoles";
import CommonMaterialDeliveryForm from "./CommonMaterialDeliveryForm";
import CommonMaterialDeliveryDetail from "./CommonMaterialDeliveryDetail";
import CommonMaterialDeliveryPartialForm from "./CommonMaterialDeliveryPartialForm";

export default function MaterialDeliveryPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const deliveredMaterialsPagination = useSelector(
    (state) => state.commonDeliverMaterials.pagination
  );
  const forDeliveryMaterials = useSelector(
    (state) => state.commonDeliverMaterials.forDeliveryMaterials
  );
  const forDeliveryPagination = useSelector(
    (state) => state.commonDeliverMaterials.forDeliveryPagination
  );
  const deliveredMaterials = useSelector(
    (state) => state.commonDeliverMaterials.deliveredMaterials
  );
  const partialDeliveredMaterials = useSelector(
    (state) => state.commonDeliverMaterials.partialDeliveredMaterials
  );
  const partialDeliveredPagination = useSelector(
    (state) => state.commonDeliverMaterials.partialDeliveredPagination
  );
  const deliveredMaterialsIsLoading = useSelector(
    (state) => state.commonDeliverMaterials.isLoading
  );
  const [status, setStatus] = useState("For Delivery");
  const [addModal, setAddModal] = useState(false);
  const [partialModal, setPartialModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deliveredMaterialsPageNumber, setDeliveredMaterialsPageNumber] =
    useState(1);
  const [
    partialDeliveredMaterialsPageNumber,
    setPartialDeliveredMaterialsPageNumber,
  ] = useState(1);
  const [forDeliveryMaterialsPageNumber, setForDeliveryMaterialsPageNumber] =
    useState(1);

  useEffect(() => {
    if (status === "For Delivery") {
      dispatch(getForDeliveryMaterials(forDeliveryMaterialsPageNumber));
    } else if (status === "Partially Delivered") {
      dispatch(
        getPartialDeliveredMaterials(partialDeliveredMaterialsPageNumber)
      );
    } else {
      dispatch(getDeliveredMaterials(deliveredMaterialsPageNumber));
    }
  }, [
    status,
    dispatch,
    partialDeliveredMaterialsPageNumber,
    deliveredMaterialsPageNumber,
    forDeliveryMaterialsPageNumber,
  ]);

  return (
    <div className="mt-4">
      {addModal && (
        <Modal>
          <CommonMaterialDeliveryForm
            onFormClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {partialModal && (
        <Modal>
          <CommonMaterialDeliveryPartialForm
            onFormClose={() => {
              setPartialModal(false);
            }}
          />
        </Modal>
      )}

      <h1 className="font-bold mb-4">Select Delivery Status</h1>

      <div className="mb-4">
        <Tab>
          <Tab.Item
            name="For Delivery"
            isActive={status === "For Delivery"}
            onTabClick={() => {
              setStatus("For Delivery");
            }}
          />
          <Tab.Item
            name="Partially Delivered"
            isActive={status === "Partially Delivered"}
            onTabClick={() => {
              setStatus("Partially Delivered");
            }}
          />
          <Tab.Item
            name="Delivered"
            isActive={status === "Delivered"}
            onTabClick={() => {
              setStatus("Delivered");
            }}
          />
        </Tab>
      </div>

      {status === "For Delivery" && (
        <>
          <table className="table-fixed w-100">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">PO Date</th>
                <th className="text-xs px-4 py-2">PO #</th>
                <th className="text-xs px-4 py-2">Vendor</th>
                <th className="text-xs px-4 py-2">Ship To</th>
                <th className="text-xs px-4 py-2">Issued By</th>
                <th className="text-xs px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {forDeliveryMaterials.map((forDeliveryMaterial) => (
                <tr key={forDeliveryMaterial.id} className="hover:bg-gray-200">
                  <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.date}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.po_no}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.common_supplier ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            <img
                              alt="Common Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={
                                forDeliveryMaterial.common_supplier
                                  .photo_full_url
                              }
                            />
                          </span>
                          <span className="text-xs ml-2">
                            {forDeliveryMaterial.common_supplier.name}
                            <br />
                            {forDeliveryMaterial.common_supplier.contact_person}
                            <br />
                            {forDeliveryMaterial.common_supplier.contact_number}
                            <br />
                            {forDeliveryMaterial.common_supplier.address}
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      " - "
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.ship_to ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {forDeliveryMaterial.ship_to.avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={forDeliveryMaterial.ship_to.avatar.image}
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {forDeliveryMaterial.ship_to.first_name}{" "}
                            {forDeliveryMaterial.ship_to.last_name}
                            <br />
                            {forDeliveryMaterial.ship_to.contact_number}
                            <br />
                            {forDeliveryMaterial.ship_to_address}
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forDeliveryMaterial.issued_by ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {forDeliveryMaterial.issued_by.avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={forDeliveryMaterial.issued_by.avatar.image}
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {forDeliveryMaterial.issued_by.first_name}{" "}
                            {forDeliveryMaterial.issued_by.last_name}
                            <br />
                            {forDeliveryMaterial.issued_by.contact_number}
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <div className="flex">
                      <div className="flex-initial">
                        <Button
                          text={`Process Goods Delivered`}
                          warning="true"
                          onClick={() => {
                            dispatch(
                              selectForDeliveryMaterial(forDeliveryMaterial)
                            );
                            setAddModal(true);
                          }}
                        />
                      </div>
                      <div className="flex-initial">
                        {user.employee.role_id === roles.su && (
                          <Button
                            text={`Delete`}
                            danger="true"
                            onClick={() => {
                              swal({
                                icon: "error",
                                title: "Are you sure?",
                                text: `This will delete this Issue Purchase Order.`,
                                buttons: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    deleteCommonIssuePoMaterial(
                                      forDeliveryMaterial.id
                                    )
                                  );
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {forDeliveryMaterials < 1 && (
            <EmptyPlaceholder text="Nothing For Delivery" />
          )}

          {forDeliveryPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={forDeliveryPagination.from}
              to={forDeliveryPagination.to}
              total={forDeliveryPagination.total}
              lastPage={forDeliveryPagination.last_page}
              currentPage={forDeliveryPagination.current_page}
              onPageClick={(page) => {
                setForDeliveryMaterialsPageNumber(page);
              }}
            />
          )}

          {deliveredMaterialsIsLoading && <Spinner />}
        </>
      )}

      {status === "Partially Delivered" && (
        <>
          <table className="table-fixed w-100">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">PO Date</th>
                <th className="text-xs px-4 py-2">PO #</th>
                <th className="text-xs px-4 py-2">Vendor</th>
                <th className="text-xs px-4 py-2">Ship To</th>
                <th className="text-xs px-4 py-2">Issued By</th>
                <th className="text-xs px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {partialDeliveredMaterials.map((partialDeliveredMaterial) => (
                <tr
                  key={partialDeliveredMaterial.id}
                  className="hover:bg-gray-200"
                >
                  <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.date}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.po_no}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.common_supplier ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            <img
                              alt="Common Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={
                                partialDeliveredMaterial.common_supplier
                                  .photo_full_url
                              }
                            />
                          </span>
                          <span className="text-xs ml-2">
                            {partialDeliveredMaterial.common_supplier.name}
                            <br />
                            {
                              partialDeliveredMaterial.common_supplier
                                .contact_person
                            }
                            <br />
                            {
                              partialDeliveredMaterial.common_supplier
                                .contact_number
                            }
                            <br />
                            {partialDeliveredMaterial.common_supplier.address}
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      " - "
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.ship_to ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {partialDeliveredMaterial.ship_to.avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={
                                  partialDeliveredMaterial.ship_to.avatar.image
                                }
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {partialDeliveredMaterial.ship_to.first_name}{" "}
                            {partialDeliveredMaterial.ship_to.last_name}
                            <br />
                            {partialDeliveredMaterial.ship_to.contact_number}
                            <br />
                            {partialDeliveredMaterial.ship_to_address}
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  {/* <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.request_purchase_order.materials
                      ? `${partialDeliveredMaterial.request_purchase_order.materials.length}`
                      : "-"}
                  </td> */}
                  <td className="text-xs border px-2 py-2">
                    {partialDeliveredMaterial.issued_by ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {partialDeliveredMaterial.issued_by.avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={
                                  partialDeliveredMaterial.issued_by.avatar
                                    .image
                                }
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {partialDeliveredMaterial.issued_by.first_name}{" "}
                            {partialDeliveredMaterial.issued_by.last_name}
                            <br />
                            {partialDeliveredMaterial.issued_by.contact_number}
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <div className="flex">
                      <div className="flex-initital">
                        <Button
                          text={`Process Goods Delivered`}
                          warning="true"
                          onClick={() => {
                            dispatch(
                              selectPartialDeliveredMaterial(
                                partialDeliveredMaterial
                              )
                            );
                            setPartialModal(true);
                          }}
                        />
                      </div>
                      <div className="flex-initial">
                        {user.employee.role_id === roles.su && (
                          <Button
                            text={`Delete`}
                            danger="true"
                            onClick={() => {
                              swal({
                                icon: "error",
                                title: "Are you sure?",
                                text: `This will delete this Deliver PO.`,
                                buttons: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    deleteCommonIssuePoMaterial(
                                      partialDeliveredMaterial.id
                                    )
                                  );
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {partialDeliveredMaterials < 1 && (
            <EmptyPlaceholder text="Nothing Partially Delivered" />
          )}

          {partialDeliveredPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={partialDeliveredPagination.from}
              to={partialDeliveredPagination.to}
              total={partialDeliveredPagination.total}
              lastPage={partialDeliveredPagination.last_page}
              currentPage={partialDeliveredPagination.current_page}
              onPageClick={(page) => {
                setPartialDeliveredMaterialsPageNumber(page);
              }}
            />
          )}

          {deliveredMaterialsIsLoading && <Spinner />}
        </>
      )}

      {status === "Delivered" && (
        <>
          <table className="table-fixed w-100">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date Delivered</th>
                <th className="text-xs px-4 py-2">PO #</th>
                <th className="text-xs px-4 py-2">Received By</th>
                <th className="text-xs px-4 py-2">Vendor</th>
                <th className="text-xs px-4 py-2">Ship To</th>
                <th className="text-xs px-4 py-2">Issued By</th>
                <th className="text-xs px-4 py-2">Attachment</th>
                <th className="text-xs px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {deliveredMaterials.map((deliverMaterial) => (
                <tr key={deliverMaterial.id} className="hover:bg-gray-200">
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.delivered_date}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.common_issue_purchase_order.po_no}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.received_by}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.common_issue_purchase_order
                      .common_supplier ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            <img
                              alt="Common Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={
                                deliverMaterial.common_issue_purchase_order
                                  .common_supplier.photo_full_url
                              }
                            />
                          </span>
                          <span className="text-xs ml-2">
                            {
                              deliverMaterial.common_issue_purchase_order
                                .common_supplier.name
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .common_supplier.contact_person
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .common_supplier.contact_number
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .common_supplier.address
                            }
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      " - "
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.common_issue_purchase_order.ship_to ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {deliverMaterial.common_issue_purchase_order.ship_to
                              .avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={
                                  deliverMaterial.common_issue_purchase_order
                                    .ship_to.avatar.image
                                }
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {
                              deliverMaterial.common_issue_purchase_order
                                .ship_to.first_name
                            }{" "}
                            {
                              deliverMaterial.common_issue_purchase_order
                                .ship_to.last_name
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .ship_to.contact_number
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .ship_to_address
                            }
                            <br />
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.common_issue_purchase_order.issued_by ? (
                      <>
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                            {deliverMaterial.common_issue_purchase_order
                              .issued_by.avatar && (
                              <img
                                alt="Supplier"
                                className="rounded-full border border-gray-100 object-cover"
                                src={
                                  deliverMaterial.common_issue_purchase_order
                                    .issued_by.avatar.image
                                }
                              />
                            )}
                          </span>
                          <span className="text-xs ml-2">
                            {
                              deliverMaterial.common_issue_purchase_order
                                .issued_by.first_name
                            }{" "}
                            {
                              deliverMaterial.common_issue_purchase_order
                                .issued_by.last_name
                            }
                            <br />
                            {
                              deliverMaterial.common_issue_purchase_order
                                .issued_by.contact_number
                            }
                          </span>
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {deliverMaterial.attachment ? (
                      <Attachment path={deliverMaterial.attachment_full_url} />
                    ) : (
                      <p className="text-gray-600 text-xs">No attachments.</p>
                    )}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <div className="flex">
                      <div className="flex-initial">
                        <Button
                          primary="true"
                          text="View"
                          onClick={() => {
                            dispatch(selectDeliveredMaterial(deliverMaterial));
                            setViewModal(true);
                          }}
                        />
                      </div>
                      <div className="flex-initial">
                        {user.employee.role_id === roles.su && (
                          <Button
                            text={`Delete`}
                            danger="true"
                            onClick={() => {
                              swal({
                                icon: "error",
                                title: "Are you sure?",
                                text: `This will delete this Deliver PO.`,
                                buttons: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    deleteDeliveryMaterial(deliverMaterial.id)
                                  );
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {deliveredMaterialsPagination && !deliveredMaterialsIsLoading && (
            <Pagination
              from={deliveredMaterialsPagination.from}
              to={deliveredMaterialsPagination.to}
              total={deliveredMaterialsPagination.total}
              lastPage={deliveredMaterialsPagination.last_page}
              currentPage={deliveredMaterialsPagination.current_page}
              onPageClick={(page) => {
                setDeliveredMaterialsPageNumber(page);
              }}
            />
          )}

          {deliveredMaterialsIsLoading && <Spinner />}
        </>
      )}

      {viewModal && (
        <Modal>
          <CommonMaterialDeliveryDetail
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
