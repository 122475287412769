import {
  GET_WAREHOUSES,
  ADD_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  SELECT_WAREHOUSE,
} from "actions/types";

const initialState = {
  warehouses: [],
  selectedWarehouse: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_WAREHOUSES:
      return {
        ...state,
        warehouses: payload,
      };
    case ADD_WAREHOUSE:
      return {
        ...state,
        warehouses: [...state.warehouses, payload],
      };
    case UPDATE_WAREHOUSE:
      return {
        ...state,
        warehouses: state.warehouses.map((warehouse) => {
          if (payload.id !== warehouse.id) {
            return warehouse;
          }

          return {
            ...warehouse,
            ...payload,
          };
        }),
      };
    case SELECT_WAREHOUSE:
      return {
        ...state,
        selectedWarehouse: payload,
      };
    case DELETE_WAREHOUSE:
      return {
        ...state,
        warehouses: state.warehouses.filter(
          (warehouse) => warehouse.id !== payload
        ),
      };
    default:
      return state;
  }
}
