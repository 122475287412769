import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_USERS,
  GET_USERS,
  SELECT_USER,
  DELETE_USER,
  ADD_USER,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET USERS
export const getUsers = (search_term, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_USERS });
  axios
    .get(
      BASE_URL + `/user/accounts?search_term=${search_term}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data.data.data,
        pagination: {
          from: res.data.data.from,
          to: res.data.data.to,
          total: res.data.data.total,
          last_page: res.data.data.last_page,
          current_page: res.data.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// ADD USER
export const addUser = (data) => (dispatch, getState) => {
  dispatch({ type: LOADING_USERS });
  axios
    .post(BASE_URL + `/user`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_USER,
        payload: res.data.data,
      });
      swal({
        title: "User Credentials Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// SELECT USER
export const selectUser = (user) => (dispatch) => {
  dispatch({
    type: SELECT_USER,
    payload: user,
  });
};

// UPDATE USERNAME
export const updateUser = (id, data) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/user/${id}/change-user`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: SELECT_USER,
        payload: res.data,
      });
      swal({
        title: "User Credentials Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.log(err, "error edit username"));
};

// DELETE USER
export const deleteUser = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/user/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
      swal({
        title: "User Deleted!",
        text: "You can create user credential again for this employee just in case.",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GET AVAILABLE EMPLOYEES
export const getAvailableEmployees = (search_term) => (dispatch, getState) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        BASE_URL + `/user/available-employees?search_term=${search_term}`,
        tokenConfig(getState)
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
