import React from "react";

const Avatar = (props) => (
  <div className="flex items-center">
    <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
      {props.image && (
        <img
          alt="..."
          className="rounded-full border border-gray-100 object-cover"
          src={props.image}
        />
      )}
    </span>
    <span className="text-xs ml-2">{props.name}</span>
  </div>
);

export default Avatar;
