import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Label,
  Button,
  Pagination,
  EmptyPlaceholder,
  Spinner,
} from "../../common";
import moment from "moment";
import swal from "sweetalert";
import { addStocktake } from "../../../actions/commonStocktakes";
import { searchEmployees } from "../../../actions/employees";
import { getAvailableCommonMaterials } from "../../../actions/commonMaterialRequests";
import AsyncSelect from "react-select/async";

import DatePicker from "react-datepicker";
import ImageUtils from "../../../helpers/ImageUtils";
import useDebounce from "../../../helpers/useDebounce";

export default function CommonMaterialStocktakeAdd(props) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const user = useSelector((state) => state.auth.user);
  const [checkedBy, setCheckedBy] = useState(null);
  const [action, setAction] = useState("No Adjustment");
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [chosenMaterial, setChosenMaterial] = useState(null);
  const [chosenMaterialQty, setChosenMaterialQty] = useState(0);
  const [chosenMaterialRemarks, setChosenMaterialRemarks] = useState("");
  const [chosenMaterialOperation, setChosenMaterialOperation] =
    useState("Decrease");
  const [selectedFile, setSelectedFile] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const availableMaterials = useSelector(
    (state) => state.commonMaterialRequests.availableMaterials
  );
  const availableMaterialsPagination = useSelector(
    (state) => state.commonMaterialRequests.availableMaterialsPagination
  );
  const availableMaterialsLoading = useSelector(
    (state) => state.commonMaterialRequests.isLoading
  );

  const fileInputRef = useRef();

  const addSelectedMaterial = () => {
    let selected = {
      ...chosenMaterial,
      adjustment_qty: chosenMaterialQty,
      remarks: chosenMaterialRemarks,
      operation: chosenMaterialOperation,
    };

    setSelectedMaterials([...selectedMaterials, selected]);
    setChosenMaterialQty(0);
    setChosenMaterialRemarks("");
    setChosenMaterialOperation("Decrease");
    setChosenMaterial(null);
  };

  const removeSelectedMaterial = (material) => {
    setSelectedMaterials(
      selectedMaterials.filter((ma) => ma.id !== material.id)
    );
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleSelectMaterial = (material) => {
    setChosenMaterial(material);
  };

  const handleCheckedByChange = (option) => {
    setCheckedBy(option);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onSubmit = async () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add a Stocktake record.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        let stocktake = {
          prepared_by: user.employee.id,
          checked_by: checkedBy.value,
          date: moment(date).format("YYYY-MM-DD").toString(),
          action: action,
          materials: selectedMaterials,
        };

        stocktake.attachment = file;

        dispatch(addStocktake(stocktake));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  useEffect(() => {
    dispatch(getAvailableCommonMaterials(pageNumber, debouncedSearchTerm));
  }, [dispatch, pageNumber, debouncedSearchTerm]);

  const loadCheckedByOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let checkedByOptions = [];

    for (let i in search) {
      checkedByOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(checkedByOptions);
  };

  return (
    <form>
      <Typography type="h1">Add Stocktake</Typography>
      <br />
      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Date" />
          <DatePicker
            showYearDropdown
            selected={date}
            dateFormat="yyyy-MM-dd"
            onChange={handleDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            style={{ zIndex: 10 }}
          />
        </div>
        <div className="w-1/2 px-4">
          <Label name="Checked by" />
          <AsyncSelect
            loadOptions={loadCheckedByOptions}
            value={checkedBy}
            onChange={handleCheckedByChange}
            className="text-xs shadow-sm"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Prepared by" />
          <p className="text-xs font-bold">
            {user.employee.first_name} {user.employee.last_name}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Operation" />
          <div className="flex mt-4">
            <div className="flex">
              <input
                type="radio"
                name="action"
                value="No Adjustment"
                className="h-5 w-5 text-gray-600"
                defaultChecked={action === "No Adjustment"}
                onClick={() => {
                  setAction("No Adjustment");
                }}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">
                No Adjustment
              </span>
            </div>

            <div className="flex">
              <input
                type="radio"
                name="action"
                value="With Adjustment"
                className="h-5 w-5 text-gray-600"
                defaultChecked={action === "With Adjustment"}
                onClick={() => {
                  setAction("With Adjustment");
                }}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">
                With Adjustment
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Attachment" />
          <input
            className="text-xs"
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>
      </div>

      {action === "With Adjustment" && (
        <>
          <Typography type="h1">Selected Materials for Adjustment</Typography>
          <Typography type="muted">
            Please choose materials from the "Choose materials to make
            adjustments" table.
          </Typography>

          <table className="table-fixed w-100 mb-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Category</th>
                <th className="text-xs px-4 py-2">Code</th>
                <th className="text-xs px-4 py-2">Description</th>
                <th className="text-xs px-4 py-2">Unit</th>
                <th className="text-xs px-4 py-2">Price</th>
                <th className="text-xs px-4 py-2">Operation</th>
                <th className="text-xs px-4 py-2">Adjustment Qty</th>
                <th className="text-xs px-4 py-2">Remarks</th>
                <th className="text-xs px-4 py-2">Remove?</th>
              </tr>
            </thead>
            <tbody>
              {selectedMaterials.map((material, index) => (
                <tr key={index} className="hover:bg-gray-200 ">
                  <td className="text-xs border px-2 py-2">
                    {material.common_category.name}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.code}</td>
                  <td className="text-xs border px-2 py-2">
                    {material.description}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.unit}</td>
                  <td className="text-xs border px-2 py-2">
                    ${material.price}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.operation}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.adjustment_qty}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.remarks}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <Button
                      text="-"
                      danger="true"
                      onClick={() => {
                        removeSelectedMaterial(material);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Typography type="h1">
            Choose materials to make adjustments
          </Typography>
          <h3 className="font-bold">Materials currently available</h3>
          <Typography type="muted">
            Search available materials, click on the row of the desired
            material, put in quantity and click "+ add item" button.
          </Typography>
          <div className="flex mb-4">
            <div className="w-1/3">
              <Label name="Category" />
              <p className="text-xs font-bold">
                {chosenMaterial && chosenMaterial.common_category.name}
              </p>
            </div>
            <div className="w-1/3 px-4">
              <Label name="Material" />
              <p className="text-xs font-bold">
                {chosenMaterial &&
                  `${chosenMaterial.code} ${
                    chosenMaterial.description
                      ? ` - ${chosenMaterial.description}`
                      : ``
                  }`}
              </p>
            </div>
            <div className="w-1/3 px-4">
              <Label name="Available Stock" />
              <p className="text-xs font-bold">
                {chosenMaterial && `${chosenMaterial.common_in_stock}`}
              </p>
            </div>
          </div>
          <div className="flex mb-4">
            <div className="w-1/3">
              <Label name="Operation" />
              <div className="flex mb-2">
                <input
                  type="radio"
                  name="chosen_operation"
                  value="Decrease"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={chosenMaterialOperation === "Decrease"}
                  onClick={() => {
                    setChosenMaterialOperation("Decrease");
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  Decrease
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="chosen_operation"
                  value="Increase"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={chosenMaterialOperation === "Increase"}
                  onClick={() => {
                    setChosenMaterialOperation("Increase");
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  Increase
                </span>
              </div>
            </div>

            <div className="w-1/3 px-4">
              <Label name="Qty to Adjust" />
              <input
                type="number"
                autoComplete="off"
                disabled={!chosenMaterial}
                value={chosenMaterialQty}
                onChange={(e) => {
                  setChosenMaterialQty(e.target.value);
                }}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="w-1/3 px-4">
              <Label name="Remarks (optional)" />
              <input
                type="text"
                name="chosen_remarks"
                value={chosenMaterialRemarks}
                onChange={(e) => setChosenMaterialRemarks(e.target.value)}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
          <div className="mb-4">
            <Button
              primary="true"
              text="+ add item"
              disabled={!chosenMaterial}
              onClick={() => {
                addSelectedMaterial();
              }}
            />
          </div>

          <Label name="Search for Available Materials" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            type="text"
          />

          <table className="table-fixed w-100 mb-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Category</th>
                <th className="text-xs px-4 py-2">Code</th>
                <th className="text-xs px-4 py-2">Description</th>
                <th className="text-xs px-4 py-2">Unit</th>
                <th className="text-xs px-4 py-2">Price</th>
                <th className="text-xs px-4 py-2">
                  Available
                  <br />
                  Stock
                </th>
              </tr>
            </thead>
            <tbody>
              {availableMaterials.map((material, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-200 cursor-pointer"
                  onClick={() => {
                    handleSelectMaterial(material);
                  }}
                >
                  <td className="text-xs border px-2 py-2">
                    {material.common_category.name}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.code}</td>
                  <td className="text-xs border px-2 py-2">
                    {material.description}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.unit}</td>
                  <td className="text-xs border px-2 py-2">
                    ${material.price}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.common_in_stock}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {availableMaterials < 1 && !availableMaterialsLoading && (
            <EmptyPlaceholder text="No Available Materials Found" />
          )}

          {availableMaterialsLoading && <Spinner />}

          {availableMaterialsPagination && !availableMaterialsLoading && (
            <Pagination
              from={availableMaterialsPagination.from}
              to={availableMaterialsPagination.to}
              total={availableMaterialsPagination.total}
              lastPage={availableMaterialsPagination.last_page}
              currentPage={availableMaterialsPagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </>
      )}
      <br />
      <hr />
      <br />
      <br />

      <Button
        text="Save"
        success="true"
        onClick={() => {
          onSubmit();
        }}
      />
      <Button
        primary="true"
        text="Close"
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
