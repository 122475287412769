import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { useYupValidationResolver } from "../../helpers/useYupValidationResolver";
import { ToolRequestValidationSchema } from "./ToolRequestValidationSchema";
import moment from "moment";
import Select from "react-select";
import { MinusCircleButton } from "../common/icons/HeroIcons";

import { addRequestTool, getUnissuedTools } from "../../actions/tools";

import {
  transformDate,
  filterUnissuedTools,
  filterUnissuedToolsBySerialNo,
} from "../../helpers/useTransformData";
import {
  requestListColumns,
  serialNoRequestListColumns,
} from "./MyTasksListColumns";

import { Button, Label, Typography, Table, EmptyPlaceholder } from "../common";
import CustomDatePicker from "components/common/CustomDatePicker";
import swal from "sweetalert";

function MyTasksToolRequestForm(props) {
  const { projectId, employeeId } = props;
  const dispatch = useDispatch();

  const unissuedTools = useSelector((state) => state.tools.unissuedTools);

  useEffect(() => {
    dispatch(getUnissuedTools());
  }, [dispatch]);

  const toolOptions = filterUnissuedTools(unissuedTools);
  const serialToolOptions = filterUnissuedToolsBySerialNo(unissuedTools);

  const [requestDateValue] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [quantityValue, setQuantityValue] = useState(0);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [selectedToolDescription, setSelectedToolDescription] = useState(null);
  const [selectedToolSerialNo, setSelectedToolSerialNo] = useState(null);
  const [tableListItems, setTableListItems] = useState([]);
  const [addDisabled, setAddDisabled] = useState(true);
  const [requestByType, setRequestByType] = useState("Description");

  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: useYupValidationResolver(ToolRequestValidationSchema),
  });

  useEffect(() => {
    if (
      startDateValue &&
      endDateValue &&
      (selectedToolDescription || selectedToolSerialNo)
    ) {
      setAddDisabled(false);
    }
  }, [
    startDateValue,
    endDateValue,
    selectedToolDescription,
    selectedToolSerialNo,
    quantityValue,
  ]);

  const handleToolDescriptionOnChange = (option) => {
    setSelectedToolDescription(option);
    setValue("toolDescription", option, { shouldValidate: true });
  };

  const handleToolSerialNoOnChange = (option) => {
    setSelectedToolSerialNo(option);
    setValue("toolSerialNo", option, { shouldValidate: true });
  };

  const handleQuantityChange = (e) => {
    setQuantityValue(e.target.value);
    setValue("quantity", e.target.value, { shouldValidate: true });
  };
  const handleStartDateChange = (date) => {
    setStartDateValue(date);
    setValue("startDate", date, { shouldValidate: true });
  };

  const handleEndDateChange = (date) => {
    setEndDateValue(date);
    setValue("endDate", date, { shouldValidate: true });
  };

  const onReset = () => {
    setSelectedToolDescription(null);
    setSelectedToolSerialNo(null);
    setQuantityValue(0);
    setStartDateValue(null);
    setEndDateValue(null);

    setValue("startDate", null);
    setValue("endDate", null);
  };

  useEffect(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformDataToAPI = (data) => {
    const { startDate, endDate } = data;

    // const tempArr = [];
    // tableListItems.map((data) => {
    //   tempArr.push({
    //     description: data.description,
    //     serial_no: data.serial_no,
    //     quantity: data.quantity,
    //   });
    // });

    return {
      date: requestDateValue,
      status: "Pending",
      request_by_type: requestByType,
      requested_by: employeeId,
      project_id: projectId,
      from_date: startDate,
      to_date: endDate,
      items: tableListItems,
    };
  };

  const addItemsToTable = () => {
    // copy prevs items, push new item in an array
    const newArr = [
      ...tableListItems,
      {
        description:
          requestByType === "Description"
            ? selectedToolDescription.description
            : selectedToolSerialNo.description,
        label:
          requestByType === "Description"
            ? selectedToolDescription.label
            : selectedToolSerialNo.label,
        quantity: requestByType === "Description" ? quantityValue : 1,
        serial_no:
          requestByType === "Description"
            ? null
            : selectedToolSerialNo.serial_no,
        tool_id:
          requestByType === "Description" ? null : selectedToolSerialNo.value,
        start_date: moment(startDateValue).format("YYYY-MM-DD"),
        end_date: moment(endDateValue).format("YYYY-MM-DD"),
      },
    ];

    setTableListItems(newArr);
    setAddDisabled(true);
    // onReset();
  };

  const deleteItemToTable = (index) => {
    // copy prevs items, remove item in an array
    const items = [...tableListItems];
    items.splice(index, 1);
    setTableListItems(items);
    onReset();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setSubmitting(true);

    const body = transformDataToAPI(data);

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will submit a request for the tools mentioned.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        if (body) {
          dispatch(addRequestTool(body));
          onReset();
        }

        setSubmitting(false);
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full flex flex-row justify-between">
          <Typography type="h1">Request Tools</Typography>

          <Typography type="p" style={{ fontSize: 12 }}>
            Request Date: {requestDateValue}
          </Typography>
        </div>

        <div className="flex flex-row w-full mt-4">
          <div className="w-1/4">
            <Label name="Request Tool By" />
            <input
              type="radio"
              name="requestByType"
              value="Description"
              className="text-gray-600"
              defaultChecked
              onChange={() => {
                setRequestByType("Description");
                setSelectedToolDescription(null);
                setSelectedToolSerialNo(null);
                setTableListItems([]);
              }}
            />
            <span className="ml-2 mr-2 text-gray-700 text-xs">
              by Description
            </span>

            <input
              type="radio"
              name="requestByType"
              value="Serial No."
              className="text-gray-600"
              onChange={() => {
                setRequestByType("Serial No.");
                setSelectedToolDescription(null);
                setSelectedToolSerialNo(null);
                setTableListItems([]);
              }}
            />
            <span className="ml-2 mr-2 text-gray-700 text-xs">
              by Serial Number
            </span>
          </div>

          <div className="w-1/4 mr-2"></div>
        </div>

        <div className="flex flex-row w-full justify-between mt-4">
          <div className="w-full mr-2">
            <Label name="Start Date" />
            <Controller
              control={control}
              name="startDate"
              render={(props) => (
                <CustomDatePicker
                  {...props}
                  selected={startDateValue}
                  onChange={handleStartDateChange}
                />
              )}
            />
            {errors?.startDate && (
              <span className="text-xs text-red-600 mt-1">
                {errors?.startDate?.message}
              </span>
            )}
          </div>
          <div className="w-full ml-2">
            <Label name="End Date" />
            <Controller
              control={control}
              name="endDate"
              render={(props) => (
                <CustomDatePicker
                  {...props}
                  selected={endDateValue}
                  minDate={startDateValue}
                  onChange={handleEndDateChange}
                />
              )}
            />
            {errors?.endDate && (
              <span className="text-xs text-red-600 mt-1">
                {errors?.endDate?.message}
              </span>
            )}
          </div>
        </div>

        {requestByType === "Description" && (
          <div className="flex flex-row w-full justify-between mt-4">
            <div className="w-2/3 mr-2">
              <Label name="Tool Description" />
              <Controller
                control={control}
                name="toolDescription"
                render={(props) => (
                  <Select
                    {...props}
                    className="text-xs shadow-sm"
                    options={toolOptions}
                    onChange={handleToolDescriptionOnChange}
                    value={selectedToolDescription}
                  />
                )}
              />
              {errors?.toolDescription && (
                <span className="text-xs text-red-600">
                  {errors?.toolDescription?.message}
                </span>
              )}
            </div>
            <div className="w-1/3 ml-2">
              <Label name="Quantity" />
              <Controller
                control={control}
                name="quantity"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    autoComplete="off"
                    onChange={handleQuantityChange}
                    value={quantityValue}
                    style={{ padding: "9px 10px", border: "1px solid #ccc" }}
                  />
                )}
              />
              {errors?.quantity && (
                <span className="text-xs text-red-600">
                  {errors?.quantity?.message}
                </span>
              )}
            </div>
          </div>
        )}

        {requestByType === "Serial No." && (
          <div className="flex flex-row w-full justify-between mt-4">
            <div className="w-full mr-2">
              <Label name="Tool Serial Number" />
              <Controller
                control={control}
                name="toolSerialNo"
                render={(props) => (
                  <Select
                    {...props}
                    className="text-xs shadow-sm"
                    options={serialToolOptions}
                    onChange={handleToolSerialNoOnChange}
                    value={selectedToolSerialNo}
                  />
                )}
              />
              {errors?.toolSerialNo && (
                <span className="text-xs text-red-600">
                  {errors?.toolSerialNo?.message}
                </span>
              )}
            </div>
          </div>
        )}

        <Button
          className="bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold mb-2 mt-6 mr-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          text="+ Add Items"
          onClick={() => addItemsToTable()}
          style={{ backgroundColor: addDisabled ? "#ccc" : "#4299e1" }}
          disabled={addDisabled}
        />

        <Table
          columns={
            requestByType === "Description"
              ? requestListColumns
              : serialNoRequestListColumns
          }
          data={
            <>
              {tableListItems.length > 0 && (
                <>
                  {tableListItems.map((tool, index) => (
                    <tr
                      key={index}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer"
                        // {
                        // "text-blue-600 bg-teal-200":
                        //     (selectedTool ? selectedTool.id : 0) === tool.id,
                        // }
                      )}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {tool.label}
                        </div>
                      </td>
                      {requestByType === "Description" && (
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {tool.quantity}
                          </div>
                        </td>
                      )}

                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {tool.start_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {tool.end_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => deleteItemToTable(index)}
                          >
                            <MinusCircleButton title={"Remove"} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </>
          }
        />

        {tableListItems.length <= 0 && (
          <div className="flex flex-col w-full justify-center">
            <EmptyPlaceholder text="Nothing Requested Yet" />
          </div>
        )}

        <Button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold my-2 mr-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          text="Submit Tool Request"
          disabled={submitting}
        />
      </form>
    </>
  );
}

export default MyTasksToolRequestForm;
