import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_EMPLOYEES,
  GET_EMPLOYEES,
  SELECT_EMPLOYEE,
  ADD_EMPLOYEE,
  EMPLOYEE_DROPDOWN,
  GET_LEAVE_CREDITS,
  EMPLOYEE_UPDATE_SCREEN,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET EMPLOYEES
export const getEmployees =
  (search_term, page, status) => (dispatch, getState) => {
    dispatch({ type: LOADING_EMPLOYEES });
    axios
      .get(
        BASE_URL +
          `/employees/all?company_id=&status=${status}&search_term=${search_term}&page=${page}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEES,
          payload: res.data.data.data,
          pagination: {
            from: res.data.data.from,
            to: res.data.data.to,
            total: res.data.data.total,
            last_page: res.data.data.last_page,
            current_page: res.data.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// SEARCH EMPLOYEES
export const searchEmployees =
  (search_term, role_id) => (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      axios
        .get(
          BASE_URL +
            `/employees/all?search_term=${
              search_term ? search_term : ""
            }&role=${role_id ? role_id : ""}`,
          tokenConfig(getState)
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

// GET LEAVE CREDITS
export const getLeaveCredits = (employeeId, year) => (dispatch, getState) => {
  axios
    .get(
      BASE_URL + `/employee-leave/leave-credits/${employeeId}?year=${year}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_LEAVE_CREDITS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE LEAVE CREDITS
export const updateLeaveCredits = (data) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + `/employee-leave/update-leave-credits/${data.employee_id}`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      swal({
        title: "Leave Credits Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GET UNASSIGNED EMPLOYEES
export const getUnassignedEmployees =
  (forId, type, role_id = null, role_ids = null) =>
  async (dispatch, getState) => {
    let response = null;
    let endpoint = BASE_URL + `/company/employees/unassigned/${type}/${forId}`;

    if (role_id) {
      endpoint += `?role_id=${role_id}`;
    } else if (role_ids) {
      endpoint += `?role_ids=${role_ids}`;
    }

    try {
      const resp = await axios.get(endpoint, tokenConfig(getState));
      response = resp.data;
    } catch (err) {
      console.error(err);
    }

    return response;
  };

// EMPLOYEE DROPDOWN
export const employeeDropdown = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/employees/1`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: EMPLOYEE_DROPDOWN,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

// SELECT EMPLOYEE
export const selectEmployee = (employee) => (dispatch) => {
  dispatch({
    type: SELECT_EMPLOYEE,
    payload: employee,
  });
};

// EDIT EMPLOYEE
export const editEmployee = (id, data, pagination) => (dispatch, getState) => {
  return axios
    .put(BASE_URL + `/employee/${id}`, data, tokenConfig(getState))
    .then((res) => {
      if (res.data.status_code && res.data.status_code !== 200) {
        swal({
          title: "Error Updating Employee",
          icon: "error",
          text: res.data.data.unique_id_exist
            ? "Duplicate Employee Code. Please enter a different one."
            : "Something's went wrong on our side. Please try again later.",
        });
      } else {
        dispatch(
          getEmployees(
            pagination.employeeSearchTerm,
            pagination.pageNumber,
            pagination.active
          )
        );
        dispatch(selectEmployee(res.data.data));
        dispatch(updateEmployeeScreen());
        swal({
          title: "Employee Information Updated!",
          icon: "success",
        });
      }

      return res;
    })
    .catch((err) => err);
};

// ADD EMPLOYEE
export const addEmployee = (employee) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/employee`, employee, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_EMPLOYEE,
        payload: res.data.data,
      });
      swal({
        title: "Employee Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const updateEmployeeScreen = () => (dispatch) => {
  dispatch({
    type: EMPLOYEE_UPDATE_SCREEN,
  });
};

// UPDATE EMPLOYEE STATUS
export const updateEmployeeStatus =
  (id, status, pagination) => (dispatch, getState) => {
    return axios
      .put(
        BASE_URL + `/employee/${id}/status`,
        { status },
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch(
          getEmployees(
            pagination.employeeSearchTerm,
            pagination.pageNumber,
            pagination.active
          )
        );
        console.log(res.data.data, "res.data.data");
        dispatch(selectEmployee(res.data.data));
        return res;
      })
      .catch((err) => err);
  };

export const updateProfilePicture = (id, data) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + `/employee/update/avatar/${id}/`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      // dispatch({
      //   type: SELECT_EMPLOYEE,
      //   payload: res.data,
      // });
      swal({
        title: "Profile Picture Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.log(err, "error edit profile picture"));
};
