import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import {
  Button,
  Fieldset,
  Label,
  Pagination,
  Spinner,
  Tab,
  EmptyPlaceholder,
} from "../common";

import {
  getEquipmentRequests,
  selectEquipmentRequest,
  approveEquipmentRequest,
  denyEquipmentRequest,
} from "../../actions/equipments";

export default function EquipmentRequestPage(props) {
  const dispatch = useDispatch();
  const equipmentRequests = useSelector(
    (state) => state.equipments.equipmentRequests
  );
  const selectedEquipmentRequest = useSelector(
    (state) => state.equipments.selectedEquipmentRequest
  );
  const pagination = useSelector(
    (state) => state.equipments.paginationEquipmentRequests
  );
  const equipmentsLoading = useSelector(
    (state) => state.equipments.equipmentsLoading
  );
  const [status, setStatus] = useState("Pending");
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getEquipmentRequests(status, pageNumber));
  }, [dispatch, status, pageNumber]);

  return (
    <div className="flex">
      <div className="w-2/3">
        <Fieldset>
          <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
            Equipment Requests
          </h1>

          <Tab>
            <Tab.Item
              name="Pending"
              isActive={status === "Pending"}
              onTabClick={() => {
                setStatus("Pending");
              }}
            />
            <Tab.Item
              name="Approved"
              isActive={status === "Approved"}
              onTabClick={() => {
                setStatus("Approved");
              }}
            />
            <Tab.Item
              name="Denied"
              isActive={status === "Denied"}
              onTabClick={() => {
                setStatus("Denied");
              }}
            />
          </Tab>

          <table className="table-auto ml-6 mt-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date Requested</th>
                <th className="text-xs px-4 py-2">Project</th>
                <th className="text-xs px-4 py-2">Requested By</th>
                <th className="text-xs px-4 py-2">Items</th>
              </tr>
            </thead>

            <tbody>
              {!equipmentsLoading &&
                equipmentRequests.map((equipmentRequest) => (
                  <tr
                    key={equipmentRequest.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedEquipmentRequest
                          ? selectedEquipmentRequest.id
                          : 0) === equipmentRequest.id,
                    })}
                    onClick={() => {
                      dispatch(selectEquipmentRequest(equipmentRequest));
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {equipmentRequest.created_at &&
                        moment(equipmentRequest.created_at)
                          .format("MMM DD Y")
                          .toString()}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {equipmentRequest.project &&
                        equipmentRequest.project.title}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {equipmentRequest.requested_by &&
                        `${equipmentRequest.requested_by.first_name} ${equipmentRequest.requested_by.last_name}`}
                    </td>
                    <td className="text-xs border px-2 py-2 text-right">
                      {equipmentRequest.equipments.length}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {equipmentsLoading && <Spinner />}

          {pagination && !equipmentsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      <div className="w-1/3 px-4">
        {!equipmentsLoading && (
          <Fieldset>
            {!selectedEquipmentRequest ? (
              <EmptyPlaceholder
                text="Select a Tool Request"
                subText="then we will show the rest of the details here."
              />
            ) : (
              <div>
                <h1 className="text-sm font-extrabold text-gray-600 mb-4">
                  {`Tool Request - ${selectedEquipmentRequest.status}`}
                </h1>
                <Label name="Date" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedEquipmentRequest.date}
                </p>
                <Label name="Project" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedEquipmentRequest.project &&
                    selectedEquipmentRequest.project.title}
                </p>
                <Label name="Requested By" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedEquipmentRequest.requested_by &&
                    `${selectedEquipmentRequest.requested_by.first_name} ${selectedEquipmentRequest.requested_by.last_name}`}
                </p>
                <Label name="Comments" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedEquipmentRequest.comments ? (
                    selectedEquipmentRequest.comments
                  ) : (
                    <i>No comments set</i>
                  )}
                </p>
                <Label name="Equipments Requested" />
                <table className="table-auto mb-4">
                  <thead>
                    <tr>
                      <th className="text-xs px-4 py-2">Description</th>
                      <th className="text-xs px-4 py-2">Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedEquipmentRequest.equipments &&
                      selectedEquipmentRequest.equipments.map((tool) => (
                        <tr key={tool.id}>
                          <td className="text-xs border px-2 py-2">
                            {tool.description}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {tool.quantity}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                {(selectedEquipmentRequest.status === "Pending" ||
                  selectedEquipmentRequest.status === "Denied") && (
                  <div>
                    <Label name="Actions" />
                    <div className="flex">
                      <Button
                        onClick={() => {
                          dispatch(
                            approveEquipmentRequest(
                              selectedEquipmentRequest.id,
                              ""
                            )
                          );
                        }}
                        text={"Approve"}
                        primary
                      />
                      <Button
                        onClick={() => {
                          dispatch(
                            denyEquipmentRequest(
                              selectedEquipmentRequest.id,
                              ""
                            )
                          );
                        }}
                        text={"Deny"}
                        danger
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Fieldset>
        )}
      </div>
    </div>
  );
}
