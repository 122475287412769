import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Button, Label } from "../common";
import moment from "moment";
import {
  addEquipment,
  updateEquipment,
  selectEquipment,
  deleteEquipment,
  getEquipmentFilters,
} from "../../actions/equipments";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default function EquipmentForm() {
  const { register, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedEquipment = useSelector(
    (state) => state.equipments.selectedEquipment
  );
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const companies = useSelector((state) => state.equipments.filters.companies);
  const categories = useSelector(
    (state) => state.equipments.filters.categories
  );
  const onSubmit = (data, e) => {
    if (selectedEquipment) {
      dispatch(updateEquipment(selectedEquipment.id, data));
    } else {
      dispatch(addEquipment(data));
      e.target.reset();
    }
  };

  let companiesDropdown = [];
  let categoriesDropdown = [];

  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  const handlePurchaseDateChange = (date) => {
    setPurchaseDate(date);
    setValue("purchase_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
    setValue(
      "warranty_expiry_date",
      moment(date).format("YYYY-MM-DD").toString()
    );
  };

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setValue("equipment_category_id", option.value);
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setValue("company_id", option.value);
  };

  useEffect(() => {
    dispatch(getEquipmentFilters());

    register("purchase_date");
    register("warranty_expiry_date");
    register("equipment_category_id");
    register("company_id");

    setPurchaseDate(
      selectedEquipment && selectedEquipment.purchase_date
        ? new Date(selectedEquipment.purchase_date)
        : null
    );
    selectedEquipment && selectedEquipment.purchase_date
      ? setValue(
          "purchase_date",
          moment(new Date(selectedEquipment.purchase_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("purchase_date", null);

    setExpiryDate(
      selectedEquipment && selectedEquipment.warranty_expiry_date
        ? new Date(selectedEquipment.warranty_expiry_date)
        : null
    );
    selectedEquipment && selectedEquipment.warranty_expiry_date
      ? setValue(
          "warranty_expiry_date",
          moment(new Date(selectedEquipment.warranty_expiry_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("warranty_expiry_date", null);

    setSelectedCategory(
      selectedEquipment && selectedEquipment.category
        ? {
            value: selectedEquipment.category.id,
            label: selectedEquipment.category.name,
          }
        : null
    );
    selectedEquipment && selectedEquipment.category
      ? setValue("equipment_category_id", selectedEquipment.category.id)
      : setValue("equipment_category_id", null);

    setSelectedCompany(
      selectedEquipment && selectedEquipment.company
        ? {
            value: selectedEquipment.company.id,
            label: selectedEquipment.company.name,
          }
        : null
    );

    selectedEquipment && selectedEquipment.company
      ? setValue("company_id", selectedEquipment.company.id)
      : setValue("company_id", null);
  }, [selectedEquipment, register, dispatch, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedEquipment
          ? `Equipment - ${selectedEquipment.brand} ${selectedEquipment.description}`
          : `Add Equipment`}
      </h1>

      <Label name="Company" />
      <Select
        options={companiesDropdown}
        value={selectedCompany}
        onChange={handleCompanyChange}
        className="text-xs"
      />

      <Label name="Category" />
      <Select
        options={categoriesDropdown}
        value={selectedCategory}
        onChange={handleCategoryChange}
        className="text-xs"
      />

      <Label name="Brand" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={selectedEquipment ? selectedEquipment.brand : "brand"}
        defaultValue={selectedEquipment ? selectedEquipment.brand : ""}
        name="brand"
        type="text"
      />

      <Label name="Description" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={selectedEquipment ? selectedEquipment.description : "description"}
        defaultValue={selectedEquipment ? selectedEquipment.description : ""}
        name="description"
        type="text"
      />

      <Label name="S/N" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={selectedEquipment ? selectedEquipment.serial_no : "serial_no"}
        defaultValue={selectedEquipment ? selectedEquipment.serial_no : ""}
        name="serial_no"
        type="text"
      />
      <br />

      <Label name="Purchase Date" />
      <DatePicker
        showYearDropdown
        selected={purchaseDate}
        dateFormat="yyyy-MM-dd"
        onChange={handlePurchaseDateChange}
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
      />
      <Label name="Expiry Date" />
      <DatePicker
        showYearDropdown
        selected={expiryDate}
        dateFormat="yyyy-MM-dd"
        onChange={handleExpiryDateChange}
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
      />

      <br />

      {selectedEquipment ? (
        <div className="flex">
          <Button text="Update" success="true" type="submit" />
          <Button
            onClick={() => {
              swal({
                icon: "warning",
                title: "Are you sure?",
                text: `Issued equipments should be unaffected.`,
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  dispatch(deleteEquipment(selectedEquipment.id));
                  selectEquipment(null);
                  reset();
                }
              });
            }}
            danger
            text="Delete"
          />
        </div>
      ) : (
        <Button text="Save" success="true" type="submit" />
      )}
    </form>
  );
}
