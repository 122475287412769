import React from "react";
import { useSelector } from "react-redux";
import { Label, Button, Typography, Attachment } from "../../common";

export default function CommonMaterialStocktakeView(props) {
  const selectedStocktake = useSelector(
    (state) => state.commonStocktakes.selectedStocktake
  );

  return (
    <div>
      <Typography type="h1">Common Stocktake Detail</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Prepared by" />
          <p className="text-xs">
            {selectedStocktake.prepared_by &&
              `${selectedStocktake.prepared_by.first_name} ${selectedStocktake.prepared_by.last_name}`}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Checked by" />
          <p className="text-xs">
            {selectedStocktake.checked_by &&
              `${selectedStocktake.checked_by.first_name} ${selectedStocktake.checked_by.last_name}`}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/3">
          <Label name="Date" />
          <p className="text-xs">{selectedStocktake.date}</p>
        </div>

        <div className="w-1/3 px-4">
          <Label name="Attachment" />
          {selectedStocktake.attachment ? (
            <Attachment path={selectedStocktake.attachment_full_url} />
          ) : (
            <p className="text-xs">No attachment.</p>
          )}
        </div>

        <div className="w-1/3 px-4">
          <Label name="Operation" />
          <p className="text-xs">{selectedStocktake.action}</p>
        </div>
      </div>

      {selectedStocktake.action === "With Adjustment" && (
        <>
          <Typography type="h1">Materials Adjusted</Typography>
          <table className="table-fixed w-100 mb-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Category</th>
                <th className="text-xs px-4 py-2">Code</th>
                <th className="text-xs px-4 py-2">Description</th>
                <th className="text-xs px-4 py-2">Unit</th>
                <th className="text-xs px-4 py-2">Price</th>
                <th className="text-xs px-4 py-2">Warehouse</th>
                <th className="text-xs px-4 py-2">Operation</th>
                <th className="text-xs px-4 py-2">Adjusted Qty</th>
                <th className="text-xs px-4 py-2">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {selectedStocktake.materials.map((material, index) => (
                <tr key={index} className="hover:bg-gray-200">
                  <td className="text-xs border px-2 py-2">
                    {material.common_category.name}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.code}</td>
                  <td className="text-xs border px-2 py-2">
                    {material.description}
                  </td>
                  <td className="text-xs border px-2 py-2">{material.unit}</td>
                  <td className="text-xs border px-2 py-2">
                    ${material.price}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.warehouse ? material.warehouse.name : ""}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.pivot.operation}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.pivot.qty}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {material.pivot.remarks}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      <Button
        text="Close"
        primary="true"
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </div>
  );
}
