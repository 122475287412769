import React from "react";
import "../../assets/css/Spinner.css";

function Spinner(props) {
  return (
    <div className="spinner self-center">
      <div className="dot1"></div>
      <div className="dot2"></div>
    </div>
  );
}

export default Spinner;
