import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Spinner,
  Pagination,
  Label,
  Modal,
  StyledDialog,
} from "../common";
import { EditButton } from "../common/icons/HeroIcons";
import MaterialIcon from "../../assets/img/material_icon.png";
import { getMaterials, selectMaterial } from "../../actions/materials";
import { getWarehouses } from "../../actions/warehouses";
import { getMaterialCategories } from "../../actions/materialCategories";
import {
  // getListMaterials,
  exportListMaterials,
} from "../../actions/materialWithdrawals";
import { getProjects } from "../../actions/projects";
import { getSuppliers } from "../../actions/suppliers";

import { specialisedMaterialListColumns } from "./MaterialTableColumns";
import useDebounce from "../../helpers/useDebounce";
import { useTransformOption } from "../../helpers/useTransformData";
import classNames from "classnames";
import Select from "react-select";
import MaterialForm from "./MaterialForm";
import MaterialPurchasedTotal from "./MaterialPurchasedTotal";

export default function SpecialisedMaterialList(props) {
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials.materials);
  const pagination = useSelector(
    (state) => state.materials.paginationMaterials
  );
  const projects = useSelector((state) => state.projects.projects);
  const categories = useSelector(
    (state) => state.materialCategories.materialCategories
  );
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const categoriesDropdown = useTransformOption([
    { id: null, name: "All" },
    ...categories,
  ]);
  const warehouses = useSelector((state) => state.warehouses.warehouses);
  const warehousesDropdown = useTransformOption([
    { id: null, name: "All" },
    ...warehouses,
  ]);
  const isLoading = useSelector((state) => state.materials.materialsLoading);
  const isExcelLoading = useSelector(
    (state) => state.materialWithdrawals.isExcelLoading
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState({
    value: null,
    label: "All",
  });
  const [selectedType, setSelectedType] = useState({
    value: null,
    label: "All",
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const projectsDropdown = useTransformOption(
    [{ id: null, title: "All" }, ...projects],
    "id",
    "title"
  );
  const suppliersDropdown = useTransformOption([
    { id: null, name: "All" },
    ...suppliers,
  ]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [formModal, setFormModal] = useState(false);
  const [purchasedAmountModal, setPurchasedAmountModal] = useState(false);

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    setPageNumber(1);
  };

  const handleWarehouseChange = (option) => {
    setSelectedWarehouse(option);
    setPageNumber(1);
  };

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setPageNumber(1);
  };

  const handleTypeChange = (option) => {
    setSelectedType(option);
    setPageNumber(1);
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getMaterialCategories());
    dispatch(getWarehouses());
    dispatch(getSuppliers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getMaterials(
        pageNumber,
        selectedProject ? selectedProject.value : null,
        null,
        selectedCategory ? selectedCategory.value : null,
        selectedType ? selectedType.value : null,
        debouncedSearchTerm,
        selectedWarehouse ? selectedWarehouse.value : null,
        selectedSupplier ? selectedSupplier.value : null
      )
    );
  }, [
    dispatch,
    pageNumber,
    selectedProject,
    selectedCategory,
    selectedType,
    debouncedSearchTerm,
    selectedWarehouse,
    selectedSupplier,
  ]);

  return (
    <div className="mt-4">
      {formModal && (
        <Modal>
          <MaterialForm
            onViewClose={() => {
              setFormModal(false);
            }}
            view={props.view}
          />
        </Modal>
      )}

      {purchasedAmountModal && (
        <StyledDialog isOpen={purchasedAmountModal} screenWidth={400}>
          <MaterialPurchasedTotal
            onViewClose={() => {
              setPurchasedAmountModal(false);
            }}
            projectId={selectedProject ? selectedProject.value : null}
          />
        </StyledDialog>
      )}
      <div className="flex mb-4">
        <div className="w-2/3">
          <Label name="Search" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNumber(1);
            }}
            type="text"
            placeholder="Search Code or Description..."
          />
        </div>

        <div className="w-1/3 px-4">
          <Label name="Project" />
          <Select
            className="text-xs"
            options={projectsDropdown}
            value={selectedProject}
            onChange={handleProjectChange}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/4 px-4">
          <Label name="Type" />
          <Select
            className="text-xs"
            options={[
              { value: null, label: "All" },
              { value: "Specialised", label: "Specialised" },
              { value: "Common", label: "Common" },
            ]}
            value={selectedType}
            onChange={handleTypeChange}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Category" />
          <Select
            className="text-xs"
            options={categoriesDropdown}
            value={selectedCategory}
            onChange={handleCategoryChange}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Warehouse" />
          <Select
            className="text-xs"
            options={warehousesDropdown}
            value={selectedWarehouse}
            onChange={handleWarehouseChange}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Supplier" />
          <Select
            className="text-xs"
            options={suppliersDropdown}
            value={selectedSupplier}
            onChange={handleSupplierChange}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-2/4 m-4">
          <Button
            success="true"
            text="Add New Material"
            onClick={() => {
              dispatch(selectMaterial(null));
              setFormModal(true);
            }}
          />
          <Button
            primary="true"
            text="Export to Excel"
            disabled={isExcelLoading}
            onClick={() => {
              dispatch(
                exportListMaterials(
                  selectedProject?.value ?? null,
                  null,
                  debouncedSearchTerm,
                  selectedType?.value ?? null,
                  selectedCategory?.value ?? null,
                  selectedSupplier?.value ?? null
                )
              );
            }}
          />
          <Button
            primary="true"
            text="Check Purchased Amount Total"
            onClick={() => {
              setPurchasedAmountModal(true);
            }}
          />
        </div>
      </div>
      <Table
        columns={specialisedMaterialListColumns}
        data={
          <>
            {!isLoading &&
              materials.map((material, index) => (
                <tr
                  key={material.id}
                  className={classNames("hover:bg-gray-200", {
                    "bg-red-200 hover:bg-red-400":
                      material.filtered_in_stock <= 0,
                  })}
                >
                  <td className="px-6 py-2 whitespace-nowrap">
                    <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                      {material.photo_full_url ? (
                        <img
                          alt="..."
                          className="w-full align-middle border-none"
                          src={material.photo_full_url}
                        />
                      ) : (
                        <img
                          alt="..."
                          className="w-full align-middle border-none"
                          src={MaterialIcon}
                        />
                      )}
                    </span>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.type}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.category
                        ? material.category.name
                        : "Uncategorized"}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.supplier ? material.supplier.name : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.code}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.description}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">{material.unit}</div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      $ {material.price}
                    </div>
                  </td>
                  {/* <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.warehouse ? material.warehouse.name : "-"}
                    </div>
                  </td> */}
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_bulk_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_request_order_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_delivered_order_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      ${" "}
                      {material.filtered_delivered_amount_total
                        ? material.filtered_delivered_amount_total.toFixed(3)
                        : 0}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_stocktake_adjustments}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_used_qty}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {material.filtered_in_stock}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex flex-row justify-between">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900 cursor-pointer"
                        onClick={() => {
                          dispatch(selectMaterial(material));
                          setFormModal(true);
                        }}
                      >
                        <EditButton title={"Update"} />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </>
        }
      />

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
