import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function StyledDialog(props) {
    const { 
        setIsOpen, 
        isOpen, 
        title,
        children,
        screenWidth 
    } = props;

    let completeButtonRef = useRef(null)

    return (
        <Fragment>
            <Transition 
                as={Fragment}
                show={isOpen}
                appear 
            >
                <Dialog
                    as="div"
                    initialFocus={completeButtonRef}
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={() => setIsOpen(false)}
                >
                <div 
                    className="min-h-screen px-4 text-center"
                    ref={completeButtonRef}
                >
                    <Transition.Child
                        as={Fragment}
                        // enter="ease-out duration-300"
                        // enterFrom="opacity-0"
                        // enterTo="opacity-100"
                        // leave="ease-in duration-200"
                        // leaveFrom="opacity-100"
                        // leaveTo="opacity-0"
                    >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                    &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        // enter="ease-out duration-300"
                        // enterFrom="opacity-0 scale-95"
                        // enterTo="opacity-100 scale-100"
                        // leave="ease-in duration-200"
                        // leaveFrom="opacity-100 scale-100"
                        // leaveTo="opacity-0 scale-95"
                    >
                    <div 
                        className="inline-block w-1/2 max-w-lg p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                        style={{ maxWidth: screenWidth }}
                    >
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                            {title}
                        </Dialog.Title>
                        <Dialog.Description
                            as="p"
                            className="text-sm"
                        >
                            {children}
                        </Dialog.Description>
                        {/* <div className="mt-4">
                        <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={() => setIsOpen(false)}
                        >
                            OK
                        </button>
                        </div> */}
                    </div>
                    </Transition.Child>
                </div>
                </Dialog>
            </Transition>
        </Fragment>
    )
}

export default StyledDialog;