import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_MATERIALS,
  LOADING_MATERIALS,
  SELECT_MATERIAL,
  ADD_MATERIAL,
  UPDATE_MATERIAL,
  DELETE_MATERIAL,
  LOADING_PURCHASED_MATERIALS_TOTAL,
  GET_PURCHASED_MATERIALS_TOTAL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET MATERIALS
export const getMaterials =
  (
    page,
    project = null,
    task = null,
    category = null,
    type = null,
    search_term = null,
    warehouse = null,
    supplier = null
  ) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_MATERIALS });

    let endpoint = `/materials/list?page=${page}`;

    if (project) endpoint += `&project_id=${project}`;
    if (task) endpoint += `&task_id=${task}`;
    if (category) endpoint += `&category=${category}`;
    if (type) endpoint += `&type=${type}`;
    if (search_term) endpoint += `&search_term=${search_term}`;
    if (warehouse) endpoint += `&warehouse_id=${warehouse}`;
    if (supplier) endpoint += `&supplier_id=${supplier}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// GET PURCHASED MATERIALS TOTAL
export const getPurchasedMaterialsTotal =
  (year = null, projectId = null) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_PURCHASED_MATERIALS_TOTAL });

    let endpoint = `/materials/total-purchased?year=${year}`;

    if (projectId) {
      endpoint += `&project=${projectId}`;
    }

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_PURCHASED_MATERIALS_TOTAL,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

// SELECT MATERIAL
export const selectMaterial = (material) => (dispatch) => {
  dispatch({
    type: SELECT_MATERIAL,
    payload: material,
  });
};

// SEARCH MATERIAL CODE
export const searchMaterials =
  (
    search_term,
    category = null,
    supplier = null,
    project = null,
    task = null
  ) =>
  (dispatch, getState) => {
    return new Promise(function (resolve, reject) {
      let endpoint = `/materials/search-code?search_term=${search_term}`;
      if (supplier) endpoint += `&supplier_id=${supplier}`;
      if (project) endpoint += `&project_id=${project}`;
      if (task) endpoint += `&task_id=${task}`;
      if (category) endpoint += `&category_id=${category}`;
      axios
        .get(BASE_URL + endpoint, tokenConfig(getState))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };

// ADD MATERIAL
export const addMaterial = (material) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/material`, material, tokenConfig(getState))
    .then((res) => {
      if (res.data.code_exists) {
        swal({
          title: "Code already exists",
          text: "Material not saved. Kindly change the code and try again.",
          icon: "warning",
        });
      } else {
        dispatch({
          type: ADD_MATERIAL,
          payload: res.data,
        });
        swal({
          title: "Material Added!",
          icon: "success",
        });
      }
    })
    .catch((err) => console.error(err));
};

// UPDATE MATERIAL
export const updateMaterial = (id, material) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/material/${id}`, material, tokenConfig(getState))
    .then((res) => {
      if (res.data.code_exists) {
        swal({
          title: "Code already exists",
          text: "Material not saved. Kindly change the code and try again.",
          icon: "warning",
        });
      } else {
        dispatch({
          type: UPDATE_MATERIAL,
          payload: res.data,
        });
        swal({
          title: "Material Updated!",
          icon: "success",
        });
      }
    })
    .catch((err) => console.error(err));
};

// DELETE MATERIAL
export const deleteMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/material/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_MATERIAL,
        payload: id,
      });
      swal({
        title: "Material Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
