import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Fieldset, Label, Pagination, Spinner } from "../common";
import Select from "react-select";

import { getMaterials, selectMaterial } from "../../actions/materials";
import { getMaterialCategories } from "../../actions/materialCategories";
import { getToolFilters } from "../../actions/tools";

export default function MaterialInventoryPage(props) {
  const dispatch = useDispatch();
  const materials = useSelector((state) => state.materials.materials);
  const materialsLoading = useSelector(
    (state) => state.materials.materialsLoading
  );
  const pagination = useSelector(
    (state) => state.materials.paginationMaterials
  );
  const selectedMaterial = useSelector(
    (state) => state.materials.selectedMaterial
  );
  const projects = useSelector((state) => state.tools.filters.projects);
  const categories = useSelector(
    (state) => state.materialCategories.materialCategories
  );

  //FILTERS
  const [categorySearchTerm, setCategorySearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "All",
  });
  const [selectedProject, setSelectedProject] = useState({
    value: "",
    label: "All",
  });
  const [pageNumber, setPageNumber] = useState(1);

  let projectsDropdown = [{ value: "", label: "All" }];
  let categoriesDropdown = [{ value: "", label: "All" }];

  for (let i in projects) {
    let projectDropdownObj = {
      value: projects[i].id,
      label: projects[i].title,
    };

    projectsDropdown.push(projectDropdownObj);
  }

  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  useEffect(() => {
    dispatch(getToolFilters());
    dispatch(getMaterialCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getMaterials(
        pageNumber,
        selectedProject.value,
        "",
        selectedCategory.value,
        categorySearchTerm
      )
    );
  }, [
    dispatch,
    pageNumber,
    selectedProject,
    selectedCategory,
    categorySearchTerm,
  ]);

  return (
    <Fieldset>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
        Material Inventories
      </h1>
      <div className="mb-4">
        <div className="flex mt-4 px-4">
          <div className="w-1/3 px-2">
            <Label name="Category" />
            <Select
              options={categoriesDropdown}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Project" />
            <Select
              options={projectsDropdown}
              value={selectedProject}
              onChange={setSelectedProject}
            />
          </div>
          <div className="w-1/3 px-2">
            <Label name="Search" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              value={categorySearchTerm}
              onChange={(e) => setCategorySearchTerm(e.target.value)}
              type="text"
              placeholder="Search for Item Code or Description..."
            />
          </div>
        </div>
      </div>

      <table className="table-auto ml-6">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Estimated</th>
            <th className="text-xs px-4 py-2">PO</th>
            <th className="text-xs px-4 py-2">Requested</th>
            <th className="text-xs px-4 py-2">Withdrawn</th>
            <th className="text-xs px-4 py-2">
              Stock Remaining <br />
              (Warehouse)
            </th>
          </tr>
        </thead>

        <tbody>
          {!materialsLoading &&
            materials.map((material) => (
              <tr
                key={material.id}
                className={classNames("hover:bg-gray-200 cursor-pointer", {
                  "text-blue-600 bg-teal-200":
                    (selectedMaterial ? selectedMaterial.id : 0) ===
                    material.id,
                })}
                onClick={() => {
                  dispatch(selectMaterial(material));
                }}
              >
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">
                  {material.estimated}
                </td>
                <td className="text-xs border px-2 py-2">{material.in}</td>
                <td className="text-xs border px-2 py-2">
                  {material.withdrawn}
                </td>
                <td className="text-xs border px-2 py-2">
                  {material.withdrawn}
                </td>
                <td className="text-xs border px-2 py-2">{material.stock}</td>
              </tr>
            ))}
        </tbody>
      </table>

      {materialsLoading && <Spinner />}

      {pagination && !materialsLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </Fieldset>
  );
}
