import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button } from "../../common";
import swal from "sweetalert";
import { allocateMaterial } from "../../../actions/commonAllocatedMaterials";

export default function CommonMaterialAllocateForm(props) {
  const dispatch = useDispatch();
  const selectedForAllocate = useSelector(
    (state) => state.commonAllocatedMaterials.selectedForAllocate
  );
  const [checkedState, setCheckedState] = useState(
    new Array(selectedForAllocate.materials.length).fill(false)
  );
  const [inputFields, setInputFields] = useState(
    new Array(selectedForAllocate.materials.length).fill({
      allocated_qty: 0,
    })
  );
  const user = useSelector((state) => state.auth.user);

  const [allocatedComments, setAllocatedComments] = useState("");

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const handleAllChecked = (event) => {
    const updatedCheckedState = checkedState.map(() => event.target.checked);

    setCheckedState(updatedCheckedState);
  };

  const handleInputChange = (position, event) => {
    const values = inputFields.map((item, index) => {
      if (index !== position) {
        return item;
      }

      return {
        ...item,
        [event.target.name]: event.target.value,
      };
    });

    setInputFields(values);
  };

  const onSubmit = () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will allocate the materials to the requestor.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let selectedMaterials = [];
        selectedForAllocate.materials.forEach((material, index) => {
          if (checkedState[index] === true) {
            selectedMaterials.push({
              id: material.id,
              allocated_qty: inputFields[index].allocated_qty,
            });
          }
        });

        let data = {
          materials: selectedMaterials,
          id: selectedForAllocate.id,
          allocated_comments: allocatedComments,
          allocated_by: user.employee_id,
        };

        dispatch(allocateMaterial(data));
        props.onFormClose && props.onFormClose();
      }
    });
  };

  return (
    <form>
      <h1 className="font-bold">Allocate Material</h1>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Comments" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            name="comments"
            type="text"
            value={allocatedComments}
            onChange={(e) => setAllocatedComments(e.target.value)}
          />
        </div>
      </div>

      <p className="font-bold mt-4">Materials</p>
      <p className="text-xs text-gray-600">
        please choose materials to be allocated to requestor.
      </p>
      <p className="text-xs text-gray-600 mb-4">
        note: allocated quantity would be the actual count that can be used on
        project tasks.
      </p>

      <div className="ml-4">
        <input
          name="select_all"
          type="checkbox"
          onChange={(e) => handleAllChecked(e)}
        />
        <label for="select_all" className="text-xs font-bold ml-4">
          Check/Uncheck All
        </label>
      </div>

      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Select</th>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Price</th>
            <th className="text-xs px-4 py-2">Warehouse</th>
            <th className="text-xs px-4 py-2">Ordered Qty</th>
            <th className="text-xs px-4 py-2">
              Delivered Qty <br />
              (Stock Qty)
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedForAllocate.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td>
                <input
                  type="checkbox"
                  name="material_id[]"
                  checked={checkedState[index]}
                  onChange={() => handleCheckboxChange(index)}
                  className="ml-4"
                />
              </td>
              <td className="text-xs border px-2 py-2">
                {material.common_category.name}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">${material.price}</td>
              <td className="text-xs border px-2 py-2">
                {material.warehouse ? material.warehouse.name : ""}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.common_requested_qty}
              </td>
              <td className="text-xs border px-2 py-2">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  id={`allocated_qty-${index}`}
                  name="allocated_qty"
                  type="text"
                  value={inputFields[index].allocated_qty}
                  onChange={(event) => handleInputChange(index, event)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Allocate"
          success="true"
          onClick={() => {
            onSubmit();
          }}
          disabled={!selectedForAllocate}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose() && props.onFormClose();
          }}
        />
      </div>
    </form>
  );
}
