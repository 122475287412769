import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Spinner, Table, EmptyPlaceholder } from "../common";
import { getProjectToolRequests } from "../../actions/tools";
import { toolRequestListColumns } from "./MyTasksListColumns";
import moment from "moment";

export default function MyTasksToolList(props) {
  const dispatch = useDispatch();
  const projectToolRequests = useSelector(
    (state) => state.tools.projectToolRequests
  );
  const selectedMyTask = useSelector((state) => state.tasks.selectedMyTask);
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const pagination = useSelector(
    (state) => state.tools.paginationProjectToolRequests
  );
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getProjectToolRequests(selectedMyTask.id, pageNumber));
  }, [dispatch, selectedMyTask, pageNumber]);

  return (
    <>
      <Table
        columns={toolRequestListColumns}
        data={
          <>
            {!toolsLoading &&
              projectToolRequests.map(
                (toolRequest) =>
                  (toolRequest.status !== "Denied" ||
                    (toolRequest.status === "Denied" &&
                      moment(moment(new Date())).diff(
                        moment(toolRequest.date),
                        "days"
                      ) <= 30)) && (
                    <tr
                      key={toolRequest.id}
                      className="hover:bg-gray-200 cursor-pointer"
                    >
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-full"
                            style={{
                              background:
                                toolRequest.status === "Pending"
                                  ? "#ffb52c"
                                  : toolRequest.status === "Approved"
                                  ? "#57bf57"
                                  : "#f56565",
                              color: "#fff",
                            }}
                          >
                            {toolRequest.status}
                          </span>
                          {toolRequest.status === "Denied" && (
                            <span className="text-gray-600">
                              Will automatically be archived after 30 days.
                            </span>
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.date}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.requested_by &&
                            `${toolRequest.requested_by.first_name} ${toolRequest.requested_by.last_name}`}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.request_by_type}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tools.map((tool) => (
                            <>
                              {`- ${tool.description} `}
                              {toolRequest.request_by_type === "Description"
                                ? `(Qty: ${tool.quantity})`
                                : `(S/N: ${tool.serial_no})`}
                              <br />
                            </>
                          ))}
                        </div>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.comments}
                        </div>
                      </td>
                    </tr>
                  )
              )}
          </>
        }
      />

      {projectToolRequests < 1 && (
        <EmptyPlaceholder
          text="No Requests Found"
          subText="click on Requests for Tools tab to request for one."
        />
      )}

      {toolsLoading && <Spinner />}

      {pagination && !toolsLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </>
  );
}
