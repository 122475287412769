export const initialValueTool = {
  company: null,
  category: null,
  brand: "",
  description: "",
  serialNumber: "",
  purchaseDate: null,
  expiryDate: null,
};

export const toolConditionOptions = [
  {
    value: "Good",
    label: "Good",
  },
  {
    value: "Poor",
    label: "Poor",
  },
];
