import {
  GET_COMMON_STOCKTAKES,
  SELECT_COMMON_STOCKTAKE,
  ADD_COMMON_STOCKTAKE,
  DELETE_COMMON_STOCKTAKE,
  LOADING_COMMON_STOCKTAKES,
} from "../actions/types";

const initialState = {
  isLoading: false,
  stocktakes: [],
  stocktakesPagination: null,
  selectedStocktake: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_COMMON_STOCKTAKES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMMON_STOCKTAKES:
      return {
        ...state,
        stocktakes: action.payload,
        stocktakesPagination: action.pagination,
        isLoading: false,
      };
    case ADD_COMMON_STOCKTAKE:
      return {
        ...state,
        stocktakes: [...state.stocktakes, action.payload],
        stocktakesPagination: {
          ...state.stocktakesPagination,
          total: state.stocktakesPagination.total + 1,
        },
      };
    case SELECT_COMMON_STOCKTAKE:
      return {
        ...state,
        selectedStocktake: action.payload,
      };
    case DELETE_COMMON_STOCKTAKE:
      return {
        ...state,
        stocktakes: state.stocktakes.filter(
          (stocktake) => stocktake.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
