import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSidebarNav } from "../../actions/general";
import { roles } from "../../helpers/userRoles";
import Logo from "../../assets/img/sbhepm_logo_black.png";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const user = useSelector((state) => state.auth.user);
  const activeSidebarNav = useSelector(
    (state) => state.general.activeSidebarNav
  );
  const dispatch = useDispatch();

  return (
    <div className="transform ease-in-out transition-all duration-300 fixed h-full">
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
            className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
            to="/"
            onClick={() => {
              dispatch(setActiveSidebarNav("My Tasks"));
            }}
          >
            <img src={Logo} alt="SBHE PM Logo" />
          </Link>

          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                    onClick={() => {
                      dispatch(setActiveSidebarNav("My Tasks"));
                    }}
                  >
                    SBHE PM
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Navigation */}
            {(user.employee.role_id !== roles.drvr ||
              user.employee.role_id !== roles.log ||
              user.employee.role_id !== roles.proc) && (
              <>
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <li className="items-center">
                    <Link
                      className={
                        activeSidebarNav === "My Tasks"
                          ? "text-orange-500 hover:text-orange-600 text-xs uppercase py-3 font-bold block"
                          : "text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block"
                      }
                      to="/"
                      onClick={() => {
                        dispatch(setActiveSidebarNav("My Tasks"));
                      }}
                    >
                      <i className="fas fa-tools opacity-75 mr-2 text-sm"></i>{" "}
                      My Tasks
                    </Link>
                  </li>
                </ul>
                {/* Divider */}
                <hr className="my-4 md:min-w-full" />
              </>
            )}

            {(user.employee.role_id === roles.drvr ||
              user.employee.role_id === roles.log ||
              user.employee.role_id === roles.dir ||
              user.employee.role_id === roles.coord ||
              user.employee.role_id === roles.proc) && (
              <div>
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  {/* Heading */}
                  <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    manage
                  </h6>
                  {user.employee.role_id !== roles.engr && (
                    <li className="inline-flex">
                      <Link
                        className={
                          activeSidebarNav === "Tools"
                            ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                            : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                        }
                        onClick={() => {
                          dispatch(setActiveSidebarNav("Tools"));
                        }}
                        to="/tools"
                      >
                        <i className="fas fa-dot-circle mr-2 text-gray-500 text-base"></i>{" "}
                        Tools
                      </Link>
                    </li>
                  )}
                  {/* <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Equipments"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Equipments"));
                      }}
                      to="/equipments"
                    >
                      <i className="fas fa-hammer mr-2 text-gray-500 text-base"></i>{" "}
                      Equipments
                    </Link>
                  </li> */}
                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Materials"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Materials"));
                      }}
                      to="/materials"
                    >
                      <i className="fas fa-anchor mr-2 text-gray-500 text-base"></i>{" "}
                      Materials
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            {(user.employee.role_id === roles.su ||
              user.employee.role_id === roles.hr) && (
              <div>
                {/* Heading */}
                <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  personnel
                </h6>
                {/* Navigation */}
                <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Attendance"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Attendance"));
                      }}
                      to="/attendance"
                    >
                      <i className="far fa-calendar-check mr-2 text-gray-500 text-base"></i>{" "}
                      Attendance
                    </Link>
                  </li>
                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Employees"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Employees"));
                      }}
                      to="/employees"
                    >
                      <i className="far fa-address-card mr-2 text-gray-500 text-base"></i>{" "}
                      Employees
                    </Link>
                  </li>
                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Users"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Users"));
                      }}
                      to="/users"
                    >
                      <i className="far fa-user mr-2 text-gray-500 text-base"></i>{" "}
                      Users
                    </Link>
                  </li>
                  {/* Divider */}
                  <hr className="my-4 md:min-w-full" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                    manage
                  </h6>
                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Project Setup"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Project Setup"));
                      }}
                      to="/projects"
                    >
                      <i className="fas fa-cog mr-2 text-gray-500 text-base"></i>{" "}
                      Project Setup
                    </Link>
                  </li>

                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Materials"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Materials"));
                      }}
                      to="/materials"
                    >
                      <i className="fas fa-anchor mr-2 text-gray-500 text-base"></i>{" "}
                      Materials
                    </Link>
                  </li>

                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Tools"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Tools"));
                      }}
                      to="/tools"
                    >
                      <i className="fas fa-tools mr-2 text-gray-500 text-base"></i>{" "}
                      Tools
                    </Link>
                  </li>

                  {/* <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Equipments"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Equipments"));
                      }}
                      to="/equipments"
                    >
                      <i className="fas fa-hammer mr-2 text-gray-500 text-base"></i>{" "}
                      Equipments
                    </Link>
                  </li> */}

                  <li className="inline-flex">
                    <Link
                      className={
                        activeSidebarNav === "Announcements"
                          ? "text-orange-500 hover:text-orange-600 text-sm block mb-4 no-underline font-semibold"
                          : "text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                      }
                      onClick={() => {
                        dispatch(setActiveSidebarNav("Announcements"));
                      }}
                      to="/announcements"
                    >
                      <i className="fas fa-bell mr-2 text-gray-500 text-base"></i>{" "}
                      Announcements
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
