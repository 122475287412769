import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, CropPicUpload } from "../common";
import swal from "sweetalert";

import { updateProfilePicture } from "actions/employees";

export default function ChangeProfilePicture(props) {
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState([]);
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee
  );

  const onSubmit = async () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will change the Profile Picture.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let data = {};
        if (photo) {
          data.base64image = photo;
        } else {
          data.base64image = null;
        }
        dispatch(updateProfilePicture(selectedEmployee.id, data));
        props.onPictureSubmit && props.onPictureSubmit();
      }
    });
  };

  return (
    <div>
      <h1 className="font-bold">Update Profile Picture</h1>

      <div className="mt-4">
        <Label name="Photo" />
        <CropPicUpload
          onSaveCroppedImage={(image) => {
            setPhoto(image);
          }}
        />
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Save Changes"
          success="true"
          type="button"
          onClick={() => {
            onSubmit();
          }}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
