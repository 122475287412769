import { roles } from "./userRoles";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (employees, assigneeId = 0, picIds) {
  let engineers = [];
  let coordinators = [];
  let workers = [];
  let supervisors = [];
  let inactiveEmployees = [];
  let safety = [];
  let qs = [];
  let director = [];
  let pm = null;
  let pics = [];
  for (let i in employees) {
    if (employees[i].role_id !== roles.su) {
      //Assign Project Manager/Coordinator
      if (employees[i].employee_status === "Inactive") {
        inactiveEmployees.push(employees[i]);
      } else if (
        // assigneeId === employees[i].id &&
        employees[i].role_id === roles.pm
      ) {
        pm = employees[i];
      }
      if (employees[i].employee_status !== "Inactive") {
        if (picIds) {
          if (picIds.indexOf(employees[i].id) !== -1) {
            //Assign Person-in-charge
            pics.push(employees[i]);
          }
        }

        if (employees[i].role_id === roles.dir) {
          //Assign Directors
          director.push(employees[i]);
        } else if (employees[i].role_id === roles.coord) {
          //Assign Coordinators
          coordinators.push(employees[i]);
        } else if (employees[i].role_id === roles.engr) {
          //Assign Engineers
          engineers.push(employees[i]);
        } else if (employees[i].role_id === roles.sup) {
          //Assign Supervisors
          supervisors.push(employees[i]);
        } else if (employees[i].role_id === roles.wrkr) {
          //Assign Supervisors
          workers.push(employees[i]);
        } else if (employees[i].role_id === roles.sfty) {
          //Assign Safety
          safety.push(employees[i]);
        } else if (employees[i].role_id === roles.qs) {
          qs.push(employees[i]);
        }
      }
    }
  }

  return {
    inactiveEmployees,
    pm,
    director,
    pics,
    engineers,
    coordinators,
    supervisors,
    workers,
    safety,
    qs,
  };
}
