import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Typography,
  Label,
  Table,
  EmptyPlaceholder,
} from "components/common";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import swal from "sweetalert";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { getUnissuedTools, issueTool } from "../../actions/tools";
import { searchEmployees } from "../../actions/employees";
import { searchProjects } from "../../actions/projects";
import {
  transformDate,
  transformUnissuedTools,
} from "../../helpers/useTransformData";
import { toolRequestRequestedToolsColumns } from "./ToolTableColumns";

function ToolIssuingForm(props) {
  const dispatch = useDispatch();
  const selectedToolRequest = useSelector(
    (state) => state.tools.selectedToolRequest
  );
  const unissuedTools = useSelector((state) => state.tools.unissuedTools);

  useEffect(() => {
    dispatch(getUnissuedTools());
  }, [dispatch]);

  const [issueDate, setIssueDate] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [project, setProject] = useState(null);
  const [driver, setDriver] = useState(null);
  const [comments, setComments] = useState(null);
  const [requestedTools, setRequestedTools] = useState(
    selectedToolRequest ? selectedToolRequest.tools : []
  );

  const [additionalQuantity, setAdditionalQuantity] = useState(1);
  const additionalToolOptions = transformUnissuedTools(unissuedTools);
  const [selectedAdditionalTool, setSelectedAdditionalTool] = useState(null);

  const handleAdditionalToolChange = (option) => {
    setSelectedAdditionalTool(option);
  };

  const handleIssueDateChange = (date) => {
    setIssueDate(date);
  };

  const handleRecipientChange = (option) => {
    setRecipient(option);
  };

  const handleDriverChange = (option) => {
    setDriver(option);
  };

  const handleProjectChange = (option) => {
    setProject(option);
  };

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  const loadRecipientOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let recipientOptions = [];

    for (let i in search) {
      recipientOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(recipientOptions);
  };

  const loadDriverOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "drvr")).then((res) => {
        resolve(res.data);
      });
    });

    let driverOptions = [];

    for (let i in search) {
      if (search) {
        driverOptions.push({
          value: search[i].id,
          label: `${search[i].first_name} ${search[i].last_name}`,
        });
      }
    }

    callback(driverOptions);
  };

  const loadProjectOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchProjects(inputValue)).then((res) => {
        console.log(res);
        resolve(res);
      });
    });

    let projectOptions = [];

    for (let i in search) {
      projectOptions.push({
        value: search[i].id,
        label: search[i].title,
      });
    }

    callback(projectOptions);
  };

  const addAdditionalTool = () => {
    let toolsArr = [
      ...requestedTools,
      {
        id: requestedTools.reduce((max, r) => (r.id > max ? r.id : max), 0) + 1, //fake ID: get possible max ID and add 1
        quantity: additionalQuantity,
        tool: { ...selectedAdditionalTool },
      },
    ];
    setRequestedTools(toolsArr);
  };

  const removeTool = (toolRequest) => {
    let toolsArr = requestedTools;
    let result = toolsArr.filter((toolArr) => toolArr.id !== toolRequest.id);

    setRequestedTools(result);
  };

  const onSubmit = async () => {
    let toolsRequested = [];

    if (requestedTools) {
      const tools = requestedTools;
      for (let i in tools) {
        toolsRequested.push({
          id: tools[i].tool.id,
          condition: tools[i].tool.condition,
        });
      }
    }
    const body = {
      driver: driver.value,
      project_id: project.value,
      recipient: recipient.value,
      date: issueDate ? transformDate(issueDate) : null,
      comments: comments,
      items: toolsRequested,
    };

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `You want to issue this tool?`,
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(issueTool(body));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <S.ToolIssuingForm>
      <Typography type="h1">Issue Tool</Typography>
      <div className="flex flex-row w-full justify-between">
        <div className="w-full mr-2" style={{ zIndex: 20 }}>
          <Label name="Issue Date" />
          <DatePicker
            selected={issueDate}
            dateFormat="yyyy-MM-dd"
            autoComplete="off"
            minDate={new Date()}
            onChange={handleIssueDateChange}
            value={issueDate}
            wrapperClassName="w-full"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div className="w-full ml-2">
          <Label name="Issue To (Recipient)" />
          <AsyncSelect
            loadOptions={loadRecipientOptions}
            value={recipient}
            onChange={handleRecipientChange}
            className="text-xs shadow-sm"
          />
        </div>
      </div>

      <div className="flex flex-row w-full justify-between mt-4">
        <div className="w-full mr-2">
          <Label name="Driver" />
          <AsyncSelect
            loadOptions={loadDriverOptions}
            value={driver}
            onChange={handleDriverChange}
            className="text-xs shadow-sm"
          />
        </div>
        <div className="w-full ml-2">
          <Label name="Project" />
          <AsyncSelect
            loadOptions={loadProjectOptions}
            value={project}
            onChange={handleProjectChange}
            className="text-xs shadow-sm"
          />
        </div>
      </div>

      <div className="flex flex-row w-full justify-between mt-4 mb-4">
        <div className="w-full">
          <Label name="Comments (Optional)" />
          <input
            type="text"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            autoComplete="off"
            name="comments"
            onChange={handleCommentChange}
            value={comments}
          />
        </div>
      </div>

      <Typography type="h1">Tools Requested</Typography>
      <Typography type="muted">
        Double check the tools being selected for its condition. You can add
        more tools to issue if necessary.
      </Typography>

      <div className="flex flex-row w-full justify-between mt-4 mb-2">
        <div className="w-full mr-2">
          <Label name="Add More Tools" />
          <Select
            className="text-xs shadow-sm"
            options={additionalToolOptions}
            onChange={handleAdditionalToolChange}
            value={selectedAdditionalTool}
          />
        </div>
        <div className="w-full ml-2">
          <Label name="Quantity" />
          <input
            type="number"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            autoComplete="off"
            value={additionalQuantity}
            onChange={(e) => {
              setAdditionalQuantity(e.target.value);
            }}
          />
        </div>
        <div className="ml-4">
          <Button
            primary="true"
            text="+ add tool"
            disabled={!selectedAdditionalTool}
            onClick={() => {
              addAdditionalTool();
            }}
          />
        </div>
      </div>

      <Table
        columns={[...toolRequestRequestedToolsColumns, { name: "Remove?" }]}
        data={
          <>
            {requestedTools ? (
              <>
                {requestedTools.map(
                  (toolRequest, index) =>
                    toolRequest.tool && (
                      <tr
                        key={index}
                        className="hover:bg-gray-200 cursor-pointer"
                      >
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.tool.brand
                              ? toolRequest.tool.brand
                              : ""}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.tool.description}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.quantity}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.tool.serial_no}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.tool.purchase_date}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.tool.warranty_expiry_date}
                          </div>
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          {toolRequest.tool.condition ? (
                            <span
                              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-full"
                              style={{
                                background:
                                  toolRequest.tool.condition === "Poor"
                                    ? "#ffb52c"
                                    : "#57bf57",
                                color: "#fff",
                              }}
                            >
                              {toolRequest.tool.condition}
                            </span>
                          ) : (
                            "---"
                          )}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap">
                          <Button
                            danger="true"
                            text="-"
                            onClick={() => {
                              removeTool(toolRequest);
                            }}
                          />
                        </td>
                      </tr>
                    )
                )}
              </>
            ) : (
              <EmptyPlaceholder text="No Tools Found" />
            )}
          </>
        }
      />

      <div className="justify-center flex mt-4 px-4">
        <Button onClick={() => onSubmit()} success="true" text="Submit" />

        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </S.ToolIssuingForm>
  );
}

const S = {};

S.ToolIssuingForm = styled.div`
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

export default ToolIssuingForm;
