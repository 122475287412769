import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_MATERIAL_CATEGORIES,
  ADD_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY,
  SELECT_MATERIAL_CATEGORY,
  DELETE_MATERIAL_CATEGORY,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET MATERIAL CATEGORIES
export const getMaterialCategories = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/material_categories`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MATERIAL_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// ADD MATERIAL CATEGORY
export const addMaterialCategory = (category) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/material_category`, category, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_MATERIAL_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Material Category Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE MATERIAL CATEGORY
export const updateMaterialCategory =
  (id, category) => (dispatch, getState) => {
    axios
      .put(
        BASE_URL + `/material_category/${id}`,
        category,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UPDATE_MATERIAL_CATEGORY,
          payload: res.data,
        });
        swal({
          title: "Material Category Updated",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// SELECT MATERIAL CATEGORY
export const selectMaterialCategory = (category) => (dispatch) => {
  dispatch({
    type: SELECT_MATERIAL_CATEGORY,
    payload: category,
  });
};

// DELETE MATERIAL CATEGORY
export const deleteMaterialCategory = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/material_category/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_MATERIAL_CATEGORY,
        payload: id,
      });
      swal({
        title: "Material Category Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
