import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_DOCUMENTATIONS,
  GET_DOCUMENTATIONS,
  SELECT_DOCUMENTATION,
  ADD_DOCUMENTATION,
  LOADING_DOCUMENTATION_FORM,
  APPROVE_REJECT_DOCUMENTATION,
  UPDATE_DOCUMENTATION,
  DELETE_DOCUMENTATION,
  LOADING_MANPOWER_COUNT,
  GET_MANPOWER_COUNT,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET DOCUMENTATIONS
export const getDocumentations =
  (forType, forId, docType, params = {}, offset = 0, search, page = 1) =>
  (dispatch, getState) => {
    let endpoint = BASE_URL + "/";
    if (docType === "daily_report") {
      if (forType === "projects") {
        endpoint += `daily-report/project/${forId}?page=${page}`;
      } else {
        //task
        endpoint += `daily-report/task/${forId}?page=${page}`;
      }
    } else {
      endpoint += `documents/mobile?for=${forType}&forId=${forId}&type=${docType}&offset=${offset}`;
    }
    if (params.date) endpoint += `&date=${params.date}`;
    if (search) endpoint += `&search=${search}`;

    dispatch({ type: LOADING_DOCUMENTATIONS });
    axios
      .get(endpoint, tokenConfig(getState))
      .then((res) => {
        if (docType === 'daily_report') {
          dispatch({
            type: GET_DOCUMENTATIONS,
            payload: res.data.data,
            pagination: {
              from: res.data.from,
              to: res.data.to,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            }
          });
        } else {
          dispatch({
            type: GET_DOCUMENTATIONS,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => console.error(err));
  };

export const selectDocumentation = (document) => (dispatch) => {
  dispatch({
    type: SELECT_DOCUMENTATION,
    payload: document,
  });
};

export const generatePDF = (document, type) => (dispatch, getState) => {
  dispatch({ type: LOADING_DOCUMENTATION_FORM });
  let url = BASE_URL + "/";

  if (type === "daily_report") {
    url += "daily-report/pdf/" + document.id;
  } else if (type === "instruction") {
    url += "instruction/" + document.id + "/generate";
  } else {
    url += "documents/generate/" + document.id;
  }

  axios
    .get(url, tokenConfig(getState))
    .then((res) => {
      window.open(res.data.data.pdf_full_url, "_blank");
      dispatch({ type: APPROVE_REJECT_DOCUMENTATION }); //just setting the loading to false.
    })
    .catch((err) => console.error(err));
};

export const addDocumentation =
  (id, type, payload, selectedTask) => (dispatch, getState) => {
    let endpoint = BASE_URL;

    if (payload.type === "daily_report") {
      endpoint += `/daily-report`;
    } else {
      endpoint += `/documents/${type}/${id}`;
    }
    axios
      .post(endpoint, payload, tokenConfig(getState))
      .then((res) => {
        swal({
          title: "Documentation Added",
          text: "Kindly proceed to Documentations tab to see the report.",
          icon: "success",
        });
        dispatch({ type: ADD_DOCUMENTATION });
        dispatch(
          getDocumentations(
            selectedTask.type,
            selectedTask.id,
            payload.type,
            {},
            0,
            ""
          )
        );
      })
      .catch((err) => console.error(err));
  };

export const updateDocumentation = (id, data) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/documents/${id}`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_DOCUMENTATION,
        payload: res.data.data,
      });
      swal({
        title: "Documentation Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// DELETE DOCUMENTATION
export const deleteDocumentation = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/documents/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_DOCUMENTATION,
        payload: id,
      });
      swal({
        title: "Selected Documentation Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const approveDailyProgressReport =
  (id, comments) => (dispatch, getState) => {
    dispatch({ type: LOADING_DOCUMENTATION_FORM });
    axios
      .put(
        BASE_URL + `/daily-report/submit/${id}`,
        {
          _method: "put",
          comments,
          status: "approved",
        },
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: APPROVE_REJECT_DOCUMENTATION });
        swal({
          title: "Report Approved!",
          icon: "success",
        });
      })
      .catch((err) => {
        dispatch({ type: APPROVE_REJECT_DOCUMENTATION });
        console.error(err);
      });
  };

export const rejectDailyProgressReport =
  (id, comments) => (dispatch, getState) => {
    dispatch({ type: LOADING_DOCUMENTATION_FORM });
    axios
      .put(
        BASE_URL + `/daily-report/submit/${id}`,
        {
          _method: "put",
          comments,
          status: "rejected",
        },
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({ type: APPROVE_REJECT_DOCUMENTATION });
        swal({
          title: "Report Rejected!",
          icon: "success",
        });
      })
      .catch((err) => {
        dispatch({ type: APPROVE_REJECT_DOCUMENTATION });
        console.error(err);
      });
  };

// DELETE DAILY PROGRESS REPORT
export const deletedDailyProgressReport = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/daily-report/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_DOCUMENTATION,
        payload: id,
      });
      swal({
        title: "Daily Progress Report Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE DAILY PROGRESS REPORT
export const updateDailyProgressReport =
  (id, data, mainType) => (dispatch, getState) => {
    axios
      .put(BASE_URL + `/daily-report/${id}`, data, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_DOCUMENTATION,
          payload: res.data.data,
        });
        swal({
          title: "Daily Progress Report Updated!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// GET MANPOWER COUNT
export const getManpowerCount =
  (projectId, type, startDate, endDate) => (dispatch, getState) => {
    dispatch({ type: LOADING_MANPOWER_COUNT });

    let endpoint = BASE_URL + `/documents/project/${projectId}?`;
    if (type) {
      endpoint += `type=${type}&`;
    }

    if (startDate) {
      endpoint += `start_date=${startDate}&`;
    }

    if (endDate) {
      endpoint += `end_date=${endDate}&`;
    }

    axios
      .get(endpoint, tokenConfig(getState))
      .then((res) => {
        let manpowerCount = 0;
        let tasks = [];
        console.log(res.data.data);
        if (res.data.data) {
          let data = res.data.data;
          data.forEach((task) => {
            let taskData = {
              id: task.id,
              title: task.title,
              manpower_count: task.manpower_count,
            };
            tasks.push(taskData);
            manpowerCount += task.manpower_count;
          });
        }
        dispatch({
          type: GET_MANPOWER_COUNT,
          payload: {
            manpower_count: manpowerCount,
            tasks,
          },
        });
      })
      .catch((err) => console.error(err));
  };
