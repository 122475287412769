import React, { useState } from "react";
import classNames from "classnames";

function SortBy({ order = "desc", onOrderChange }) {
  return (
    <i
      className={classNames("cursor-pointer fas px-2", {
        "fa-chevron-circle-down": order === "desc",
        "fa-chevron-circle-up": order === "asc",
      })}
      onClick={() => {
        if (onOrderChange) {
          onOrderChange(order === "desc" ? "asc" : "desc");
        }
      }}
    ></i>
  );
}

export default SortBy;
