import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, Avatar } from "../common";
import moment from "moment";
import swal from "sweetalert";
import {
  addIssuePoMaterial,
  getRunningNumber,
} from "../../actions/issuePoMaterials";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { searchEmployees } from "../../actions/employees";
import { getCompanies } from "../../actions/companies";
import { getWarehouses } from "../../actions/warehouses";
import { paymentTermOptions } from "../../constants/materials";
import { useTransformOption } from "../../helpers/useTransformData";
import MaterialIcon from "../../assets/img/material_icon.png";

export default function MaterialIssuePOAdd(props) {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const warehouses = useSelector((state) => state.warehouses.warehouses);
  const warehousesDropdown = useTransformOption(warehouses);
  const selectedRequestPo = useSelector(
    (state) => state.requestPoMaterials.selected
  );
  // const runningNumber = useSelector(
  //   (state) => state.issuePoMaterials.runningNumber
  // );
  const user = useSelector((state) => state.auth.user);
  const [date, setDate] = useState(new Date());
  const [shipTo, setShipTo] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null);
  const [runningNumber, setRunningNumber] = useState("");
  const [comments, setComments] = useState("");
  let materialFields = [];
  for (let i in selectedRequestPo.materials) {
    if (
      selectedRequestPo.materials[i].supplier_id === props.selectedSupplierId
    ) {
      if (selectedRequestPo.materials[i].type === "Specialised") {
        let materialObj = {
          ...selectedRequestPo.materials[i],
          issue_qty: selectedRequestPo.materials[i].pivot.order_qty,
          issue_price: parseFloat(selectedRequestPo.materials[i].price),
        };

        materialFields.push(materialObj);
      }
    }
  }
  const [materials, setMaterials] = useState(materialFields);

  let companiesDropdown = [];
  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  const handleMaterialChange = (position, event) => {
    const values = materials.map((material, index) => {
      if (index !== position) {
        return material;
      }

      return {
        ...material,
        [event.target.name]: event.target.value,
      };
    });

    setMaterials(values);
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleShipToChange = (option) => {
    setShipTo(option);
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
  };

  const handleWarehouseChange = (option, index) => {
    setSelectedWarehouse(option);
  };

  const handlePaymentTermChange = (option) => {
    setSelectedPaymentTerm(option);
  };

  const loadRecipientOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let recipientOptions = [];

    for (let i in search) {
      recipientOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(recipientOptions);
  };

  const onSubmit = async () => {
    if (!selectedCompany) {
      alert("Company is required.");
      return;
    }
    if (!selectedWarehouse) {
      alert("Place of Delivery is required.");
      return;
    }
    if (!shipTo) {
      alert("Person to Contact is required.");
      return;
    }
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will issue the selected purchase order.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        const data = {
          issued_by: user.employee.id,
          task_id: selectedRequestPo.task_id,
          project_id: selectedRequestPo.project_id,
          request_purchase_order_id: selectedRequestPo.id,
          ship_to_id: shipTo.value,
          supplier_id: props.selectedSupplierId,
          company_id: selectedCompany ? selectedCompany.value : null,
          warehouse_id: selectedWarehouse ? selectedWarehouse.value : null,
          ref_no: runningNumber,
          date: moment(date).format("YYYY-MM-DD").toString(),
          payment_term: selectedPaymentTerm
            ? selectedPaymentTerm.value
            : "Not specified",
          // place_of_delivery: field.place_of_delivery,
          materials: materials,
          comments: comments,
        };

        dispatch(addIssuePoMaterial(data));
        props.onFormClose && props.onFormClose();
      }
    });
  };

  // useEffect(() => {
  //   dispatch(getRunningNumber(selectedRequestPo.series_no));
  // }, [dispatch, selectedRequestPo]);

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getWarehouses());
  }, [dispatch]);

  return (
    <form>
      <h1 className="font-bold">Issue Purchase Order</h1>
      <p className="text-xs text-gray-600 mb-4">
        Details here will be generated into PDF for issuance to supplier.
      </p>
      <div className="grid mb-4">
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="For Supplier" />
            <Avatar
              name={props.selectedSupplier?.name}
              image={
                props.selectedSupplier
                  ? props.selectedSupplier.photo_full_url
                  : null
              }
            />
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-1/4">
            <Label name="Request PO Series No." />
            <p className="text-xs">{selectedRequestPo.series_no}</p>
          </div>

          <div className="w-1/4 px-4">
            <Label name="Issue PO S/N" />
            {/* <p className="text-xs">{runningNumber}</p> */}
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="running_number"
              type="text"
              value={runningNumber}
              onChange={(event) => setRunningNumber(event.target.value)}
            />
          </div>

          <div className="w-1/4">
            <Label name="Issue PO Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => {
                handleDateChange(date);
              }}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Company (required)" />
            <Select
              className="text-xs"
              options={companiesDropdown}
              value={selectedCompany}
              onChange={(option) => {
                handleCompanyChange(option);
              }}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            {/* <Label name="Warehouse" /> */}
            <Label name="Place of Delivery (required)" />
            <Select
              className="text-xs"
              options={warehousesDropdown}
              value={selectedWarehouse}
              onChange={(option) => {
                handleWarehouseChange(option);
              }}
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="comments"
              type="text"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </div>
        </div>

        <div className="flex mb-4">
          {/* <div className="w-1/3">
                  <Label name="Place of Delivery" />
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    id={`place_of_delivery-${index}`}
                    name="place_of_delivery"
                    type="text"
                    value={inputFields[index].place_of_delivery}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </div> */}
          <div className="w-1/2">
            <Label name="Person to Contact (required)" />
            <AsyncSelect
              loadOptions={loadRecipientOptions}
              value={shipTo}
              onChange={(option) => handleShipToChange(option)}
              className="text-xs shadow-sm"
            />
          </div>
          <div className="w-1/2 px-4">
            <Label name="Payment Term" />
            <Select
              className="text-xs"
              options={paymentTermOptions}
              value={selectedPaymentTerm}
              onChange={(option) => {
                handlePaymentTermChange(option);
              }}
            />
            {/* <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="payment_term"
              type="text"
              value={paymentTerm}
              onChange={(event) => setPaymentTerm(event.target.value)}
            /> */}
          </div>
        </div>

        <p className="font-bold mt-4">Materials</p>
        <p className="text-xs text-gray-600 mb-4">
          these are the materials under the selected supplier; make
          modifications if necessary.
        </p>
        <table className="table-fixed w-100 mb-4">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Photo</th>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">Price</th>
              <th className="text-xs px-4 py-2">Issue Qty</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="hover:bg-gray-200">
                <td className="text-xs border px-2 py-2">
                  <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                    {material.photo_full_url ? (
                      <img
                        alt="..."
                        className="w-full align-middle border-none"
                        src={material.photo_full_url}
                      />
                    ) : (
                      <img
                        alt="..."
                        className="w-full align-middle border-none"
                        src={MaterialIcon}
                      />
                    )}
                  </span>
                </td>
                <td className="text-xs border px-2 py-2">
                  {material.category ? material.category.name : "Uncategorized"}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    id={`issue_price-${index}`}
                    name="issue_price"
                    type="number"
                    step="any"
                    value={materials[index].issue_price}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    id={`issue_qty-${index}`}
                    name="issue_qty"
                    type="number"
                    value={materials[index].issue_qty}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Issue Purchase Order"
          success="true"
          onClick={() => {
            onSubmit();
          }}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose && props.onFormClose();
          }}
        />
      </div>
    </form>
  );
}
