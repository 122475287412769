import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import swal from "sweetalert";

import { Button, Fieldset, Table, Typography, StyledDialog } from "../common";

import { commonMaterialCategoryColumns } from "./MaterialTableColumns";

import {
  EditButton,
  ApproveButton,
  DenyButton,
  CancelButton,
  DotsHorizontalButton,
} from "../common/icons/HeroIcons";

import {
  selectMaterialCategory,
  addMaterialCategory,
  updateMaterialCategory,
  deleteMaterialCategory,
  getMaterialCategories,
} from "../../actions/materialCategories";

export default function MaterialCategoryPage(props) {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.materials.materialCategories);
  const selected = useSelector(
    (state) => state.materialCategories.selectedMaterialCategory
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [nameUpdate, setNameUpdate] = useState("");
  const [isAddModal, setIsAddModal] = useState(false);

  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    dispatch(getMaterialCategories());
  }, [dispatch]);

  const handleUpdateChange = (e) => {
    setNameUpdate(e.target.value);
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const onSubmit = (id) => {
    if (id) {
      dispatch(updateMaterialCategory(selected.id, { id, name: nameUpdate }));
      setIsUpdate(false);
      setIsEdit(false);
    } else {
      dispatch(addMaterialCategory({ name: categoryName }));
      setIsEdit(false);
    }
  };

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Common Material Categories</Typography>
          <div className="flex justify-end">
            <Button
              text="+ add category"
              primary="true"
              onClick={() => {
                setIsAddModal(!isAddModal);
              }}
            />
          </div>

          <Table
            columns={commonMaterialCategoryColumns}
            data={
              <>
                {categories.map((category) => (
                  <tr
                    key={category.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selected ? selected.id : 0) === category.id,
                    })}
                    onClick={() => {
                      dispatch(selectMaterialCategory(category));
                    }}
                  >
                    <td className="px-6 py-3 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {isUpdate ? (
                          <input
                            key={category.id}
                            type="text"
                            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                            style={{ border: "1px solid #ccc" }}
                            autoComplete="off"
                            onChange={handleUpdateChange}
                            defaultValue={category.name}
                          />
                        ) : (
                          category.name
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex flex-row justify-end">
                        {isUpdate ? (
                          <>
                            <div
                              className="flex flex-col text-gray-600 hover:text-gray-900"
                              onClick={() => onSubmit(category.id)}
                            >
                              <ApproveButton title={"Okay"} />
                            </div>
                            <div
                              className="flex flex-col text-gray-600 hover:text-gray-900"
                              onClick={() => {
                                setIsUpdate(false);
                              }}
                            >
                              <CancelButton title={"Cancel"} />
                            </div>
                          </>
                        ) : (
                          <>
                            {!isEdit ? (
                              <div
                                className="flex flex-col text-gray-600 hover:text-gray-900"
                                onClick={() => {
                                  setIsEdit(true);
                                }}
                              >
                                <DotsHorizontalButton title={"Edit"} />
                              </div>
                            ) : (
                              <>
                                <div
                                  className="flex flex-col text-gray-600 hover:text-gray-900"
                                  onClick={() => {
                                    setIsUpdate(true);
                                  }}
                                >
                                  <EditButton
                                    title={"Update"}
                                    width={25}
                                    color={"#57bf57"}
                                  />
                                </div>
                                <div
                                  className="flex flex-col text-gray-600 hover:text-gray-900"
                                  onClick={() => {
                                    swal({
                                      icon: "warning",
                                      title: "Are you sure?",
                                      text: `Deleting this category will label common materials as "Uncategorized".`,
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        dispatch(
                                          deleteMaterialCategory(category.id)
                                        );
                                        selectMaterialCategory(null);
                                        setIsEdit(false);
                                      }
                                    });
                                  }}
                                >
                                  <DenyButton title={"Delete"} />
                                </div>
                                <div
                                  className="flex flex-col text-gray-600 hover:text-gray-900"
                                  onClick={() => {
                                    setIsEdit(false);
                                    setIsUpdate(false);
                                  }}
                                >
                                  <CancelButton title={"Cancel"} />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}

                {isAddModal && (
                  <StyledDialog
                    isOpen={isAddModal}
                    setIsOpen={() => setIsAddModal(!isAddModal)}
                    onClose={() => setIsAddModal(false)}
                    screenWidth={500}
                  >
                    <Typography type="h1">Add Category</Typography>
                    <input
                      type="text"
                      autoComplete="off"
                      className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                      style={{ border: "1px solid #ccc" }}
                      onChange={handleCategoryNameChange}
                      value={categoryName}
                    />
                    <Button
                      type="submit"
                      className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold mt-4 mr-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      text="Submit"
                      onClick={() => onSubmit()}
                    />
                  </StyledDialog>
                )}
              </>
            }
          />
        </Fieldset>
      </div>
    </div>
  );
}
