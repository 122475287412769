import {
  GET_AVAILABLE_SPECIALISED_MATERIALS,
  GET_WITHDRAWAL_REQUESTS,
  GET_LIST_MATERIALS,
  ADD_WITHDRAWAL_REQUEST,
  UPDATE_WITHDRAWAL_REQUEST,
  SELECT_WITHDRAWAL_REQUEST,
  LOADING_WITHDRAWAL_REQUESTS,
  DELETE_WITHDRAWAL_REQUEST,
  LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS,
  GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS,
  SET_LOADING_MATERIALS_EXCEL,
} from "../actions/types";

const initialState = {
  listMaterials: [],
  availableMaterials: [],
  availableMaterialsPagination: null,
  isLoading: false,
  withdrawalRequests: [],
  withdrawalRequestsPagination: null,
  selectedWithdrawalRequest: null,
  reportMaterials: [],
  reportMaterialsLoading: false,
  isExcelLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOADING_MATERIALS_EXCEL:
      return {
        ...state,
        isExcelLoading: action.payload,
      };
    case GET_AVAILABLE_SPECIALISED_MATERIALS:
      return {
        ...state,
        availableMaterials: action.payload,
        availableMaterialsPagination: action.pagination,
        isLoading: false,
      };
    case GET_LIST_MATERIALS:
      return {
        ...state,
        listMaterials: action.payload,
        isLoading: false,
      };
    case GET_WITHDRAWAL_REQUESTS:
      return {
        ...state,
        withdrawalRequests: action.payload,
        withdrawalRequestsPagination: action.pagination,
        isLoading: false,
      };
    case SELECT_WITHDRAWAL_REQUEST:
      return {
        ...state,
        selectedWithdrawalRequest: action.payload,
      };
    case ADD_WITHDRAWAL_REQUEST:
      return {
        ...state,
        withdrawalRequests: [...state.withdrawalRequests, action.payload],
        withdrawalRequestsPagination: {
          ...state.withdrawalRequestsPagination,
          total: state.withdrawalRequestsPagination.total + 1,
        },
      };
    case UPDATE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        selectedWithdrawalRequest: action.payload,
        withdrawalRequests: state.withdrawalRequests.map(
          (withdrawalRequest) => {
            if (action.payload.id !== withdrawalRequest.id) {
              return withdrawalRequest;
            }

            return {
              ...withdrawalRequest,
              ...action.payload,
            };
          }
        ),
      };
    case DELETE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        withdrawalRequests: state.withdrawalRequests.filter(
          (material) => material.id !== action.payload
        ),
      };
    case LOADING_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS:
      return {
        ...state,
        reportMaterialsLoading: true,
      };
    case GET_WITHDRAWN_MATERIALS_FOR_DAILY_REPORTS:
      return {
        ...state,
        reportMaterialsLoading: false,
        reportMaterials: action.payload,
      };
    default:
      return state;
  }
}
