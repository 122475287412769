import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import attendances from "./attendances";
import auth from "./auth";
import bulkPoMaterials from "./bulkPoMaterials";
import commonAllocatedMaterials from "./commonAllocatedMaterials";
import commonDeliverMaterials from "./commonDeliverMaterials";
import commonMaterialCategories from "./commonMaterialCategories";
import commonMaterialRequests from "./commonMaterialRequests";
import commonIssuePoMaterials from "./commonIssuePoMaterials";
import commonStocktakes from "./commonStocktakes";
import commonSuppliers from "./commonSuppliers";
import companies from "./companies";
import deliverMaterials from "./deliverMaterials";
import documentations from "./documentations";
import employees from "./employees";
import equipments from "./equipments";
import issuePoMaterials from "./issuePoMaterials";
import materials from "./materials";
import materialCategories from "./materialCategories";
import materialWithdrawals from "./materialWithdrawals";
import general from "./general";
import projects from "./projects";
import requestPoMaterials from "./requestPoMaterials";
import roles from "./roles";
import stocktakes from "./stocktakes";
import suppliers from "./suppliers";
import tasks from "./tasks";
import taskCategories from "./taskCategories";
import tools from "./tools";
import users from "./users";
import warehouses from "./warehouses";

// eslint-disable-next-line import/no-anonymous-default-export
export default (history) =>
  combineReducers({
    attendances,
    auth,
    bulkPoMaterials,
    commonAllocatedMaterials,
    commonDeliverMaterials,
    commonMaterialCategories,
    commonMaterialRequests,
    commonIssuePoMaterials,
    commonStocktakes,
    commonSuppliers,
    companies,
    deliverMaterials,
    documentations,
    employees,
    equipments,
    issuePoMaterials,
    materialCategories,
    materials,
    materialWithdrawals,
    general,
    projects,
    requestPoMaterials,
    roles,
    stocktakes,
    suppliers,
    tasks,
    taskCategories,
    tools,
    users,
    warehouses,
    router: connectRouter(history),
  });
