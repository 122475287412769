import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { Button, Label } from "../common";
import moment from "moment";
import swal from "sweetalert";

import { addProject } from "../../actions/projects";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import DatePicker from "react-datepicker";

import ImageUtils from "../../helpers/ImageUtils";

export default function ProjectForm(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const descriptionRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();
  const fileInputRef4 = useRef();
  const fileInputRef5 = useRef();
  const [selectedWorkingDrawing, setSelectedWorkingDrawing] = useState(null);
  const [selectedWorkingDrawing2, setSelectedWorkingDrawing2] = useState(null);
  const [selectedWorkingDrawing3, setSelectedWorkingDrawing3] = useState(null);
  const [selectedWorkingDrawing4, setSelectedWorkingDrawing4] = useState(null);
  const [selectedWorkingDrawing5, setSelectedWorkingDrawing5] = useState(null);

  const handleFileChange = (e) => {
    setSelectedWorkingDrawing(fileInputRef.current.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedWorkingDrawing2(fileInputRef2.current.files[0]);
  };

  const handleFileChange3 = (e) => {
    setSelectedWorkingDrawing3(fileInputRef3.current.files[0]);
  };

  const handleFileChange4 = (e) => {
    setSelectedWorkingDrawing4(fileInputRef4.current.files[0]);
  };

  const handleFileChange5 = (e) => {
    setSelectedWorkingDrawing5(fileInputRef5.current.files[0]);
  };

  useEffect(() => {
    register("start_date");
    register("end_date");
    register("description");
  }, [register]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue("start_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue("end_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleDescriptionChange = (content) => {
    setValue("description", content);
  };

  const onSubmit = async (data, e) => {
    swal({
      title: "Add Project",
      text: `Are you sure you want to add this new project?`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let file,
          file2,
          file3,
          file4,
          file5 = null;
        if (selectedWorkingDrawing) {
          file = await ImageUtils.fileToBase64(selectedWorkingDrawing);
        }
        if (selectedWorkingDrawing2) {
          file2 = await ImageUtils.fileToBase64(selectedWorkingDrawing2);
        }
        if (selectedWorkingDrawing3) {
          file3 = await ImageUtils.fileToBase64(selectedWorkingDrawing3);
        }
        if (selectedWorkingDrawing4) {
          file4 = await ImageUtils.fileToBase64(selectedWorkingDrawing4);
        }
        if (selectedWorkingDrawing5) {
          file5 = await ImageUtils.fileToBase64(selectedWorkingDrawing5);
        }

        if (file) {
          data.base64image = file;
        }
        if (file2) {
          data.base64image2 = file2;
        }
        if (file3) {
          data.base64image3 = file3;
        }
        if (file4) {
          data.base64image4 = file4;
        }
        if (file5) {
          data.base64image5 = file5;
        }

        dispatch(addProject(data));
        e.target.reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold text-gray-900">Create New Project</h1>

      <p className="text-xs text-gray-600 mb-4">
        Provide basic info about the project.
      </p>

      <Label name="Project Title" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={"title"}
        name="title"
        type="text"
      />

      <div className="flex mb-4 mt-4">
        <div className="w-1/2">
          <Label name="Code" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={"code"}
            name="code"
            type="text"
          />
        </div>
        <div className="w-1/2 ml-4">
          <Label name="Location" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={"location"}
            name="location"
            type="text"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Client Name" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={"client_name"}
            name="client_name"
            type="text"
          />
        </div>
        <div className="w-1/2 ml-4">
          <Label name="Client Address" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={"client_address"}
            name="client_address"
            type="text"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2" style={{ zIndex: 20 }}>
          <Label name="Start Date" />
          <DatePicker
            showYearDropdown
            selected={startDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleStartDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mb-2"
          />
        </div>
        <div className="w-1/2" style={{ zIndex: 20 }}>
          <Label name="End Date" />
          <DatePicker
            showYearDropdown
            selected={endDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleEndDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mb-2"
          />
        </div>
      </div>

      <Label name="Description" />
      <SunEditor
        height="100"
        name="description"
        onChange={handleDescriptionChange}
        ref={descriptionRef}
      />

      <div className="flex mb-4 mt-4">
        <div className="flex">
          <div>
            <Label name="Upload File 1" />
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </div>
          <div>
            <Label name="Upload File 2" />
            <input
              ref={fileInputRef2}
              type="file"
              onChange={(e) => {
                handleFileChange2(e);
              }}
            />
          </div>
          <div>
            <Label name="Upload File 3" />
            <input
              ref={fileInputRef3}
              type="file"
              onChange={(e) => {
                handleFileChange3(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div>
          <Label name="Upload File 4" />
          <input
            ref={fileInputRef4}
            type="file"
            onChange={(e) => {
              handleFileChange4(e);
            }}
          />
        </div>
        <div>
          <Label name="Upload File 5" />
          <input
            ref={fileInputRef5}
            type="file"
            onChange={(e) => {
              handleFileChange5(e);
            }}
          />
        </div>
      </div>

      <br />

      <div className="justify-center flex mt-4 px-4">
        <Button text="Save" success="true" type="submit" />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </form>
  );
}
