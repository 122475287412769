import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

export default function Tree(props) {
  // The nodes, or `null` if we don't have them yet
  const [childNodes, setChildNodes] = useState(null);
  // Flag for whether this node is expanded
  const [expanded, setExpanded] = useState(false);
  // Flag for whether we're fetching child nodes
  const [fetching, setFetching] = useState(false);
  // Flag for whether child node fetch failed
  const [failed, setFailed] = useState(false);
  const [margin] = useState(0);

  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  // Toggle our display of child nodes
  const toggleExpanded = useCallback(
    (id) => {
      setExpanded(!expanded);
      if (!expanded && !childNodes && !fetching) {
        setFailed(false);
        setFetching(true);
        console.log(id);
        props
          .fetchChildNodes(id)
          .then((nodes) =>
            setChildNodes(
              nodes.map((node) => (
                <div style={{ marginLeft: margin + 40 }} key={node.id}>
                  <Tree
                    node={node}
                    key={node.id}
                    fetchChildNodes={props.fetchChildNodes}
                    margin={margin}
                    onNodeSelected={(node) => {
                      props.onNodeSelected && props.onNodeSelected(node);
                    }}
                    onAddChildNode={(node) => {
                      props.onAddChildNode && props.onAddChildNode(node);
                    }}
                  />
                </div>
              ))
            )
          )
          .catch((error) => setFailed(true))
          .finally(() => setFetching(false));
        // console.log(props.fetchChildNodes(props.node.id));
      }
    },
    [expanded, childNodes, fetching, margin, props]
  );

  if (selectedTask.id === props.node.id) {
    props.node.percentage = selectedTask.percentage;
    props.node.current_percentage = selectedTask.percentage;
  }

  return (
    <div>
      <div
        className={classNames(
          "flex shadow text-sm cursor-pointer hover:bg-gray-200 p-2 rounded",
          {
            "text-blue-600 bg-teal-200": selectedTask.id === props.node.id,
          }
        )}
      >
        {props.node.is_parent ? (
          <span
            onClick={() => {
              toggleExpanded(props.node.id);
            }}
            style={{ width: "15px" }}
          >
            {expanded ? "-" : "+"}
          </span>
        ) : (
          <span style={{ marginRight: 15 }} />
        )}

        <div
          onClick={() => {
            props.onNodeSelected && props.onNodeSelected(props.node);
          }}
        >
          {props.node.code && (
            <div className="text-gray-600">
              {`${props.node.code}`}
              <br />
            </div>
          )}
          <p>
            {`${props.node.title} - `}
            <strong className="text-blue-600">{`${
              selectedTask.id === props.node.id
                ? selectedTask.percentage
                : props.node.percentage
            }%`}</strong>
          </p>
        </div>

        <button
          className="rounded-full px-2 outline-none mr-2 mb-1"
          data-tip
          data-for={`addSubtaskTip-${props.node.id}`}
          type="button"
          onClick={() => {
            props.onAddChildNode && props.onAddChildNode(props.node);
          }}
        >
          <i className="fas fa-level-down-alt"></i>
        </button>
        <div>
          <div>
            {props.node.percentage === 100 && props.node.is_inspected === 0 && (
              <p
                className={classNames(
                  "text-xs px-4 text-red-600 p-2 rounded leading-none items-center"
                )}
              >
                NI
              </p>
            )}
            {props.node.percentage === 100 && props.node.is_inspected === 1 && (
              <p
                className={classNames(
                  "text-xs px-4 text-green-600 p-2 rounded leading-none items-center"
                )}
              >
                INS
              </p>
            )}
          </div>
          <p className="text-red-600">
            {props.node.set_percentage > 0 && `[${props.node.set_percentage}%]`}
          </p>
        </div>
      </div>

      {failed && expanded && (
        <div className="failed">Error fetching child nodes</div>
      )}
      {fetching && <div className="loading">Loading...</div>}
      {!failed &&
        !fetching &&
        expanded &&
        childNodes &&
        (childNodes.length > 0 ? (
          childNodes
        ) : (
          <div className="none">(none)</div>
        ))}
    </div>
  );
}
