export const specialisedMaterialListColumns = [
  {
    name: "Photo",
  },
  {
    name: "Type",
  },
  {
    name: "Category",
  },
  {
    name: "Supplier",
  },
  {
    name: "Code",
  },
  {
    name: "Description",
  },
  {
    name: "Unit",
  },
  // {
  //   name: "Warehouse",
  // },
  {
    name: "Bulk Qty",
  },
  {
    name: "Requested Qty",
  },
  {
    name: "Delivered Qty",
  },
  {
    name: "Adjusted Qty",
  },
  {
    name: "Used Qty",
  },
  {
    name: "In Stock",
  },
];

export const commonMaterialListColumns = [
  {
    name: "Category",
  },
  {
    name: "Code",
  },
  {
    name: "Description",
  },
  {
    name: "Unit",
  },
  {
    name: "Warehouse",
  },
  {
    name: "Issue Qty",
  },
  {
    name: "Delivered Qty",
  },
  {
    name: "Requested for Allocation Qty",
  },
  {
    name: "Allocated Qty",
  },
  {
    name: "Adjusted Qty",
  },
  {
    name: "In Stock Qty",
  },
];
