export const userListColumns = [
  {
    name: "Name",
  },
  {
    name: "Role",
  },
  {
    name: "Username",
  },
];
