import React, { useState } from "react";
import { Expand } from "../common/icons";
import classNames from "classnames";

function Dropdown({ options, onOptionSelect, placeholder, selected }) {
  // Keep track of whether the dropdown is open or not.
  const [isActive, setActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    selected ? selected : null
  );

  const buttonClasses = `inline-flex justify-between w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-xs leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-500 active:text-gray-200 transition ease-in-out duration-150`;

  return (
    <div>
      <button
        onClick={() => setActive(!isActive)}
        className={classNames(buttonClasses, {
          "text-gray-900": selectedOption !== null,
          "text-gray-600": selectedOption === null,
        })}
      >
        {selectedOption === null
          ? placeholder
            ? placeholder
            : "Select... "
          : selectedOption.text}
        <Expand width={16} />
      </button>

      <div
        className={classNames(
          "origin-top-right absolute right-4 w-9/12 rounded mt-2 py-2 text-gray-900 bg-gray-200 shadow w-64 z-10",
          {
            block: isActive,
            hidden: !isActive,
          }
        )}
      >
        {options.map((option) => (
          <div
            className={classNames(
              "text-xs cursor-pointer rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
            )}
            key={option.id}
            onClick={(e) => {
              e.preventDefault();
              onOptionSelect(option);
              setActive(false);
              setSelectedOption(option);
            }}
          >
            {option.text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;
