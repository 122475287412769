import React from 'react';


function Table(props) {
    const {
        columns,
        data,
    } = props;

    return (
        <div className="flex flex-col my-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div
                    // className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                        <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                {
                                    columns.map((data, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                                        >
                                            {data.name}
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data}
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Table;