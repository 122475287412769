import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_COMMON_ISSUE_MATERIALS,
  LOADING_COMMON_ISSUE_MATERIALS,
  SELECT_COMMON_ISSUE_MATERIAL,
  ISSUE_COMMON_MATERIAL,
  DELETE_COMMON_ISSUE_MATERIAL,
  UPDATE_COMMON_ISSUE_MATERIAL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET ISSUE PO MATERIALS
export const getIssuePoMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_COMMON_ISSUE_MATERIALS });

  let endpoint = `/common-issue-po-materials?page=${page}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMMON_ISSUE_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT ISSUE PO MATERIAL
export const selectIssuePoMaterial = (requestPoMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_COMMON_ISSUE_MATERIAL,
    payload: requestPoMaterial,
  });
};

// ADD ISSUE PO MATERIAL
export const addIssuePoMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/common-issue-po-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Issue PO Added!",
        icon: "success",
      });
      dispatch({ type: ISSUE_COMMON_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

export const generatePDF = (id) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/common-issue-po-materials/${id}/generate`;
  let config = tokenConfig(getState);
  config.method = "GET";
  config.responseType = "blob";

  axios(endpoint, config)
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch((err) => console.error(err));
};

export const deleteCommonIssuePoMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(
      BASE_URL + `/common-issue-po-materials/${id}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: DELETE_COMMON_ISSUE_MATERIAL,
        payload: id,
      });
      swal({
        title: "Issue PO Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const updateIssuePoMaterial =
  (id, commonIssuePoMaterial) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/common-issue-po-materials/${id}`;

    axios
      .put(endpoint, commonIssuePoMaterial, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_COMMON_ISSUE_MATERIAL,
          payload: res.data,
        });

        swal({
          title: "Issue PO Updated!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };
