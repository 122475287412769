import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Fieldset, Tab } from "../common";

import SpecialisedMaterialList from "./SpecialisedMaterialList";
import MaterialInventoryPage from "./MaterialInventoryPage";
import MaterialRequestOrderPage from "./MaterialRequestOrderPage";
import MaterialIssuePOPage from "./MaterialIssuePOPage";
import MaterialBulkPOPage from "./MaterialBulkPOPage";
import MaterialDeliveryPage from "./MaterialDeliveryPage";
import MaterialStocktakePage from "./MaterialStocktakePage";
import MaterialCategoryPage from "./MaterialCategoryPage";
import MaterialSupplierPage from "./MaterialSupplierPage";
import CommonMaterialCategoryPage from "./common/CommonMaterialCategoryPage";
import CommonMaterialIssuePOPage from "./common/CommonMaterialIssuePOPage";
import CommonMaterialDeliveryPage from "./common/CommonMaterialDeliveryPage";
import CommonMaterialAllocatePage from "./common/CommonMaterialAllocatePage";
import CommonMaterialStocktakePage from "./common/CommonMaterialStocktakePage";
import CommonMaterialList from "./common/CommonMaterialList";
import CommonMaterialSupplierPage from "./common/CommonMaterialSupplierPage";
import MaterialWarehousePage from "./warehouses/MaterialWarehousePage";

import { roles } from "../../helpers/userRoles";

export default function MaterialPage(props) {
  const user = useSelector((state) => state.auth.user);
  const [mainActiveTab, setMainActiveTab] = useState("Materials");
  const [activeTab, setActiveTab] = useState("List");

  return (
    <div className="m-12">
      <Tab>
        <Tab.Item
          name="Materials"
          isActive={mainActiveTab === "Materials"}
          onTabClick={() => {
            setMainActiveTab("Materials");
            setActiveTab("List");
          }}
        />
        <Tab.Item
          name="Warehouses"
          isActive={mainActiveTab === "Warehouses"}
          onTabClick={() => {
            setMainActiveTab("Warehouses");
            setActiveTab("Warehouse");
          }}
        />
      </Tab>
      <Fieldset>
        {mainActiveTab === "Materials" && (
          <div>
            <Tab>
              {user.employee.role_id !== roles.coord && (
                <Tab.Item
                  name="List"
                  isActive={activeTab === "List"}
                  onTabClick={() => {
                    setActiveTab("List");
                  }}
                />
              )}

              <Tab.Item
                name="Bulk POs"
                isActive={activeTab === "Special Bulk POs"}
                onTabClick={() => {
                  setActiveTab("Special Bulk POs");
                }}
              />
              <Tab.Item
                name="Request Orders"
                isActive={activeTab === "Special Request Orders"}
                onTabClick={() => {
                  setActiveTab("Special Request Orders");
                }}
              />

              <Tab.Item
                name="Issued POs"
                isActive={activeTab === "Special Issue POs"}
                onTabClick={() => {
                  setActiveTab("Special Issue POs");
                }}
              />
              <Tab.Item
                name="Deliveries"
                isActive={activeTab === "Special Deliveries"}
                onTabClick={() => {
                  setActiveTab("Special Deliveries");
                }}
              />
              <Tab.Item
                name="Stocktakes"
                isActive={activeTab === "Special Stocktakes"}
                onTabClick={() => {
                  setActiveTab("Special Stocktakes");
                }}
              />
              <Tab.Item
                name="Suppliers"
                isActive={activeTab === "Special Suppliers"}
                onTabClick={() => {
                  setActiveTab("Special Suppliers");
                }}
              />
              <Tab.Item
                name="Categories"
                isActive={activeTab === "Special Categories"}
                onTabClick={() => {
                  setActiveTab("Special Categories");
                }}
              />
            </Tab>
          </div>
        )}

        {mainActiveTab === "Common Materials" && (
          <div>
            <Tab>
              <Tab.Item
                name="List"
                isActive={activeTab === "Common List"}
                onTabClick={() => {
                  setActiveTab("Common List");
                }}
              />
              <Tab.Item
                name="Issue PO"
                isActive={activeTab === "Common Issue PO"}
                onTabClick={() => {
                  setActiveTab("Common Issue PO");
                }}
              />
              <Tab.Item
                name="Deliveries"
                isActive={activeTab === "Common Deliveries"}
                onTabClick={() => {
                  setActiveTab("Common Deliveries");
                }}
              />
              <Tab.Item
                name="Allocations"
                isActive={activeTab === "Common Allocations"}
                onTabClick={() => {
                  setActiveTab("Common Allocations");
                }}
              />
              <Tab.Item
                name="Stocktakes"
                isActive={activeTab === "Common Stocktakes"}
                onTabClick={() => {
                  setActiveTab("Common Stocktakes");
                }}
              />
              <Tab.Item
                name="Suppliers"
                isActive={activeTab === "Common Suppliers"}
                onTabClick={() => {
                  setActiveTab("Common Suppliers");
                }}
              />
              <Tab.Item
                name="Categories"
                isActive={activeTab === "Common Categories"}
                onTabClick={() => {
                  setActiveTab("Common Categories");
                }}
              />
            </Tab>
          </div>
        )}

        {activeTab === "Common List" && <CommonMaterialList />}

        {activeTab === "Common Issue PO" && <CommonMaterialIssuePOPage />}

        {activeTab === "Common Deliveries" && <CommonMaterialDeliveryPage />}

        {activeTab === "Common Allocations" && <CommonMaterialAllocatePage />}

        {activeTab === "Common Stocktakes" && <CommonMaterialStocktakePage />}

        {activeTab === "Common Categories" && <CommonMaterialCategoryPage />}

        {activeTab === "Common Suppliers" && <CommonMaterialSupplierPage />}

        {activeTab === "List" && <SpecialisedMaterialList />}

        {activeTab === "Special Bulk POs" && (
          <MaterialBulkPOPage view="materials" />
        )}

        {activeTab === "Special Request Orders" && (
          <MaterialRequestOrderPage view="materials" />
        )}
        {activeTab === "Special Issue POs" && (
          <MaterialIssuePOPage view="materials" />
        )}

        {activeTab === "Special Inventories" && (
          <MaterialInventoryPage view="materials" />
        )}

        {activeTab === "Special Deliveries" && (
          <MaterialDeliveryPage view="materials" />
        )}

        {activeTab === "Special Stocktakes" && <MaterialStocktakePage />}

        {activeTab === "Special Categories" && <MaterialCategoryPage />}

        {activeTab === "Special Suppliers" && <MaterialSupplierPage />}

        {activeTab === "Warehouse" && <MaterialWarehousePage />}
      </Fieldset>
    </div>
  );
}
