import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Fieldset, Table, Button, Spinner, Typography, Modal } from "../common";
import { getProjects, selectProject } from "../../actions/projects";
import classNames from "classnames";
import ProjectForm from "./ProjectForm";

import { projectsColumns } from "./ProjectTableColumns";

export default function ProjectSetupPage() {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const selected = useSelector((state) => state.projects.selectedProject);
  const isLoading = useSelector((state) => state.projects.isLoading);
  const [formModal, setFormModal] = useState(false);

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  return (
    <div className="flex">
      {formModal && (
        <Modal>
          <ProjectForm
            onViewClose={() => {
              setFormModal(false);
            }}
          />
        </Modal>
      )}
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">All Projects</Typography>
          <div className="flex justify-end">
            <Button
              text="+ add project"
              primary="true"
              onClick={() => {
                //add project modal
                setFormModal(true);
              }}
            />
          </div>
          {!isLoading && (
            <Table
              columns={projectsColumns}
              data={
                <>
                  {projects.map((project) => (
                    <tr
                      key={project.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selected ? selected.id : 0) === project.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectProject(project));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {project.title}
                        </div>
                        {project.tender_project && (
                          <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                            Tender: {project.tender_project.code} (
                            {project.tender_project.reference_no})
                          </span>
                        )}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {project.start_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {project.end_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div
                          className={classNames(
                            "flex flex-col text-xs rounded-full px-4 text-white p-2 rounded leading-none items-center",
                            {
                              "bg-blue-600": project.percentage > 0,
                              "bg-green-600": project.percentage === 100,
                              "bg-teal-400": project.percentage === 0,
                            }
                          )}
                        >
                          {project.percentage > 0 &&
                            project.percentage < 100 &&
                            "In Progress"}
                          {project.percentage === 0 && "New"}
                          {project.percentage === 100 && "Completed"}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="w-full">
                          <div className="shadow w-full bg-grey-900 mt-2">
                            <div
                              className="bg-teal-200 text-xs leading-none py-1 text-center"
                              style={{ width: `${project.percentage}%` }}
                            >
                              {project.percentage}%
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              }
            />
          )}
          {isLoading && <Spinner />}
        </Fieldset>
      </div>
    </div>
  );
}
