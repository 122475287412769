import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "../common";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import swal from "sweetalert";
import ImageUtils from "../../helpers/ImageUtils";
import { transformDate } from "../../helpers/useTransformData";

import { addServiceTool } from "../../actions/tools";

function ToolServiceForm(props) {
  const { employeeId } = props;

  const dispatch = useDispatch();

  const [serviceDate, setServiceDate] = useState(null);
  const [issueComment, setIssueComment] = useState("");
  const [servicePerson, setServicePerson] = useState("");
  const [multipleFiles, setMultipleFiles] = useState("");
  const selectedTool = useSelector((state) => state.tools.selectedTool);

  const fileInputRef = useRef();

  const handleServiceDateChange = (date) => {
    setServiceDate(date);
  };

  const handleIssueCommentChange = (e) => {
    setIssueComment(e.target.value);
  };

  const handleMultipleFilesOnChange = (e) => {
    setMultipleFiles(fileInputRef.current.files);
  };

  const onSubmit = async () => {
    // converting 'File' to URI Data Schema
    let filesArr = [];
    for (let i = 0; i < multipleFiles.length; i++) {
      const convertURIDataSchema = await ImageUtils.fileToBase64(
        multipleFiles[i]
      );
      filesArr.push(convertURIDataSchema);
    }

    // body file
    const body = {
      performed_by: employeeId,
      date: serviceDate ? transformDate(serviceDate) : null,
      issue: issueComment,
      service_by: servicePerson,
      items: [
        {
          id: selectedTool.id,
          condition: selectedTool.condition,
        },
      ],
      files: filesArr,
      tool: selectedTool,
    };

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `Issued tools should be unaffected.`,
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(addServiceTool(body));
      }
    });
  };

  const handleServicePersonChange = (e) => {
    setServicePerson(e.target.value);
  };

  return (
    <S.ToolServiceForm>
      <div className="flex flex-col w-full">
        <Typography type="h1">Service Tool</Typography>
        <div className="my-1">
          <Typography type="p">Service Date</Typography>
          <DatePicker
            selected={serviceDate}
            dateFormat="yyyy-MM-dd"
            autoComplete="off"
            popperPlacement="top-end"
            onChange={handleServiceDateChange}
            value={serviceDate}
            wrapperClassName="w-full"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div className="my-1">
          <Typography type="p">Issue</Typography>
          <input
            type="text"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            autoComplete="off"
            name="issueComment"
            onChange={handleIssueCommentChange}
            value={issueComment}
          />
        </div>
        <div className="my-1">
          <Typography type="p">Service By</Typography>
          <input
            type="text"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            autoComplete="off"
            name="servicePerson"
            onChange={handleServicePersonChange}
            value={servicePerson}
          />
        </div>
        <div className="my-2">
          <Typography type="p">Upload (Image/File)</Typography>
          <input
            ref={fileInputRef}
            type="file"
            multiple={true}
            onChange={(e) => handleMultipleFilesOnChange(e)}
            className="w-full p-2 border border-gray-400 rounded"
          />
        </div>
        <Button
          onClick={() => onSubmit()}
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold py-2 mt-3 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          text="Submit"
        />
      </div>
    </S.ToolServiceForm>
  );
}

const S = {};

S.ToolServiceForm = styled.div`
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

export default ToolServiceForm;
