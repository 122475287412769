import axios from "axios";
import { tokenConfig } from "./auth";

import { GET_COMPANIES } from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const getCompanies = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/company`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMPANIES,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};
