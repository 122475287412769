import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_COMMON_MATERIAL_CATEGORIES,
  ADD_COMMON_MATERIAL_CATEGORY,
  UPDATE_COMMON_MATERIAL_CATEGORY,
  SELECT_COMMON_MATERIAL_CATEGORY,
  DELETE_COMMON_MATERIAL_CATEGORY,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET COMMON MATERIAL CATEGORIES
export const getCommonMaterialCategories = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/common-material-categories`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMMON_MATERIAL_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// ADD COMMON MATERIAL CATEGORY
export const addCommonMaterialCategory = (category) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + `/common-material-categories`,
      category,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: ADD_COMMON_MATERIAL_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Common Material Category Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE COMMON MATERIAL CATEGORY
export const updateCommonMaterialCategory =
  (id, category) => (dispatch, getState) => {
    axios
      .put(
        BASE_URL + `/common-material-categories/${id}`,
        category,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UPDATE_COMMON_MATERIAL_CATEGORY,
          payload: res.data,
        });
        swal({
          title: "Common Material Category Updated",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// SELECT COMMON MATERIAL CATEGORY
export const selectCommonMaterialCategory = (category) => (dispatch) => {
  dispatch({
    type: SELECT_COMMON_MATERIAL_CATEGORY,
    payload: category,
  });
};

// DELETE COMMON MATERIAL CATEGORY
export const deleteCommonMaterialCategory = (id) => (dispatch, getState) => {
  axios
    .delete(
      BASE_URL + `/common-material-categories/${id}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: DELETE_COMMON_MATERIAL_CATEGORY,
        payload: id,
      });
      swal({
        title: "Common Material Category Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
