import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { createHashHistory } from "history";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

export const history = createHashHistory({
  hashType: "slash",
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const middleware = [thunk, routerMiddleware(history)];

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  return store;
}
