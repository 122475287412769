import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  Fieldset,
  Modal,
  Pagination,
  Spinner,
  Tab,
  Typography,
  Table,
} from "../common";
import { ViewButton } from "../common/icons/HeroIcons";

import { getToolDeliveries, selectToolDelivery } from "../../actions/tools";
import { toolListDeliveryColumns } from "./ToolTableColumns";
import ToolDeliveryDetail from "./ToolDeliveryDetail";

export default function ToolDeliveryPage(props) {
  const dispatch = useDispatch();
  const toolDeliveries = useSelector((state) => state.tools.toolDeliveries);
  const selectedToolDelivery = useSelector(
    (state) => state.tools.selectedToolDelivery
  );
  const pagination = useSelector(
    (state) => state.tools.paginationToolDeliveries
  );
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const [status, setStatus] = useState("for-delivery");
  const [pageNumber, setPageNumber] = useState(1);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {
    dispatch(getToolDeliveries(status, pageNumber));
  }, [dispatch, status, pageNumber]);

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Tool Deliveries</Typography>
          <Tab>
            <Tab.Item
              name="For Delivery"
              isActive={status === "for-delivery"}
              onTabClick={() => {
                setStatus("for-delivery");
              }}
            />
            <Tab.Item
              name="Delivered"
              isActive={status === "delivered"}
              onTabClick={() => {
                setStatus("delivered");
              }}
            />
          </Tab>

          <Table
            columns={toolListDeliveryColumns}
            data={
              <>
                {!toolsLoading &&
                  toolDeliveries.map((toolDelivery) => (
                    <tr
                      key={toolDelivery.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedToolDelivery
                              ? selectedToolDelivery.id
                              : 0) === toolDelivery.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectToolDelivery(toolDelivery));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolDelivery.date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolDelivery.recipient &&
                            `${toolDelivery.recipient.first_name} ${toolDelivery.recipient.last_name}`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolDelivery.project && toolDelivery.project.title}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolDelivery.driver
                            ? `${toolDelivery.driver.first_name} ${toolDelivery.driver.last_name}`
                            : "-- On the Spot --"}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolDelivery.tools && toolDelivery.tools.length}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectToolDelivery(toolDelivery));
                              setViewModal(true);
                            }}
                          >
                            <ViewButton title={"Details"} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            }
          />

          {toolsLoading && <Spinner />}

          {pagination && !toolsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>

        {viewModal && (
          <Modal>
            <ToolDeliveryDetail
              onViewClose={() => {
                setViewModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
