import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  Fieldset,
  Pagination,
  Spinner,
  Table,
  Typography,
  Modal,
} from "../common";
import { ViewButton } from "../common/icons/HeroIcons";

import { getServicedTools, selectServicedTool } from "../../actions/tools";
import { toolListServiceColumns } from "./ToolTableColumns";
import ToolServiceDetail from "./ToolServiceDetail";

export default function ToolServicePage(props) {
  const dispatch = useDispatch();
  const servicedTools = useSelector((state) => state.tools.servicedTools);
  const selectedServicedTool = useSelector(
    (state) => state.tools.selectedServicedTool
  );
  const pagination = useSelector(
    (state) => state.tools.paginationServicedTools
  );
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {
    dispatch(getServicedTools(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Serviced Tools</Typography>
          <Table
            columns={toolListServiceColumns}
            data={
              <>
                {!toolsLoading &&
                  servicedTools.map((servicedTool) => (
                    <tr
                      key={servicedTool.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedServicedTool
                              ? selectedServicedTool.id
                              : 0) === servicedTool.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectServicedTool(servicedTool));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.performed_by &&
                            `${servicedTool.performed_by.first_name} ${servicedTool.performed_by.last_name}`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.extra}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.tools &&
                            servicedTool.tools.map((tool) => (
                              <>
                                {`${tool.tool.brand} - ${tool.tool.description}`}
                                <br />
                              </>
                            ))}
                          {!servicedTool.tools && `Empty`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.tools &&
                            servicedTool.tools.map((tool) => (
                              <>
                                {`${tool.tool.serial_no}`}
                                <br />
                              </>
                            ))}
                          {!servicedTool.tools && `Empty`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {servicedTool.comments}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectServicedTool(servicedTool));
                              setViewModal(true);
                            }}
                          >
                            <ViewButton title={"Details"} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            }
          />

          {toolsLoading && <Spinner />}

          {pagination && !toolsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>

        {viewModal && (
          <Modal>
            <ToolServiceDetail
              onViewClose={() => {
                setViewModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
