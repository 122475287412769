import {
  GET_AVAILABLE_COMMON_MATERIALS,
  GET_LIST_COMMON_MATERIALS,
  GET_COMMON_MATERIAL_REQUESTS,
  ADD_COMMON_MATERIAL_REQUEST,
  UPDATE_COMMON_MATERIAL_REQUEST,
  SELECT_COMMON_MATERIAL_REQUEST,
  LOADING_COMMON_MATERIAL_REQUESTS,
  DELETE_COMMON_MATERIAL_REQUEST,
} from "../actions/types";

const initialState = {
  availableMaterials: [],
  availableMaterialsPagination: null,
  listMaterials: [],
  isLoading: false,
  commonMaterialRequests: [],
  commonMaterialRequestsPagination: null,
  selectedCommonMaterialRequest: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_COMMON_MATERIAL_REQUESTS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_AVAILABLE_COMMON_MATERIALS:
      return {
        ...state,
        availableMaterials: action.payload,
        availableMaterialsPagination: action.pagination,
        isLoading: false,
      };
    case GET_LIST_COMMON_MATERIALS:
      return {
        ...state,
        listMaterials: action.payload,
        isLoading: false,
      };
    case GET_COMMON_MATERIAL_REQUESTS:
      return {
        ...state,
        commonMaterialRequests: action.payload,
        commonMaterialRequestsPagination: action.pagination,
        isLoading: false,
      };
    case SELECT_COMMON_MATERIAL_REQUEST:
      return {
        ...state,
        selectedCommonMaterialRequest: action.payload,
      };
    case ADD_COMMON_MATERIAL_REQUEST:
      return {
        ...state,
        commonMaterialRequests: [
          ...state.commonMaterialRequests,
          action.payload,
        ],
        commonMaterialRequestsPagination: {
          ...state.commonMaterialRequestsPagination,
          total: state.commonMaterialRequestsPagination.total + 1,
        },
      };
    case UPDATE_COMMON_MATERIAL_REQUEST:
      return {
        ...state,
        commonMaterialRequests: state.commonMaterialRequests.map(
          (withdrawalRequest) => {
            if (action.payload.id !== withdrawalRequest.id) {
              return withdrawalRequest;
            }

            return {
              ...withdrawalRequest,
              ...action.payload,
            };
          }
        ),
      };
    case DELETE_COMMON_MATERIAL_REQUEST:
      return {
        ...state,
        commonMaterialRequests: state.commonMaterialRequests.filter(
          (material) => material.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
