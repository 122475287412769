import React from "react";
import { useSelector } from "react-redux";
import { Label, Button, Typography, Attachment } from "../common";
import moment from "moment";

export default function MaterialDeliveryDetail(props) {
  const selectedDelivered = useSelector(
    (state) => state.deliverMaterials.selectedDelivered
  );

  return (
    <div>
      <Typography type="h1">Delivery Detail</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Received by" />
          <p className="text-xs">
            {selectedDelivered.received_by ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedDelivered.received_by.avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={selectedDelivered.received_by.avatar.image}
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {selectedDelivered.received_by.first_name}{" "}
                    {selectedDelivered.received_by.last_name}
                    <br />
                    {selectedDelivered.received_by.contact_number}
                    <br />
                    {selectedDelivered.received_by.address}
                    <br />
                  </span>
                </div>
              </>
            ) : (
              "-"
            )}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Date Delivered" />
          <p className="text-xs">
            {selectedDelivered.delivered_date
              ? `${moment(selectedDelivered.delivered_date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Attachment" />
          {selectedDelivered.attachment ? (
            <Attachment path={selectedDelivered.attachment_full_url} />
          ) : (
            <p className="text-gray-600 text-xs">No attachments.</p>
          )}
        </div>

        <div className="w-1/2 px-4">
          <Label name="Vendor" />
          <p className="text-xs">
            {selectedDelivered.issue_purchase_order?.supplier ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    <img
                      alt="Common Supplier"
                      className="rounded-full border border-gray-100 object-cover"
                      src={
                        selectedDelivered.issue_purchase_order.supplier
                          .photo_full_url
                      }
                    />
                  </span>
                  <span className="text-xs ml-2">
                    {selectedDelivered.issue_purchase_order.supplier.name}
                    <br />
                    {
                      selectedDelivered.issue_purchase_order.supplier
                        .contact_person
                    }
                    <br />
                    {
                      selectedDelivered.issue_purchase_order.supplier
                        .contact_number
                    }
                    <br />
                    {selectedDelivered.issue_purchase_order.supplier.address}
                    <br />
                  </span>
                </div>
              </>
            ) : selectedDelivered.supplier ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    <img
                      alt="Supplier"
                      className="rounded-full border border-gray-100 object-cover"
                      src={selectedDelivered.supplier.photo_full_url}
                    />
                  </span>
                  <span className="text-xs ml-2">
                    {selectedDelivered.supplier.name}
                    <br />
                    {selectedDelivered.supplier.contact_person}
                    <br />
                    {selectedDelivered.supplier.contact_number}
                    <br />
                    {selectedDelivered.supplier.address}
                    <br />
                  </span>
                </div>
              </>
            ) : (
              " - "
            )}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Place of Delivery" />
          <p className="text-xs">
            {selectedDelivered.issue_purchase_order &&
            selectedDelivered.issue_purchase_order?.warehouse
              ? selectedDelivered.issue_purchase_order.warehouse.name
              : "-"}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Ship To" />
          <p className="text-xs">
            {selectedDelivered.issue_purchase_order?.ship_to ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedDelivered.issue_purchase_order.ship_to.avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={
                          selectedDelivered.issue_purchase_order.ship_to.avatar
                            .image
                        }
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {selectedDelivered.issue_purchase_order.ship_to.first_name}{" "}
                    {selectedDelivered.issue_purchase_order.ship_to.last_name}
                    <br />
                    {
                      selectedDelivered.issue_purchase_order.ship_to
                        .contact_number
                    }
                    <br />
                    {selectedDelivered.issue_purchase_order.ship_to_address}
                    <br />
                  </span>
                </div>
              </>
            ) : (
              "-"
            )}
          </p>
        </div>
      </div>

      <h1 className="mt-4 font-bold">Materials Delivered</h1>

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Price</th>
            {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
            <th className="text-xs px-4 py-2">Delivered Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectedDelivered.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {material.category ? material.category.name : "Uncategorized"}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">
                $
                {material.pivot.delivery_price
                  ? material.pivot.delivery_price
                  : material.price}
              </td>
              {/* <td className="text-xs border px-2 py-2">
                {selectedDelivered.issue_purchase_order &&
                  selectedDelivered.issue_purchase_order?.warehouse 
                    ? selectedDelivered.issue_purchase_order.warehouse.name
                    : "-"}
              </td> */}
              <td className="text-xs border px-2 py-2">
                {material.pivot.stock_qty}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
