import {
  GET_TASK_CATEGORIES,
  UPDATE_TASK_CATEGORY,
  DELETE_TASK_CATEGORY,
  ADD_TASK_CATEGORY,
  SELECT_TASK_CATEGORY,
  LOADING_TASK_CATEGORIES,
} from "../actions/types";
import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET TASK CATEGORIES
export const getTaskCategories = () => (dispatch, getState) => {
  dispatch({ type: LOADING_TASK_CATEGORIES });
  axios
    .get(BASE_URL + `/task_categories`, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: GET_TASK_CATEGORIES, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// SELECT TASK CATEGORY
export const selectTaskCategory = (taskCategory) => (dispatch) => {
  dispatch({
    type: SELECT_TASK_CATEGORY,
    payload: taskCategory,
  });
};

// ADD TASK CATEGORY
export const addTaskCategory = (taskCategory) => (dispatch, getState) => {
  dispatch({ type: LOADING_TASK_CATEGORIES });
  axios
    .post(BASE_URL + `/task_category`, taskCategory, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: ADD_TASK_CATEGORY, payload: res.data });
      swal({
        title: "Task Category Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE TASK CATEGORY
export const updateTaskCategory = (id, taskCategory) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_TASK_CATEGORIES });
  axios
    .put(BASE_URL + `/task_category/${id}`, taskCategory, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_TASK_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Task Category Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// DELETE TASK CATEGORY
export const deleteTaskCategory = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_TASK_CATEGORIES });
  axios
    .delete(BASE_URL + `/task_category/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_TASK_CATEGORY,
        payload: id,
      });
      swal({
        title: "Task Category Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
