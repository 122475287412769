import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import fp from "lodash/fp";
import { Label, Maps, Button } from "../common";
import moment from "moment";
import {
  getAttendanceDetails,
  exportAttendance,
} from "../../actions/attendances";

export default function AttendanceDetail(props) {
  const dispatch = useDispatch();
  const selectedAttendance = useSelector(
    (state) => state.attendances.selectedAttendance
  );
  const [range, setRange] = useState("daily");
  const exportToExcel = () => {
    dispatch(
      exportAttendance(
        selectedAttendance.id,
        moment(props.attendanceDate).format("Y-MM-DD")
      )
    );
  };

  const selectedAttendanceDetails = useSelector(
    (state) => state.attendances.selectedAttendanceDetails
  );

  const inTaskHeirarchy =
    selectedAttendance && selectedAttendance.in && selectedAttendance.in.task
      ? selectedAttendance.in.task.heirarchies
      : [];
  const outTaskHeirarchy =
    selectedAttendance && selectedAttendance.out && selectedAttendance.out.task
      ? selectedAttendance.out.task.heirarchies
      : [];

  return (
    <div>
      <Label name="Range" />
      <div className="mb-4">
        <input
          type="radio"
          name="range"
          value="daily"
          className="h-5 w-5 text-gray-600"
          defaultChecked
          onChange={() => {
            setRange("daily");
            dispatch(
              getAttendanceDetails(
                selectedAttendance.id,
                "daily",
                moment(props.attendanceDate).format("Y-MM-DD")
              )
            );
          }}
        />
        <span className="ml-2 mr-2 text-gray-700 text-xs">Daily</span>

        <input
          type="radio"
          name="range"
          value="monthly"
          className="h-5 w-5 text-gray-600"
          onChange={() => {
            setRange("monthly");
            dispatch(
              getAttendanceDetails(
                selectedAttendance.id,
                "monthly",
                moment(props.attendanceDate).format("Y-MM-DD")
              )
            );
          }}
        />
        <span className="ml-2 mr-2 text-gray-700 text-xs">Monthly</span>
      </div>

      {range === "daily" && (
        <div>
          <p className="text-gray-700 font-bold">In</p> <hr className="mb-2" />
          <div className="grid grid-cols-2 gap-8">
            <div>
              <Label name="Project/Task" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.in && selectedAttendance.in.project.title}
              </p>
            </div>
            <div>
              <Label name="Task Heirarchy" />
              {fp.isEmpty(inTaskHeirarchy) ? (
                <p className="text-sm text-gray-700 mb-2">
                  ---No Task Hierarchy---
                </p>
              ) : (
                inTaskHeirarchy.map((task) => (
                  <p className="text-sm text-gray-700 mb-2">{task.title}</p>
                ))
              )}
            </div>
            <div>
              <Label name="Time" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.in && selectedAttendance.in.time_taken}
              </p>
            </div>
            <div>
              <Label name="Location" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.in &&
                  `${selectedAttendance.in.latitude}, ${selectedAttendance.in.longitude}`}
              </p>
              {selectedAttendance.in &&
                selectedAttendance.in.latitude &&
                selectedAttendance.in.longitude && (
                  <Maps
                    lat={selectedAttendance.in.latitude}
                    lng={selectedAttendance.in.longitude}
                  />
                )}
            </div>
          </div>
          {/** OUT PROJECT */}
          <p className="text-gray-700 font-bold">Out</p> <hr className="mb-2" />
          <div className="grid grid-cols-2 gap-8">
            <div>
              <Label name="Project/Task" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.out && selectedAttendance.out.project.title}
              </p>
            </div>
            <div>
              <Label name="Task Heirarchy" />
              <p className="text-sm text-gray-700 mb-2">
                {fp.isEmpty(outTaskHeirarchy) ? (
                  <p className="text-sm text-gray-700 mb-2">
                    ---No Task Heirarchy---
                  </p>
                ) : (
                  outTaskHeirarchy.map((task) => (
                    <p className="text-sm text-gray-700 mb-2">{task.title}</p>
                  ))
                )}
              </p>
            </div>
            <div>
              <Label name="Time" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.out && selectedAttendance.out.time_taken}
              </p>
            </div>
            <div>
              <Label name="Location" />
              <p className="text-sm text-gray-700 mb-2">
                {selectedAttendance.out &&
                  `${selectedAttendance.out.latitude}, ${selectedAttendance.out.longitude}`}
              </p>
              {selectedAttendance.out &&
                selectedAttendance.out.latitude &&
                selectedAttendance.out.longitude && (
                  <Maps
                    lat={selectedAttendance.out.latitude}
                    lng={selectedAttendance.out.longitude}
                  />
                )}
            </div>
          </div>
        </div>
      )}

      {range === "monthly" && selectedAttendanceDetails && (
        <div>
          <Button
            text="Export to Excel"
            success="true"
            type="button"
            onClick={() => exportToExcel()}
          />
          <table className="table-fixed" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date</th>
                <th className="text-xs px-4 py-2">Time-in</th>
                <th className="text-xs px-4 py-2">Time-out</th>
                <th className="text-xs px-4 py-2">
                  Working
                  <br />
                  Hours
                </th>
                <th className="text-xs px-4 py-2">OT</th>
                <th className="text-xs px-4 py-2">
                  OT
                  <br />
                  Details
                </th>
                <th className="text-xs px-4 py-2">Location</th>
              </tr>
            </thead>
            <tbody>
              {selectedAttendanceDetails.map((attendanceDetail, index) => (
                <tr className="hover:bg-gray-200" key={index}>
                  <td className="border px-2 py-2 text-xs">
                    {attendanceDetail.date}
                  </td>
                  {attendanceDetail.is_leave ? (
                    <>
                      {attendanceDetail.leave_no_of_days < 1 ? (
                        <>
                          {/* half day Leave only, so show the half day working hours. */}
                          <td className="border px-2 py-2 text-xs">
                            {attendanceDetail.attendance_pairs &&
                              attendanceDetail.attendance_pairs.map(
                                (pair, index) => (
                                  <span>
                                    {pair.attendance_in.time_taken}
                                    <br />
                                  </span>
                                )
                              )}
                          </td>
                          <td className="border px-2 py-2 text-xs">
                            {attendanceDetail.attendance_pairs &&
                              attendanceDetail.attendance_pairs.map((pair) => (
                                <span>
                                  {pair.attendance_out.time_taken}
                                  <br />
                                </span>
                              ))}
                          </td>
                          <td className="border px-2 py-2 text-xs">
                            {attendanceDetail.total_work_hours}
                          </td>
                          <td className="border px-2 py-2 text-xs"></td>
                          <td className="border px-2 py-2 text-xs"></td>
                        </>
                      ) : (
                        <td
                          colSpan="5"
                          className="border px-2 py-2 text-xs"
                        ></td>
                      )}
                      <td className="border px-2 py-2 text-xs">
                        <p className="text-xs font-bold">On Leave:</p>
                        <p className="text-xs">
                          {attendanceDetail.leave_type}{" "}
                          {attendanceDetail.leave_no_of_days < 1 &&
                            " (Half Day)"}
                        </p>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.attendance_pairs &&
                          attendanceDetail.attendance_pairs.map(
                            (pair, index) => (
                              <span>
                                {pair.attendance_in.time_taken}
                                <br />
                              </span>
                            )
                          )}
                      </td>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.attendance_pairs &&
                          attendanceDetail.attendance_pairs.map((pair) => (
                            <span>
                              {pair.attendance_out.time_taken}
                              <br />
                            </span>
                          ))}
                      </td>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.total_work_hours}
                      </td>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.total_ot}
                      </td>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.overtime_details && (
                          <>
                            <strong>Time:</strong>
                            {attendanceDetail.overtime_details &&
                              ` ${attendanceDetail.overtime_details.start_time} - ${attendanceDetail.overtime_details.end_time}`}
                            <br />
                            <strong>Reason:</strong>
                            {attendanceDetail.overtime_details &&
                              ` ${attendanceDetail.overtime_details.reason}`}
                            <br />
                            <strong>Prepared by:</strong>
                            {attendanceDetail.overtime_details &&
                              attendanceDetail.overtime_details.prepared_by &&
                              ` ${attendanceDetail.overtime_details.prepared_by.first_name} ${attendanceDetail.overtime_details.prepared_by.last_name}`}
                            <br />
                            <strong>Approved by:</strong>
                            {attendanceDetail.overtime_details &&
                              attendanceDetail.overtime_details.approved_by &&
                              ` ${attendanceDetail.overtime_details.approved_by.first_name} ${attendanceDetail.overtime_details.approved_by.last_name}`}
                          </>
                        )}
                      </td>
                      <td className="border px-2 py-2 text-xs">
                        {attendanceDetail.attendance_pairs &&
                          attendanceDetail.attendance_pairs.map((pair) => (
                            <span>
                              {pair.hierarchy}
                              <br />
                            </span>
                          ))}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
