import {
  GET_STOCKTAKES,
  SELECT_STOCKTAKE,
  ADD_STOCKTAKE,
  DELETE_STOCKTAKE,
  LOADING_STOCKTAKES,
} from "../actions/types";

const initialState = {
  isLoading: false,
  stocktakes: [],
  stocktakesPagination: null,
  selectedStocktake: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_STOCKTAKES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STOCKTAKES:
      return {
        ...state,
        stocktakes: action.payload,
        stocktakesPagination: action.pagination,
        isLoading: false,
      };
    case ADD_STOCKTAKE:
      return {
        ...state,
        stocktakes: [...state.stocktakes, action.payload],
        stocktakesPagination: {
          ...state.stocktakesPagination,
          total: state.stocktakesPagination.total + 1,
        },
      };
    case SELECT_STOCKTAKE:
      return {
        ...state,
        selectedStocktake: action.payload,
      };
    case DELETE_STOCKTAKE:
      return {
        ...state,
        stocktakes: state.stocktakes.filter(
          (stocktake) => stocktake.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
