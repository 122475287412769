import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_STOCKTAKES,
  ADD_STOCKTAKE,
  SELECT_STOCKTAKE,
  DELETE_STOCKTAKE,
  LOADING_STOCKTAKES,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// get stocktakes
export const getStocktakes = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_STOCKTAKES });

  let endpoint = `/stocktakes?page=${page}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_STOCKTAKES,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// select stocktake
export const selectStocktake = (stocktake) => (dispatch) => {
  dispatch({
    type: SELECT_STOCKTAKE,
    payload: stocktake,
  });
};

// add stocktake
export const addStocktake = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/stocktakes/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Stocktake Added!",
        icon: "success",
      });
      dispatch({ type: ADD_STOCKTAKE, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// delete stocktake
export const deleteStocktake = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/stocktakes/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_STOCKTAKE,
        payload: id,
      });
      swal({
        title: "Stocktake Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
