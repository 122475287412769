import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { Spinner, Label, Pagination } from "../common";

import { getEquipments, getEquipmentFilters } from "../../actions/equipments";

import Select from "react-select";

export default function MyTasksEquipments(props) {
  const dispatch = useDispatch();
  const equipments = useSelector((state) => state.equipments.equipments);
  const equipmentsLoading = useSelector(
    (state) => state.equipments.equipmentsLoading
  );
  const equipmentsPagination = useSelector(
    (state) => state.equipments.pagination
  );
  const categories = useSelector(
    (state) => state.equipments.filters.categories
  );
  const selectedProject = props.selectedProject;

  const [equipmentSearchTerm, setEquipmentSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "All",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCondition, setSelectedCondition] = useState({
    value: "",
    label: "All",
  });

  let categoriesDropdown = [{ value: "", label: "All" }];
  let conditionsDropdown = [
    { value: "", label: "All" },
    { value: "Good", label: "Good" },
    { value: "Poor", label: "Poor" },
    { value: "For Repair", label: "For Repair" },
    { value: "Scrap", label: "Scrap" },
  ];
  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  useEffect(() => {
    dispatch(getEquipmentFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getEquipments(
        equipmentSearchTerm,
        pageNumber,
        "",
        selectedProject.id,
        selectedCondition.value,
        selectedCategory.value
      )
    );
  }, [
    dispatch,
    equipmentSearchTerm,
    pageNumber,
    selectedProject,
    selectedCondition,
    selectedCategory,
  ]);

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
        List of Equipments
      </h1>

      <div className="mb-4">
        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Category" />
            <Select
              options={categoriesDropdown}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </div>
          <div className="w-1/2 px-2">
            <Label name="Condition" />
            <Select
              options={conditionsDropdown}
              value={selectedCondition}
              onChange={setSelectedCondition}
            />
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Search" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              value={equipmentSearchTerm}
              onChange={(e) => setEquipmentSearchTerm(e.target.value)}
              type="text"
              placeholder="Search for Serial #, Description or Brand..."
            />
          </div>
        </div>

        <table className="table-auto ml-6">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Brand</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">S/N</th>
              <th className="text-xs px-4 py-2">Condition</th>
              <th className="text-xs px-4 py-2">Issued To</th>
            </tr>
          </thead>
          <tbody>
            {!equipmentsLoading &&
              equipments.map((equipment) => (
                <tr
                  key={equipment.id}
                  className={classNames("hover:bg-gray-200 cursor-pointer")}
                >
                  <td className="text-xs border px-2 py-2">
                    {equipment.brand}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {equipment.description}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {equipment.serial_no}
                  </td>

                  <td className="text-xs border px-2 py-2">
                    {equipment.condition}
                  </td>
                  <td className="text-xs border px-2 py-2"></td>
                </tr>
              ))}
          </tbody>
        </table>

        {equipmentsLoading && <Spinner />}

        {equipmentsPagination && !equipmentsLoading && (
          <Pagination
            from={equipmentsPagination.from}
            to={equipmentsPagination.to}
            total={equipmentsPagination.total}
            lastPage={equipmentsPagination.last_page}
            currentPage={equipmentsPagination.current_page}
            onPageClick={(page) => {
              setPageNumber(page);
            }}
          />
        )}
      </div>
    </div>
  );
}
