import React from "react";

export default function Footer() {
  return (
    <footer className="block py-4">
      <div className="container mx-auto px-4">
        <hr className="mb-4 border-b-1 border-gray-300" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}
              <a
                href="https://sbhe-ume.com.sg"
                className="text-gray-600 hover:text-gray-800 text-sm font-semibold py-1"
              >
                SBHE PTE LTD
              </a>
            </div>
          </div>
          <div className="w-full md:w-8/12 px-4">
            <ul className="flex flex-wrap list-none md:justify-end  justify-center">
              <li>
                <a
                  href="http://tender-staging.sbhe-ume-pm.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-gray-900 text-sm font-semibold block py-1 px-3"
                >
                  Tender Module
                </a>
              </li>
              <li>
                <a
                  href="http://drawing.sbhe-ume-pm.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-gray-900 text-sm font-semibold block py-1 px-3"
                >
                  Drawing Module
                </a>
              </li>
              <li>
                <a
                  href="http://old.sbhe-ume-pm.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-700 hover:text-gray-900 text-sm font-semibold block py-1 px-3"
                >
                  Old PM Web App
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
