export const initialValueEmployee = {
  company_id: null,
  role_id: null,
  employee_status: "Active",
  unique_id: "",
  serial_no: "",
  first_name: "",
  last_name: "",
  gender: "male",
  birthdate: null,
  nationality: "",
  civil_status: "Single",
  contact_number: "",
  emergency_number: "",
  designation: "",
  start_date: null,
  end_date: null,
  probation_end_date: null,
  wp_no: "",
  wp_application_date: "",
  wp_expiry_date: "",
  mye: "",
  nric: "",
  airport_pass_expiry_date: null,
  pass_type: "",
  passport_no: "",
  passport_expiry_date: "",
  remarks: "",
  levy_payment: 0,
  doa: null,
  basic_pay: 0,
  daily_rate: 0,
  sunday_rate: 0,
  holiday_rate: 0,
  ot_rate: 0,
  ph_rate: 0,
  food_rate: 0,
  night_shift_rate: 0,
  dinner_rate: 0,
};

export const civilStatusOptions = [
  {
    value: "Single",
    label: "Single",
  },
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Widowed",
    label: "Widowed",
  },
  {
    value: "Separated",
    label: "Separated",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
];
