import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label, Typography } from "../common";
import { unscrapTool } from "../../actions/tools";
import styled from "styled-components";

import swal from "sweetalert";

function ToolUnscrapForm(props) {
  const dispatch = useDispatch();

  const selectedScrappedTool = useSelector(
    (state) => state.tools.selectedScrappedTool
  );

  const [condition, setCondition] = useState(null);

  const onSubmit = () => {
    let data = {
      tool_movement_id: selectedScrappedTool.latest_movement.tool_movement_id,
      tool_id: selectedScrappedTool.id,
      status: condition,
    };
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "Unscrapping the tool will return it back to the main Tool List.",
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        dispatch(unscrapTool(data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <S.ToolUnscrapForm>
      <div>
        <Typography type="h1">Unscrap Tool</Typography>
        <Label name="Unscrap the tool and update its condition." />
        <div className="flex w-full mt-4 mb-4">
          <div className="mr-4">
            <Label name="Condition" />
          </div>
          <div className="flex">
            <input
              type="radio"
              name="send_to"
              value="Good"
              className="h-5 w-5 text-gray-600"
              defaultChecked={condition === "Good"}
              onClick={() => {
                setCondition("Good");
              }}
            />
            <span className="ml-2 mr-2 text-gray-700 text-xs">Good</span>
          </div>

          <div className="flex">
            <input
              type="radio"
              name="send_to"
              value="Poor"
              className="h-5 w-5 text-gray-600"
              defaultChecked={condition === "Poor"}
              onClick={() => {
                setCondition("Poor");
              }}
            />
            <span className="ml-2 mr-2 text-gray-700 text-xs">Poor</span>
          </div>
        </div>
        <div className="mt-2 flex">
          <Button
            success="true"
            text="Unscrap"
            onClick={() => {
              onSubmit();
            }}
          />
          <Button
            primary="true"
            text="Close"
            onClick={() => {
              props.onViewClose && props.onViewClose();
            }}
          />
        </div>
      </div>
    </S.ToolUnscrapForm>
  );
}

const S = {};

S.ToolUnscrapForm = styled.div`
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

export default ToolUnscrapForm;
