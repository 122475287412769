import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  getMyTasks,
  getUnacceptedTasks,
  getSubtasks,
  selectMyTask,
  selectTask,
} from "./tasks";

import {
  ADD_PROJECT,
  GET_PROJECTS,
  GET_PROJECT_DETAILS,
  SELECT_PROJECT_TASK,
  SELECT_PROJECT,
  LOADING_PROJECTS,
  GET_UNASSIGNED_PIC,
  GET_UNASSIGNED_PM,
  UPDATE_PROJECT,
  UPDATE_TASK,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const addProject = (project) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/project`, project, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_PROJECT,
        payload: res.data.data,
      });
      swal({
        title: "Project Added",
        text: "Kindly proceed to assigning Project Manager and PIC on Manpower tab.",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const getProjects = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/projects/all`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROJECTS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const searchProjects = (search_term) => (dispatch, getState) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        BASE_URL + `/projects/all?search_term=${search_term}`,
        tokenConfig(getState)
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const updateProject = (id, data) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/project/${id}/`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_PROJECT,
        payload: res.data.data,
      });
      swal({
        title: "Project Updated",
        text: "Details have been successfully updated.",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const deleteMapProject = (id, key) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/project/${id}/delete-map`, { key }, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_PROJECT,
        payload: res.data,
      });
      dispatch({
        type: UPDATE_TASK,
        payload: {
          response: res.data,
          input: res.data, // to accommodate compatibility with task reducer.
        },
      });
      swal({
        title: "File Removed",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const deleteProject = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/project/${id}`, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Project Deleted",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const getProjectDetail = (id) => (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_DETAILS,
    payload: [],
  });
  dispatch({ type: LOADING_PROJECTS });
  axios
    .get(BASE_URL + `/project/${id}/tasks?child=true`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_PROJECT_DETAILS,
        payload: res.data.data,
      });
    })
    .catch((err) => console.error(err));
};

export const selectProject = (project) => (dispatch) => {
  dispatch({
    type: SELECT_PROJECT,
    payload: project,
  });
};

export const selectProjectTask = (projectTask) => (dispatch) => {
  dispatch({
    type: SELECT_PROJECT_TASK,
    payload: projectTask,
  });
};

export const getUnassignedPIC = (projectID) => (dispatch, getState) => {
  axios
    .get(
      BASE_URL + `/company/employees/unassigned/project/${projectID}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_UNASSIGNED_PIC,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const getUnassignedPM = (projectID) => (dispatch, getState) => {
  axios
    .get(
      BASE_URL + `/company/employees/unassigned/project/${projectID}?role_id=3`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_UNASSIGNED_PM,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const assignToProject =
  (employee_id, project_ids, assignee = null, pic = null) =>
  (dispatch, getState) => {
    const data = {
      project_ids,
      assignee,
      pic,
    };

    axios
      .post(
        BASE_URL + `/employee/assign/${employee_id}/projects`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        swal({
          title: "Employee Assigned!",
          icon: "success",
        });
        dispatch(getProjectDetail(project_ids[0]));
      })
      .catch((err) => console.error(err));
  };

export const computeProjectPercentage =
  (project_id, id, type) => (dispatch, getState) => {
    dispatch({
      type: GET_PROJECT_DETAILS,
      payload: [],
    });
    dispatch({ type: LOADING_PROJECTS });
    axios
      .get(BASE_URL + `/project/${project_id}/details`, tokenConfig(getState))
      .then((res) => {
        console.log(res.data);
        if (type === "projects") {
          dispatch(getProjectDetail(project_id));
          dispatch(selectTask(res.data));
          dispatch(selectMyTask(res.data));
        } else {
          dispatch(getSubtasks(id));
        }
        swal({
          title: "Project Percentage Calculated!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

export const unassignToProject =
  (employee_id, project_ids) => (dispatch, getState) => {
    let data = { project_ids };
    axios
      .post(
        BASE_URL + `/employee/unassign/${employee_id}/projects`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        swal({
          title: "Employee Unassigned!",
          icon: "success",
        });
        dispatch(getProjectDetail(project_ids[0]));
      })
      .catch((err) => console.error(err));
  };

export const massAssignToProject =
  (employee_ids, project_id) => (dispatch, getState) => {
    const data = { employee_ids };
    axios
      .post(
        BASE_URL + `/project/assign/${project_id}/assignee-employees`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        swal({
          title: "Employee Assigned!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

export const acceptProject = (project_id) => (dispatch, getState) => {
  const data = {
    project_id,
    type: "project",
  };
  dispatch({ type: LOADING_PROJECTS });
  axios
    .post(
      BASE_URL + `/employee/assignments/accept`,
      data,
      tokenConfig(getState)
    )
    .then((res) => {
      swal({
        title: "Accepted Project!",
        icon: "success",
      });
      dispatch(getMyTasks());
      dispatch(getUnacceptedTasks());
    });
};
