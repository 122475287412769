import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import moment from "moment";
import { Modal, Button } from "../common";
import useDebounce from "helpers/useDebounce";

import { Avatar, Fieldset, Label, Pagination, Spinner } from "../common";

import { getProjects } from "../../actions/projects";
import { getAttendances, selectAttendance } from "../../actions/attendances";

import AttendanceDetail from "./AttendanceDetail";

export default function AttendancePage(props) {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);
  const attendances = useSelector((state) => state.attendances.attendances);
  const attendancesLoading = useSelector(
    (state) => state.attendances.attendancesLoading
  );
  // const attendanceDetailsLoading = useSelector(
  //   (state) => state.attendances.attendanceDetailsLoading
  // );
  const attendancePagination = useSelector(
    (state) => state.attendances.pagination
  );
  const selectedAttendance = useSelector(
    (state) => state.attendances.selectedAttendance
  );

  const [attendanceDate, setAttendanceDate] = useState(new Date());
  const [selectedProject, setSelectedProject] = useState({
    value: 0,
    label: "All",
  });
  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  const debouncedSearchTerm = useDebounce(employeeSearchTerm, 500);

  let projectsDropdown = [];

  projectsDropdown.push({ value: 0, label: "All" });
  for (let i in projects) {
    let projectDropdownObj = {
      value: projects[i].id,
      label: projects[i].title,
    };

    projectsDropdown.push(projectDropdownObj);
  }

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAttendances(
        moment(attendanceDate).format("Y-MM-DD").toString(),
        selectedProject.value,
        debouncedSearchTerm,
        pageNumber
      )
    );
  }, [
    dispatch,
    attendanceDate,
    selectedProject,
    debouncedSearchTerm,
    pageNumber,
  ]);

  return (
    <div className="m-12">
      <Fieldset>
        <h1 className="text-sm font-extrabold text-gray-600 mb-4">
          Filter Attendance
        </h1>
        <div className="mb-4">
          <Label name="Employee Name" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={employeeSearchTerm}
            onChange={(e) => setEmployeeSearchTerm(e.target.value)}
            type="text"
            placeholder="Search Employee Name..."
          />
        </div>

        <div className="mb-4">
          <div className="flex">
            <div className="flex-1">
              <Label name="Project" />
              <Select
                options={projectsDropdown}
                onChange={setSelectedProject}
                className="text-xs"
              />
            </div>

            <div className="flex-1 ml-4">
              <Label name="Date" />
              <DatePicker
                showYearDropdown
                selected={attendanceDate}
                dateFormat="yyyy-MM-dd"
                onChange={(date) => setAttendanceDate(date)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-xs"
              />
            </div>
          </div>
        </div>
      </Fieldset>

      <Fieldset>
        <h1 className="text-sm font-extrabold text-gray-600 mb-4">
          Attendance - {moment(attendanceDate).format("Y/MM/DD").toString()}
        </h1>
        <table className="w-full">
          <thead>
            <tr>
              <th className="text-xs w-1/4 px-4 py-2">Employee</th>
              <th className="text-xs w-1/12 px-4 py-2">In</th>
              <th className="text-xs w-1/8 px-4 py-2">Project</th>
              <th className="text-xs w-1/12 px-4 py-2">Out</th>
              <th className="text-xs w-1/8 px-4 py-2">Project</th>
              <th className="text-xs w-1/12 px-4 py-2">
                Total
                <br />
                Hours
              </th>
              <th className="text-xs w-1/12 px-4 py-2">
                Overtime
                <br />
                Hours
              </th>
              <th className="text-xs w-1/12 px-4 py-2">
                Overtime
                <br />
                Details
              </th>
              <th className="text-xs w-1/12 px-4 py-2">Total</th>
            </tr>
          </thead>
          <tbody>
            {!attendancesLoading &&
              attendances.map((attendance) => (
                <tr
                  key={attendance.id}
                  className={classNames("hover:bg-gray-200 cursor-pointer", {
                    "text-blue-600 bg-teal-200":
                      (selectedAttendance ? selectedAttendance.id : 0) ===
                      attendance.id,
                  })}
                  onClick={() => {
                    dispatch(selectAttendance(attendance));
                    setModalOpen(true);
                  }}
                >
                  <td className="border px-2 py-2">
                    <Avatar
                      name={`${attendance.first_name} ${attendance.last_name}`}
                      image={attendance.avatar ? attendance.avatar.image : null}
                    />
                  </td>

                  {(attendance.leave_application &&
                    attendance.leave_application.days_used < 1) ||
                  !attendance.leave_application ? (
                    <>
                      <td className="text-xs border px-2 py-2">
                        {attendance.in && attendance.in.time_taken}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {attendance.in &&
                          attendance.in.project &&
                          attendance.in.project.title}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {attendance.out && attendance.out.time_taken}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {attendance.out &&
                          attendance.out.project &&
                          attendance.out.project.title}
                      </td>
                      <td className="text-xs border px-4 py-2">
                        {attendance.out && attendance.hours}
                      </td>
                    </>
                  ) : (
                    <>
                      <td colSpan="5" className="text-xs border px-2 py-2"></td>
                    </>
                  )}
                  {attendance.leave_application ? (
                    <>
                      <td colSpan="2" className="border px-2 py-2 text-xs">
                        <p className="text-xs font-bold">On Leave:</p>
                        <p className="text-xs">
                          {attendance.leave_application.type_of_leave}{" "}
                          {attendance.leave_application.days_used < 1 &&
                            " (Half Day)"}
                        </p>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="text-xs border px-4 py-2">
                        {attendance.overtime}
                      </td>
                      <td className="text-xs border px-4 py-2">
                        {attendance.overtime_details && (
                          <>
                            <strong>Time:</strong>
                            {attendance.overtime_details &&
                              ` ${attendance.overtime_details.start_time} - ${attendance.overtime_details.end_time}`}
                            <br />
                            <strong>Reason:</strong>
                            {attendance.overtime_details &&
                              ` ${attendance.overtime_details.reason}`}
                            <br />
                            <strong>Prepared by:</strong>
                            {attendance.overtime_details &&
                              attendance.overtime_details.prepared_by &&
                              ` ${attendance.overtime_details.prepared_by.first_name} ${attendance.overtime_details.prepared_by.last_name}`}
                            <br />
                            <strong>Approved by:</strong>
                            {attendance.overtime_details &&
                              attendance.overtime_details.approved_by &&
                              ` ${attendance.overtime_details.approved_by.first_name} ${attendance.overtime_details.approved_by.last_name}`}
                          </>
                        )}
                      </td>
                    </>
                  )}

                  {attendance.leave_application &&
                  attendance.leave_application.days_used >= 1 ? (
                    <td className="text-xs border px-2 py-2"></td>
                  ) : (
                    <td className="text-xs font-bold border px-4 py-2">
                      {parseInt(attendance.hours || 0) +
                        parseInt(attendance.overtime || 0)}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>

        {attendancesLoading && <Spinner />}

        {attendancePagination && !attendancesLoading && (
          <Pagination
            from={attendancePagination.from}
            to={attendancePagination.to}
            total={attendancePagination.total}
            lastPage={attendancePagination.last_page}
            currentPage={attendancePagination.current_page}
            onPageClick={(page) => {
              setPageNumber(page);
            }}
          />
        )}
      </Fieldset>
      {modalOpen && selectedAttendance && (
        <Modal
          title={`${selectedAttendance.first_name} ${
            selectedAttendance.last_name
          } - ${moment(attendanceDate).format("MMM D, YYYY").toString()}`}
        >
          <AttendanceDetail attendanceDate={attendanceDate} />
          <div className="flex" style={{ marginLeft: -15 }}>
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                setModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
