import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import swal from "sweetalert";
import ToolCategoryForm from "./ToolCategoryForm";

import {
  Button,
  Table,
  Fieldset,
  Pagination,
  Spinner,
  Typography,
} from "../common";

import {
  getToolCategories,
  selectToolCategory,
  addToolCategory,
  updateToolCategory,
  deleteToolCategory,
} from "../../actions/tools";
import { toolListCategoriesColumns } from "./ToolTableColumns";
import {
  EditButton,
  ApproveButton,
  DenyButton,
  CancelButton,
  DotsHorizontalButton,
} from "../common/icons/HeroIcons";
import StyledDialog from "../common/StyledDialog";

export default function ToolCategoryPage(props) {
  const dispatch = useDispatch();
  const toolCategories = useSelector((state) => state.tools.toolCategories);
  const selectedToolCategory = useSelector(
    (state) => state.tools.selectedToolCategory
  );
  const pagination = useSelector(
    (state) => state.tools.paginationToolCategories
  );
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);

  const [pageNumber, setPageNumber] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [nameUpdate, setNameUpdate] = useState("");
  const [isAddModal, setIsAddModal] = useState(false);

  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    dispatch(getToolCategories(pageNumber));
  }, [dispatch, pageNumber]);

  const handleUpdateChange = (e) => {
    setNameUpdate(e.target.value);
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const onSubmit = (id) => {
    if (id) {
      dispatch(
        updateToolCategory(selectedToolCategory.id, { id, name: nameUpdate })
      );
      setIsUpdate(false);
      setIsEdit(false);
    } else {
      dispatch(addToolCategory({ name: categoryName }));
      setIsEdit(false);
    }
  };

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Tool Categories</Typography>
          <div className="flex justify-end">
            <Button
              text="+ add category"
              primary="true"
              onClick={() => {
                setIsAddModal(!isAddModal);
                // dispatch(selectToolCategory(null));
              }}
            />
          </div>

          <Table
            columns={toolListCategoriesColumns}
            data={
              <>
                {!toolsLoading &&
                  toolCategories.map((toolCategory) => (
                    <tr
                      key={toolCategory.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedToolCategory
                              ? selectedToolCategory.id
                              : 0) === toolCategory.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectToolCategory(toolCategory));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {isUpdate ? (
                            <input
                              key={toolCategory.id}
                              type="text"
                              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                              style={{ border: "1px solid #ccc" }}
                              autoComplete="off"
                              onChange={handleUpdateChange}
                              defaultValue={toolCategory.name}
                            />
                          ) : (
                            toolCategory.name
                          )}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-end">
                          {isUpdate ? (
                            <>
                              <div
                                className="flex flex-col text-gray-600 hover:text-gray-900"
                                onClick={() => onSubmit(toolCategory.id)}
                              >
                                <ApproveButton title={"Okay"} />
                              </div>
                              <div
                                className="flex flex-col text-gray-600 hover:text-gray-900"
                                onClick={() => {
                                  setIsUpdate(false);
                                }}
                              >
                                <CancelButton title={"Cancel"} />
                              </div>
                            </>
                          ) : (
                            <>
                              {!isEdit ? (
                                <div
                                  className="flex flex-col text-gray-600 hover:text-gray-900"
                                  onClick={() => {
                                    setIsEdit(true);
                                  }}
                                >
                                  <DotsHorizontalButton title={"Edit"} />
                                </div>
                              ) : (
                                <>
                                  <div
                                    className="flex flex-col text-gray-600 hover:text-gray-900"
                                    onClick={() => {
                                      setIsUpdate(true);
                                    }}
                                  >
                                    <EditButton
                                      title={"Update"}
                                      width={25}
                                      color={"#57bf57"}
                                    />
                                  </div>
                                  <div
                                    className="flex flex-col text-gray-600 hover:text-gray-900"
                                    onClick={() => {
                                      swal({
                                        icon: "warning",
                                        title: "Are you sure?",
                                        text: `Once deleted, it will label tools assigned as "Uncategorized"`,
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          dispatch(
                                            deleteToolCategory(
                                              selectedToolCategory.id
                                            )
                                          );
                                          selectToolCategory(null);
                                          setIsEdit(false);
                                          // reset();
                                        }
                                      });
                                    }}
                                  >
                                    <DenyButton title={"Delete"} />
                                  </div>
                                  <div
                                    className="flex flex-col text-gray-600 hover:text-gray-900"
                                    onClick={() => {
                                      setIsEdit(false);
                                      setIsUpdate(false);
                                    }}
                                  >
                                    <CancelButton title={"Cancel"} />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                {isAddModal && (
                  <StyledDialog
                    isOpen={isAddModal}
                    setIsOpen={() => setIsAddModal(!isAddModal)}
                    onClose={() => setIsAddModal(false)}
                    screenWidth={500}
                  >
                    <Typography type="h1">Add Category</Typography>
                    <input
                      type="text"
                      className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                      style={{ border: "1px solid #ccc" }}
                      autoComplete="off"
                      onChange={handleCategoryNameChange}
                      value={categoryName}
                    />
                    <Button
                      type="submit"
                      className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold mt-4 mr-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      text="Submit"
                      onClick={() => onSubmit()}
                    />
                  </StyledDialog>
                )}
              </>
            }
          />

          {toolsLoading && <Spinner />}

          {pagination && !toolsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      {/* <div className="w-1/2 px-4">
        <Fieldset>
          <ToolCategoryForm />
        </Fieldset>
      </div> */}
    </div>
  );
}
