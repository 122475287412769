//Tab.js
import React, { Component } from "react";
import classNames from "classnames";

const TabItem = (props) => (
  <button
    onClick={() => {
      props.onTabClick(props.name);
    }}
    className={classNames(
      "text-xs text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none",
      {
        "text-blue-500 border-b-2 font-medium border-blue-500": props.isActive,
      }
    )}
  >
    {props.name}
  </button>
);

export default class Tab extends Component {
  static Item = TabItem;

  render() {
    return (
      <nav className="flex flex-col sm:flex-row">{this.props.children}</nav>
    );
  }
}
