import {
  GET_TOOLS,
  GET_TOOL_MOVEMENTS,
  GET_UNISSUED_TOOLS,
  ADD_TOOL,
  UPDATE_TOOL,
  DELETE_TOOL,
  GET_TOOL_FILTERS,
  APPROVE_TOOL_REQUEST,
  DENY_TOOL_REQUEST,
  SELECT_TOOL_REQUEST,
  GET_TOOL_REQUESTS,
  LOADING_TOOLS,
  SELECT_TOOL,
  GET_SCRAPPED_TOOLS,
  UNSCRAP_TOOL,
  SELECT_SCRAPPED_TOOL,
  ISSUE_TOOL,
  GET_TOOL_DELIVERIES,
  SELECT_TOOL_DELIVERY,
  GET_SERVICED_TOOLS,
  SELECT_SERVICED_TOOL,
  GET_TOOL_CATEGORIES,
  UPDATE_TOOL_CATEGORY,
  DELETE_TOOL_CATEGORY,
  ADD_TOOL_CATEGORY,
  SELECT_TOOL_CATEGORY,
  ADD_REQUEST_TOOL,
  ADD_SCRAP_TOOL,
  ADD_SERVICE_TOOL,
  GET_PROJECT_TOOL_REQUESTS,
  RETURN_TOOL,
} from "../actions/types";

const initialState = {
  tools: [],
  toolMovements: [],
  unissuedTools: [],
  selectedTool: null,
  pagination: null,
  toolsLoading: false,
  toolCategories: [],
  scrappedTools: [],
  toolRequests: [],
  projectToolRequests: [],
  toolDeliveries: [],
  servicedTools: [],
  selectedToolCategory: null,
  selectedToolRequest: null,
  selectedToolDelivery: null,
  selectedServicedTool: null,
  selectedScrappedTool: null,
  paginationToolRequests: null,
  paginationProjectToolRequests: null,
  paginationToolDeliveries: null,
  paginationServicedTools: null,
  paginationToolCategories: null,
  paginationScrappedTools: null,
  filters: {
    companies: [],
    projects: [],
    employees: [],
    drivers: [],
    categories: [],
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TOOLS:
      return {
        ...state,
        toolsLoading: true,
      };
    case GET_TOOLS:
      return {
        ...state,
        tools: action.payload,
        pagination: action.pagination,
        toolsLoading: false,
      };
    case GET_TOOL_MOVEMENTS:
      return {
        ...state,
        toolMovements: action.payload,
      };
    case GET_UNISSUED_TOOLS:
      return {
        ...state,
        unissuedTools: action.payload,
      };
    case SELECT_TOOL:
      return {
        ...state,
        selectedTool: action.payload,
      };
    case SELECT_TOOL_REQUEST:
      return {
        ...state,
        selectedToolRequest: action.payload,
      };
    case GET_TOOL_FILTERS:
      return {
        ...state,
        filters: {
          companies: action.payload.companies,
          projects: action.payload.projects,
          employees: action.payload.employees,
          drivers: action.payload.drivers,
          categories: action.payload.categories,
        },
      };
    case GET_SCRAPPED_TOOLS:
      return {
        ...state,
        toolsLoading: false,
        scrappedTools: action.payload,
        paginationScrappedTools: action.pagination,
      };
    case GET_SERVICED_TOOLS:
      return {
        ...state,
        toolsLoading: false,
        servicedTools: action.payload,
        paginationServicedTools: action.pagination,
      };
    case SELECT_SERVICED_TOOL:
      return {
        ...state,
        selectedServicedTool: action.payload,
      };
    case GET_TOOL_DELIVERIES:
      return {
        ...state,
        toolsLoading: false,
        toolDeliveries: action.payload,
        paginationToolDeliveries: action.pagination,
      };
    case SELECT_TOOL_DELIVERY:
      return {
        ...state,
        selectedToolDelivery: action.payload,
      };
    case GET_TOOL_REQUESTS:
      return {
        ...state,
        toolsLoading: false,
        toolRequests: action.payload,
        paginationToolRequests: action.pagination,
      };
    case GET_PROJECT_TOOL_REQUESTS:
      return {
        ...state,
        toolsLoading: false,
        projectToolRequests: action.payload,
        paginationProjectToolRequests: action.pagination,
      };
    case APPROVE_TOOL_REQUEST:
      return {
        ...state,
        toolsLoading: false,
        // toolRequests: state.toolRequests.map((toolRequest) => {
        //   if (action.payload.id !== toolRequest.id) {
        //     return toolRequest;
        //   }

        //   return {
        //     ...toolRequest,
        //     status: "Approved",
        //     approved_comments: action.payload.comments,
        //   };
        // }),
        toolRequests: state.toolRequests.filter(
          (toolRequest) => toolRequest.id !== action.payload.id
        ),
      };
    case DENY_TOOL_REQUEST:
      return {
        ...state,
        toolsLoading: false,
        // toolRequests: state.toolRequests.map((toolRequest) => {
        //   if (action.payload.id !== toolRequest.id) {
        //     return toolRequest;
        //   }

        //   return {
        //     ...toolRequest,
        //     status: "Denied",
        //     denied_comments: action.payload.comments,
        //   };
        // }),
        toolRequests: state.toolRequests.filter(
          (toolRequest) => toolRequest.id !== action.payload.id
        ),
      };
    case GET_TOOL_CATEGORIES:
      return {
        ...state,
        toolCategories: action.payload,
        paginationToolCategories: action.pagination,
        toolsLoading: false,
      };
    case ADD_TOOL_CATEGORY:
      return {
        ...state,
        toolCategories: [...state.toolCategories, action.payload],
        paginationToolCategories: {
          ...state.paginationToolCategories,
          total: state.paginationToolCategories.total + 1,
        },
        toolsLoading: false,
      };
    case UPDATE_TOOL_CATEGORY:
      return {
        ...state,
        toolCategories: state.toolCategories.map((toolCategory) => {
          if (action.payload.id !== toolCategory.id) {
            return toolCategory;
          }

          return {
            ...toolCategory,
            ...action.payload,
          };
        }),
        toolsLoading: false,
      };
    case DELETE_TOOL_CATEGORY:
      return {
        ...state,
        toolCategories: state.toolCategories.filter(
          (toolCategory) => toolCategory.id !== action.payload
        ),
        toolsLoading: false,
      };
    case SELECT_TOOL_CATEGORY:
      return {
        ...state,
        selectedToolCategory: action.payload,
      };
    case ADD_TOOL:
      return {
        ...state,
        tools: [...state.tools, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        toolsLoading: false,
      };
    case UPDATE_TOOL:
      return {
        ...state,
        tools: state.tools.map((tool) => {
          if (action.payload.id !== tool.id) {
            return tool;
          }

          return {
            ...tool,
            ...action.payload,
          };
        }),
        toolsLoading: false,
      };
    case DELETE_TOOL:
      return {
        ...state,
        tools: state.tools.filter((tool) => tool.id !== action.payload),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
        toolsLoading: false,
      };
    case ADD_REQUEST_TOOL:
      return {
        ...state,
        tools: [...state.tools, action.payload],
        // pagination: {
        //   ...state.pagination,
        //   total: state.pagination.total + 1,
        // },
        toolsLoading: false,
      };
    case ADD_SCRAP_TOOL:
      return {
        ...state,
        tools: state.tools.filter((tool) => tool.id !== action.payload.tool.id),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
      };
    case UNSCRAP_TOOL:
      return {
        ...state,
        scrappedTools: state.scrappedTools.filter(
          (scrappedTool) => scrappedTool.id !== action.payload
        ),
        toolsLoading: false,
      };
    case ADD_SERVICE_TOOL:
      return {
        ...state,
        tools: state.tools.filter((tool) => tool.id !== action.payload.tool.id),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
      };
    case SELECT_SCRAPPED_TOOL:
      return {
        ...state,
        selectedScrappedTool: action.payload,
      };
    case ISSUE_TOOL:
      return {
        ...state,
        tools: [...state.tools, action.payload],
      };
    case RETURN_TOOL:
      return {
        ...state,
        selectedTool: { ...state.selectedTool, project: null },
        tools: state.tools.map((tool) => {
          if (action.payload !== tool.id) {
            return tool;
          }

          return {
            ...tool,
            project: null,
          };
        }),
        toolsLoading: false,
      };
    default:
      return state;
  }
}
