import {
  GET_COMMON_ISSUE_MATERIALS,
  LOADING_COMMON_ISSUE_MATERIALS,
  SELECT_COMMON_ISSUE_MATERIAL,
  ISSUE_COMMON_MATERIAL,
  DELETE_COMMON_ISSUE_MATERIAL,
  UPDATE_COMMON_ISSUE_MATERIAL,
} from "../actions/types";

const initialState = {
  issuePoMaterials: [],
  pagination: null,
  selected: null,
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_COMMON_ISSUE_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMMON_ISSUE_MATERIALS:
      return {
        ...state,
        issuePoMaterials: action.payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case SELECT_COMMON_ISSUE_MATERIAL:
      return {
        ...state,
        selected: action.payload,
      };
    case ISSUE_COMMON_MATERIAL:
      return {
        ...state,
        issuePoMaterials: [...state.issuePoMaterials, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case DELETE_COMMON_ISSUE_MATERIAL:
      return {
        ...state,
        issuePoMaterials: state.issuePoMaterials.filter(
          (material) => material.id !== action.payload
        ),
        isLoading: false,
      };
    case UPDATE_COMMON_ISSUE_MATERIAL:
      return {
        ...state,
        issuePoMaterials: state.issuePoMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    default:
      return state;
  }
}
