import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Label } from "components/common";
import moment from "moment";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import Range from "react-range-progress";
import ImageUploader from "react-images-upload";
import ImageUtils from "../../helpers/ImageUtils";
import MaterialIcon from "../../assets/img/material_icon.png";
import { updateDailyProgressReport } from "../../actions/documentations";
import { SRLWrapper } from "simple-react-lightbox";

export default function EditDailyProgressReport(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const [currentPercentage, setCurrentPercentage] = useState(
    selectedDocument.accomplishments
  );
  const [reportDate, setReportDate] = useState(
    selectedDocument.report_date
      ? new Date(selectedDocument.report_date)
      : new Date()
  );
  const [photos, setPhotos] = useState([]);
  const imageUploadRef = useRef(null);
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const onDrop = (photo) => {
    setPhotos(photo);
  };

  useEffect(() => {
    register("report_date");
    register("accomplishments");
    setValue(
      "report_date",
      moment(selectedDocument.report_date).format("YYYY-MM-DD").toString()
    );
    setValue("accomplishments", selectedDocument.accomplishments);
  }, [register, setValue, selectedDocument]);

  useEffect(() => {
    if (selectedDocument.materials) {
      let newMaterials = [];
      selectedDocument.materials.forEach((m) => {
        let material = {
          ...m,
          report_qty: m.pivot.report_qty,
        };
        newMaterials.push(material);
      });
      setSelectedMaterials(newMaterials);
    }
  }, [selectedDocument]);

  const handleReportDateChange = (date) => {
    setReportDate(date);
    setValue("report_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleProgressBarChange = (value) => {
    setCurrentPercentage(value);
    setValue("accomplishments", value);
  };

  const handleMaterialChange = (index, event) => {
    const values = [...selectedMaterials];
    values[index][event.target.name] = event.target.value;

    setSelectedMaterials(values);
  };

  const removeMaterialEntry = (index) => {
    let values = [...selectedMaterials];
    values.splice(index, 1);
    setSelectedMaterials(values);
  };

  const onUpdate = async (data, e) => {
    e.persist();
    swal({
      icon: "warning",
      title: "Are you sure you want to update this Daily Progress Report?",
      text: `This will update Daily Progress Report to the Project/Task.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        //If the photos were changed.
        if (photos.length > 0) {
          let tempImg = [];
          for (let i = 0; i < photos.length; i++) {
            tempImg.push(await ImageUtils.resizeImage(photos[i]));
          }
          if (tempImg.length > 0) {
            data.base64image = tempImg;
          }
        }

        if (selectedMaterials) {
          data.materials = selectedMaterials;
        }

        dispatch(updateDailyProgressReport(selectedDocument.id, data));

        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdate)}>
        {/* <h1 className="font-bold">Update Daily Progress Report</h1> */}

        <div className="grid">
          <div className="flex">
            <div className="w-1/3">
              <Label name="Start Date" />
              <DatePicker
                showYearDropdown
                selected={reportDate}
                dateFormat="yyyy-MM-dd"
                onChange={handleReportDateChange}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
                style={{ zIndex: 10 }}
              />
            </div>

            <div className="w-2/3 px-4">
              <Label name="Activity" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="activity"
                type="text"
                key={selectedDocument.activity}
                defaultValue={selectedDocument.activity}
                required
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/3">
              <Label name="Location" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="location"
                type="text"
                key={selectedDocument.location}
                defaultValue={selectedDocument.location}
                readOnly={true}
                required
              />
            </div>

            <div className="w-2/3 px-4">
              <Label name={`Accomplishments - ${currentPercentage}%`} />
              <Range
                value={parseInt(currentPercentage || 0)}
                fillColor={{
                  r: 20,
                  g: 150,
                  b: 100,
                  a: 0.75,
                }}
                trackColor={{
                  r: 10,
                  g: 10,
                  b: 0,
                  a: 0.5,
                }}
                height={14}
                width="100%"
                onChange={handleProgressBarChange}
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Drawing No." />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="drawing_no"
                key={selectedDocument.drawing_no}
                defaultValue={selectedDocument.drawing_no}
                type="text"
                required
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Grid Line" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="gridline"
                key={selectedDocument.gridline}
                defaultValue={selectedDocument.gridline}
                type="text"
                required
              />
            </div>
          </div>

          <div className="flex mt-4">
            <div className="w-1/2">
              <Label name="Hours" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="hours"
                key={selectedDocument.hours}
                defaultValue={selectedDocument.hours}
                type="text"
                required
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Manpower" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                name="manpower"
                key={selectedDocument.manpower}
                defaultValue={selectedDocument.manpower}
                type="text"
                required
              />
            </div>
          </div>

          <div className="mt-4">
            <Label name="Photos" />
            {selectedDocument && selectedDocument.attachments && (
              <SRLWrapper>
                <div className="flex">
                  {selectedDocument.attachments.map((attachment, index) => (
                    <img
                      alt={`attachment-${index}`}
                      className="border-none p-4"
                      src={attachment.image_full_url}
                      style={{
                        width: "256px",
                        height: "256px",
                      }}
                    />
                  ))}
                </div>
              </SRLWrapper>
            )}

            <ImageUploader
              ref={imageUploadRef}
              withPreview={true}
              onChange={onDrop}
              imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
              fileTypeError={" - image not supported"}
              fileSizeError={" - image size too big"}
            />
          </div>

          <div className="mt-4">
            <Label name="Materials used (if any)" />
            <p className="text-xs text-gray-600 mb-4">
              These are based on withdrawal requests made for this task.
            </p>

            <table className="table-fixed w-100 mb-4">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Photo</th>
                  <th className="text-xs px-4 py-2">Type</th>
                  <th className="text-xs px-4 py-2">Category</th>
                  <th className="text-xs px-4 py-2">Code</th>
                  <th className="text-xs px-4 py-2">Description</th>
                  <th className="text-xs px-4 py-2">Unit</th>
                  {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
                  <th className="text-xs px-4 py-2">Used Qty</th>
                  <th className="text-xs px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedMaterials.map((material, index) => (
                  <tr key={index} className="hover:bg-gray-200">
                    <td className="text-xs border px-2 py-2">
                      <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                        {material.photo_full_url ? (
                          <img
                            alt="..."
                            className="w-full align-middle border-none"
                            src={material.photo_full_url}
                          />
                        ) : (
                          <img
                            alt="..."
                            className="w-full align-middle border-none"
                            src={MaterialIcon}
                          />
                        )}
                      </span>
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {material.type}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {material.category?.name}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {material.code}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {material.description}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {material.unit}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      <input
                        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        autoComplete="off"
                        name="report_qty"
                        type="text"
                        value={material.report_qty}
                        onChange={(event) => handleMaterialChange(index, event)}
                      />
                    </td>
                    <td className="text-xs border px-2 py-2">
                      <div className="flex">
                        <button
                          type="button"
                          className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                          onClick={() => {
                            removeMaterialEntry(index);
                          }}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-4 mb-4">
          <Label name="Material Comments (other used materials)" />
          <textarea
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              rows={5}
              ref={register}
              key={selectedDocument.material_comments}
              defaultValue={selectedDocument.material_comments}
              name="material_comments"
            />
        </div>

        <Button text="Update" success="true" type="submit" />
        <Button
          text="Close"
          primary="true"
          // disabled={isFormLoading}
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </form>
    </div>
  );
}
