import React, { useState } from "react";
import { Fieldset, Tab } from "../common";

import ProjectSetupPage from "./ProjectSetupPage";
import TaskCategoryPage from "./TaskCategoryPage";

export default function ProjectPage(props) {
  const [activeTab, setActiveTab] = useState("Project Setup");

  return (
    <div className="m-12">
      <Fieldset>
        <Tab>
          <Tab.Item
            name="Project Setup"
            isActive={activeTab === "Project Setup"}
            onTabClick={() => {
              setActiveTab("Project Setup");
            }}
          />
          <Tab.Item
            name="Task Categories"
            isActive={activeTab === "Task Categories"}
            onTabClick={() => {
              setActiveTab("Task Categories");
            }}
          />
        </Tab>
      </Fieldset>

      {activeTab === "Project Setup" && <ProjectSetupPage />}
      {activeTab === "Task Categories" && <TaskCategoryPage />}
    </div>
  );
}
