import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliers, selectSupplier } from "../../actions/suppliers";
import { Button, Typography, Table, Modal } from "../../components/common";
import classNames from "classnames";

import { EditButton } from "../../components/common/icons/HeroIcons";

import { supplierColumns } from "./MaterialTableColumns";
import MaterialSupplierForm from "./MaterialSupplierForm";

export default function MaterialSupplierPage() {
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const selectedSupplier = useSelector(
    (state) => state.suppliers.selectedSupplier
  );

  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  return (
    <div className="m-12">
      <div className="flex">
        <div className="w-full">
          <Typography type="h1">
            List of Suppliers for Specialised Materials
          </Typography>
          <div className="flex justify-end mb-4">
            <Button
              text="+ add supplier"
              primary="true"
              onClick={() => {
                dispatch(selectSupplier(null));
                setAddModal(true);
              }}
            />
          </div>

          <Table
            columns={supplierColumns}
            data={
              <>
                {suppliers.map((supplier) => (
                  <tr
                    key={supplier.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedSupplier ? selectedSupplier.id : 0) ===
                        supplier.id,
                    })}
                    onClick={() => {
                      dispatch(selectSupplier(supplier));
                    }}
                  >
                    <td className="px-6 py-3 whitespace-nowrap">
                      <img
                        src={supplier.photo_full_url}
                        alt={`Supplier - ${supplier.name}`}
                        style={{ width: 200 }}
                      />
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {supplier.name}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {supplier.address}
                      </div>
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap">
                      <div className="text-xs text-gray-900">
                        {supplier.contact_person}
                        <br />
                        {supplier.contact_number}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div
                        className="flex flex-col text-gray-600 hover:text-gray-900"
                        onClick={() => {
                          dispatch(selectSupplier(supplier));
                          setAddModal(true);
                        }}
                      >
                        <EditButton title={"Update"} />
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          />
        </div>

        {addModal && (
          <Modal>
            <MaterialSupplierForm
              onViewClose={() => {
                setAddModal(false);
              }}
            />
          </Modal>
        )}
      </div>
    </div>
  );
}
