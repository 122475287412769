import {
  GET_COMMON_SUPPLIERS,
  ADD_COMMON_SUPPLIER,
  UPDATE_COMMON_SUPPLIER,
  DELETE_COMMON_SUPPLIER,
  SELECT_COMMON_SUPPLIER,
} from "../actions/types";

const initialState = {
  suppliers: [],
  selectedSupplier: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMON_SUPPLIERS:
      return {
        ...state,
        suppliers: payload,
      };
    case ADD_COMMON_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, payload],
      };
    case UPDATE_COMMON_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (payload.id !== supplier.id) {
            return supplier;
          }

          return {
            ...supplier,
            payload,
          };
        }),
      };
    case SELECT_COMMON_SUPPLIER:
      return {
        ...state,
        selectedSupplier: payload,
      };
    case DELETE_COMMON_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.filter(
          (supplier) => supplier.id !== payload
        ),
      };
    default:
      return state;
  }
}
