import React from "react";
import { useSelector } from "react-redux";
import { Button, Label, Table } from "components/common";
import moment from "moment";
import {
  toolRequestRequestedSNToolsColumns,
  toolRequestRequestedToolsColumns,
} from "./ToolTableColumns";

export default function ToolRequestDetail(props) {
  const selectedToolRequest = useSelector(
    (state) => state.tools.selectedToolRequest
  );

  const tools = selectedToolRequest.tools ? selectedToolRequest.tools : [];

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Request Tool Details
      </h1>

      <div
        className="grid grid-cols-2 gap-4"
        style={{
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 30,
        }}
      >
        <div>
          <Label name="Status" />
          {selectedToolRequest.status ? (
            <span
              className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-25"
              style={{
                background:
                  selectedToolRequest.status === "Pending"
                    ? "#ffb52c"
                    : "#57bf57",
                color: "#fff",
              }}
            >
              {selectedToolRequest.status}
            </span>
          ) : (
            "---"
          )}
        </div>
        <div>
          <Label name="Date Requested" />
          <p className="text-sm mb-4">
            {selectedToolRequest.date
              ? `${moment(selectedToolRequest.date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Start Date" />
          <p className="text-sm mb-4">
            {selectedToolRequest.from_date
              ? `${moment(selectedToolRequest.from_date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="End Date" />
          <p className="text-sm mb-4">
            {selectedToolRequest.to_date
              ? `${moment(selectedToolRequest.to_date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Requested by" />
          <p className="text-sm mb-4">
            {selectedToolRequest.requested_by
              ? `${selectedToolRequest.requested_by.first_name} ${selectedToolRequest.requested_by.last_name}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="From Project" />
          <p className="text-sm mb-4">
            {selectedToolRequest.project
              ? selectedToolRequest.project.title
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Comments" />
          <p className="text-sm mb-4">{selectedToolRequest.comments}</p>
        </div>

        <div>
          <Label name="Request By Type" />
          <p className="text-sm mb-4">{selectedToolRequest.request_by_type}</p>
        </div>
      </div>

      <Table
        columns={
          selectedToolRequest.request_by_type === "Description"
            ? toolRequestRequestedToolsColumns
            : toolRequestRequestedSNToolsColumns
        }
        data={
          <>
            {tools.length > 0 ? (
              tools.map(
                (toolRequest, index) =>
                  toolRequest.tool && (
                    <tr
                      key={index}
                      className="hover:bg-gray-200 cursor-pointer"
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tool.brand ? toolRequest.tool.brand : ""}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tool.description}
                        </div>
                      </td>
                      {selectedToolRequest.request_by_type ===
                        "Description" && (
                        <td className="px-6 py-3 whitespace-nowrap">
                          <div className="text-xs text-gray-900">
                            {toolRequest.quantity}
                          </div>
                        </td>
                      )}

                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tool.serial_no}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tool.purchase_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {toolRequest.tool.warranty_expiry_date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {toolRequest.tool.condition ? (
                          <span
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-full"
                            style={{
                              background:
                                toolRequest.tool.condition === "Poor"
                                  ? "#ffb52c"
                                  : "#57bf57",
                              color: "#fff",
                            }}
                          >
                            {toolRequest.tool.condition}
                          </span>
                        ) : (
                          "---"
                        )}
                      </td>
                    </tr>
                  )
              )
            ) : (
              <div className="align-self-center">
                <p className="text-xs text-gray-600 text-center mt-4">
                  <i>No tools specified.</i>
                </p>
              </div>
            )}
          </>
        }
      />

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
