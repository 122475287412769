import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fieldset, Spinner, Button } from "../common";
import classNames from "classnames";

import {
  getTaskCategories,
  selectTaskCategory,
} from "../../actions/taskCategories";

import TaskCategoryForm from "./TaskCategoryForm";

export default function TaskCategoryPage(props) {
  const dispatch = useDispatch();
  const taskCategories = useSelector(
    (state) => state.taskCategories.taskCategories
  );
  const selectedTaskCategory = useSelector(
    (state) => state.taskCategories.selectedTaskCategory
  );
  const isLoading = useSelector((state) => state.taskCategories.isLoading);

  useEffect(() => {
    dispatch(getTaskCategories());
  }, [dispatch]);

  return (
    <div className="flex">
      <div classname="w-1/2">
        <Fieldset>
          <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
            Task Categories
          </h1>

          <Button
            text="+ add category"
            primary="true"
            onClick={() => {
              dispatch(selectTaskCategory(null));
            }}
          />

          <table className="table-auto ml-6 mt-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Name</th>
                <th className="text-xs px-4 py-2">Level</th>
              </tr>
            </thead>

            <tbody>
              {!isLoading &&
                taskCategories.map((taskCategory) => (
                  <tr
                    key={taskCategory.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedTaskCategory ? selectedTaskCategory.id : 0) ===
                        taskCategory.id,
                    })}
                    onClick={() => {
                      dispatch(selectTaskCategory(taskCategory));
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {taskCategory.name}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {taskCategory.level}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {isLoading && <Spinner />}
        </Fieldset>
      </div>

      <div className="w-1/2 px-4">
        <Fieldset>
          <TaskCategoryForm />
        </Fieldset>
      </div>
    </div>
  );
}
