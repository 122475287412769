import {
  GET_FOR_ALLOCATE_MATERIALS,
  LOADING_ALLOCATE_MATERIALS,
  GET_ALLOCATED_MATERIALS,
  SELECT_FOR_ALLOCATE_MATERIAL,
  SELECT_ALLOCATED_MATERIAL,
  ALLOCATE_MATERIAL,
} from "../actions/types";

const initialState = {
  forAllocateMaterials: [],
  allocatedMaterials: [],
  allocatedPagination: null,
  forAllocatePagination: null,
  selectedAllocated: null,
  selectedForAllocate: null,
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ALLOCATE_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_FOR_ALLOCATE_MATERIALS:
      return {
        ...state,
        forAllocateMaterials: action.payload,
        forAllocatePagination: action.pagination,
        isLoading: false,
      };
    case GET_ALLOCATED_MATERIALS:
      return {
        ...state,
        allocatedMaterials: action.payload,
        allocatedPagination: action.pagination,
        isLoading: false,
      };
    case SELECT_FOR_ALLOCATE_MATERIAL:
      return {
        ...state,
        selectedForAllocate: action.payload,
      };
    case SELECT_ALLOCATED_MATERIAL:
      return {
        ...state,
        selectedAllocated: action.payload,
      };
    case ALLOCATE_MATERIAL:
      return {
        ...state,
        forAllocateMaterials: state.forAllocateMaterials.filter(
          (forAllocateMaterial) => forAllocateMaterial.id !== action.payload
        ),
        forAllocatePagination: {
          ...state.forAllocatePagination,
          total: state.forAllocatePagination.total
            ? state.forAllocatePagination - 1
            : state.forAllocateMaterials
            ? state.forAllocateMaterials.length
            : 0,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}
