import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import {
  Button,
  Fieldset,
  Label,
  Pagination,
  Spinner,
  Tab,
  Typography,
  Table,
} from "../common";
import ToolRequestPage from "./ToolRequestPage";
import ToolScrappedPage from "./ToolScrappedPage";
import ToolServicePage from "./ToolServicePage";
import ToolDeliveryPage from "./ToolDeliveryPage";
import ToolCategoryPage from "./ToolCategoryPage";
import ToolForm from "./ToolForm";
import Select from "react-select";
import ToolScrappingForm from "./ToolScrappingForm";
import ToolServiceForm from "./ToolServiceForm";
import ToolMovementView from "./ToolMovementView";
import ToolReturnForm from "./ToolReturnForm";
import StyledDialog from "../common/StyledDialog";
import { roles } from "../../helpers/userRoles";
import {
  ViewButton,
  EditButton,
  OptionButton,
} from "../common/icons/HeroIcons";

import { getTools, selectTool, getToolFilters } from "../../actions/tools";
import { toolListColumns } from "./ToolTableColumns";

export default function ToolPage(props) {
  const dispatch = useDispatch();
  const tools = useSelector((state) => state.tools.tools);
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const toolsPagination = useSelector((state) => state.tools.pagination);
  const selectedTool = useSelector((state) => state.tools.selectedTool);

  const projects = useSelector((state) => state.tools.filters.projects);
  const companies = useSelector((state) => state.tools.filters.companies);
  const categories = useSelector((state) => state.tools.filters.categories);

  const user = useSelector((state) => state.auth.user);
  const { employee_id } = user;

  const [activeTab, setActiveTab] = useState("List");
  const [isReset, setIsReset] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isScrapModal, setIsScrapModal] = useState(false);
  const [isServiceModal, setIsServiceModal] = useState(false);
  const [isMovementModal, setIsMovementModal] = useState(false);
  const [isReturnModal, setIsReturnModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  //FILTERS
  const [toolSearchTerm, setToolSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState({
    value: "",
    label: "All",
  });
  const [selectedProject, setSelectedProject] = useState({
    value: "",
    label: "All",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    value: "",
    label: "All",
  });
  const [selectedCondition, setSelectedCondition] = useState({
    value: "",
    label: "All",
  });
  const [pageNumber, setPageNumber] = useState(1);

  let projectsDropdown = [{ value: "", label: "All" }];
  let categoriesDropdown = [{ value: "", label: "All" }];
  let conditionsDropdown = [
    { value: "", label: "All" },
    { value: "Good", label: "Good" },
    { value: "Poor", label: "Poor" },
    // { value: "For Repair", label: "For Repair" },
    // { value: "Scrap", label: "Scrap" },
  ];
  let companiesDropdown = [{ value: "", label: "All" }];

  for (let i in projects) {
    let projectDropdownObj = {
      value: projects[i].id,
      label: projects[i].title,
    };

    projectsDropdown.push(projectDropdownObj);
  }

  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  useEffect(() => {
    dispatch(getToolFilters());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getTools(
        toolSearchTerm,
        pageNumber,
        selectedCompany.value,
        selectedProject.value,
        selectedCondition.value,
        selectedCategory.value
      )
    );
  }, [
    dispatch,
    toolSearchTerm,
    pageNumber,
    selectedCompany,
    selectedProject,
    selectedCondition,
    selectedCategory,
  ]);

  return (
    <div className="m-12">
      <Fieldset>
        <Tab>
          <Tab.Item
            name="List"
            isActive={activeTab === "List"}
            onTabClick={() => {
              setActiveTab("List");
            }}
          />
          <Tab.Item
            name="Requests"
            isActive={activeTab === "Requests"}
            onTabClick={() => {
              setActiveTab("Requests");
            }}
          />
          <Tab.Item
            name="Delivery"
            isActive={activeTab === "Delivery"}
            onTabClick={() => {
              setActiveTab("Delivery");
            }}
          />
          <Tab.Item
            name="Service"
            isActive={activeTab === "Service"}
            onTabClick={() => {
              setActiveTab("Service");
            }}
          />
          <Tab.Item
            name="Scrapped"
            isActive={activeTab === "Scrapped"}
            onTabClick={() => {
              setActiveTab("Scrapped");
            }}
          />
          <Tab.Item
            name="Categories"
            isActive={activeTab === "Categories"}
            onTabClick={() => {
              setActiveTab("Categories");
            }}
          />
        </Tab>
      </Fieldset>

      {activeTab === "List" && (
        <div className="flex">
          <div className="w-full">
            <Fieldset>
              <Typography type="h1">List of Tools</Typography>
              <div className="mb-4">
                <div className="flex mt-4">
                  <div className="w-1/2 px-2">
                    <Label name="Category" />
                    <Select
                      className="text-xs"
                      options={categoriesDropdown}
                      value={selectedCategory}
                      onChange={(option) => {
                        setSelectedCategory(option);
                        setPageNumber(1);
                      }}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label name="Company" />
                    <Select
                      className="text-xs"
                      options={companiesDropdown}
                      value={selectedCompany}
                      onChange={(option) => {
                        setSelectedCompany(option);
                        setPageNumber(1);
                      }}
                    />
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="w-1/2 px-2">
                    <Label name="Project" />
                    <Select
                      className="text-xs"
                      options={projectsDropdown}
                      value={selectedProject}
                      onChange={(option) => {
                        setSelectedProject(option);
                        setPageNumber(1);
                      }}
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <Label name="Condition" />
                    <Select
                      className="text-xs"
                      options={conditionsDropdown}
                      value={selectedCondition}
                      onChange={(option) => {
                        setSelectedCondition(option);
                        setPageNumber(1);
                      }}
                    />
                  </div>
                </div>

                <div className="flex mt-4">
                  <div className="w-1/2 px-2">
                    <Label name="Search" />
                    <input
                      className="text-xs shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      autoComplete="off"
                      value={toolSearchTerm}
                      onChange={(e) => {
                        setToolSearchTerm(e.target.value);
                        setPageNumber(1);
                      }}
                      type="text"
                      placeholder="Search for Serial #, Description or Brand..."
                    />
                  </div>
                </div>

                <div className="flex justify-end">
                  <Button
                    text="+ add tool"
                    primary="true"
                    onClick={() => {
                      dispatch(selectTool(null));
                      setIsReset(true);
                      setIsEdit(false);
                      setIsOpenModal(!isOpenModal);
                    }}
                  />
                </div>
              </div>

              <Table
                columns={toolListColumns}
                data={
                  <>
                    {!toolsLoading &&
                      tools.map((tool) => (
                        <tr
                          key={tool.id}
                          className={classNames(
                            "hover:bg-gray-200 cursor-pointer",
                            {
                              "text-blue-600 bg-teal-200":
                                (selectedTool ? selectedTool.id : 0) ===
                                tool.id,
                            }
                          )}
                          onClick={() => {
                            dispatch(selectTool(tool));
                          }}
                        >
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.brand}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.description}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.company && tool.company.name}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.serial_no}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.purchase_date}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            <div className="text-xs text-gray-900">
                              {tool.warranty_expiry_date}
                            </div>
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap">
                            {tool.condition ? (
                              <span
                                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-full"
                                style={{
                                  background:
                                    tool.condition === "Poor"
                                      ? "#ffb52c"
                                      : "#57bf57",
                                  color: "#fff",
                                }}
                              >
                                {tool.condition}
                              </span>
                            ) : (
                              "---"
                            )}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                            {tool.project && tool.project.title}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                            {tool.latest_movement &&
                              tool.latest_movement.movement.recipient &&
                              tool.latest_movement.movement.recipient
                                .first_name}{" "}
                            {tool.latest_movement &&
                              tool.latest_movement.movement.recipient &&
                              tool.latest_movement.movement.recipient.last_name}
                          </td>
                          <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                            <div className="flex flex-row justify-between">
                              <div
                                className="flex flex-col text-gray-600 hover:text-gray-900"
                                onClick={() => {
                                  dispatch(selectTool(tool));
                                  setIsOpenModal(true);
                                  setIsEdit(true);
                                }}
                              >
                                <ViewButton title={"View"} />
                              </div>
                              {(user.employee.role_id === roles.su ||
                                user.employee.role_id === roles.proc) && (
                                <>
                                  <div
                                    className="flex flex-col text-gray-600 hover:text-gray-900"
                                    onClick={() => {
                                      dispatch(selectTool(tool));
                                      setIsOpenModal(true);
                                      setIsEdit(false);
                                    }}
                                  >
                                    <EditButton title={"Update"} />
                                  </div>
                                  <div className="flex flex-col">
                                    <Popover className="relative top-0 left-0 text-center">
                                      <Popover.Button>
                                        <OptionButton title={"Option"} />
                                      </Popover.Button>
                                      <Popover.Panel
                                        className="absolute z-10 px-4 mt-3 transform -translate-x-1/2 sm:px-0 lg:max-w-3xl shadow-lg rounded-xl w-9"
                                        style={{
                                          top: 0,
                                          right: -55,
                                          width: 150,
                                        }}
                                      >
                                        <div className="flex flex-col bg-white rounded-lg">
                                          <div
                                            className="border-b border-gray-300 px-4 py-2 text-gray-600 hover:text-gray-900"
                                            onClick={() => {
                                              dispatch(selectTool(tool));
                                              setIsMovementModal(true);
                                            }}
                                          >
                                            <p>View Movement</p>
                                          </div>
                                          {tool.project && (
                                            <div
                                              className="border-b border-gray-300 px-4 py-2 text-gray-600 hover:text-gray-900"
                                              onClick={() => {
                                                dispatch(selectTool(tool));
                                                setSelectedProjectId(
                                                  tool.project.id
                                                );
                                                setIsReturnModal(true);
                                              }}
                                            >
                                              <p>Return</p>
                                            </div>
                                          )}
                                          <div
                                            className="border-b border-gray-300 px-4 py-2 text-gray-600 hover:text-gray-900"
                                            onClick={() => {
                                              dispatch(selectTool(tool));
                                              setIsScrapModal(true);
                                            }}
                                          >
                                            <p>Scrap</p>
                                          </div>
                                          <div
                                            className="px-4 py-2 text-gray-600 hover:text-gray-900"
                                            onClick={() => {
                                              dispatch(selectTool(tool));
                                              setIsServiceModal(true);
                                            }}
                                          >
                                            <p>Service</p>
                                          </div>
                                        </div>
                                      </Popover.Panel>
                                    </Popover>
                                  </div>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </>
                }
              />

              {toolsLoading && <Spinner />}

              {toolsPagination && !toolsLoading && (
                <Pagination
                  from={toolsPagination.from}
                  to={toolsPagination.to}
                  total={toolsPagination.total}
                  lastPage={toolsPagination.last_page}
                  currentPage={toolsPagination.current_page}
                  onPageClick={(page) => {
                    setPageNumber(page);
                  }}
                />
              )}
            </Fieldset>
          </div>
          {isOpenModal && (
            <StyledDialog
              isOpen={isOpenModal}
              setIsOpen={() => setIsOpenModal(!isOpenModal)}
              onClose={() => setIsOpenModal(false)}
              screenWidth={500}
            >
              <ToolForm
                onViewClose={() => setIsOpenModal(false)}
                isReset={isReset}
                setIsReset={setIsReset}
                isEdit={isEdit}
              />
            </StyledDialog>
          )}

          {isScrapModal && (
            <StyledDialog
              isOpen={isScrapModal}
              setIsOpen={() => setIsScrapModal(!isScrapModal)}
              onClose={() => setIsScrapModal(false)}
              screenWidth={400}
            >
              <ToolScrappingForm employeeId={employee_id} />
            </StyledDialog>
          )}

          {isServiceModal && (
            <StyledDialog
              isOpen={isServiceModal}
              setIsOpen={() => setIsServiceModal(!isServiceModal)}
              onClose={() => setIsServiceModal(false)}
              screenWidth={400}
            >
              <ToolServiceForm employeeId={employee_id} />
            </StyledDialog>
          )}

          {isMovementModal && (
            <StyledDialog
              isOpen={isMovementModal}
              setIsOpen={() => setIsMovementModal(!isMovementModal)}
              onClose={() => setIsMovementModal(false)}
              screenWidth={600}
            >
              <ToolMovementView
                onViewClose={() => {
                  setIsMovementModal(false);
                }}
              />
            </StyledDialog>
          )}

          {isReturnModal && (
            <StyledDialog
              isOpen={isReturnModal}
              setIsOpen={() => setIsReturnModal(!isReturnModal)}
              onClose={() => setIsReturnModal(false)}
              screenWidth={600}
            >
              <ToolReturnForm projectId={selectedProjectId} />
            </StyledDialog>
          )}
        </div>
      )}

      {activeTab === "Requests" && <ToolRequestPage />}
      {activeTab === "Scrapped" && <ToolScrappedPage />}
      {activeTab === "Service" && <ToolServicePage />}
      {activeTab === "Delivery" && <ToolDeliveryPage />}
      {activeTab === "Categories" && <ToolCategoryPage />}
    </div>
  );
}
