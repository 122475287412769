import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_BULK_PO_MATERIALS,
  GET_BULK_PO_MATERIALS_DROPDOWN,
  LOADING_BULK_PO_MATERIALS,
  SELECT_BULK_PO_MATERIAL,
  ADD_BULK_PO_MATERIAL,
  UPDATE_BULK_PO_MATERIAL,
  DELETE_BULK_PO_MATERIAL,
  SET_LOADING_BULK_PO_PDF,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET BULK PO MATERIALS
export const getBulkPoMaterials =
  (page, project_id, task_id, sort_by) => (dispatch, getState) => {
    dispatch({ type: LOADING_BULK_PO_MATERIALS });

    let endpoint = `/bulk-po-materials?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;
    if (sort_by) endpoint += `&sort_by=${sort_by}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_BULK_PO_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

export const getBulkPoMaterialsDropdown =
  (project_id, task_id) => (dispatch, getState) => {
    let endpoint = `/bulk-po-materials?dropdown=true`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_BULK_PO_MATERIALS_DROPDOWN,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

// SELECT BULK PO MATERIAL
export const selectBulkPoMaterial = (bulkPoMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_BULK_PO_MATERIAL,
    payload: bulkPoMaterial,
  });
};

// ADD BULK PO MATERIAL
export const addBulkPoMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/bulk-po-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Bulk PO Added!",
        icon: "success",
      });
      dispatch({ type: ADD_BULK_PO_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

export const updateBulkPoMaterial =
  (id, bulkPoMaterial) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/bulk-po-materials/${id}`;

    axios
      .put(endpoint, bulkPoMaterial, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: UPDATE_BULK_PO_MATERIAL,
          payload: res.data,
        });
        swal({
          title: "Bulk PO Updated!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

export const deleteBulkPoMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/bulk-po-materials/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_BULK_PO_MATERIAL,
        payload: id,
      });
      swal({
        title: "Bulk Purchase Order Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GENERATE PDF (EXPORT PDF)
export const generatePDF = (id) => (dispatch, getState) => {
  dispatch({ type: SET_LOADING_BULK_PO_PDF, payload: true });
  let endpoint = BASE_URL + `/bulk-po-materials/${id}/generate`;
  let config = tokenConfig(getState);
  config.method = "GET";
  config.responseType = "blob";

  axios(endpoint, config)
    .then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: "application/pdf" });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      dispatch({ type: SET_LOADING_BULK_PO_PDF, payload: false });
      //Open the URL on new Window
      window.open(fileURL);
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: SET_LOADING_BULK_PO_PDF, payload: false });
    });
};
