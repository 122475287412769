import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "../common";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import swal from "sweetalert";
import ImageUtils from "../../helpers/ImageUtils";
import { transformDate } from "../../helpers/useTransformData";

import { addScrapTool } from "../../actions/tools";

function ToolScrappingForm(props) {
  const { employeeId } = props;

  const dispatch = useDispatch();
  const selectedTool = useSelector((state) => state.tools.selectedTool);

  const [scrapDate, setScrapDate] = useState(null);
  const [scrappedTo, setScrappedTo] = useState("");
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const attachmentRef = useRef();

  const handleScrapDateChange = (date) => {
    setScrapDate(date);
  };

  const handleScrappedToChange = (e) => {
    setScrappedTo(e.target.value);
  };

  const handleAttachmentFileChange = (e) => {
    setSelectedAttachment(attachmentRef.current.files[0]);
  };

  const onSubmit = async () => {
    let attachmentFile = null;

    if (selectedAttachment) {
      attachmentFile = await ImageUtils.fileToBase64(selectedAttachment);
    }

    const body = {
      performed_by: employeeId,
      date: scrapDate ? transformDate(scrapDate) : null,
      to: scrappedTo,
      items: [
        {
          id: selectedTool.id,
          condition: selectedTool.condition,
        },
      ],
      tool: selectedTool,
      files: [attachmentFile],
    };

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `Issued tools should be unaffected.`,
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(addScrapTool(body));
      }
    });
  };

  return (
    <S.ToolScrappingForm>
      <div>
        <Typography type="h1">Scrapping Tool</Typography>
        <div className="flex w-full">
          <Typography type="p">Scrap Date</Typography>
        </div>
        <div className="flex flex-col">
          <DatePicker
            selected={scrapDate}
            dateFormat="yyyy-MM-dd"
            autoComplete="off"
            popperPlacement="top-end"
            onChange={handleScrapDateChange}
            value={scrapDate}
            wrapperClassName="w-full"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded py-2 px-3 text-gray-700 focus:ring-2 focus:ring-blue-600"
          />
          <div className="my-2">
            <Typography type="p">Scrapped To</Typography>
            <input
              type="text"
              className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              autoComplete="off"
              name="to"
              onChange={handleScrappedToChange}
              value={scrappedTo}
            />
          </div>
          <div className="my-2">
            <Typography type="p">Attachment (Optional)</Typography>
            <input
              ref={attachmentRef}
              type="file"
              className="text-xs"
              onChange={(e) => {
                handleAttachmentFileChange(e);
              }}
            />
          </div>
          <Button
            onClick={() => onSubmit()}
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold ml-2 mr-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/4"
            text="Scrap"
          />
        </div>
      </div>
    </S.ToolScrappingForm>
  );
}

const S = {};

S.ToolScrappingForm = styled.div`
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

export default ToolScrappingForm;
