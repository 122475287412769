import {
  GET_BULK_PO_MATERIALS,
  GET_BULK_PO_MATERIALS_DROPDOWN,
  LOADING_BULK_PO_MATERIALS,
  SELECT_BULK_PO_MATERIAL,
  ADD_BULK_PO_MATERIAL,
  UPDATE_BULK_PO_MATERIAL,
  DELETE_BULK_PO_MATERIAL,
  SET_LOADING_BULK_PO_PDF,
} from "../actions/types";

const initialState = {
  bulkPoMaterials: [],
  bulkPoMaterialsDropdown: [],
  pagination: null,
  selected: null,
  isLoading: false,
  isPDFLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BULK_PO_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOADING_BULK_PO_PDF:
      return {
        ...state,
        isPDFLoading: action.payload,
      };
    case GET_BULK_PO_MATERIALS:
      return {
        ...state,
        bulkPoMaterials: action.payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case GET_BULK_PO_MATERIALS_DROPDOWN:
      let result = [];
      if (action.payload) {
        const bulkPOs = action.payload;
        for (let i in bulkPOs) {
          result.push({
            value: bulkPOs[i].id,
            label: `${bulkPOs[i].task ? bulkPOs[i].task.code : ""} - ${
              bulkPOs[i].series_no
            }`,
            bulkPoMaterial: bulkPOs[i],
          });
        }
      }
      return {
        ...state,
        bulkPoMaterialsDropdown: result,
      };
    case SELECT_BULK_PO_MATERIAL:
      return {
        ...state,
        selected: action.payload,
      };
    case ADD_BULK_PO_MATERIAL:
      return {
        ...state,
        bulkPoMaterials: [...state.bulkPoMaterials, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case UPDATE_BULK_PO_MATERIAL:
      return {
        ...state,
        bulkPoMaterials: state.bulkPoMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    case DELETE_BULK_PO_MATERIAL:
      return {
        ...state,
        bulkPoMaterials: state.bulkPoMaterials.filter(
          (material) => material.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
