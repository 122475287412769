import { transformDate } from "../../helpers/useTransformData";

export const transformDataToAPI = (data) => {
  const {
    brand,
    category,
    condition,
    company,
    description,
    expiryDate,
    purchaseDate,
    serialNumber,
    invoice,
    catalogue,
    invoice_description,
    catalogue_description,
    project_id,
  } = data;

  return {
    company_id: company.value,
    tool_category_id: category.value,
    condition: condition.value,
    brand,
    description,
    serial_no: serialNumber,
    purchase_date: purchaseDate ? transformDate(purchaseDate) : null,
    warranty_expiry_date: expiryDate ? transformDate(expiryDate) : null,
    invoice,
    catalogue,
    invoice_description,
    catalogue_description,
    project_id,
  };
};
