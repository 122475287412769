import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fp from "lodash/fp";
import classNames from "classnames";
import { userListColumns } from "./UserTableColumns";
import {
  Button,
  Fieldset,
  Label,
  Pagination,
  Spinner,
  Typography,
  Modal,
  Table,
} from "../common";
import { ViewButton } from "../common/icons/HeroIcons";

import { getUsers, selectUser } from "../../actions/users";
import { getUserPosition } from "../../helpers/user";
import UserDetail from "./UserDetail";
import UserForm from "./UserForm";

export default function UserPage(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const usersLoading = useSelector((state) => state.users.usersLoading);
  const usersPagination = useSelector((state) => state.users.pagination);
  const selectedUser = useSelector((state) => state.users.selectedUser);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [viewModal, setViewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    dispatch(getUsers(userSearchTerm, pageNumber));
  }, [dispatch, userSearchTerm, pageNumber]);

  const sortedNamesAlphabetically = fp.sortBy(
    (o) =>
      o.employee.first_name.charAt(0).toUpperCase() +
      o.employee.first_name.slice(1)
  )(users);

  return (
    <div className="m-12">
      <Fieldset>
        <h1 className="text-sm font-extrabold text-gray-600 mb-4">
          Filter Users
        </h1>
        <div className="mb-4">
          <Label name="Employee Name" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={userSearchTerm}
            onChange={(e) => setUserSearchTerm(e.target.value)}
            type="text"
            placeholder="Search Employee Name..."
          />
        </div>
      </Fieldset>

      <Fieldset>
        <Typography type="h1">List of Users</Typography>

        <div className="flex justify-end">
          <Button
            text="+ add user"
            primary="true"
            onClick={() => {
              setAddModal(true);
            }}
          />
        </div>

        <Table
          columns={userListColumns}
          data={
            <>
              {!usersLoading &&
                sortedNamesAlphabetically.map((user) => (
                  <tr
                    key={user.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedUser ? selectedUser.id : 0) === user.id,
                    })}
                    onClick={() => dispatch(selectUser(user))}
                  >
                    <td className="px-6 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        {user.employee && (
                          <>
                            <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
                              {user.employee.avatar && (
                                <img
                                  alt="..."
                                  className="w-full rounded-full align-middle border-none shadow-lg"
                                  src={user.employee.avatar.image}
                                />
                              )}
                            </span>
                            <span className="text-xs text-gray-900 ml-2">
                              {`${
                                user.employee.first_name
                                  .charAt(0)
                                  .toUpperCase() +
                                user.employee.first_name.slice(1)
                              } ${
                                user.employee.last_name
                                  .charAt(0)
                                  .toUpperCase() +
                                user.employee.last_name.slice(1)
                              }`}
                            </span>
                          </>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                      {user.employee ? getUserPosition(user.employee) : ""}
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                      {user.username}
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex flex-row justify-between">
                        <div
                          className="flex flex-col text-gray-600 hover:text-gray-900"
                          onClick={() => {
                            dispatch(selectUser(user));
                            setViewModal(true);
                          }}
                        >
                          <ViewButton title={"Details"} />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </>
          }
        />

        {usersLoading && <Spinner />}

        {usersPagination && !usersLoading && (
          <Pagination
            from={usersPagination.from}
            to={usersPagination.to}
            total={usersPagination.total}
            lastPage={usersPagination.last_page}
            currentPage={usersPagination.current_page}
            onPageClick={(page) => {
              setPageNumber(page);
            }}
          />
        )}
      </Fieldset>

      {viewModal && (
        <Modal>
          <UserDetail
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}

      {addModal && (
        <Modal>
          <UserForm
            onViewClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
