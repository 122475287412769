export default function (employees) {
  let dropdown = [];
  for (let i in employees) {
    dropdown.push({
      value: employees[i].id,
      label: employees[i].first_name + " " + employees[i].last_name,
    });
  }
  return dropdown;
}
