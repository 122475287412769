import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Range from "react-range-progress";

import { Button, Label } from "../common";
import moment from "moment";

import { addTask } from "../../actions/tasks";
import ImageUtils from "../../helpers/ImageUtils";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function MyTasksAddSubTask(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const descriptionRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [status, setStatus] = useState({
    label: "In Progress",
    value: "In Progress",
  });

  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const selectedMyTask = useSelector((state) => state.tasks.selectedMyTask);
  const user = useSelector((state) => state.auth.user);

  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();
  const fileInputRef4 = useRef();
  const fileInputRef5 = useRef();
  const [selectedWorkingDrawing, setSelectedWorkingDrawing] = useState(null);
  const [selectedWorkingDrawing2, setSelectedWorkingDrawing2] = useState(null);
  const [selectedWorkingDrawing3, setSelectedWorkingDrawing3] = useState(null);
  const [selectedWorkingDrawing4, setSelectedWorkingDrawing4] = useState(null);
  const [selectedWorkingDrawing5, setSelectedWorkingDrawing5] = useState(null);

  const handleFileChange = (e) => {
    setSelectedWorkingDrawing(fileInputRef.current.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedWorkingDrawing2(fileInputRef2.current.files[0]);
  };

  const handleFileChange3 = (e) => {
    setSelectedWorkingDrawing3(fileInputRef3.current.files[0]);
  };

  const handleFileChange4 = (e) => {
    setSelectedWorkingDrawing4(fileInputRef4.current.files[0]);
  };

  const handleFileChange5 = (e) => {
    setSelectedWorkingDrawing5(fileInputRef5.current.files[0]);
  };

  useEffect(() => {
    register("start_date");
    register("end_date");
    register("parent_id");
    register("current_percentage");
    register("status");
    register("description");

    setValue(
      "parent_id",
      props.operation === "add-sub-task" ? selectedTask.id : null
    );
    setValue("current_percentage", 0);
    setValue("status", "In Progress");
  }, [register, selectedTask, props, setValue]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue("start_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue("end_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleStatusChange = (option) => {
    setStatus(option);
    setValue("status", option.value);
  };

  const handleProgressBarChange = (value) => {
    setCurrentPercentage(value);
    setValue("current_percentage", value);
  };

  const handleDescriptionChange = (content) => {
    setValue("description", content);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will and update overall progress of the project.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file,
          file2,
          file3,
          file4,
          file5 = null;
        if (selectedWorkingDrawing) {
          file = await ImageUtils.fileToBase64(selectedWorkingDrawing);
        }
        if (selectedWorkingDrawing2) {
          file2 = await ImageUtils.fileToBase64(selectedWorkingDrawing2);
        }
        if (selectedWorkingDrawing3) {
          file3 = await ImageUtils.fileToBase64(selectedWorkingDrawing3);
        }
        if (selectedWorkingDrawing4) {
          file4 = await ImageUtils.fileToBase64(selectedWorkingDrawing4);
        }
        if (selectedWorkingDrawing5) {
          file5 = await ImageUtils.fileToBase64(selectedWorkingDrawing5);
        }

        if (file) {
          data.base64image = file;
        }
        if (file2) {
          data.base64image2 = file2;
        }
        if (file3) {
          data.base64image3 = file3;
        }
        if (file4) {
          data.base64image4 = file4;
        }
        if (file5) {
          data.base64image5 = file5;
        }

        dispatch(
          addTask(
            selectedMyTask.type,
            props.operation === "add-sub-task"
              ? selectedTask.project_id
              : selectedTask.id,
            data
          )
        );
        props.onTaskSubmit && props.onTaskSubmit();
        e.target.reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">
        {selectedTask.heirarchies.length === 2 ? "Add New Area" : ""}
        {selectedTask.heirarchies.length === 3 ? "Add New Location" : ""}
        {selectedTask.heirarchies.length >= 4
          ? `Add New Sub Task ${props.clicked}`
          : "Add New Area"}
      </h1>

      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Title" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            name="title"
            type="text"
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Code" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            name="code"
            type="text"
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Status" />
          <Select
            options={[
              { value: "In Progress", label: "In Progress" },
              { value: "Completed", label: "Completed" },
              { value: "On Hold", label: "On Hold" },
            ]}
            value={status}
            onChange={handleStatusChange}
            className="text-xs"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Location" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            name="location"
            type="text"
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name={`Current Progress - ${currentPercentage}%`} />
          <Range
            value={currentPercentage}
            fillColor={{
              r: 20,
              g: 150,
              b: 100,
              a: 0.75,
            }}
            trackColor={{
              r: 10,
              g: 10,
              b: 0,
              a: 0.5,
            }}
            height={14}
            width="100%"
            onChange={handleProgressBarChange}
          />
        </div>
      </div>

      {/* <div className="h-64 box border rounded flex flex-col shadow bg-white mb-4">
        <div className="bg-gray-400 px-3 py-2 border-b">
          <h3 className="text-sm text-gray-900 font-medium">New Updates</h3>
        </div>
        <textarea
          className="text-xs text-gray-900 flex-1 p-2 m-1 bg-transparent"
          ref={register}
          name="new_updates"
        />
      </div> */}

      <div className="h-64 box border rounded flex flex-col shadow bg-white mb-4">
        <div className="bg-gray-400 px-3 py-2 border-b">
          <h3 className="text-sm text-gray-900 font-medium">Description</h3>
        </div>
        <SunEditor
          height="100"
          name="description"
          onChange={handleDescriptionChange}
          ref={descriptionRef}
        />
      </div>

      <div className="flex mb-4">
        <div className="w-1/3">
          <div className="flex">
            <div>
              <Label name="Start Date" />
              <DatePicker
                showYearDropdown
                selected={startDate}
                dateFormat="yyyy-MM-dd"
                onChange={handleStartDateChange}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
                style={{ zIndex: 10 }}
              />
            </div>
            <div className="ml-4">
              <Label name="End Date" />
              <DatePicker
                showYearDropdown
                selected={endDate}
                dateFormat="yyyy-MM-dd"
                onChange={handleEndDateChange}
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
              />
            </div>
          </div>
        </div>
        <div className="w-1/3 ml-4">
          <Label name="Created by" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            key={`${user.employee ? user.employee.first_name : ""} ${
              user.employee ? user.employee.last_name : ""
            }`}
            defaultValue={`${user.employee ? user.employee.first_name : ""} ${
              user.employee ? user.employee.last_name : ""
            }`}
            name="created_by"
            type="text"
            readOnly={true}
          />
        </div>
        <div className="w-1/3 ml-4">
          <Label name="Percentage Distribution (0 if 1:1)" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            defaultValue={0}
            name="set_percentage"
            type="number"
            step="any"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="flex">
          <div>
            <Label name="Upload Working Drawing 1" />
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </div>
          <div>
            <Label name="Upload Working Drawing 2" />
            <input
              ref={fileInputRef2}
              type="file"
              onChange={(e) => {
                handleFileChange2(e);
              }}
            />
          </div>
          <div>
            <Label name="Upload Working Drawing 3" />
            <input
              ref={fileInputRef3}
              type="file"
              onChange={(e) => {
                handleFileChange3(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div>
          <Label name="Upload Working Drawing 4" />
          <input
            ref={fileInputRef4}
            type="file"
            onChange={(e) => {
              handleFileChange4(e);
            }}
          />
        </div>
        <div>
          <Label name="Upload Working Drawing 5" />
          <input
            ref={fileInputRef5}
            type="file"
            onChange={(e) => {
              handleFileChange5(e);
            }}
          />
        </div>
      </div>

      <Button text="Save" success="true" type="submit" />
    </form>
  );
}
