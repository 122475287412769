import {
  ADD_PROJECT,
  UPDATE_PROJECT,
  GET_PROJECTS,
  GET_PROJECT_DETAILS,
  SELECT_PROJECT,
  SELECT_PROJECT_TASK,
  LOADING_PROJECTS,
  GET_UNASSIGNED_PIC,
  GET_UNASSIGNED_PM,
  COMPUTE_PROJECT_PERCENTAGE,
} from "../actions/types";

const initialState = {
  isLoading: false,
  projects: [],
  projectTasks: null,
  selectedProjectPercentage: 0,
  selectedProject: null,
  selectedProjectTask: null,
  selectedProjectDropdown: {},
  selectedProjectAssigned: {},
  unassignedPM: [],
  unassignedPIC: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PROJECTS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        isLoading: false,
      };
    case ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
        isLoading: false,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        selectedProject: {
          ...action.payload,
        },
        isLoading: false,
      };
    case GET_PROJECT_DETAILS:
      return {
        ...state,
        projectTasks: action.payload,
        // selectedProjectPercentage: action.payload.calculated_percentage,
        // selectedProjectAssigned: {
        //   pm: action.payload.employee_assignee,
        //   engineers: action.payload.employee_engineers,
        //   pic: action.payload.employee_pic,
        // },
        isLoading: false,
      };
    case COMPUTE_PROJECT_PERCENTAGE:
      return {
        ...state,
        selectedProjectPercentage: action.payload.calculated_percentage,
      };
    case SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
        selectedProjectPercentage: 0,
      };
    case SELECT_PROJECT_TASK:
      return {
        ...state,
        selectedProjectTask: action.payload,
      };
    case GET_UNASSIGNED_PM:
      return {
        ...state,
        unassignedPM: action.payload,
      };
    case GET_UNASSIGNED_PIC:
      return {
        ...state,
        unassignedPIC: action.payload,
      };
    default:
      return state;
  }
}
