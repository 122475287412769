import React from "react";
import { useSelector } from "react-redux";
import { Button, Label, Table } from "components/common";
import moment from "moment";
import { toolDeliveryRequestedToolsColumns } from "./ToolTableColumns";
import { SRLWrapper } from "simple-react-lightbox";

export default function ToolDeliveryDetail(props) {
  const selectedToolDelivery = useSelector(
    (state) => state.tools.selectedToolDelivery
  );

  const tools = selectedToolDelivery.tools ? selectedToolDelivery.tools : [];

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Tool Delivery Details
      </h1>

      <div
        className="grid grid-cols-2 gap-4"
        style={{
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 30,
        }}
      >
        <div>
          <Label name="For delivery to" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.recipient
              ? `${selectedToolDelivery.recipient.first_name} ${selectedToolDelivery.recipient.last_name}`
              : "-- No Recipient --"}
          </p>
        </div>

        <div>
          <Label name="Project" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.project
              ? `${selectedToolDelivery.project.title}`
              : "-- Unassigned --"}
          </p>
        </div>

        <div>
          <Label name="Issued by" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.performed_by
              ? `${selectedToolDelivery.performed_by.first_name} ${selectedToolDelivery.performed_by.last_name}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Date Issued" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.created_at
              ? `${moment(selectedToolDelivery.created_at)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Assigned Driver" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.driver
              ? `${selectedToolDelivery.driver.first_name} ${selectedToolDelivery.driver.last_name}`
              : "-- On the Spot --"}
          </p>
        </div>

        <div>
          <Label name="Delivery Date" />
          <p className="text-sm mb-4">
            {selectedToolDelivery.date
              ? `${moment(selectedToolDelivery.date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Comments" />
          <p className="text-sm mb-4">{selectedToolDelivery.comments}</p>
        </div>

        <div>
          <Label name="Attachments" />
          {selectedToolDelivery.reference &&
            selectedToolDelivery.reference.files && (
              <SRLWrapper>
                <div className="flex">
                  {selectedToolDelivery.reference.files.map(
                    (attachment, index) => (
                      <img
                        alt={`attachment-${index}`}
                        className="border-none p-4"
                        src={attachment.full_path}
                        style={{
                          width: "256px",
                          height: "256px",
                        }}
                      />
                    )
                  )}
                </div>
              </SRLWrapper>
            )}
        </div>
      </div>

      <Table
        columns={toolDeliveryRequestedToolsColumns}
        data={
          <>
            {tools.length > 0 ? (
              tools.map((toolDelivery, index) => (
                <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolDelivery.tool.brand}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolDelivery.tool.description}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolDelivery.tool.serial_no}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolDelivery.tool.purchase_date}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolDelivery.tool.warranty_expiry_date}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    {toolDelivery.tool.condition ? (
                      <span
                        className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 justify-center py-1 w-full"
                        style={{
                          background:
                            toolDelivery.tool.condition === "Poor"
                              ? "#ffb52c"
                              : "#57bf57",
                          color: "#fff",
                        }}
                      >
                        {toolDelivery.tool.condition}
                      </span>
                    ) : (
                      "---"
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div className="align-self-center">
                <p className="text-xs text-gray-600 text-center mt-4">
                  <i>No tools specified.</i>
                </p>
              </div>
            )}
          </>
        }
      />

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
