import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Button, Label } from "../common";

import {
  addEquipmentCategory,
  updateEquipmentCategory,
  deleteEquipmentCategory,
  selectEquipmentCategory,
} from "../../actions/equipments";
import swal from "sweetalert";

export default function EquipmentCategoryForm(props) {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const selectedEquipmentCategory = useSelector(
    (state) => state.equipments.selectedEquipmentCategory
  );
  const onSubmit = (data, e) => {
    if (selectedEquipmentCategory) {
      dispatch(updateEquipmentCategory(selectedEquipmentCategory.id, data));
    } else {
      dispatch(addEquipmentCategory(data));
      e.target.reset();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedEquipmentCategory
          ? `Equipment Category - ${selectedEquipmentCategory.name}`
          : `Add Equipment Category`}
      </h1>

      <Label name="Name" />
      <input
        className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        autoComplete="off"
        ref={register}
        key={
          selectedEquipmentCategory ? selectedEquipmentCategory.name : "name"
        }
        defaultValue={
          selectedEquipmentCategory ? selectedEquipmentCategory.name : ""
        }
        name="name"
        type="text"
      />

      {selectedEquipmentCategory ? (
        <div className="flex">
          <Button text="Update" success="true" type="submit" />
          <Button
            onClick={() => {
              swal({
                icon: "warning",
                title: "Are you sure?",
                text: `Once deleted, it will label equipments assigned as "Uncategorized"`,
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  dispatch(
                    deleteEquipmentCategory(selectedEquipmentCategory.id)
                  );
                  selectEquipmentCategory(null);
                  reset();
                }
              });
            }}
            danger
            text="Delete"
          />
        </div>
      ) : (
        <Button text="Save" success="true" type="submit" />
      )}
    </form>
  );
}
