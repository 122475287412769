import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Table, EmptyPlaceholder } from "components/common";
import { toolMovementColumns } from "./ToolTableColumns";

import { getToolMovements } from "../../actions/tools";

function ToolMovementView(props) {
  const dispatch = useDispatch();
  const selectedTool = useSelector((state) => state.tools.selectedTool);
  const toolMovements = useSelector((state) => state.tools.toolMovements);

  useEffect(() => {
    dispatch(getToolMovements(selectedTool.id));
  }, [dispatch, selectedTool]);

  return (
    <div className="w-full">
      <Typography type="h1">View Movement</Typography>
      <Table
        columns={toolMovementColumns}
        data={
          <>
            {toolMovements.map((toolMovement, index) => (
              <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                <td className="px-6 py-3 whitespace-nowrap">
                  <div className="text-xs text-gray-900">{index + 1}</div>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <div className="text-xs text-gray-900">
                    {toolMovement.movement.date}
                  </div>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <div className="text-xs text-gray-900">
                    {`${toolMovement.movement.event
                      .charAt(0)
                      .toUpperCase()}${toolMovement.movement.event.slice(1)}`}
                  </div>
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  <div className="text-xs text-gray-900">
                    {toolMovement.condition}
                  </div>
                </td>
              </tr>
            ))}
          </>
        }
      />

      {toolMovements < 1 && <EmptyPlaceholder text="No movements found" />}
      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}

export default ToolMovementView;
