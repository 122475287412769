import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToolValidationSchema } from "./ToolFormValidationSchema";
import { useYupValidationResolver } from "../../helpers/useYupValidationResolver";
import ImageUtils from "../../helpers/ImageUtils";

import { Button, Label, CustomDatePicker, Attachment } from "../common";

import {
  addTool,
  updateTool,
  selectTool,
  deleteTool,
  getToolFilters,
} from "../../actions/tools";
import { searchProjects } from "../../actions/projects";
import swal from "sweetalert";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
  useTransformOption,
  transformDate,
} from "../../helpers/useTransformData";
import { initialValueTool, toolConditionOptions } from "../../constants/tools";
import { transformDataToAPI } from "./ToolTransformData";

export default function ToolForm({ isReset, setIsReset, isEdit, onViewClose }) {
  const dispatch = useDispatch();

  // options
  const companies = useSelector((state) => state.tools.filters.companies);
  const categories = useSelector((state) => state.tools.filters.categories);

  const companiesOption = useTransformOption(companies);
  const categoriesOption = useTransformOption(categories);

  // state
  const selectedTool = useSelector((state) => state.tools.selectedTool);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [brandValue, setBrandValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState(null);
  const [serialNumberValue, setSerialNumberValue] = useState(null);
  const [purchaseDateValue, setPurchaseDateValue] = useState(null);
  const [expiryDateValue, setExpiryDateValue] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceDescription, setInvoiceDescription] = useState(null);
  const [selectedCatalogue, setSelectedCatalogue] = useState(null);
  const [catalogueDescription, setCatalogueDescription] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const invoiceRef = useRef();
  const catalogueRef = useRef();

  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValueTool,
    resolver: useYupValidationResolver(ToolValidationSchema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setSubmitting(true);
    let invoiceFile = null;
    let catalogueFile = null;

    if (selectedInvoice) {
      invoiceFile = await ImageUtils.fileToBase64(selectedInvoice);
    }
    if (selectedCatalogue) {
      catalogueFile = await ImageUtils.fileToBase64(selectedCatalogue);
    }
    data.invoice = invoiceFile;
    data.catalogue = catalogueFile;
    data.project_id = selectedTool ? selectedTool.project_id : null;

    if (!isEdit && selectedTool && selectedProject) {
      data.project_id = selectedProject.value;
    }

    let body = transformDataToAPI(data);

    if (selectedTool) {
      dispatch(updateTool(selectedTool.id, body));
    } else {
      dispatch(addTool(body));
    }

    resetFunc();
    setSubmitting(false);
  };

  const handleProjectChange = (option) => {
    setSelectedProject(option);
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setValue("company", option, { shouldValidate: true });
  };

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setValue("category", option, { shouldValidate: true });
  };

  const handleConditionChange = (option) => {
    setSelectedCondition(option);
    setValue("condition", option, { shouldValidate: true });
  };

  const handleBrandChange = (e) => {
    setBrandValue(e.target.value);
    setValue("brand", e.target.value, { shouldValidate: true });
  };

  const handleDescriptionChange = (e) => {
    setDescriptionValue(e.target.value);
    setValue("description", e.target.value, { shouldValidate: true });
  };

  const handleSerialNumberChange = (e) => {
    setSerialNumberValue(e.target.value);
    setValue("serialNumber", e.target.value, { shouldValidate: true });
  };

  const handlePurchaseDateChange = (date) => {
    setPurchaseDateValue(date);
    setValue("purchaseDate", transformDate(date), { shouldValidate: true });
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDateValue(date);
    setValue("expiryDate", transformDate(date), { shouldValidate: true });
  };

  const handleCatalogueFileChange = (e) => {
    setSelectedCatalogue(catalogueRef.current.files[0]);
  };

  const handleCatalogueDescriptionChange = (e) => {
    setCatalogueDescription(e.target.value);
    setValue("catalogue_description", e.target.value, { shouldValidate: true });
  };

  const handleInvoiceFileChange = (e) => {
    setSelectedInvoice(invoiceRef.current.files[0]);
  };

  const handleInvoiceDescriptionChange = (e) => {
    setInvoiceDescription(e.target.value);
    setValue("invoice_description", e.target.value, { shouldValidate: true });
  };

  const loadProjectOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchProjects(inputValue)).then((res) => {
        console.log(res);
        resolve(res);
      });
    });

    let projectOptions = [];

    for (let i in search) {
      projectOptions.push({
        value: search[i].id,
        label: search[i].title,
      });
    }

    callback(projectOptions);
  };

  const resetFunc = async () => {
    await reset();
    setIsReset(false);
    setSelectedCompany(null);
    setSelectedCategory(null);
    setSelectedCondition(null);
    setBrandValue("");
    setDescriptionValue("");
    setSerialNumberValue("");
    setPurchaseDateValue(null);
    setExpiryDateValue(null);
  };

  useEffect(() => {
    dispatch(getToolFilters());

    if (isReset) {
      resetFunc();
    }

    setSelectedCompany(
      selectedTool && selectedTool.company
        ? { value: selectedTool.company.id, label: selectedTool.company.name }
        : null
    );

    selectedTool && selectedTool.company
      ? setValue(
          "company",
          { value: selectedTool.company.id, label: selectedTool.company.name },
          { shouldValidate: true }
        )
      : setValue("company", null);

    setSelectedCategory(
      selectedTool && selectedTool.category
        ? {
            value: selectedTool.category.id,
            label: selectedTool.category.name,
          }
        : null
    );

    selectedTool && selectedTool.category
      ? setValue(
          "category",
          {
            value: selectedTool.category.id,
            label: selectedTool.category.name,
          },
          { shouldValidate: true }
        )
      : setValue("category", null);

    setSelectedCondition(
      selectedTool && selectedTool.condition
        ? {
            value: selectedTool.condition,
            label: selectedTool.condition,
          }
        : null
    );

    selectedTool && selectedTool.condition
      ? setValue(
          "condition",
          {
            value: selectedTool.condition,
            label: selectedTool.condition,
          },
          { shouldValidate: true }
        )
      : setValue("condition", null);

    setBrandValue(
      selectedTool && selectedTool.brand ? selectedTool.brand : null
    );

    selectedTool && selectedTool.brand
      ? setValue("brand", selectedTool.brand, { shouldValidate: true })
      : setValue("brand", null);

    setDescriptionValue(
      selectedTool && selectedTool.description ? selectedTool.description : null
    );

    selectedTool && selectedTool.description
      ? setValue("description", selectedTool.description, {
          shouldValidate: true,
        })
      : setValue("description", null);

    setSerialNumberValue(
      selectedTool && selectedTool.serial_no ? selectedTool.serial_no : null
    );

    selectedTool && selectedTool.serial_no
      ? setValue("serialNumber", selectedTool.serial_no, {
          shouldValidate: true,
        })
      : setValue("serialNumber", null);

    setPurchaseDateValue(
      selectedTool && selectedTool.purchase_date
        ? new Date(selectedTool.purchase_date)
        : null
    );

    selectedTool && selectedTool.purchase_date
      ? setValue(
          "purchaseDate",
          transformDate(new Date(selectedTool.purchase_date)),
          { shouldValidate: true }
        )
      : setValue("purchaseDate", null);

    setExpiryDateValue(
      selectedTool && selectedTool.warranty_expiry_date
        ? new Date(selectedTool.warranty_expiry_date)
        : null
    );

    selectedTool && selectedTool.warranty_expiry_date
      ? setValue(
          "expiryDate",
          transformDate(new Date(selectedTool.warranty_expiry_date)),
          { shouldValidate: true }
        )
      : setValue("expiryDate", null);

    setCatalogueDescription(
      selectedTool && selectedTool.catalogue
        ? selectedTool.catalogue.description
        : null
    );

    selectedTool && selectedTool.catalogue
      ? setValue("catalogue_description", selectedTool.catalogue.description, {
          shouldValidate: true,
        })
      : setValue("catalogue_description", null);

    setInvoiceDescription(
      selectedTool && selectedTool.invoice
        ? selectedTool.invoice.description
        : null
    );

    selectedTool && selectedTool.invoice_description
      ? setValue("invoice_description", selectedTool.invoice_description, {
          shouldValidate: true,
        })
      : setValue("invoice_description", null);

    setSelectedProject(
      selectedTool && selectedTool.project
        ? { value: selectedTool.project.id, label: selectedTool.project.title }
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool, register, dispatch, setValue, isReset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedTool
          ? `Tool - ${selectedTool.brand} ${selectedTool.description}`
          : `Add Tool`}
      </h1>

      <div className="mb-2">
        <Label name="Company" />
        <Controller
          control={control}
          name="company"
          render={(props) => (
            <Select
              {...props}
              className="text-xs shadow-sm"
              options={companiesOption}
              onChange={handleCompanyChange}
              value={selectedCompany}
              isDisabled={isReset || isEdit}
            />
          )}
        />
        {errors?.company && (
          <span className="text-xs text-red-600">
            {errors?.company?.message}
          </span>
        )}
      </div>
      <div className="mb-2">
        {!isEdit && selectedTool && (
          <>
            <Label name="Project" />
            <AsyncSelect
              loadOptions={loadProjectOptions}
              value={selectedProject}
              onChange={handleProjectChange}
              className="text-xs shadow-sm mb-2"
              placeholder="Type to search for a project."
            />
          </>
        )}
        <Label name="Category" />
        <Controller
          control={control}
          name="category"
          render={(props) => (
            <Select
              {...props}
              className="text-xs shadow-sm"
              options={categoriesOption}
              onChange={handleCategoryChange}
              value={selectedCategory}
              isDisabled={isReset || isEdit}
            />
          )}
        />
        {errors?.category && (
          <span className="text-xs text-red-600">
            {errors?.category?.message}
          </span>
        )}
      </div>
      <div className="mb-2">
        <Label name="Condition" />
        <Controller
          control={control}
          name="condition"
          render={(props) => (
            <Select
              {...props}
              className="text-xs shadow-sm"
              options={toolConditionOptions}
              onChange={handleConditionChange}
              value={selectedCondition}
              isDisabled={isReset || isEdit}
            />
          )}
        />
        {errors?.condition && (
          <span className="text-xs text-red-600">
            {errors?.condition?.message}
          </span>
        )}
      </div>
      <div className="mb-2">
        <Label name="Brand" />
        <Controller
          control={control}
          name="brand"
          render={(props) => (
            <input
              {...props}
              key={selectedTool ? selectedTool.brand : "brand"}
              type="text"
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              autoComplete="off"
              onChange={handleBrandChange}
              value={brandValue}
              disabled={isReset || isEdit}
            />
          )}
        />
        {errors?.brand && (
          <span className="text-xs text-red-600">{errors?.brand?.message}</span>
        )}
      </div>
      <div className="mb-2">
        <Label name="Description" />
        <Controller
          control={control}
          name="description"
          render={(props) => (
            <input
              {...props}
              key={selectedTool ? selectedTool.description : "description"}
              type="text"
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              autoComplete="off"
              onChange={handleDescriptionChange}
              value={descriptionValue}
              disabled={isReset || isEdit}
            />
          )}
        />
        {errors?.description && (
          <span className="text-xs text-red-600">
            {errors?.description?.message}
          </span>
        )}
      </div>
      <div className="mb-2">
        <Label name="S/N" />
        <Controller
          control={control}
          name="serialNumber"
          render={(props) => (
            <input
              {...props}
              key={selectedTool ? selectedTool.serialNumber : "serialNumber"}
              type="text"
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              autoComplete="off"
              onChange={handleSerialNumberChange}
              value={serialNumberValue}
              disabled={isReset || isEdit}
            />
          )}
        />
        {errors?.serialNumber && (
          <span className="text-xs text-red-600">
            {errors?.serialNumber?.message}
          </span>
        )}
      </div>
      <div className="flex flex-col mb-2">
        <Label name="Purchase Date" />
        <Controller
          control={control}
          name="purchaseDate"
          render={(props) => (
            <CustomDatePicker
              {...props}
              onChange={handlePurchaseDateChange}
              selected={purchaseDateValue}
              value={purchaseDateValue}
              disabled={isReset || isEdit}
            />
          )}
        />
        {errors?.purchaseDate && (
          <span className="text-xs text-red-600 mt-1">
            {errors?.purchaseDate?.message}
          </span>
        )}
      </div>
      <div className="flex flex-col mb-2">
        <Label name="Expiry Date" />
        <Controller
          control={control}
          name="expiryDate"
          render={(props) => (
            <CustomDatePicker
              {...props}
              onChange={handleExpiryDateChange}
              selected={expiryDateValue}
              value={purchaseDateValue}
              disabled={isEdit || isReset ? true : false}
            />
          )}
        />
        {errors?.expiryDate && (
          <span className="text-xs text-red-600 mt-1">
            {errors?.expiryDate?.message}
          </span>
        )}
      </div>
      <div className="flex flex-col mb-2">
        <Label name="Catalogue" />
        {selectedTool && selectedTool.catalogue && (
          <div className="mb-4">
            <Attachment path={selectedTool.catalogue.full_path} />
          </div>
        )}
        <input
          ref={catalogueRef}
          type="file"
          disabled={isReset || isEdit}
          className="text-xs"
          onChange={(e) => {
            handleCatalogueFileChange(e);
          }}
        />
      </div>
      <div className="mb-2">
        <Label name="Description (Catalogue)" />
        <Controller
          control={control}
          name="catalogue_description"
          render={(props) => (
            <input
              {...props}
              key={
                selectedTool
                  ? selectedTool.catalogue_description
                  : "catalogue_description"
              }
              type="text"
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              autoComplete="off"
              onChange={handleCatalogueDescriptionChange}
              value={catalogueDescription}
              disabled={isReset || isEdit}
            />
          )}
        />
      </div>
      <div className="flex flex-col mb-2">
        <Label name="Invoice" />
        {selectedTool && selectedTool.invoice && (
          <div className="mb-4">
            <Attachment path={selectedTool.invoice.full_path} />
          </div>
        )}
        <input
          ref={invoiceRef}
          type="file"
          disabled={isReset || isEdit}
          className="text-xs"
          onChange={(e) => {
            handleInvoiceFileChange(e);
          }}
        />
      </div>
      <div className="mb-2">
        <Label name="Description (Invoice)" />
        <Controller
          control={control}
          name="invoice_description"
          render={(props) => (
            <input
              {...props}
              key={
                selectedTool
                  ? selectedTool.invoice_description
                  : "invoice_description"
              }
              type="text"
              className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
              style={{ border: "1px solid #ccc" }}
              onChange={handleInvoiceDescriptionChange}
              value={invoiceDescription}
              disabled={isReset || isEdit}
            />
          )}
        />
      </div>

      {selectedTool ? (
        <>
          {!isEdit && (
            <div className="flex">
              <Button type="submit" success="true" text="Update" />
              <Button
                onClick={() => {
                  swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: `Issued tools should be unaffected.`,
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      dispatch(deleteTool(selectedTool.id));
                      selectTool(null);
                      reset();
                    }
                  });
                }}
                warning="true"
                text="Delete"
              />
              <Button
                type="primary"
                primary="true"
                text="Close"
                onClick={() => {
                  onViewClose && onViewClose();
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="flex">
          <Button
            type="submit"
            success="true"
            text="Submit"
            disabled={submitting}
          />

          <Button
            type="primary"
            primary="true"
            text="Close"
            onClick={() => {
              onViewClose && onViewClose();
            }}
          />
        </div>
      )}
    </form>
  );
}
