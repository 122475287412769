import React from "react";

const ProfileCard = (props) => (
  <div className="my-2 pb-6 w-full justify-center items-center overflow-hidden md:max-w-sm rounded-lg shadow-lg mx-auto">
    <div className="mt-4 relative shadow mx-auto h-24 w-24 border-white rounded-full overflow-hidden border-4">
      <img
        className="object-cover w-full h-full"
        src={props.image}
        alt={"Avatar"}
      />
    </div>
    <div className="mt-2">
      <h1 className="text-lg text-center font-semibold">{props.headerText}</h1>
      <p className="text-sm text-gray-600 text-center">{props.subHeaderText}</p>
    </div>
    {props.status && (
      <div className="mt-2 text-center">
        <button
          onClick={props.onClickStatus}
          className={
            props.status === "Active"
              ? "p-1 pr-2 pl-2 bg-green-500 text-sm text-white rounded-md font-medium"
              : "p-1 pr-2 pl-2 bg-red-500 text-sm text-white rounded-md font-medium"
          }
        >
          {props.status}
        </button>
      </div>
    )}
  </div>
);

export default ProfileCard;
