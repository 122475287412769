import React from "react";

function Fieldset({ children }) {
  return (
    <div className="bg-white shadow-md rounded px-8 pt-8 pb-8 mb-4">
      {children}
    </div>
  );
}

export default Fieldset;
