import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

function CustomDatePicker(props) {
  const { selected, onChange } = props;

  return (
    <S.CustomDatePicker style={{ width: "100%" }}>
      <DatePicker
        {...props}
        className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
        dateFormat="yyyy-MM-dd"
        autoComplete="off"
        popperPlacement="top-start"
        showMonthDropdown={true}
        showYearDropdown={true}
        onChange={onChange}
        selected={selected}
      />
    </S.CustomDatePicker>
  );
}

const S = {};

S.CustomDatePicker = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-read-view {
    border: 1px solid #cdcdcd;
    padding: 0.25rem 0.5rem;
    background: white;
    text-align: start;
  }

  .react-datepicker__year-read-view {
    border: 1px solid #cdcdcd;
    padding: 0.25rem 0.5rem;
    background: white;
    text-align: start;
  }

  input {
    width: 100%;
    border: 1px solid #ccc;
  }
`;

export default CustomDatePicker;
