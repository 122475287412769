import React from "react";
import classNames from "classnames";

function Pagination(props) {
  let pages = [];

  // for (let page = 1; page <= props.lastPage; page++) {
  //   pages.push(page);
  // }

  const createPaginationItem = (i) => {
    return (
      <li key={i}>
        <button
          type="button"
          className={classNames("block px-3 py-2 text-sm", {
            "hover:text-white hover:bg-blue-900 text-blue-900 border-r border-grey-400":
              props.currentPage !== i,
            "cursor-not-allowed text-white bg-blue-900 border-r border-blue-400":
              props.currentPage === i,
          })}
          onClick={() => {
            props.onPageClick && props.onPageClick(i);
          }}
        >
          {i}
        </button>
      </li>
    );
  };

  const createEllipsis = () => {
    return (
      <li>
        <button
          type="button"
          className="block px-3 py-2 text-sm cursor-not-allowed border-r border-gray-400"
          disabled
        >
          ...
        </button>
      </li>
    );
  };

  //for pages less than 5
  if (props.lastPage <= 5) {
    for (let i = 1; i <= props.lastPage; i++) {
      pages.push(createPaginationItem(i));
    }
  } else {
    //Create pages divisible by 5
    let firstMiddlePage =
      props.lastPage - props.currentPage < 5
        ? props.currentPage - 4
        : props.currentPage;
    let lastMiddlePage =
      props.lastPage - props.currentPage < 5
        ? props.lastPage - 1
        : firstMiddlePage + 4;

    //Create initial page number
    pages.push(createPaginationItem(1));
    pages.push(createEllipsis());
    //Create page numbers in the middle (10-14)
    for (let i = firstMiddlePage; i <= lastMiddlePage; i++) {
      if (i > 1) {
        pages.push(createPaginationItem(i));
      }
    }
    if (props.lastPage > 5) {
      //Add the second ellipsis
      pages.push(createEllipsis());
      //Add the last item
      pages.push(createPaginationItem(props.lastPage));
    }
  }

  return (
    <div className="m-2 flex p-2">
      {props.from && props.to && props.total && (
        <p className="mt-2 text-sm">
          Showing {props.from} to {props.to} of {props.total} entries
        </p>
      )}

      <ul className="ml-4 flex flex-wrap list-reset content-center border border-grey-400 rounded w-auto">
        <li>
          <button
            type="button"
            className={classNames(
              "block border-r border-grey-400 px-3 py-2 text-sm",
              {
                "hover:text-white hover:bg-blue-900 text-blue-900":
                  props.currentPage !== 1,
                "cursor-not-allowed text-gray-600": props.currentPage === 1,
              }
            )}
            disabled={props.currentPage === 1}
            onClick={() => {
              props.onPageClick && props.onPageClick(props.currentPage - 1);
            }}
          >
            Previous
          </button>
        </li>
        {/* {pages.map((page) => ({ page }))} */}
        {pages}
        <li>
          <button
            type="button"
            className={classNames(
              "block border-r border-grey-400 px-3 py-2 text-sm",
              {
                "hover:text-white hover:bg-blue-900 text-blue-900":
                  props.currentPage !== props.lastPage,
                "cursor-not-allowed text-gray-600":
                  props.currentPage === props.lastPage,
              }
            )}
            disabled={props.currentPage === props.lastPage}
            onClick={() => {
              props.onPageClick && props.onPageClick(props.currentPage + 1);
            }}
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Pagination;
