import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import ImageUploader from "react-images-upload";
import swal from "sweetalert";
import { updateDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";
import { SRLWrapper } from "simple-react-lightbox";

export default function EditSiteSurvey(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const [date, setDate] = useState(
    selectedDocument.date ? new Date(selectedDocument.date) : new Date()
  );
  const [photos, setPhotos] = useState([]);
  const imageUploadRef = useRef(null);

  useEffect(() => {
    register("date");
    setValue(
      "date",
      moment(selectedDocument.date).format("YYYY-MM-DD").toString()
    );
  }, [register, setValue, selectedDocument]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const onDrop = (photo) => {
    setPhotos(photo);
  };

  const onUpdate = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update Site Survey.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        if (photos.length > 0) {
          let tempImg = [];
          for (let i = 0; i < photos.length; i++) {
            tempImg.push(await ImageUtils.resizeImage(photos[i]));
          }
          if (tempImg.length > 0) {
            data.base64image = tempImg;
          }
        }

        dispatch(updateDocumentation(selectedDocument.id, data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Subject" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subject"
              key={selectedDocument.subject}
              defaultValue={selectedDocument.subject}
              type="text"
              required
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Description" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="description"
              key={selectedDocument.description}
              defaultValue={selectedDocument.description}
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
              key={selectedDocument.comments}
              defaultValue={selectedDocument.comments}
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="Photos" />
          {selectedDocument && selectedDocument.files && (
            <SRLWrapper>
              <div className="flex">
                {selectedDocument.files.map((attachment, index) => (
                  <img
                    alt={`attachment-${index}`}
                    className="border-none p-4"
                    src={attachment.full_path}
                    style={{
                      width: "256px",
                      height: "256px",
                    }}
                  />
                ))}
              </div>
            </SRLWrapper>
          )}
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>
      <Button text="Save" success="true" type="submit" />
      <Button
        text="Close"
        primary="true"
        // disabled={isFormLoading}
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
