import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import fp from "lodash/fp";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { employeeListColumns } from "./EmployeeTableColumns";
import useDebounce from "helpers/useDebounce";

import {
  Button,
  Fieldset,
  Label,
  Pagination,
  Spinner,
  Table,
  Typography,
  Modal,
} from "../common";
import { ViewButton } from "../common/icons/HeroIcons";

import { getEmployees, selectEmployee } from "../../actions/employees";

import EmployeeDetail from "./EmployeeDetail";
import EmployeeForm from "./EmployeeForm";

export default function EmployeePage(props) {
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees.employees);
  const companies = useSelector((state) => state.companies.companies);
  const roles = useSelector((state) => state.roles.roles);
  const employeesLoading = useSelector(
    (state) => state.employees.employeesLoading
  );
  const employeesPagination = useSelector(
    (state) => state.employees.pagination
  );
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee
  );

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("Active");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const filtered = employees.filter(
    (emp) => emp.company_id === fp.toNumber(selectedCategory.value)
  );
  const dataToRender = fp.isEmpty(filtered) ? employees : filtered;

  const debouncedSearchTerm = useDebounce(employeeSearchTerm, 500);
  let companiesDropdown = [];
  let rolesDropdown = [];

  companiesDropdown.push({ value: 0, label: "All" });
  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }

  for (let i in roles) {
    let roleDropdownObj = {
      value: roles[i].id,
      label: roles[i].name,
    };

    rolesDropdown.push(roleDropdownObj);
  }

  useEffect(() => {
    dispatch(getEmployees(debouncedSearchTerm, pageNumber, employeeStatus));
  }, [dispatch, debouncedSearchTerm, pageNumber, employeeStatus]);

  return (
    <div className="m-12">
      <Fieldset>
        <h1 className="text-sm font-extrabold text-gray-600 mb-4">
          Filter Employees
        </h1>

        <div className="flex">
          <div className="mb-4 w-2/4">
            <Label name="Filter By Company:" />
            <Select
              options={companiesDropdown}
              onChange={(option) => {
                setSelectedCategory(option);
                setPageNumber(1);
              }}
              className="text-xs"
            />
          </div>
          <div className="mb-4 ml-4 w-1/4">
            <Label name="Employee Status" />
            <div className="mb-4 flex mt-4">
              <div className="flex">
                <input
                  type="radio"
                  value="Active"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={employeeStatus === "Active"}
                  onClick={() => {
                    setEmployeeStatus("Active");
                    setPageNumber(1);
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">Active</span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  value="Inactive"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={employeeStatus === "Inactive"}
                  onClick={() => {
                    setEmployeeStatus("Inactive");
                    setPageNumber(1);
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  Inactive
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <Label name="Employee Name" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={employeeSearchTerm}
            onChange={(e) => {
              setEmployeeSearchTerm(e.target.value);
              setPageNumber(1);
            }}
            type="text"
            placeholder="Search Employee Name..."
          />
        </div>
      </Fieldset>

      <Fieldset>
        <div className="w-full">
          <div className="float-right">
            <Button
              text="+ add employee"
              primary="true"
              onClick={() => {
                dispatch(selectEmployee(null));
                setAddModal(true);
              }}
            />
          </div>
          <Typography type="h1">List of Employees</Typography>

          <Table
            columns={employeeListColumns}
            data={
              <>
                {!employeesLoading &&
                  dataToRender.map((employee) => (
                    <tr
                      key={employee.id}
                      className={classNames(
                        "hover:bg-gray-200 cursor-pointer",
                        {
                          "text-blue-600 bg-teal-200":
                            (selectedEmployee ? selectedEmployee.id : 0) ===
                            employee.id,
                        }
                      )}
                      onClick={() => {
                        dispatch(selectEmployee(employee));
                      }}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="flex items-center">
                          <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
                            {employee.avatar && (
                              <img
                                alt="..."
                                className="w-full rounded-full align-middle border-none shadow-lg"
                                src={employee.avatar.image}
                              />
                            )}
                          </span>
                          <span className="text-xs text-gray-900 ml-2">
                            {`${employee.first_name} ${employee.last_name}`}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                        {employee.employee_status}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                        {employee.employee_role.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                        {employee.company.name}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                        {employee.nationality}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-900">
                        {employee.computed_yrs_of_service}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectEmployee(employee));
                              setViewModal(true);
                            }}
                          >
                            <ViewButton title={"Details"} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            }
          />

          {employeesLoading && <Spinner />}

          {employeesPagination && !employeesLoading && (
            <Pagination
              from={employeesPagination.from}
              to={employeesPagination.to}
              total={employeesPagination.total}
              lastPage={employeesPagination.last_page}
              currentPage={employeesPagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </div>
      </Fieldset>

      {viewModal && (
        <Modal>
          <EmployeeDetail
            onViewClose={() => {
              setViewModal(false);
            }}
            employeeSearchTerm={employeeSearchTerm}
            pageNumber={pageNumber}
          />
        </Modal>
      )}

      {addModal && (
        <Modal>
          <EmployeeForm
            onViewClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
