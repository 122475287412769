import React from "react";
import { useSelector } from "react-redux";
import { Label, Button, Typography } from "../../common";
import moment from "moment";

export default function CommonMaterialAllocateDetail(props) {
  const selectedAllocated = useSelector(
    (state) => state.commonAllocatedMaterials.selectedAllocated
  );

  return (
    <div>
      <Typography type="h1">Allocated Materials Detail</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Allocated by" />
          <p className="text-xs">
            {selectedAllocated.allocated_by
              ? `${selectedAllocated.allocated_by.first_name} ${selectedAllocated.allocated_by.last_name}`
              : ""}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Date Allocated" />
          <p className="text-xs">{selectedAllocated.updated_at}</p>
        </div>
      </div>

      <h1 className="mt-4 font-bold">Materials Allocated</h1>

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Price</th>
            <th className="text-xs px-4 py-2">Warehouse</th>
            <th className="text-xs px-4 py-2">Allocated Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectedAllocated.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {material.common_category.name}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">${material.price}</td>
              <td className="text-xs border px-2 py-2">
                {material.warehouse ? material.warehouse.name : ""}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.pivot.allocated_qty}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
