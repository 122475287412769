import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, Attachment } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { updateDeliveryMaterial } from "../../actions/deliverMaterials";
import { searchEmployees } from "../../actions/employees";
import { getSuppliers } from "../../actions/suppliers";
import { useTransformOption } from "../../helpers/useTransformData";
import Select from "react-select";
import ImageUtils from "../../helpers/ImageUtils";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

export default function MaterialDeliveryDeliveredForm(props) {
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const suppliersDropdown = useTransformOption(suppliers);
  const selectedDelivered = useSelector(
    (state) => state.deliverMaterials.selectedDelivered
  );
  const [receivedBy, setReceivedBy] = useState(null);
  //   const [deliveredBy, setDeliveredBy] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [deliveredDate, setDeliveredDate] = useState(
    selectedDelivered.delivered_date
      ? new Date(selectedDelivered.delivered_date)
      : new Date()
  );
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDeliveredDateChange = (date) => {
    setDeliveredDate(date);
  };

  const handleReceivedByChange = (option) => {
    setReceivedBy(option);
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const loadRecipientOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let recipientOptions = [];

    for (let i in search) {
      recipientOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(recipientOptions);
  };

  useEffect(() => {
    dispatch(getSuppliers());

    if (selectedDelivered.received_by) {
      setReceivedBy({
        value: selectedDelivered.received_by.id,
        label: `${selectedDelivered.received_by.first_name} ${selectedDelivered.received_by.last_name}`,
      });
    }

    if (selectedDelivered.supplier) {
      setSelectedSupplier({
        value: selectedDelivered.supplier.id,
        label: `${selectedDelivered.supplier.name}`,
      });
    }
  }, [dispatch, selectedDelivered]);

  const onSubmit = async () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the delivered issued order details.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        const data = {
          id: selectedDelivered.id,
          received_by: receivedBy ? receivedBy.value : null,
          delivered_date: moment(deliveredDate).format("YYYY-MM-DD").toString(),
          supplier_id: selectedSupplier ? selectedSupplier.value : null,
        };
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        if (file) {
          data.attachment = file;
        }

        dispatch(updateDeliveryMaterial(data));
      }
    });
  };

  return (
    <form>
      <h1 className="font-bold">Edit Delivered Issued Order</h1>
      <div className="grid mb-4">
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Received by" />
            <AsyncSelect
              loadOptions={loadRecipientOptions}
              value={receivedBy}
              onChange={(option) => handleReceivedByChange(option)}
              className="text-xs shadow-sm"
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Date Delivered" />
            <DatePicker
              showYearDropdown
              selected={deliveredDate}
              dateFormat="yyyy-MM-dd"
              onChange={(date) => {
                handleDeliveredDateChange(date);
              }}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              i
              style={{ zIndex: 10 }}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Attachment" />
            {selectedDelivered.attachment ? (
              <Attachment path={selectedDelivered.attachment_full_url} />
            ) : (
              <p className="text-gray-600 text-xs">No attachments.</p>
            )}
            <br />
            <p className="text-xs text-gray-600 mb-4">
              note that uploading will overwrite the existing attachment (if
              any).
            </p>
            <input
              ref={fileInputRef}
              type="file"
              className="text-xs"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Vendor" />
            <Select
              className="text-xs"
              options={suppliersDropdown}
              value={selectedSupplier}
              onChange={(option) => {
                handleSupplierChange(option);
              }}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Place of Delivery" />
            <p className="text-xs">
              {selectedDelivered.issue_purchase_order &&
              selectedDelivered.issue_purchase_order?.warehouse
                ? selectedDelivered.issue_purchase_order.warehouse.name
                : "-"}
            </p>
          </div>

          <div className="w-1/2 px-4">
            <Label name="Ship To" />
            <p className="text-xs">
              {selectedDelivered.issue_purchase_order?.ship_to ? (
                <>
                  <div className="flex items-center">
                    <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                      {selectedDelivered.issue_purchase_order.ship_to
                        .avatar && (
                        <img
                          alt="Supplier"
                          className="rounded-full border border-gray-100 object-cover"
                          src={
                            selectedDelivered.issue_purchase_order.ship_to
                              .avatar.image
                          }
                        />
                      )}
                    </span>
                    <span className="text-xs ml-2">
                      {
                        selectedDelivered.issue_purchase_order.ship_to
                          .first_name
                      }{" "}
                      {selectedDelivered.issue_purchase_order.ship_to.last_name}
                      <br />
                      {
                        selectedDelivered.issue_purchase_order.ship_to
                          .contact_number
                      }
                      <br />
                      {selectedDelivered.issue_purchase_order.ship_to_address}
                      <br />
                    </span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>

        <h1 className="mt-4 font-bold">Materials Delivered</h1>

        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">Price</th>
              {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
              <th className="text-xs px-4 py-2">Delivered Qty</th>
            </tr>
          </thead>
          <tbody>
            {selectedDelivered.materials.map((material, index) => (
              <tr key={index} className="hover:bg-gray-200">
                <td className="text-xs border px-2 py-2">
                  {material.category ? material.category.name : "Uncategorized"}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                <td className="text-xs border px-2 py-2">
                  $
                  {material.pivot.delivery_price
                    ? material.pivot.delivery_price
                    : material.price}
                </td>
                {/* <td className="text-xs border px-2 py-2">
                {selectedDelivered.issue_purchase_order &&
                  selectedDelivered.issue_purchase_order?.warehouse 
                    ? selectedDelivered.issue_purchase_order.warehouse.name
                    : "-"}
              </td> */}
                <td className="text-xs border px-2 py-2">
                  {material.pivot.stock_qty}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="justify-center flex mt-4 px-4">
          <Button
            text="Update Delivered Issued Order"
            success="true"
            onClick={() => {
              onSubmit();
            }}
          />
          <Button
            text="Close"
            primary="true"
            onClick={() => {
              props.onFormClose && props.onFormClose();
            }}
          />
        </div>
      </div>
    </form>
  );
}
