/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Label, Spinner, Attachment, Pagination } from "../common";
import Select from "react-select";
import moment from "moment";
import fp from "lodash/fp";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  getDocumentations,
  selectDocumentation,
  getManpowerCount,
  deleteDocumentation,
} from "../../actions/documentations";
import MyTasksAddDailyProgressReport from "./MyTasksAddDailyProgressReport";
import MyTasksAddToolboxMeeting from "./MyTasksAddToolboxMeeting";
import MyTasksAddSDICVI from "./MyTasksAddSDICVI";
import MyTasksAddElectInspection from "./MyTasksAddElectInspection";
import MyTasksAddSiteSurvey from "./MyTasksAddSiteSurvey";
import MyTasksAddCorrespondence from "./MyTasksAddCorrespondence";
import MyTasksAddDrawing from "./MyTasksAddDrawing";
import ViewDailyProgressReport from "../documentations/ViewDailyProgressReport";
import ViewToolboxMeeting from "../documentations/ViewToolboxMeeting";
import ViewSDICVI from "../documentations/ViewSDICVI";
import ViewElectInspection from "../documentations/ViewElectInspection";
import ViewSiteSurvey from "../documentations/ViewSiteSurvey";
import ViewCorrespondence from "../documentations/ViewCorrespondence";
import EditDailyProgressReport from "../documentations/EditDailyProgressReport";
import EditToolboxMeeting from "../documentations/EditToolboxMeeting";
import EditSDICVI from "../documentations/EditSDICVI";
import EditElectInspection from "../documentations/EditElectInspection";
import EditSiteSurvey from "../documentations/EditSiteSurvey";

import swal from "sweetalert";

export default function MyTasksDocumentations() {
  const dispatch = useDispatch();
  const documentations = useSelector(
    (state) => state.documentations.documentations
  );
  const pagination = useSelector((state) => state.documentations.pagination);
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const isLoading = useSelector((state) => state.documentations.isLoading);
  const isLoadingManpowerCount = useSelector(
    (state) => state.documentations.isLoadingManpowerCount
  );
  const manpowerCount = useSelector(
    (state) => state.documentations.manpowerCount
  );
  const [loading, setLoading] = useState(isLoading);
  const [view, setView] = useState("list");
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const [selectedReportType, setSelectedReportType] = useState({
    value: "daily_report",
    label: "Daily Progress Report",
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [offset, setOffset] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [comments, setComments] = useState("");

  let reportTypesDropdown = [
    { value: "drawing", label: "Drawing" },
    { value: "daily_report", label: "Daily Progress Report" },
    { value: "toolbox", label: "Toolbox Meeting" },
    { value: "sdi_cvi", label: "SDI/CVI" },
    { value: "elect", label: "Elect Inspection" },
    { value: "site_survey", label: "Site Survey" },
    { value: "contract", label: "Contract" },
    { value: "mos", label: "Method of Statement" },
    { value: "handover", label: "Handover" },
    { value: "correspondence", label: "Correspondence" },
    { value: "proj_sched", label: "Project Schedule" },
    { value: "dispossal", label: "Disposal Form" },
    // { value: "survey", label: "Pre Con Survey" },
  ];

  const [reportDate, setReportDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModal, setUpdateModalOpen] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const reformattedStartDate = moment(reportDate).format("YYYY-MM-DD");

  const newDocumentationData =
    documentations && shouldFilter
      ? documentations.filter(
          (date) => date.report_date === fp.toString(reformattedStartDate)
        )
      : documentations;

  useEffect(() => {
    let payload = {};

    if (reportDate) {
      payload.date = moment(reportDate).format("YYYY-MM-DD").toString();
    }
    dispatch(
      getDocumentations(
        selectedTask.type,
        selectedTask.id,
        selectedReportType.value,
        payload,
        offset,
        "",
        pageNumber,
      )
    );
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [dispatch, selectedReportType, selectedTask, offset, reportDate, pageNumber]);

  useEffect(() => {
    if (
      selectedReportType.value === "daily_report" &&
      selectedTask.type === "projects"
    ) {
      dispatch(
        getManpowerCount(
          selectedTask.type === "projects"
            ? selectedTask.id
            : selectedTask.project_id,
          "daily_report"
        )
      );
    }
  }, [dispatch, selectedReportType, selectedTask]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFilterManpowerCount = () => {
    if (startDate && endDate) {
      dispatch(
        getManpowerCount(
          selectedTask.type === "projects"
            ? selectedTask.id
            : selectedTask.project_id,
          "daily_report",
          moment(startDate).format("YYYY-MM-DD").toString(),
          moment(endDate).format("YYYY-MM-DD").toString()
        )
      );
    }
  };

  return (
    <div>
      {/** DAILY PROGRESS REPORT */}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "daily_report" && (
          <Modal title={`Daily Progress Report - ${selectedDocument.activity}`}>
            <ViewDailyProgressReport
              onEditClick={() => {
                setUpdateModalOpen(true);
                setModalOpen(false);
              }}
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {addModal && selectedReportType.value === "daily_report" && (
        <Modal>
          <MyTasksAddDailyProgressReport
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {updateModal &&
        selectedDocument &&
        selectedReportType.value === "daily_report" && (
          <Modal
            title={`Update Daily Progress Report - ${selectedDocument.activity}`}
          >
            <EditDailyProgressReport
              onViewClose={() => {
                setUpdateModalOpen(false);
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {/** DRAWING */}
      {addModal && selectedReportType.value === "drawing" && (
        <Modal>
          <MyTasksAddDrawing
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {/** TOOLBOX MEETING */}
      {addModal && selectedReportType.value === "toolbox" && (
        <Modal>
          <MyTasksAddToolboxMeeting
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "toolbox" && (
          <Modal title={`View Files : ${selectedDocument.date}`}>
            <ViewToolboxMeeting
              onEditClick={() => {
                setUpdateModalOpen(true);
                setModalOpen(false);
              }}
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {updateModal &&
        selectedDocument &&
        selectedReportType.value === "toolbox" && (
          <Modal title={`Update Toolbox Meeting`}>
            <EditToolboxMeeting
              onViewClose={() => {
                setUpdateModalOpen(false);
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {/** SDI/CVI */}
      {addModal && selectedReportType.value === "sdi_cvi" && (
        <Modal>
          <MyTasksAddSDICVI
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
          <Button
            text="Close"
            primary="true"
            onClick={() => {
              setAddModal(false);
            }}
            style={{ position: "absolute", left: 120, bottom: 15 }}
          />
        </Modal>
      )}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "sdi_cvi" && (
          <Modal title={`SDI CVI - ${selectedDocument.ref_no}`}>
            <ViewSDICVI
              onEditClick={() => {
                setUpdateModalOpen(true);
                setModalOpen(false);
              }}
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {updateModal &&
        selectedDocument &&
        selectedReportType.value === "sdi_cvi" && (
          <Modal title={`Update SDI/CVI`}>
            <EditSDICVI
              onViewClose={() => {
                setUpdateModalOpen(false);
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {/** ELECT INSPECTION*/}
      {addModal && selectedReportType.value === "elect" && (
        <Modal>
          <MyTasksAddElectInspection
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
          <Button
            text="Close"
            primary="true"
            onClick={() => {
              setAddModal(false);
            }}
            style={{ position: "absolute", left: 120, bottom: 15 }}
          />
        </Modal>
      )}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "elect" && (
          <Modal title={`Elect No. - ${selectedDocument.ref_no}`}>
            <ViewElectInspection
              onEditClick={() => {
                setUpdateModalOpen(true);
                setModalOpen(false);
              }}
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {updateModal &&
        selectedDocument &&
        selectedReportType.value === "elect" && (
          <Modal title={`Update Elect Inspection`}>
            <EditElectInspection
              onViewClose={() => {
                setUpdateModalOpen(false);
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {/** SITE SURVEY*/}
      {addModal && selectedReportType.value === "site_survey" && (
        <Modal>
          <MyTasksAddSiteSurvey
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
          <Button
            text="Close"
            primary="true"
            onClick={() => {
              setAddModal(false);
            }}
            style={{ position: "absolute", left: 120, bottom: 15 }}
          />
        </Modal>
      )}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "site_survey" && (
          <Modal title={`Site Survey - ${selectedDocument.subject}`}>
            <ViewSiteSurvey
              onEditClick={() => {
                setUpdateModalOpen(true);
                setModalOpen(false);
              }}
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {updateModal &&
        selectedDocument &&
        selectedReportType.value === "site_survey" && (
          <Modal title={`Update Site Survey`}>
            <EditSiteSurvey
              onViewClose={() => {
                setUpdateModalOpen(false);
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {/* CORRESPONDENCE */}
      {addModal && selectedReportType.value === "correspondence" && (
        <Modal>
          <MyTasksAddCorrespondence
            operation={view}
            onTaskSubmit={() => {
              setView("list");
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {modalOpen &&
        selectedDocument &&
        selectedReportType.value === "correspondence" && (
          <Modal title={`Correspondence - ${selectedDocument.subject}`}>
            <ViewCorrespondence
              onViewClose={() => {
                setModalOpen(false);
              }}
            />
          </Modal>
        )}

      {view === "list" && (
        <div>
          {/* Different Conditions for Add Buttons. */}
          {selectedReportType.value === "daily_report" && (
            <div className="float-right">
              <Button
                text={`+ Add Daily Progress Report`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={
                  selectedTask.is_last === false ||
                  selectedTask.type === "projects"
                }
              />
            </div>
          )}

          {selectedReportType.value === "drawing" && (
            <div className="float-right">
              <Button
                text={`+ Add Drawing Document`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "projects"}
              />
            </div>
          )}

          {selectedReportType.value === "toolbox" && (
            <div className="float-right">
              <Button
                text={`+ Add Toolbox Meeting`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "tasks"}
              />
            </div>
          )}

          {selectedReportType.value === "sdi_cvi" && (
            <div className="float-right">
              <Button
                text={`+ Add SDI/CVI`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "projects"}
              />
            </div>
          )}

          {selectedReportType.value === "elect" && (
            <div className="float-right">
              <Button
                text={`+ Add Elect Inspection`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "projects"}
              />
            </div>
          )}

          {selectedReportType.value === "site_survey" && (
            <div className="float-right">
              <Button
                text={`+ Add Site Survey`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "projects"}
              />
            </div>
          )}

          {selectedReportType.value === "correspondence" && (
            <div className="float-right">
              <Button
                text={`+ Add Correspondence`}
                primary="true"
                onClick={() => {
                  setAddModal(true);
                }}
                disabled={selectedTask.type === "projects"}
              />
            </div>
          )}

          <h1 className="text-sm font-extrabold text-gray-600">
            {selectedReportType.label}
          </h1>
          <p className="text-gray-600 text-xs mb-4">
            Click on the row to view its attachments or approve/decline
            operations.
          </p>

          <div className="mb-4">
            <div className="flex">
              <div className="flex-1">
                <Label name="Report Type" />
                <Select
                  options={reportTypesDropdown}
                  value={selectedReportType}
                  onChange={setSelectedReportType}
                  className="text-xs"
                />
              </div>
              <div className="flex ml-4">
                <div>
                  <Label name="Date" />
                  <DatePicker
                    showYearDropdown
                    selected={reportDate}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      setReportDate(date);
                      setShouldFilter(true);
                      setLoading(true);
                    }}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-xs"
                  />
                </div>
                <div className="flex-initial m-4">
                  <Button
                    text="Clear"
                    warning="true"
                    disabled={!reportDate}
                    onClick={() => {
                      setReportDate(null);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {selectedReportType.value === "daily_report" && !isLoading && (
            <>
              {isLoadingManpowerCount && selectedTask.type === "projects" && (
                <>
                  <Spinner />
                  <p className="text-gray-600 text-sm mb-4 text-center">
                    Loading Overall Manpower Count...
                  </p>
                </>
              )}

              {!isLoadingManpowerCount && selectedTask.type === "projects" && (
                <>
                  <div className="box border rounded flex flex-col shadow bg-white mb-4">
                    <div className="bg-gray-400 px-3 py-2 border-b">
                      <h3 className="text-sm text-gray-900 font-medium">
                        Manpower Count
                      </h3>
                    </div>

                    <div className="p-4">
                      {manpowerCount && (
                        <>
                          <Label name="Filter by Date Range" />
                          <div className="flex mb-4">
                            <div className="w-1/3">
                              <Label name="Start Date" />
                              <DatePicker
                                showYearDropdown
                                selected={startDate}
                                dateFormat="yyyy-MM-dd"
                                onChange={handleStartDateChange}
                                className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
                              />
                            </div>
                            <div className="w-1/3 ml-4">
                              <Label name="End Date" />
                              <DatePicker
                                showYearDropdown
                                selected={endDate}
                                dateFormat="yyyy-MM-dd"
                                onChange={handleEndDateChange}
                                className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
                              />
                            </div>
                            <div className="w-1/3 px-4">
                              <Button
                                text="Filter Manpower Count"
                                primary="true"
                                onClick={() => {
                                  handleFilterManpowerCount();
                                }}
                              />
                              <div className="mt-4">
                                <Button
                                  text="Clear Filters"
                                  warning="true"
                                  onClick={() => {
                                    setStartDate(null);
                                    setEndDate(null);
                                    dispatch(
                                      getManpowerCount(
                                        selectedTask.type === "projects"
                                          ? selectedTask.id
                                          : selectedTask.project_id,
                                        "daily_report"
                                      )
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <table className="table-fixed w-100">
                            <thead>
                              <tr>
                                <th className="text-xs px-4 py-2">Activity</th>
                                <th className="text-xs px-4 py-2" align="right">
                                  Manpower Count
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {manpowerCount.tasks.map((task) => (
                                <tr
                                  className="text-gray-900 text-xs"
                                  key={task.id}
                                >
                                  <td className="text-xs border px-2 py-2">
                                    {task.title}
                                  </td>
                                  <td
                                    className="text-xs border px-2 py-2"
                                    align="right"
                                  >
                                    {task.manpower_count}
                                  </td>
                                </tr>
                              ))}
                              <tr className="text-gray-900 text-sm">
                                <td className="text-sm border px-2 py-2">
                                  <strong>Total Manpower:</strong>
                                </td>
                                <td
                                  className="text-sm border px-2 py-2"
                                  align="right"
                                >
                                  <strong>
                                    {manpowerCount.manpower_count}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <table className="table-fixed w-100">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">Activity</th>
                    <th className="text-xs px-4 py-2">Location</th>
                    <th className="text-xs px-4 py-2">%</th>
                    <th className="text-xs px-4 py-2">Hours</th>
                    <th className="text-xs px-4 py-2">Manpower</th>
                    <th className="text-xs px-4 py-2">Created By</th>
                    <th className="text-xs px-4 py-2">Approved By</th>
                    <th className="text-xs px-4 py-2">Status</th>
                    <th className="text-xs px-4 py-2">
                      Material
                      <br />
                      Comments
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fp.isEmpty(newDocumentationData)
                    ? documentations.map((doc) => (
                        <tr
                          key={doc.id}
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            console.log(doc);
                            dispatch(selectDocumentation(doc));
                            setComments(doc.comments);
                            setModalOpen(true);
                          }}
                        >
                          <td className="text-xs border px-2 py-2">
                            {doc.report_date}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.activity}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.location}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.accomplishments}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.hours}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.manpower}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.created_by &&
                              `${doc.created_by.first_name} ${doc.created_by.last_name}`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.approved_by &&
                              `${doc.approved_by.first_name} ${doc.approved_by.last_name}`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.is_submitted === 1
                              ? `Submitted`
                              : `Not yet submitted`}
                            {doc.rejected_by && `Rejected`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {doc.material_comments}
                          </td>
                        </tr>
                      ))
                    : newDocumentationData.map((documentation) => (
                        <tr
                          key={documentation.id}
                          className="hover:bg-gray-200 cursor-pointer"
                          onClick={() => {
                            console.log(documentation);
                            dispatch(selectDocumentation(documentation));
                            setComments(documentation.comments);
                            setModalOpen(true);
                          }}
                        >
                          <td className="text-xs border px-2 py-2">
                            {documentation.report_date}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.activity}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.location}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.accomplishments}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.hours}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.manpower}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.created_by &&
                              `${documentation.created_by.first_name} ${documentation.created_by.last_name}`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.approved_by &&
                              `${documentation.approved_by.first_name} ${documentation.approved_by.last_name}`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.is_submitted === 1
                              ? `Submitted`
                              : documentation.rejected_by
                              ? `Rejected`
                              : `Not yet submitted`}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {documentation.material_comments}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>

              {pagination && !isLoading && (
                <Pagination
                  from={pagination.from}
                  to={pagination.to}
                  total={pagination.total}
                  lastPage={pagination.last_page}
                  currentPage={pagination.current_page}
                  onPageClick={(page) => {
                    setPageNumber(page);
                  }}
                />
              )}
            </>
          )}

          {selectedReportType.value === "drawing" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date</th>
                  <th className="text-xs px-4 py-2">Description</th>
                  <th className="text-xs px-4 py-2">File</th>
                  <th className="text-xs px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.description}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation?.files && (
                        <Attachment path={documentation.files[0].full_path} />
                      )}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      <Button
                        text="Delete"
                        danger="true"
                        onClick={() => {
                          swal({
                            icon: "warning",
                            title: "Are you sure?",
                            text: `Once deleted, selected drawing will be removed.`,
                            buttons: true,
                            dangerMode: true,
                          }).then((willDelete) => {
                            if (willDelete) {
                              dispatch(deleteDocumentation(documentation.id));
                              selectDocumentation(null);
                            }
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "toolbox" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date</th>
                  <th className="text-xs px-4 py-2">Safety</th>
                  <th className="text-xs px-4 py-2">Supervisor</th>
                  <th className="text-xs px-4 py-2">Worker</th>
                  <th className="text-xs px-4 py-2">Subcon</th>
                  <th className="text-xs px-4 py-2">Supply Labour</th>
                  <th className="text-xs px-4 py-2">Total</th>
                  {/* <th className="text-xs px-4 py-2">Photos</th> */}
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.safety}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.supervisor}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.worker}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.subcon}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.supply_labour}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.total}
                    </td>
                    {/* <td className="text-xs border px-2 py-2">
                      {documentation.files ? documentation.files.length : 0}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "sdi_cvi" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date</th>
                  <th className="text-xs px-4 py-2">Status</th>
                  <th className="text-xs px-4 py-2">Ref. No</th>
                  <th className="text-xs px-4 py-2">Subject</th>
                  <th className="text-xs px-4 py-2">SDI/CVI</th>
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.status}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.ref_no}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.subject}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.sdi_cvi}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "elect" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date</th>
                  <th className="text-xs px-4 py-2">Elect No</th>
                  <th className="text-xs px-4 py-2">Location</th>
                  <th className="text-xs px-4 py-2">Description</th>
                  <th className="text-xs px-4 py-2">Acceptable</th>
                  {/* <th className="text-xs px-4 py-2">Files</th> */}
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.ref_no}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.location}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.description}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.acceptable}
                    </td>
                    {/* <td className="text-xs border px-2 py-2">
                      {documentation.files ? documentation.files.length : 0}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "site_survey" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date</th>
                  <th className="text-xs px-4 py-2">Subject</th>
                  <th className="text-xs px-4 py-2">Description</th>
                  <th className="text-xs px-4 py-2">Comments</th>
                  {/* <th className="text-xs px-4 py-2">Files</th> */}
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.subject}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.description}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.comments}
                    </td>
                    {/* <td className="text-xs border px-2 py-2">
                      {documentation.files ? documentation.files.length : 0}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "contract" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {selectedReportType.value === "mos" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">Location</th>
                    <th className="text-xs px-4 py-2">Description</th>
                    <th className="text-xs px-4 py-2">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.location}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.description}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.comments}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {selectedReportType.value === "handover" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Inspection Date</th>
                    <th className="text-xs px-4 py-2">Location</th>
                    <th className="text-xs px-4 py-2">Acceptable?</th>
                    <th className="text-xs px-4 py-2">Description</th>
                    <th className="text-xs px-4 py-2">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.location}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.acceptable}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.description}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.comments}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {selectedReportType.value === "correspondence" && !isLoading && (
            <table className="table-auto ml-6">
              <thead>
                <tr>
                  <th className="text-xs px-4 py-2">Date of E-mail</th>
                  <th className="text-xs px-4 py-2">E-mail From</th>
                  <th className="text-xs px-4 py-2">Subject</th>
                  <th className="text-xs px-4 py-2">E-mail</th>
                </tr>
              </thead>
              <tbody>
                {documentations.map((documentation) => (
                  <tr
                    key={documentation.id}
                    className="hover:bg-gray-200 cursor-pointer"
                    onClick={() => {
                      dispatch(selectDocumentation(documentation));
                      setModalOpen(true);
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {documentation.date}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.email_from}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation.subject}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {documentation?.files && (
                        <Attachment path={documentation.files[0].full_path} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {selectedReportType.value === "proj_sched" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {selectedReportType.value === "mom" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">MOM No</th>
                    <th className="text-xs px-4 py-2">Agenda</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.ref_no}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.agenda}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {selectedReportType.value === "dispossal" &&
            selectedTask.type === "projects" &&
            !isLoading && (
              <table className="table-auto ml-6">
                <thead>
                  <tr>
                    <th className="text-xs px-4 py-2">Date</th>
                    <th className="text-xs px-4 py-2">Location</th>
                    <th className="text-xs px-4 py-2">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {documentations.map((documentation) => (
                    <tr
                      key={documentation.id}
                      className="hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        dispatch(selectDocumentation(documentation));
                      }}
                    >
                      <td className="text-xs border px-2 py-2">
                        {documentation.date}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.location}
                      </td>
                      <td className="text-xs border px-2 py-2">
                        {documentation.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {isLoading && <Spinner />}

          {!isLoading && (
            <div className="flex mt-4">
              {offset > 0 && (
                <Button
                  text="< prev"
                  primary="true"
                  onClick={() => {
                    setOffset(offset - 5);
                  }}
                />
              )}

              {documentations.length > 4 && (
                <Button
                  text="next >"
                  primary="true"
                  onClick={() => {
                    setOffset(offset + 5);
                  }}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
