import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Pagination,
  EmptyPlaceholder,
} from "../common";
import {
  //   getAvailableSpecialisedMaterials,
  selectWithdrawalRequest,
  getWithdrawalRequests,
  deleteWithdrawalRequest,
} from "../../actions/materialWithdrawals";
import { roles } from "../../helpers/userRoles";
import MaterialWithdrawalAdd from "./MaterialWithdrawalAdd";
import MaterialWithdrawalView from "./MaterialWithdrawalView";
import MaterialWithdrawalUpdate from "./MaterialWithdrawalUpdate";
import swal from "sweetalert";

export default function MaterialWithdrawalPage(props) {
  const dispatch = useDispatch();
  // const availableMaterials = useSelector((state) => state.materialWithdrawals.availableMaterials);
  // const availableMaterialsPagination = useSelector((state) => state.materialWithdrawals.availableMaterialsPagination);
  const user = useSelector((state) => state.auth.user);
  const withdrawalRequests = useSelector(
    (state) => state.materialWithdrawals.withdrawalRequests
  );
  const withdrawalRequestsPagination = useSelector(
    (state) => state.materialWithdrawals.withdrawalRequestsPagination
  );
  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  const isLoading = useSelector((state) => state.materialWithdrawals.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  useEffect(() => {
    dispatch(
      getWithdrawalRequests(
        pageNumber,
        selectedTask.type === "projects"
          ? selectedTask.id
          : selectedTask.project_id,
        selectedTask.type === "tasks" ? selectedTask.id : null
      )
    );
  }, [selectedTask, pageNumber, dispatch]);

  return (
    <div className="mt-4">
      {selectedTask.is_last === false || selectedTask.type === "projects" ? (
        <div className="mb-4">
          <div className="flex justify-end">
            <p className="text-xs text-gray-600">
              Withdrawal Request should be made at the bottom level of the Task.
            </p>
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <div className="flex justify-end">
            <Button
              text={`+ Request for Withdrawal`}
              primary="true"
              onClick={() => {
                setAddModal(true);
              }}
              disabled={
                selectedTask.is_last === false ||
                selectedTask.type === "projects"
              }
            />
          </div>
        </div>
      )}

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Status</th>
            <th className="text-xs px-4 py-2">Date</th>
            <th className="text-xs px-4 py-2">Requested by</th>
            <th className="text-xs px-4 py-2">Items</th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {withdrawalRequests.map((withdrawalRequest) => (
            <tr key={withdrawalRequest.id} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {withdrawalRequest.status === "Pending" && (
                  <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                    Pending
                  </span>
                )}

                {withdrawalRequest.status === "Approved" && (
                  <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                    Approved
                  </span>
                )}

                {withdrawalRequest.status === "Rejected" && (
                  <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                    Rejected
                  </span>
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                {withdrawalRequest.date}
              </td>
              <td className="text-xs border px-2 py-2">
                {withdrawalRequest.requested_by
                  ? `${withdrawalRequest.requested_by.first_name} ${withdrawalRequest.requested_by.last_name}`
                  : ""}
              </td>
              <td className="text-xs border px-2 py-2">
                {withdrawalRequest.materials
                  ? withdrawalRequest.materials.length
                  : 0}
              </td>
              <td className="text-xs border px-2 py-2">
                <div className="flex">
                  <div className="flex-initial">
                    <Button
                      text={
                        withdrawalRequest.status !== "Approved"
                          ? "Process"
                          : "View"
                      }
                      primary="true"
                      onClick={() => {
                        dispatch(selectWithdrawalRequest(withdrawalRequest));
                        setViewModal(true);
                      }}
                    />
                  </div>

                  {(user.employee.role_id === roles.su ||
                    user.employee.role_id === roles.proc ||
                    user.employee.role_id === roles.engr ||
                    user.employee.role_id === roles.pm ||
                    user.employee.role_id === roles.apm ||
                    user.employee.role_id === roles.coord) && (
                    <div className="flex-initial">
                      <Button
                        text={`Edit`}
                        warning="true"
                        onClick={() => {
                          dispatch(selectWithdrawalRequest(withdrawalRequest));
                          setUpdateModal(true);
                        }}
                      />
                    </div>
                  )}

                  {user.employee.role_id === roles.su && (
                    <div className="flex-initial">
                      <Button
                        text={`Delete`}
                        danger="true"
                        onClick={() => {
                          swal({
                            icon: "error",
                            title: "Are you sure?",
                            text: `This will delete this Withdrawal Request Order.`,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              dispatch(
                                deleteWithdrawalRequest(withdrawalRequest.id)
                              );
                            }
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {withdrawalRequests < 1 && <EmptyPlaceholder text="No Requests Found" />}

      {isLoading && <Spinner />}

      {withdrawalRequestsPagination && !isLoading && (
        <Pagination
          from={withdrawalRequestsPagination.from}
          to={withdrawalRequestsPagination.to}
          total={withdrawalRequestsPagination.total}
          lastPage={withdrawalRequestsPagination.last_page}
          currentPage={withdrawalRequestsPagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}

      {addModal && (
        <Modal>
          <MaterialWithdrawalAdd
            onViewClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {viewModal && (
        <Modal>
          <MaterialWithdrawalView
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}

      {updateModal && (
        <Modal>
          <MaterialWithdrawalUpdate
            onViewClose={() => {
              setUpdateModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
