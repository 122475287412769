import {
  GET_DELIVERED_MATERIALS,
  LOADING_DELIVERY_MATERIALS,
  SELECT_DELIVERED_MATERIAL,
  SELECT_FOR_DELIVERY_MATERIAL,
  DELIVER_MATERIAL,
  GET_FOR_DELIVERY_MATERIALS,
  GET_PARTIAL_DELIVERED_MATERIALS,
  SELECT_PARTIAL_DELIVERED_MATERIAL,
  DELETE_DELIVERY_MATERIAL,
  UPDATE_DELIVERY_MATERIAL,
  SET_LOADING_DELIVERY_ORDERS_EXCEL,
} from "../actions/types";

const initialState = {
  forDeliveryMaterials: [],
  deliveredMaterials: [],
  partialDeliveredMaterials: [],
  pagination: null,
  forDeliveryPagination: null,
  partialDeliveredPagination: null,
  selectedDelivered: null,
  selectedForDelivery: null,
  selectedPartialDelivered: null,
  isLoading: false,
  isExcelLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DELIVERY_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOADING_DELIVERY_ORDERS_EXCEL:
      return {
        ...state,
        isExcelLoading: action.payload,
      };
    case GET_DELIVERED_MATERIALS:
      return {
        ...state,
        deliveredMaterials: action.payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case GET_FOR_DELIVERY_MATERIALS:
      return {
        ...state,
        forDeliveryMaterials: action.payload,
        forDeliveryPagination: action.pagination,
        isLoading: false,
      };
    case GET_PARTIAL_DELIVERED_MATERIALS:
      return {
        ...state,
        partialDeliveredMaterials: action.payload,
        partialDeliveredPagination: action.pagination,
        isLoading: false,
      };
    case SELECT_DELIVERED_MATERIAL:
      return {
        ...state,
        selectedDelivered: action.payload,
      };
    case SELECT_FOR_DELIVERY_MATERIAL:
      return {
        ...state,
        selectedForDelivery: action.payload,
      };
    case SELECT_PARTIAL_DELIVERED_MATERIAL:
      return {
        ...state,
        selectedPartialDelivered: action.payload,
      };
    case DELIVER_MATERIAL:
      return {
        ...state,
        deliveredMaterials: state.deliveredMaterials.filter(
          (material) => material.id !== action.payload
        ),
        pagination: {
          ...state.pagination,
          total: state.pagination.total
            ? state.pagination.total - 1
            : state.deliveredMaterials
            ? state.deliveredMaterials.length
            : 0,
        },
        isLoading: false,
      };
    case DELETE_DELIVERY_MATERIAL:
      return {
        ...state,
        deliveredMaterials: state.deliveredMaterials.filter(
          (material) => material.id !== action.payload
        ),
        partialDeliveredMaterials: state.deliveredMaterials.filter(
          (material) => material.id !== action.payload
        ),
      };
    case UPDATE_DELIVERY_MATERIAL:
      return {
        ...state,
        selectedDelivered: action.payload,
        deliveredMaterials: state.deliveredMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    default:
      return state;
  }
}
