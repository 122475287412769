import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_WAREHOUSES,
  ADD_WAREHOUSE,
  UPDATE_WAREHOUSE,
  SELECT_WAREHOUSE,
  DELETE_WAREHOUSE,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET WAREHOUSES
export const getWarehouses = () => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/warehouses`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_WAREHOUSES,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// ADD WAREHOUSE
export const addWarehouse = (warehouse) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/warehouses`, warehouse, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_WAREHOUSE,
        payload: res.data,
      });
      swal({
        title: "Warehouse Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE WAREHOUSE
export const updateWarehouse = (id, warehouse) => (dispatch, getState) => {
  axios
    .put(BASE_URL + `/warehouses/${id}`, warehouse, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_WAREHOUSE,
        payload: res.data,
      });
      swal({
        title: "Warehouse Updated",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// SELECT WAREHOUSE
export const selectWarehouse = (warehouse) => (dispatch) => {
  dispatch({
    type: SELECT_WAREHOUSE,
    payload: warehouse,
  });
};

// DELETE WAREHOUSE
export const deleteWarehouse = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/warehouses/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_WAREHOUSE,
        payload: id,
      });
      swal({
        title: "Warehouse Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
