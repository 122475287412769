import React from "react";
import { useSelector } from "react-redux";
import { Label, Button, Typography, Attachment } from "../../common";
import moment from "moment";

export default function CommonMaterialDeliveryDetail(props) {
  const selectedDelivered = useSelector(
    (state) => state.commonDeliverMaterials.selectedDelivered
  );

  return (
    <div>
      <Typography type="h1">Delivery Detail</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Received by" />
          <p className="text-xs">{selectedDelivered.received_by}</p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Date Delivered" />
          <p className="text-xs">
            {selectedDelivered.delivered_date
              ? `${moment(selectedDelivered.delivered_date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Attachment" />
          {selectedDelivered.attachment ? (
            <Attachment path={selectedDelivered.attachment_full_url} />
          ) : (
            <p className="text-gray-600 text-xs">No attachments.</p>
          )}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Vendor" />
          <p className="text-xs">
            {selectedDelivered.common_issue_purchase_order.common_supplier ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    <img
                      alt="Common Supplier"
                      className="rounded-full border border-gray-100 object-cover"
                      src={
                        selectedDelivered.common_issue_purchase_order
                          .common_supplier.photo_full_url
                      }
                    />
                  </span>
                  <span className="text-xs ml-2">
                    {
                      selectedDelivered.common_issue_purchase_order
                        .common_supplier.name
                    }
                    <br />
                    {
                      selectedDelivered.common_issue_purchase_order
                        .common_supplier.contact_person
                    }
                    <br />
                    {
                      selectedDelivered.common_issue_purchase_order
                        .common_supplier.contact_number
                    }
                    <br />
                    {
                      selectedDelivered.common_issue_purchase_order
                        .common_supplier.address
                    }
                    <br />
                  </span>
                </div>
              </>
            ) : (
              " - "
            )}
          </p>
        </div>

        <div className="w-1/2 px-4">
          <Label name="Ship To" />
          <p className="text-xs">
            {selectedDelivered.common_issue_purchase_order.ship_to ? (
              <>
                <div className="flex items-center">
                  <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                    {selectedDelivered.common_issue_purchase_order.ship_to
                      .avatar && (
                      <img
                        alt="Supplier"
                        className="rounded-full border border-gray-100 object-cover"
                        src={
                          selectedDelivered.common_issue_purchase_order.ship_to
                            .avatar.image
                        }
                      />
                    )}
                  </span>
                  <span className="text-xs ml-2">
                    {
                      selectedDelivered.common_issue_purchase_order.ship_to
                        .first_name
                    }{" "}
                    {
                      selectedDelivered.common_issue_purchase_order.ship_to
                        .last_name
                    }
                    <br />
                    {
                      selectedDelivered.common_issue_purchase_order.ship_to
                        .contact_number
                    }
                    <br />
                    {
                      selectedDelivered.common_issue_purchase_order
                        .ship_to_address
                    }
                    <br />
                  </span>
                </div>
              </>
            ) : (
              "-"
            )}
          </p>
        </div>
      </div>

      <h1 className="mt-4 font-bold">Materials Delivered</h1>

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            <th className="text-xs px-4 py-2">Price</th>
            <th className="text-xs px-4 py-2">Warehouse</th>
            <th className="text-xs px-4 py-2">Delivered Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectedDelivered.materials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                {material.common_category.name}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              <td className="text-xs border px-2 py-2">${material.price}</td>
              <td className="text-xs border px-2 py-2">
                {material.warehouse ? material.warehouse.name : ""}
              </td>
              <td className="text-xs border px-2 py-2">
                {material.pivot.stock_qty}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
