import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";
import moment from "moment";

import {
  GET_DELIVERED_MATERIALS,
  GET_FOR_DELIVERY_MATERIALS,
  GET_PARTIAL_DELIVERED_MATERIALS,
  LOADING_DELIVERY_MATERIALS,
  SELECT_DELIVERED_MATERIAL,
  DELIVER_MATERIAL,
  SELECT_FOR_DELIVERY_MATERIAL,
  SELECT_PARTIAL_DELIVERED_MATERIAL,
  DELETE_DELIVERY_MATERIAL,
  UPDATE_DELIVERY_MATERIAL,
  SET_LOADING_DELIVERY_ORDERS_EXCEL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET DELIVERY MATERIALS
export const getDeliveredMaterials =
  (
    page,
    project_id,
    sort_by,
    start_date = null,
    end_date = null,
    supplier_id = null
  ) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_MATERIALS });

    let endpoint = `/delivery-materials?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (sort_by) endpoint += `&sort_by=${sort_by}`;
    if (supplier_id) endpoint += `&supplier_id=${supplier_id}`;
    if (start_date) endpoint += `&start_date=${start_date}`;
    if (end_date) endpoint += `&end_date=${end_date}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_DELIVERED_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// get for delivery materials (issue POs that haven't delivered yet.)
export const getForDeliveryMaterials =
  (page, project_id, sort_by) => (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_MATERIALS });

    let endpoint = `/delivery-materials/for-delivery?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (sort_by) endpoint += `&sort_by=${sort_by}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_FOR_DELIVERY_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

export const getPartialDeliveredMaterials =
  (page, project_id, sort_by) => (dispatch, getState) => {
    dispatch({ type: LOADING_DELIVERY_MATERIALS });

    let endpoint = `/delivery-materials/partial?page=${page}`;

    if (project_id) endpoint += `&project_id=${project_id}`;
    if (sort_by) endpoint += `&sort_by=${sort_by}`;

    axios.get(BASE_URL + endpoint, tokenConfig(getState)).then((res) => {
      dispatch({
        type: GET_PARTIAL_DELIVERED_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    });
  };

// select delivery material
export const selectForDeliveryMaterial =
  (forDeliveryMaterial) => (dispatch) => {
    dispatch({
      type: SELECT_FOR_DELIVERY_MATERIAL,
      payload: forDeliveryMaterial,
    });
  };

export const selectPartialDeliveredMaterial =
  (partialDeliveredMaterial) => (dispatch) => {
    dispatch({
      type: SELECT_PARTIAL_DELIVERED_MATERIAL,
      payload: partialDeliveredMaterial,
    });
  };

export const selectDeliveredMaterial = (deliveredMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_DELIVERED_MATERIAL,
    payload: deliveredMaterial,
  });
};

// DELIVER MATERIAL
export const deliverMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/delivery-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Materials marked as delivered!",
        icon: "success",
      });
      dispatch({ type: DELIVER_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

export const deleteDeliveryMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/delivery-materials/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_DELIVERY_MATERIAL,
        payload: id,
      });
      swal({
        title: "Delivery PO Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const updateDeliveryMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/delivery-materials/update`;
  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Delivery PO Updated!",
        icon: "success",
      });
      dispatch({
        type: UPDATE_DELIVERY_MATERIAL,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const exportDeliveryOrders =
  (project_id, supplier_id, start_date, end_date) => (dispatch, getState) => {
    dispatch({ type: SET_LOADING_DELIVERY_ORDERS_EXCEL, payload: true });
    let endpoint = `/delivery-materials/export?is_export=true&`;

    if (project_id) endpoint += `project_id=${project_id}&`;
    if (supplier_id) endpoint += `supplier_id=${supplier_id}&`;
    if (start_date) endpoint += `start_date=${start_date}&`;
    if (end_date) endpoint += `end_date=${end_date}&`;

    axios
      .get(BASE_URL + endpoint, {
        ...tokenConfig(getState),
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Delivered Materials - ${moment().format("Y-MM-DD").toString()}.xlsx`
        );
        dispatch({ type: SET_LOADING_DELIVERY_ORDERS_EXCEL, payload: false });
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        dispatch({ type: SET_LOADING_DELIVERY_ORDERS_EXCEL, payload: false });
        console.error(err);
      });
  };
