import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const sendAnnouncement = (announcement) => (dispatch, getState) => {
  axios
    .post(
      BASE_URL + `/notifications/send-announcement`,
      announcement,
      tokenConfig(getState)
    )
    .then((res) => {
      swal({
        title: "Announcement Sent!",
        text: res.data,
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
