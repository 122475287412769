export const projectsColumns = [
  {
    name: "Name",
  },
  {
    name: "Start Date",
  },
  {
    name: "End Date",
  },
  {
    name: "Status",
  },
  {
    name: "Progress",
  },
];
