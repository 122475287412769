import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fp from "lodash/fp";
import { Fieldset, Spinner, Button, Tab, Tree } from "../common";
import classNames from "classnames";
import { roles } from "../../helpers/userRoles";

import {
  getSubtasks,
  getSubtasksPromise,
  selectTask,
  selectMainTask,
} from "../../actions/tasks";
import {
  getProjectDetail,
  computeProjectPercentage,
} from "../../actions/projects";

import MyTasksInformation from "./MyTasksInformation";
import MyTasksManpower from "./MyTasksManpower";
import MyTasksEquipments from "./MyTasksEquipments";
import MyTasksMaterials from "./MyTasksMaterials";
// import MyTasksCommonMaterials from "./MyTasksCommonMaterials";
import MyTasksAddSubTask from "./MyTasksAddSubTask";
import MyTasksDocumentations from "./MyTasksDocumentations";
import MyTasksToolComponent from "./MyTasksToolComponent";
import swal from "sweetalert";

export default function MyTasksDetail(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const subtasks = useSelector((state) => state.tasks.subtasks);
  const projectTasks = useSelector((state) => state.projects.projectTasks);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const selectedMyTask = useSelector((state) => state.tasks.selectedMyTask);
  const tasksLoading = useSelector((state) => state.tasks.tasksLoading);
  const projectLoading = useSelector((state) => state.projects.isLoading);
  const [activeTab, setActiveTab] = useState("Information");
  const [view, setView] = useState("list");
  const [clicked, setClicked] = useState(1);

  const sortProjectTasksByStartDate = fp.orderBy(
    ["start_date"],
    ["desc"],
    projectTasks
  );

  const sortTasksByStartDate = fp.orderBy(["start_date"], ["desc"], subtasks);

  useEffect(() => {
    if (selectedMyTask.type === "projects") {
      dispatch(getProjectDetail(selectedMyTask.id));
    } else {
      dispatch(getSubtasks(selectedMyTask.id));
    }
  }, [dispatch, selectedMyTask]);

  const handleFetchTreeData = (id) => {
    return new Promise((resolve, reject) => {
      dispatch(getSubtasksPromise(id)).then((res) => {
        resolve(res);
      });
    });
  };

  const handleComputeProjectPercentage = () => {
    swal({
      icon: "warning",
      title:
        "Performing this percentage calculation may take time. Are you sure?",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        console.log(selectedMyTask.type);
        dispatch(
          computeProjectPercentage(
            selectedMyTask.type === "tasks"
              ? selectedMyTask.project_id
              : selectedMyTask.id,
            selectedMyTask.id,
            selectedMyTask.type
          )
        );
      }
    });
  };

  return (
    <div className="flex mt-12 ml-6 mr-6">
      <div className="w-1/4">
        <Fieldset>
          <h1
            className="font-bold hover:text-blue-400 cursor-pointer"
            onClick={() => {
              dispatch(selectTask(selectedMyTask));
              if (selectedMyTask.type === "tasks") {
                dispatch(selectMainTask(selectedMyTask));
              }
              setView("list");
            }}
          >
            {`${selectedMyTask.title} - ${selectedMyTask.percentage.toFixed(
              2
            )}%`}
          </h1>
          <p className="text-gray-600 text-xs">
            Click on the task to view its details.
          </p>
          <p className="text-gray-600 text-xs">
            Percentage (%) in <span className="text-red-600">red colour </span>{" "}
            indicates its distributed percentage to its parent.
          </p>
          <hr />
          {user.employee.role_id !== roles.drftr && (
            <div className="flex mb-4">
              <div className="mt-2">
                <Button
                  primary="true"
                  text="+ Add Zone"
                  onClick={() => {
                    dispatch(selectTask(selectedMyTask));
                    setView("add-main-task");
                  }}
                />
              </div>
              <div className="mt-2">
                <Button
                  danger="true"
                  text="% Compute"
                  onClick={() => {
                    handleComputeProjectPercentage();
                  }}
                />
              </div>
            </div>
          )}

          {(tasksLoading || projectLoading) && <Spinner />}

          {sortProjectTasksByStartDate.map((task, index) => (
            <Tree
              key={index}
              fetchChildNodes={(id) => {
                return handleFetchTreeData(id);
              }}
              node={task}
              onNodeSelected={(node) => {
                setView("list");
                console.log(node.parent_id);
                if (node.parent_id === null) {
                  dispatch(selectMainTask(node));
                }
                dispatch(selectTask(node));
              }}
              onAddChildNode={(node) => {
                console.log(node);
                dispatch(selectTask(node));
                setView("add-sub-task");
                setClicked(index + 1);
              }}
              margin={0}
            />
          ))}

          {sortTasksByStartDate.map((task, index) => (
            <Tree
              key={index}
              fetchChildNodes={(id) => {
                return handleFetchTreeData(id);
              }}
              node={task}
              onNodeSelected={(node) => {
                dispatch(selectTask(node));
                setView("list");
              }}
              onAddChildNode={(node) => {
                console.log(node);
                dispatch(selectTask(node));
                setView("add-sub-task");
                setClicked(index + 1);
              }}
              margin={0}
            />
          ))}
        </Fieldset>
      </div>
      <div className="w-3/4 px-4">
        {view === "list" ? (
          <Fieldset>
            <div className="flex">
              <h2 className="font-bold">
                {selectedTask && selectedTask.heirarchy}
              </h2>
              <div className="ml-4">
                {selectedTask &&
                  selectedTask.type === "tasks" &&
                  (selectedTask.is_parent
                    ? selectedTask.calculated_percentage === 100 &&
                      (selectedTask.is_inspected === 0 ? (
                        <div
                          className={classNames(
                            "text-xs rounded-full px-4 text-white p-2 rounded leading-none items-center bg-red-600"
                          )}
                        >
                          Not yet inspected
                        </div>
                      ) : (
                        <div
                          className={classNames(
                            "text-xs rounded-full px-4 text-white p-2 rounded leading-none items-center bg-green-600"
                          )}
                        >
                          Inspected
                        </div>
                      ))
                    : selectedTask.current_percentage === 100 &&
                      (selectedTask.is_inspected === 0 ? (
                        <div
                          className={classNames(
                            "text-xs rounded-full px-4 text-white p-2 rounded leading-none items-center bg-red-600"
                          )}
                        >
                          Not yet inspected
                        </div>
                      ) : (
                        <div
                          className={classNames(
                            "text-xs rounded-full px-4 text-white p-2 rounded leading-none items-center bg-green-600"
                          )}
                        >
                          Inspected
                        </div>
                      )))}
              </div>
            </div>
            <Tab>
              <Tab.Item
                name="Information"
                isActive={activeTab === "Information"}
                onTabClick={() => {
                  setActiveTab("Information");
                }}
              />
              <Tab.Item
                name="Manpower"
                isActive={activeTab === "Manpower"}
                onTabClick={() => {
                  setActiveTab("Manpower");
                }}
              />
              {user.employee.role_id !== roles.drftr && (
                <>
                  <Tab.Item
                    name="Documentations"
                    isActive={activeTab === "Documentations"}
                    onTabClick={() => {
                      setActiveTab("Documentations");
                    }}
                  />
                  <Tab.Item
                    name="Materials"
                    isActive={activeTab === "Materials"}
                    onTabClick={() => {
                      setActiveTab("Materials");
                    }}
                  />
                  {/* <Tab.Item
                    name="Common Materials"
                    isActive={activeTab === "Common Materials"}
                    onTabClick={() => {
                      setActiveTab("Common Materials");
                    }}
                  /> */}
                  {selectedTask && (
                    <Tab.Item
                      name="Tools"
                      isActive={activeTab === "Tools"}
                      onTabClick={() => {
                        setActiveTab("Tools");
                      }}
                    />
                  )}
                </>
              )}
              {/* {selectedTask && (
                <Tab.Item
                  name="Equipments"
                  isActive={activeTab === "Equipments"}
                  onTabClick={() => {
                    setActiveTab("Equipments");
                  }}
                />
              )}

              <Tab.Item
                name="Safety"
                isActive={activeTab === "Safety"}
                onTabClick={() => {
                  setActiveTab("Safety");
                }}
              /> */}
            </Tab>

            {activeTab === "Information" && (
              <div className="mt-4">
                {view === "list" && <MyTasksInformation />}
                {view === "add-main-task" && <MyTasksInformation />}
              </div>
            )}

            {activeTab === "Manpower" && (
              <div className="mt-4">
                <MyTasksManpower />
              </div>
            )}

            {activeTab === "Documentations" && (
              <div className="mt-4">
                <MyTasksDocumentations />
              </div>
            )}

            {/* {activeTab === "Tools" && (
              <div className="mt-4">
                <MyTasksTools selectedProject={selectedMyTask} />
              </div>
            )} */}

            {activeTab === "Equipments" && (
              <div className="mt-4">
                <MyTasksEquipments selectedProject={selectedMyTask} />
              </div>
            )}

            {activeTab === "Materials" && (
              <div className="mt-4">
                <MyTasksMaterials
                  selectedProject={selectedMyTask}
                  selectedTask={selectedTask}
                />
              </div>
            )}

            {/* {activeTab === "Common Materials" && (
              <div className="mt-4">
                <MyTasksCommonMaterials
                  selectedProject={selectedMyTask}
                  selectedTask={selectedTask}
                />
              </div>
            )} */}

            {activeTab === "Tools" && (
              <div className="mt-4">
                <MyTasksToolComponent
                  selectedProject={selectedMyTask}
                  selectedTask={selectedTask}
                />
              </div>
            )}
          </Fieldset>
        ) : (
          <Fieldset>
            <MyTasksAddSubTask
              operation={view}
              onTaskSubmit={() => {
                setView("list");
              }}
              onTaskDelete={() => {
                setView("list");
              }}
              clicked={clicked}
            />
          </Fieldset>
        )}
      </div>
    </div>
  );
}
