import React from "react";
import {
  EyeIcon,
  PencilIcon,
  DotsVerticalIcon,
  DotsHorizontalIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  HandIcon,
  ScissorsIcon,
} from "@heroicons/react/solid";

import DeniedIcon from "./svg/denied.svg";
import ApprovedIcon from "./svg/approved.svg";
import CancelledIcon from "./svg/cancelled.svg";

export const ViewButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <EyeIcon className="self-end text-center" style={{ width: 20 }} />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const EditButton = ({ title, width, color }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <PencilIcon
      className="self-end text-center"
      style={{ width: width ? width : 20, color: color ? color : "inherit" }}
    />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const OptionButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <DotsVerticalIcon className="self-end" style={{ width: 20 }} />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const ApproveButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <img src={ApprovedIcon} style={{ width: 25 }} alt="" />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const DenyButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <img src={DeniedIcon} style={{ width: 25 }} alt="" />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const CancelButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <img src={CancelledIcon} style={{ width: 25 }} alt="" />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const DotsHorizontalButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <DotsHorizontalIcon className="self-end" style={{ width: 20 }} />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const PlusCircleButton = ({ title }) => (
  <div
    className="flex flex-row justify-end text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <PlusCircleIcon className="self-end text-center" style={{ width: 20 }} />
    <p className="mx-2 self-center" style={{ fontSize: 14 }}>
      {title}
    </p>
  </div>
);

export const MinusCircleButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <MinusCircleIcon
      className="self-end text-red-500 hover:text-red-900"
      style={{ width: 20 }}
    />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const IssueButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <HandIcon className="self-end text-center" style={{ width: 20 }} />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);

export const CutButton = ({ title }) => (
  <div
    className="justify-center text-gray-600 hover:text-gray-900"
    style={{ textAlign: "-webkit-center" }}
  >
    <ScissorsIcon className="self-end text-center" style={{ width: 20 }} />
    <p className="mx-2" style={{ fontSize: 10 }}>
      {title}
    </p>
  </div>
);
