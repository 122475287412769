import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Spinner, Pagination, Label, SortBy } from "../common";
import swal from "sweetalert";
import {
  getRequestPoMaterials,
  selectRequestPoMaterial,
  deleteRequestPoMaterial,
} from "../../actions/requestPoMaterials";
import { getProjects } from "../../actions/projects";
import { roles } from "../../helpers/userRoles";
import MaterialRequestOrderView from "./MaterialRequestOrderView";
import MaterialIssuePOAdd from "./MaterialIssuePOAdd";
import MaterialRequestOrderForm from "./MaterialRequestOrderForm";
import MaterialRequestOrderUpdate from "./MaterialRequestOrderUpdate";
import {
  useTransformOption,
  getDistinctSuppliers,
} from "../../helpers/useTransformData";
import Select from "react-select";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";

export default function MaterialRequestOrderPage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const requestPoMaterials = useSelector(
    (state) => state.requestPoMaterials.requestPoMaterials
  );
  const selectedRequestPo = useSelector(
    (state) => state.requestPoMaterials.selected
  );
  const pagination = useSelector(
    (state) => state.requestPoMaterials.pagination
  );
  const projects = useSelector((state) => state.projects.projects);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const [selectedStatus, setSelectedStatus] = useState({
    value: null,
    label: "All",
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const isLoading = useSelector((state) => state.requestPoMaterials.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewMaterialsModal, setViewMaterialsModal] = useState(false);
  const [viewMaterials, setViewMaterials] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [orderBy, setOrderBy] = useState("desc");
  const [issueOrderModal, setIssueOrderModal] = useState(false);
  const projectsDropdown = useTransformOption(
    [{ id: null, title: "All" }, ...projects],
    "id",
    "title"
  );
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const handleProjectChange = (option) => {
    setSelectedProject(option);
    setPageNumber(1);
  };

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setPageNumber(1);
  };

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch]);

  useEffect(() => {
    if (props.view === "my-tasks") {
      dispatch(
        getRequestPoMaterials(
          pageNumber,
          selectedTask.type === "projects"
            ? selectedTask.id
            : selectedTask.project_id,
          selectedTask.type === "tasks" ? selectedTask.id : null,
          null,
          orderBy
        )
      );
    } else {
      dispatch(
        getRequestPoMaterials(
          pageNumber,
          selectedProject ? selectedProject.value : null,
          null,
          selectedStatus ? selectedStatus.value : null,
          orderBy
        )
      );
    }
  }, [
    props.view,
    selectedTask,
    selectedProject,
    selectedStatus,
    pageNumber,
    orderBy,
    dispatch,
  ]);

  return (
    <div className="mt-4">
      {/* <div className="float-right">
        <Button text={`+ Request Order from Bulk PO`} primary="true" />
        <Button text={`+ Ad-hoc Request Order`} primary="true" />
      </div> */}
      {props.view === "materials" && (
        <div className="flex mb-4">
          <div className="w-2/4">
            <Label name="Project" />
            <Select
              className="text-xs"
              options={projectsDropdown}
              value={selectedProject}
              onChange={handleProjectChange}
            />
          </div>
          <div className="w-2/4 px-4">
            <Label name="Status" />
            <Select
              className="text-xs"
              options={[
                { value: null, label: "All" },
                { value: "Approved2", label: "Approved" },
                { value: "Approved1", label: "Approved on site" },
                { value: "Pending", label: "Pending" },
                { value: "Rejected", label: "Rejected" },
              ]}
              value={selectedStatus}
              onChange={handleStatusChange}
            />
          </div>
        </div>
      )}
      {props.view === "my-tasks" ? (
        <div className="mb-4">
          <div className="flex justify-end">
            <Button
              text={`+ Create New Request Order`}
              primary="true"
              onClick={() => {
                setAddModal(true);
              }}
              disabled={selectedTask.type === "projects"}
            />
          </div>
        </div>
      ) : (
        <div className="mb-4">
          <div className="flex justify-end">
            <p className="text-xs text-gray-600">
              Make a Request Order from My Tasks page.
            </p>
          </div>
        </div>
      )}

      {addModal && (
        <Modal>
          <MaterialRequestOrderForm
            onFormClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {viewModal && (
        <Modal>
          <MaterialRequestOrderView
            onFormClose={() => {
              setViewModal(false);
            }}
            view={props.view}
          />
        </Modal>
      )}

      {editModal && (
        <Modal>
          <MaterialRequestOrderUpdate
            onFormClose={() => {
              setEditModal(false);
            }}
            view={props.view}
          />
        </Modal>
      )}

      {issueOrderModal && (
        <Modal>
          <MaterialIssuePOAdd
            onFormClose={() => {
              setIssueOrderModal(false);
            }}
            selectedSupplierId={selectedSupplierId}
            selectedSupplier={selectedSupplier}
          />
        </Modal>
      )}

      {viewMaterialsModal && (
        <Modal>
          <div>
            <h1 className="font-bold">Materials Requested</h1>
            <p className="text-xs text-gray-600 mb-4">
              These are the materials requested under this Request Order.
            </p>
            <p className="text-xs text-green-600 mb-4">
              Text in green indicates that the material has an Issue PO already.
            </p>

            {viewMaterials.map((material) => (
              <ul
                key={material.id}
                className={classNames(
                  selectedRequestPo.issue_purchase_orders &&
                    selectedRequestPo.issue_purchase_orders.find(
                      (o) => o.supplier_id === material.supplier_id
                    )
                    ? "text-green-900"
                    : "",
                  "list-disc ml-4 text-sm"
                )}
              >
                <li>
                  {material.code} - {material.description} (
                  {material.pivot.order_qty} {material.unit}){" "}
                  {/* <strong>
                    Issue PO:{" "}
                    {selectedRequestPo.issue_purchase_orders &&
                      selectedRequestPo.issue_purchase_orders.find(
                        (o) => o.supplier_id === material.supplier_id
                      ).ref_no}
                  </strong> */}
                </li>
              </ul>
            ))}

            <div className="justify-center flex mt-4 px-4">
              <Button
                text="Close"
                primary="true"
                onClick={() => {
                  setViewMaterialsModal(false);
                }}
              />
            </div>
          </div>
        </Modal>
      )}

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Status</th>
            <th className="text-xs px-4 py-2">Project/Task</th>
            <th className="text-xs px-4 py-2">RO No.</th>
            <th className="text-xs px-4 py-2 flex">
              Requested
              <br />
              Date
              <SortBy
                order={orderBy}
                onOrderChange={(order) => {
                  setOrderBy(order);
                }}
              />
            </th>
            <th className="text-xs px-4 py-2">
              Expected
              <br />
              Delivery Date
            </th>
            <th className="text-xs px-4 py-2">Requested By</th>
            <th className="text-xs px-4 py-2">
              Approved
              <br />
              on site by
            </th>
            <th className="text-xs px-4 py-2">Approved by</th>
            {/* <th className="text-xs px-4 py-2">Materials</th> */}
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            requestPoMaterials.map((requestPoMaterial) => (
              <tr key={requestPoMaterial.id} className="hover:bg-gray-200">
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.status === "Pending" && (
                    <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                      Pending
                    </span>
                  )}

                  {requestPoMaterial.status === "Approved1" && (
                    <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
                      Approved
                      <br />
                      on site
                    </span>
                  )}

                  {requestPoMaterial.status === "Approved2" && (
                    <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                      Approved
                    </span>
                  )}

                  {requestPoMaterial.status === "Approved" && (
                    <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
                      Approved (Old process)
                    </span>
                  )}

                  {requestPoMaterial.status === "Rejected" && (
                    <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                      Rejected
                    </span>
                  )}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.task
                    ? requestPoMaterial.task?.heirarchy
                    : requestPoMaterial.project?.heirarchy}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.series_no}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.created_at}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.delivery_date}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.requested_by ? (
                    <>
                      <div className="flex items-center">
                        <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                          {requestPoMaterial.requested_by.avatar && (
                            <img
                              alt="Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={requestPoMaterial.requested_by.avatar.image}
                            />
                          )}
                        </span>
                        <span className="text-xs ml-2">
                          {requestPoMaterial.requested_by.first_name}{" "}
                          {requestPoMaterial.requested_by.last_name}
                          <br />
                          {requestPoMaterial.requested_by.contact_number}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.approved_on_site_by ? (
                    <>
                      <div className="flex items-center">
                        <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                          {requestPoMaterial.approved_on_site_by.avatar && (
                            <img
                              alt="Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={
                                requestPoMaterial.approved_on_site_by.avatar
                                  .image
                              }
                            />
                          )}
                        </span>
                        <span className="text-xs ml-2">
                          {requestPoMaterial.approved_on_site_by.first_name}{" "}
                          {requestPoMaterial.approved_on_site_by.last_name}
                          <br />
                          {requestPoMaterial.approved_on_site_by.contact_number}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td className="text-xs border px-2 py-2">
                  {requestPoMaterial.approved_by ? (
                    <>
                      <div className="flex items-center">
                        <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                          {requestPoMaterial.approved_by.avatar && (
                            <img
                              alt="Supplier"
                              className="rounded-full border border-gray-100 object-cover"
                              src={requestPoMaterial.approved_by.avatar.image}
                            />
                          )}
                        </span>
                        <span className="text-xs ml-2">
                          {requestPoMaterial.approved_by.first_name}{" "}
                          {requestPoMaterial.approved_by.last_name}
                          <br />
                          {requestPoMaterial.approved_by.contact_number}
                        </span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </td>
                {/* <td className="text-xs border px-2 py-2">
                {requestPoMaterial.materials ? (
                  <div className="flex-initial">
                    <Button
                      text={`${requestPoMaterial.materials.length} materials`}
                      info="true"
                      onClick={() => {
                        dispatch(selectRequestPoMaterial(requestPoMaterial));
                        setViewMaterials(requestPoMaterial.materials);
                        setViewMaterialsModal(true);
                      }}
                    />
                  </div>
                ) : (
                  "-"
                )}
              </td> */}
                <td className="text-xs border px-2 py-2">
                  <div className="flex">
                    <div className="flex-initial">
                      <Button
                        text={`${
                          props.view === "materials" &&
                          requestPoMaterial.status !== "Approved2"
                            ? "Process"
                            : "View"
                        }`}
                        primary="true"
                        onClick={() => {
                          dispatch(selectRequestPoMaterial(requestPoMaterial));
                          setViewModal(true);
                        }}
                      />
                    </div>

                    {props.view === "materials" && (
                      <>
                        {requestPoMaterial.status === "Approved2" && (
                          <>
                            <Menu
                              as="div"
                              className="relative inline-block text-left mr-2"
                            >
                              <div>
                                <Menu.Button className="inline-flex justify-center w-full bg-orange-500 text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline">
                                  Issue PO to
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  className="text-sm origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  style={{ zIndex: 10 }}
                                >
                                  <div className="py-1">
                                    {getDistinctSuppliers(
                                      requestPoMaterial.materials
                                    ).map((supplier, index) => (
                                      <Menu.Item>
                                        {({ active }) => (
                                          <button
                                            type="button"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                            onClick={() => {
                                              setSelectedSupplierId(
                                                supplier.id
                                              );
                                              setSelectedSupplier(supplier);
                                              dispatch(
                                                selectRequestPoMaterial(
                                                  requestPoMaterial
                                                )
                                              );
                                              setIssueOrderModal(true);
                                            }}
                                          >
                                            {supplier?.name}
                                          </button>
                                        )}
                                      </Menu.Item>
                                    ))}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </>
                        )}

                        {user.employee.role_id === roles.su && (
                          <div className="flex-initial">
                            <Button
                              text={`Delete`}
                              danger="true"
                              onClick={() => {
                                swal({
                                  icon: "error",
                                  title: "Are you sure?",
                                  text: `This will delete this Request Purchase Order.`,
                                  buttons: true,
                                }).then(async (willDelete) => {
                                  if (willDelete) {
                                    dispatch(
                                      deleteRequestPoMaterial(
                                        requestPoMaterial.id
                                      )
                                    );
                                  }
                                });
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {(user.employee.role_id === roles.su ||
                      user.employee.role_id === roles.proc ||
                      user.employee.role_id === roles.engr ||
                      user.employee.role_id === roles.pm ||
                      user.employee.role_id === roles.apm ||
                      user.employee.role_id === roles.coord) &&
                      requestPoMaterial.has_deliveries === false && (
                        <div className="flex-initial">
                          <Button
                            text={`Edit`}
                            warning="true"
                            onClick={() => {
                              dispatch(
                                selectRequestPoMaterial(requestPoMaterial)
                              );
                              setEditModal(true);
                            }}
                          />
                        </div>
                      )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}
    </div>
  );
}
