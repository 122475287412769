import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Label } from "../../../components/common";
import ImageUtils from "../../../helpers/ImageUtils";
import ImageUploader from "react-images-upload";
import {
  addSupplier,
  updateSupplier,
  deleteSupplier,
  selectSupplier,
} from "../../../actions/commonSuppliers";
import swal from "sweetalert";

export default function CommonMaterialSupplierForm({ onViewClose }) {
  const dispatch = useDispatch();
  const selectedSupplier = useSelector(
    (state) => state.commonSuppliers.selectedSupplier
  );
  const [name, setName] = useState(null);
  const [address, setAddress] = useState(null);
  const [contactPerson, setContactPerson] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [photo, setPhoto] = useState([]);

  const imageUploadRef = useRef(null);
  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will ${
        selectedSupplier ? "update" : "add to"
      } the list of suppliers.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let tempImg = null;
        let data = {
          name: name,
          address: address,
          contact_person: contactPerson,
          contact_number: contactNumber,
        };

        for (let i = 0; i < photo.length; i++) {
          tempImg = await ImageUtils.resizeImage(photo[i]);
        }

        if (tempImg) {
          data.photo = tempImg;
        }

        if (selectedSupplier) {
          dispatch(updateSupplier(selectedSupplier.id, data));
        } else {
          dispatch(addSupplier(data));
        }
        onViewClose && onViewClose();
      }
    });
  };

  const removeSupplier = (e) => {
    e.preventDefault(e);
    swal({
      icon: "error",
      title: "Are you sure?",
      text: `This will remove the supplier from the list. This action is irreversible.`,
      buttons: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        dispatch(deleteSupplier(selectedSupplier.id));
        dispatch(selectSupplier(null));
        onViewClose && onViewClose();
      }
    });
  };

  useEffect(() => {
    if (selectedSupplier) {
      setName(selectedSupplier.name);
      setAddress(selectedSupplier.address);
      setContactPerson(selectedSupplier.contact_person);
      setContactNumber(selectedSupplier.contact_number);
    }
  }, [selectedSupplier]);

  return (
    <form>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        {selectedSupplier ? "Update" : "Add"} Supplier
      </h1>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Supplier Name" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Address" />
          <textarea
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            value={address}
            rows={5}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Contact Person" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={contactPerson}
            onChange={(e) => {
              setContactPerson(e.target.value);
            }}
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Contact Number" />
          <input
            type="text"
            className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            style={{ border: "1px solid #ccc" }}
            autoComplete="off"
            value={contactNumber}
            onChange={(e) => {
              setContactNumber(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-full">
          <Label name="Photo" />
          {selectedSupplier && (
            <p className="text-gray-600 text-xs">
              Current photo saved. Upload only if you want to change the photo.
            </p>
          )}
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            singleImage={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text={selectedSupplier ? "Update" : "Save"}
          success="true"
          onClick={(e) => {
            onSubmit(e);
          }}
        />
        {selectedSupplier && (
          <Button
            text={"Delete"}
            danger="true"
            onClick={(e) => {
              removeSupplier(e);
            }}
          />
        )}
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            onViewClose && onViewClose();
          }}
        />
      </div>
    </form>
  );
}
