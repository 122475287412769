/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { addDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/FileUpload.css";

const MAX_COUNT = 10;

export default function MyTasksAddCorrespondence(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  const emailInputRef = useRef();
  const [selectedEmail, setSelectedEmail] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);

  const handleEmailChange = (e) => {
    setSelectedEmail(emailInputRef.current.files[0]);
  };

  useEffect(() => {
    register("date");
    setValue("date", moment(new Date()).format("YYYY-MM-DD").toString());
  }, [register]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleAttachmentsEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleRemoveAttachment = (index) => {
    let uploaded = [...uploadedFiles];
    uploaded.splice(index, 1);
    setUploadedFiles(uploaded);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "This will add Correspondence to the Task.",
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let email = null;
        if (selectedEmail) {
          email = await ImageUtils.fileToBase64(selectedEmail);
        }
        let attachments = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
          attachments.push(await ImageUtils.fileToBase64(uploadedFiles[i]));
          //   attachments.push(uploadedFiles[i].file);
        }
        data.base64image = [email];
        data.base64imageRemarks = attachments;
        data.type = "correspondence";

        dispatch(
          addDocumentation(selectedTask.id, "tasks", data, selectedTask)
        );
        props.onTaskSubmit && props.onTaskSubmit();
        e.target.reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Add Correspondence</h1>
      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="E-mail From" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-2 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="email_from"
              type="text"
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Subject" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-2 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subject"
              type="text"
              required
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="Upload E-mail Message" />
          <input
            ref={emailInputRef}
            type="file"
            onChange={(e) => {
              handleEmailChange(e);
            }}
          />
        </div>
        <div className="mt-4">
          <Label name="Upload Attachments" />
          <input
            type="file"
            id="fileUpload"
            multiple
            onChange={handleAttachmentsEvent}
          />

          <label htmlFor="fileUpload">
            <a className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline">
              Upload Files
            </a>
          </label>

          <div className="uploaded-files-list">
            {uploadedFiles.map((file, index) => (
              <div className="flex justify-between bg-gray-200 p-2 mt-4 text-xs">
                <div>{file.name}</div>
                <Button
                  danger="true"
                  text="-"
                  onClick={() => {
                    handleRemoveAttachment(index);
                  }}
                />
              </div>
            ))}
          </div>

          <div className="flex mt-4">
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                props.onTaskSubmit && props.onTaskSubmit();
              }}
            />
            <Button text="Save" success="true" type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
}
