import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { updateDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";

export default function EditSDICVI(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    register("date");
    setValue(
      "date",
      moment(selectedDocument.date).format("YYYY-MM-DD").toString()
    );
  }, [register, setValue, selectedDocument]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onUpdate = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update SDI/CVI.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        if (file) {
          data.base64image = [file];
        }

        dispatch(updateDocumentation(selectedDocument.id, data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="Ref No" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ref_no"
              key={selectedDocument.ref_no}
              defaultValue={selectedDocument.ref_no}
              type="text"
              required
            />
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="Subject" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subject"
              key={selectedDocument.subject}
              defaultValue={selectedDocument.subject}
              type="text"
              required
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="SDI/CVI" />
            <div className="mb-4">
              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="si"
                className="h-5 w-5 text-gray-600"
                defaultChecked={selectedDocument.si_sd === "si"}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">SI</span>

              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="sd"
                className="h-5 w-5 text-gray-600"
                defaultChecked={selectedDocument.si_sd === "sd"}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">SD</span>

              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="cvi"
                className="h-5 w-5 text-gray-600"
                defaultChecked={selectedDocument.si_sd === "cvi"}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">CVI</span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
              key={selectedDocument.comments}
              defaultValue={selectedDocument.comments}
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="File" />
          {selectedDocument.files.length > 0 && (
            <p>{`${selectedDocument.files.length} file/s uploaded. Re-attaching file/s would overwrite current uploaded file/s`}</p>
          )}
          <input
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>
      </div>
      <Button text="Update" success="true" type="submit" />
      <Button
        text="Close"
        primary="true"
        // disabled={isFormLoading}
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
