import {
  GET_USERS,
  LOADING_USERS,
  SELECT_USER,
  DELETE_USER,
  ADD_USER,
} from "../actions/types";

const initialState = {
  users: [],
  pagination: null,
  usersLoading: false,
  selectedUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_USERS:
      return {
        ...state,
        usersLoading: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        pagination: action.pagination,
        usersLoading: false,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        usersLoading: false,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
        usersLoading: false,
      };
    default:
      return state;
  }
}
