import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tab } from "../common";
import MyTasksToolRequestForm from "./MyTasksToolRequestForm";
import MyTasksToolList from "./MyTasksToolList";

function MyTasksToolComponent(props) {
  const user = useSelector((state) => state.auth.user);
  const { selectedProject } = props;
  const { id } = selectedProject;
  const { employee_id } = user;
  const [activeTab, setActiveTab] = useState("List");

  return (
    <div>
      <h1 className="font-bold">Tools</h1>
      <p className="text-xs text-gray-600 mb-4">
        under {selectedProject.title}
      </p>
      <div className="mb-4">
        <Tab>
          <Tab.Item
            name="List"
            isActive={activeTab === "List"}
            onTabClick={() => {
              setActiveTab("List");
            }}
          />
          <Tab.Item
            name="Request for Tools"
            isActive={activeTab === "Request for Tools"}
            onTabClick={() => {
              setActiveTab("Request for Tools");
            }}
          />
        </Tab>
      </div>

      <div className="flex">
        <div className="w-full">
          {activeTab === "List" && <MyTasksToolList />}
          {activeTab === "Request for Tools" && (
            <MyTasksToolRequestForm projectId={id} employeeId={employee_id} />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyTasksToolComponent;
