import { SET_ACTIVE_SIDEBAR_NAV, TOGGLE_SIDEBAR } from "../actions/types";

const initialState = {
  activeSidebarNav: "My Tasks",
  navbarTitle: "My Tasks",
  sidebarVisible: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    case SET_ACTIVE_SIDEBAR_NAV:
      return {
        ...state,
        activeSidebarNav: action.payload,
        navbarTitle: action.payload,
      };
    default:
      return state;
  }
}
