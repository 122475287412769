import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_ALLOCATED_MATERIALS,
  GET_FOR_ALLOCATE_MATERIALS,
  LOADING_ALLOCATE_MATERIALS,
  SELECT_ALLOCATED_MATERIAL,
  SELECT_FOR_ALLOCATE_MATERIAL,
  ALLOCATE_MATERIAL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET FOR ALLOCATE MATERIALS
export const getForAllocateMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_ALLOCATE_MATERIALS });

  let endpoint = `/common-material-requests?page=${page}&status=Approved`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_FOR_ALLOCATE_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// GET ALLOCATED MATERIALS
export const getAllocatedMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_ALLOCATE_MATERIALS });

  let endpoint = `/common-material-requests?page=${page}&status=Allocated`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_ALLOCATED_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT FOR ALLOCATE MATERIAL
export const selectForAllocateMaterial =
  (forAllocateMaterial) => (dispatch) => {
    dispatch({
      type: SELECT_FOR_ALLOCATE_MATERIAL,
      payload: forAllocateMaterial,
    });
  };

// SELECT ALLOCATED MATERIAL
export const selectAllocatedMaterial = (allocatedMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_ALLOCATED_MATERIAL,
    payload: allocatedMaterial,
  });
};

// ALLOCATE MATERIAL
export const allocateMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/common-material-requests/allocate`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Materials now allocated!",
        icon: "success",
      });
      dispatch({ type: ALLOCATE_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};
