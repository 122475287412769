import {
  GET_ISSUE_MATERIALS,
  GET_ISSUE_PO_RUNNING_NUMBER,
  LOADING_ISSUE_MATERIALS,
  SELECT_ISSUE_MATERIAL,
  ISSUE_MATERIAL,
  DELETE_ISSUE_MATERIAL,
  UPDATE_ISSUE_MATERIAL,
} from "../actions/types";

const initialState = {
  issuePoMaterials: [],
  runningNumber: "",
  pagination: null,
  selected: null,
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ISSUE_MATERIALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ISSUE_MATERIALS:
      return {
        ...state,
        issuePoMaterials: action.payload,
        pagination: action.pagination,
        isLoading: false,
      };
    case GET_ISSUE_PO_RUNNING_NUMBER:
      return {
        ...state,
        runningNumber: action.payload,
      };
    case SELECT_ISSUE_MATERIAL:
      return {
        ...state,
        selected: action.payload,
      };
    case ISSUE_MATERIAL:
      return {
        ...state,
        issuePoMaterials: [...state.issuePoMaterials, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
      };
    case UPDATE_ISSUE_MATERIAL:
      return {
        ...state,
        selected: action.payload,
        issuePoMaterials: state.issuePoMaterials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    case DELETE_ISSUE_MATERIAL:
      return {
        ...state,
        issuePoMaterials: state.issuePoMaterials.filter(
          (material) => material.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
