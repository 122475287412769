import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Label, Button, Typography } from "../common";
import { roles } from "../../helpers/userRoles";
import { updateWithdrawalRequestStatus } from "../../actions/materialWithdrawals";
import swal from "sweetalert";

export default function MaterialWithdrawalView(props) {
  const dispatch = useDispatch();
  const selectedWithdrawalRequest = useSelector(
    (state) => state.materialWithdrawals.selectedWithdrawalRequest
  );
  const user = useSelector((state) => state.auth.user);
  const [comment, setComment] = useState("");

  const processRequest = (action) => {
    swal({
      title: "Are you sure?",
      text: `Process this request as ${action}?`,
      buttons: true,
      icon: "warning",
    }).then((willProcess) => {
      if (willProcess) {
        let data = {
          id: selectedWithdrawalRequest.id,
          status: action,
        };
        if (action === "Approved") {
          data.approved_by = user.employee.id;
          data.approved_comments = comment;
        }

        if (action === "Rejected") {
          data.rejected_by = user.employee.id;
          data.rejected_comments = comment;
        }

        dispatch(updateWithdrawalRequestStatus(data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <div>
      <Typography type="h1">View Withdrawal Request</Typography>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Project/Task" />
          <p className="text-xs">
            {selectedWithdrawalRequest.task
              ? selectedWithdrawalRequest.task.heirarchy
              : "-"}
          </p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Requested by" />
          <p className="text-xs">
            {selectedWithdrawalRequest.requested_by &&
              `${selectedWithdrawalRequest.requested_by.first_name} ${selectedWithdrawalRequest.requested_by.last_name}`}
          </p>
        </div>
        <div className="w-1/2 px-4">
          <Label name="Request Date" />
          <p className="text-xs">{selectedWithdrawalRequest.date}</p>
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Status" />
          {selectedWithdrawalRequest.status === "Pending" && (
            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-blue-600 rounded-full">
              Pending
            </span>
          )}

          {selectedWithdrawalRequest.status === "Approved" && (
            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-green-600 rounded-full">
              Approved
            </span>
          )}

          {selectedWithdrawalRequest.status === "Rejected" && (
            <span className="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
              Rejected
            </span>
          )}
        </div>
        {selectedWithdrawalRequest.status === "Approved" && (
          <div className="w-1/2 px-4">
            <Label name="Approved by" />
            <p className="text-xs">
              {selectedWithdrawalRequest.approved_by &&
                `${selectedWithdrawalRequest.approved_by.first_name} ${selectedWithdrawalRequest.approved_by.last_name}`}
            </p>
            <p className="text-xs">
              {selectedWithdrawalRequest.approved_comments}
            </p>
          </div>
        )}

        {selectedWithdrawalRequest.status === "Rejected" && (
          <div className="w-1/2 px-4">
            <Label name="Rejected by" />
            <p className="text-xs">
              {selectedWithdrawalRequest.rejected_by &&
                `${selectedWithdrawalRequest.rejected_by.first_name} ${selectedWithdrawalRequest.rejected_by.last_name}`}
            </p>
            <p className="text-xs">
              {selectedWithdrawalRequest.rejected_comments}
            </p>
          </div>
        )}
      </div>

      <Typography type="h1">Materials to Withdraw</Typography>
      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            {/* <th className="text-xs px-4 py-2">Price</th> */}
            <th className="text-xs px-4 py-2">Withdraw Qty</th>
          </tr>
        </thead>
        <tbody>
          {selectedWithdrawalRequest.materials &&
            selectedWithdrawalRequest.materials.map((material, index) => (
              <tr key={index} className="hover:bg-gray-200 ">
                <td className="text-xs border px-2 py-2">
                  {material.category ? material.category.name : "Uncategorized"}
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                {/* <td className="text-xs border px-2 py-2">${material.price}</td> */}
                <td className="text-xs border px-2 py-2">
                  {material.pivot.withdraw_qty}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {selectedWithdrawalRequest.status !== "Approved" && (
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Comments (if any)" />
            <input
              type="text"
              autoComplete="off"
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="mb-4">
        {(user.employee.role_id === roles.coord ||
          user.employee.role_id === roles.su ||
          user.employee.role_id === roles.engr) &&
          selectedWithdrawalRequest.status !== "Approved" && (
            <>
              <Button
                success="true"
                text="Approve"
                onClick={() => {
                  processRequest("Approved");
                }}
              />

              <Button
                danger="true"
                text="Reject"
                onClick={() => {
                  processRequest("Rejected");
                }}
              />
            </>
          )}
        <Button
          primary="true"
          text="Close"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
