import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../../common";
import moment from "moment";
import swal from "sweetalert";
import { addIssuePoMaterial } from "../../../actions/commonIssuePoMaterials";
import { getCommonMaterialCategories } from "../../../actions/commonMaterialCategories";
import { getWarehouses } from "../../../actions/warehouses";
import { searchMaterials } from "../../../actions/materials";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { searchEmployees } from "../../../actions/employees";
import { getCompanies } from "../../../actions/companies";
import { getSuppliers } from "../../../actions/commonSuppliers";
import { useTransformOption } from "../../../helpers/useTransformData";

export default function CommonMaterialIssuePOAdd(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.companies);
  const [date, setDate] = useState(new Date());
  const [documentReference, setDocumentReference] = useState({
    label: "Quotation",
    value: "Quotation",
  });
  const warehouses = useSelector((state) => state.warehouses.warehouses);
  const suppliers = useSelector((state) => state.commonSuppliers.suppliers);
  const suppliersDropdown = useTransformOption(suppliers);
  const warehousesDropdown = useTransformOption(warehouses);
  const documentReferences = [
    {
      label: "Quotation",
      value: "Quotation",
    },
    { label: "Others", value: "Others" },
  ];
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [shipTo, setShipTo] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const user = useSelector((state) => state.auth.user);
  let companiesDropdown = [];
  for (let i in companies) {
    let companyDropdownObj = {
      value: companies[i].id,
      label: companies[i].name,
    };

    companiesDropdown.push(companyDropdownObj);
  }
  const categories = useSelector(
    (state) => state.commonMaterialCategories.commonMaterialCategories
  );
  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleShipToChange = (option) => {
    setShipTo(option);
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
  };

  const loadRecipientOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchEmployees(inputValue, "")).then((res) => {
        resolve(res.data);
      });
    });

    let recipientOptions = [];

    for (let i in search) {
      recipientOptions.push({
        value: search[i].id,
        label: `${search[i].first_name} ${search[i].last_name}`,
      });
    }

    callback(recipientOptions);
  };

  const loadMaterialOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(searchMaterials(inputValue, "common")).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        value: search[i].id,
        label: `${search[i].code}`,
        material: search[i],
      });
    }
    callback(options);
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  const handleDocumentReferenceDropdown = (option) => {
    setDocumentReference(option);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will issue the selected purchase order.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        data.issued_by = user.employee.id;
        data.ref = documentReference.value;
        data.date = moment(date).format("YYYY-MM-DD").toString();
        data.materials = materials;
        data.common_supplier_id = selectedSupplier.value;
        data.company_id = selectedCompany ? selectedCompany.value : null;
        data.ship_to_id = shipTo.value;
        dispatch(addIssuePoMaterial(data));
        props.onFormClose && props.onFormClose();
        e.target.reset();
      }
    });
  };

  useEffect(() => {
    dispatch(getCommonMaterialCategories());
    dispatch(getWarehouses());
    dispatch(getSuppliers());
    dispatch(getCompanies());
  }, [dispatch]);

  const [materials, setMaterials] = useState([]);
  // Material fields
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [common_issue_po_qty, setCommonIssuePoQty] = useState("");

  let categoriesDropdown = [];

  for (let i in categories) {
    let categoryDropdownObj = {
      value: categories[i].id,
      label: categories[i].name,
    };

    categoriesDropdown.push(categoryDropdownObj);
  }

  const addMaterial = () => {
    let material = {
      id: materials.length + 1,
      common_material_category_id: selectedCategory.value,
      code: code,
      description: description,
      unit: unit,
      common_issue_po_qty: common_issue_po_qty,
      price: price,
      warehouse_id: selectedWarehouse.value,
    };

    setMaterials([...materials, material]);

    setCode("");
    setDescription("");
    setUnit("");
    setPrice("");
  };

  const removeMaterial = (material) => {
    setMaterials(materials.filter((ma) => ma.id !== material.id));
  };

  const handleCodeChange = (option) => {
    setCode(option.label);
    if (!option.__isNew__) {
      //Set existing values.
      setDescription(option.material.description);
      setUnit(option.material.unit);
      setPrice(option.material.price);
      if (option.material.common_category) {
        setSelectedCategory({
          label: option.material.common_category.name,
          value: option.material.common_category.id,
        });
      }
    }
  };

  //Set it before initializing component to prevent null errors.
  setValue("date", moment(new Date()).format("YYYY-MM-DD").toString());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Issue Purchase Order (Common Materials)</h1>
      <p className="text-xs text-gray-600 mb-4">
        Details here will be generated into PDF for issuance to supplier.
      </p>

      <div className="grid">
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Company" />
            <Select
              className="text-xs"
              options={companiesDropdown}
              value={selectedCompany}
              onChange={handleCompanyChange}
            />
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="PO No." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="po_no"
              type="text"
              // defaultValue={`BPO-${selectedTask.code}-${String(
              //   issuePoMaterials.length + 1
              // ).padStart(2, "0")}`}
              required
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Issue Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Document Reference" />
            <Select
              className="text-xs"
              options={documentReferences}
              value={documentReference}
              onChange={handleDocumentReferenceDropdown}
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Ref #" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ref_no"
              type="text"
              required
            />
          </div>
        </div>

        <div className="flex mt-4 mb-4">
          <div className="w-1/3">
            <Label name="Supplier" />
            <Select
              className="text-xs shadow-sm"
              options={suppliersDropdown}
              value={selectedSupplier}
              onChange={handleSupplierChange}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Payment Term" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="payment_term"
              type="text"
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="comments"
              type="text"
            />
          </div>
        </div>

        {/* <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Warehouse/Site" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ship_to_address"
              type="text"
            />
          </div>
        </div> */}

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Place of Delivery" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="place_of_delivery"
              type="text"
            />
          </div>
          <div className="w-1/2 px-4">
            <Label name="Person to Contact" />
            <AsyncSelect
              loadOptions={loadRecipientOptions}
              value={shipTo}
              onChange={handleShipToChange}
              className="text-xs shadow-sm"
            />
          </div>
        </div>
      </div>

      <div className="grid bg-gray-200 p-4 mt-4">
        <p className="text-sm font-bold text-gray-600 mb-4">
          + Add Materials to Issue Purchase Order
        </p>
        <div className="flex">
          <div className="w-1/3">
            <Label name="Category" />
            <Select
              className="text-xs"
              options={categoriesDropdown}
              value={selectedCategory}
              onChange={setSelectedCategory}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Code" />
            <AsyncCreatableSelect
              cacheOptions
              defaultOptions
              loadOptions={loadMaterialOptions}
              onChange={handleCodeChange}
              className="text-xs shadow-sm"
            />
            {/* <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            /> */}
          </div>

          <div className="w-1/3 px-4">
            <Label name="Description (optional)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="description"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="flex mt-4 mb-4">
          <div className="w-1/4">
            <Label name="Unit" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="unit"
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Price (per unit)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="price"
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Qty." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="common_issue_po_qty"
              type="text"
              value={common_issue_po_qty}
              onChange={(e) => setCommonIssuePoQty(e.target.value)}
            />
          </div>

          <div className="w-1/4 px-4">
            <Label name="Warehouse" />
            <Select
              className="text-xs"
              options={warehousesDropdown}
              value={selectedWarehouse}
              onChange={setSelectedWarehouse}
            />
          </div>
        </div>

        <Button
          text={`+ Add Material`}
          primary="true"
          onClick={() => {
            addMaterial();
          }}
        />

        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">
                Price
                <br />
                (per unit)
              </th>
              <th className="text-xs px-4 py-2">Qty</th>
              <th className="text-xs px-4 py-2">Warehouse</th>
              <th className="text-xs px-4 py-2">
                <i className="far fa-trash text-gray-500 text-base"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">
                  {
                    categoriesDropdown.find(
                      (x) => x.value === material.common_material_category_id
                    ).label
                  }
                </td>
                <td className="text-xs border px-2 py-2">{material.code}</td>
                <td className="text-xs border px-2 py-2">
                  {material.description}
                </td>
                <td className="text-xs border px-2 py-2">{material.unit}</td>
                <td className="text-xs border px-2 py-2">${material.price}</td>
                <td className="text-xs border px-2 py-2">
                  {material.common_issue_po_qty}
                </td>
                <td className="text-xs border px-2 py-2">
                  {material.warehouse ? material.warehouse.name : ""}
                </td>
                <td>
                  <Button
                    text="-"
                    danger="true"
                    onClick={() => {
                      removeMaterial(material);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button text="Issue Purchase Order" success="true" type="submit" />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose && props.onFormClose();
          }}
        />
      </div>
    </form>
  );
}
