import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { Fieldset } from "../common";

import { getScrappedEquipments } from "../../actions/equipments";

export default function EquipmentScrappedPage(props) {
  const dispatch = useDispatch();
  const scrappedEquipments = useSelector(
    (state) => state.equipments.scrappedEquipments
  );
  const equipmentsLoading = useSelector(
    (state) => state.equipments.equipmentsLoading
  );

  useEffect(() => {
    dispatch(getScrappedEquipments());
  }, [dispatch]);

  return (
    <div className="flex">
      <div className="w-2/3">
        <Fieldset>
          <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
            Scrapped Equipments
          </h1>

          <table className="table-auto ml-6 mt-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Equipment</th>
                <th className="text-xs px-4 py-2">S/N</th>
                <th className="text-xs px-4 py-2">Date Scrapped</th>
                <th className="text-xs px-4 py-2">Scrapped to</th>
              </tr>
            </thead>
            <tbody>
              {!equipmentsLoading &&
                scrappedEquipments.map((scrappedEquipment) => (
                  <tr
                    key={scrappedEquipment.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer")}
                  >
                    <td className="text-xs border px-2 py-2">{`${scrappedEquipment.brand} (${scrappedEquipment.description})`}</td>
                    <td className="text-xs border px-2 py-2">
                      {scrappedEquipment.serial_no}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {scrappedEquipment.latest_movement &&
                        moment(scrappedEquipment.latest_movement.movement.date)
                          .format("MMM DD Y")
                          .toString()}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {scrappedEquipment.latest_movement &&
                        scrappedEquipment.latest_movement.movement.extra}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Fieldset>
      </div>
    </div>
  );
}
