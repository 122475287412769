export default function () {
  let earliestYear = new Date().getFullYear() - 2;
  let nextYear = new Date().getFullYear() + 1;

  let yearsDropdown = [];

  while (nextYear >= earliestYear) {
    let yearOption = { value: nextYear, label: nextYear };
    yearsDropdown.push(yearOption);
    nextYear -= 1;
  }

  return yearsDropdown;
}
