import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "../common";
import styled from "styled-components";
import swal from "sweetalert";
import Select from "react-select";
import { toolConditionOptions } from "../../constants/tools";
import { returnTool } from "../../actions/tools";
import moment from "moment";

function ToolReturnForm(props) {
  const { projectId } = props;

  const dispatch = useDispatch();
  const selectedTool = useSelector((state) => state.tools.selectedTool);

  const [comments, setComments] = useState("");
  const [selectedCondition, setSelectedCondition] = useState(null);

  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };

  const handleConditionChange = (option) => {
    setSelectedCondition(option);
  };

  const onSubmit = async () => {
    // body file
    let body = {
      project_id: projectId,
      date: moment().format("Y-MM-DD").toString(),
      comments: comments,
      items: [{ id: selectedTool.id, condition: selectedCondition.value }],
    };

    if (selectedTool.latest_movement) {
      body.movement_id = selectedTool.latest_movement.tool_movement_id;
    }

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "Returning this tool will unassign this from Project.",
      buttons: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(returnTool(body, selectedTool.id));
      }
    });
  };

  return (
    <S.ToolReturnForm>
      <div className="flex flex-col w-full">
        <Typography type="h1">Return Tool</Typography>
        <div className="my-1">
          <Typography type="p">Set Condition</Typography>
          <Select
            className="text-sm shadow-sm"
            options={toolConditionOptions}
            onChange={handleConditionChange}
            value={selectedCondition}
          />
        </div>

        <div className="my-1">
          <Typography type="p">Comments</Typography>
          <input
            type="text"
            className="text-xs shadow-sm border border-gray-400 border-opacity-5 rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
            autoComplete="off"
            name="comments"
            onChange={handleCommentsChange}
            value={comments}
          />
        </div>
        <Button
          onClick={() => {
            onSubmit();
          }}
          className="bg-green-500 hover:bg-green-700 text-xs text-white font-bold py-2 mt-3 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          text="Submit"
        />
      </div>
    </S.ToolReturnForm>
  );
}

const S = {};

S.ToolReturnForm = styled.div`
  .react-datepicker__input-container input {
    width: 100%;
  }
`;

export default ToolReturnForm;
