import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { getMyTasks, selectMyTask, selectTask } from "../../actions/tasks";
import EnhancedTableHead from "./EnhanceTableHead";
import classNames from "classnames";
import { Spinner } from "../common";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  tableFixed: {
    inWidth: 650,
    width: "100%",
    border: 0.5,
    borderColor: "#E2E8F1",
    borderStyle: "solid",
  },
}));

const MyAssignedTasks = ({ data }) => {
  const classes = useStyles();
  const tasks = useSelector((state) => state.tasks.tasks);
  const tasksLoading = useSelector((state) => state.tasks.tasksLoading);
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");

  useEffect(() => {
    dispatch(getMyTasks());
  }, [dispatch]);

  const onGoingTasks = tasks.filter((task) => task.percentage !== 100);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const headCells = [
    { id: "title", numeric: false, label: "Name" },
    { id: "start_date", numeric: false, label: "Start Date" },
    { id: "end_date", numeric: false, label: "End Date" },
    { id: "status", numeric: false, label: "Status" },
    { id: "percentage", numeric: true, label: "Process" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <React.Fragment>
      <h1 className="font-bold text-gray-600 mb-4">Assigned Project/Tasks</h1>
      <TableContainer>
        <Table className={classes.tableFixed}>
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={onGoingTasks.length}
          />
          <TableBody>
            {stableSort(onGoingTasks, getComparator(order, orderBy)).map(
              (row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    style={{ minHeight: 5, maxHeight: 6 }}
                    onClick={() => {
                      data.history.push(`/projects/${row.id}`);
                      dispatch(selectMyTask(row));
                      dispatch(selectTask(row));
                    }}
                  >
                    <TableCell component="th">{row.title}</TableCell>
                    <TableCell align="center">
                      {moment(row.start_date).format("Y/MM/DD").toString()}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.end_date).format("Y/MM/DD").toString()}
                    </TableCell>
                    <TableCell align="center" style={{ width: "20%" }}>
                      <div
                        className={classNames(
                          "flex flex-col rounded-full px-4 text-white p-2 rounded leading-none items-center",
                          {
                            "bg-blue-600": row.percentage > 0,
                            "bg-green-600": row.percentage === 100,
                            "bg-teal-400": row.percentage === 0,
                          }
                        )}
                      >
                        {row.percentage > 0 &&
                          row.percentage < 100 &&
                          "In Progress"}
                        {row.percentage === 0 && "New"}
                        {row.percentage === 100 && "Completed"}
                      </div>
                    </TableCell>
                    <TableCell align="center" style={{ width: "20%" }}>
                      <div className="w-full">
                        <div className="shadow w-full bg-grey-900 mt-2">
                          <div
                            className="bg-teal-200 text-xs leading-none py-1 text-center"
                            style={{ width: `${row.percentage}%` }}
                          >
                            {row.percentage}%
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {tasksLoading && <Spinner />}
    </React.Fragment>
  );
};

export default MyAssignedTasks;
