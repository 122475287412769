import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, Spinner } from "../common";
import { getPurchasedMaterialsTotal } from "../../actions/materials";

export default function MaterialPurchasedTotal(props) {
  const dispatch = useDispatch();
  const purchasedMaterialsTotal = useSelector(
    (state) => state.materials.purchasedMaterialsTotal
  );
  const purchasedMaterialsTotalLoading = useSelector(
    (state) => state.materials.purchasedMaterialsTotalLoading
  );
  const [year, setYear] = useState(new Date().getFullYear().toString());

  useEffect(() => {
    dispatch(getPurchasedMaterialsTotal(year, props.projectId));
  }, [dispatch, year, props.projectId]);

  return (
    <>
      {purchasedMaterialsTotalLoading ? (
        <>
          <>
            <Spinner />
            <p className="text-gray-600 text-sm mb-4 text-center">
              Loading Total Purchased Amount...
            </p>
          </>
        </>
      ) : (
        <>
          <Label name="Materials Purchased Amount Total" />
          <div className="flex items-center justify-center mb-4">
            <Button
              text={"<"}
              onClick={() => {
                setYear(year - 1);
              }}
              primary="true"
            />
            <h3 className="mr-4 ml-2">
              <strong>{year}</strong>
            </h3>
            <Button
              text={">"}
              onClick={() => {
                setYear(year + 1);
              }}
              primary="true"
            />
          </div>
          <div className="flex">
            <table>
              <tbody>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">January</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["01"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">February</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["02"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">March</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["03"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">April</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["04"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">May</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["05"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">June</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["06"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-sm">
                  <td className="px-4">
                    <strong>Total</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">July</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["07"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">August</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["08"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">September</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["09"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">October</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["10"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">November</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["11"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-xs">
                  <td className="px-4">December</td>
                  <td align="right">
                    ${purchasedMaterialsTotal.months["12"].toFixed(3)}
                  </td>
                </tr>
                <tr className="text-gray-900 text-sm">
                  <td className="px-4"></td>
                  <td align="right">
                    ${purchasedMaterialsTotal.total_purchased.toFixed(3)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="justify-center flex mt-4 px-4">
            <Button
              text="Close"
              primary="true"
              onClick={() => {
                props.onViewClose && props.onViewClose();
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
