import {
  GET_EMPLOYEES,
  LOADING_EMPLOYEES,
  SELECT_EMPLOYEE,
  ADD_EMPLOYEE,
  EMPLOYEE_DROPDOWN,
  TEMP_EMPLOYEES,
  GET_LEAVE_CREDITS,
  EMPLOYEE_UPDATE_SCREEN,
} from "../actions/types";

const initialState = {
  employees: [],
  employeeDropdown: [],
  pagination: null,
  employeesLoading: false,
  isUpdateEmployeeScreen: false,
  selectedEmployee: null,
  selectedEmployeeLeaveCredits: null,
  tempEmployees: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_EMPLOYEES:
      return {
        ...state,
        employeesLoading: true,
      };
    case EMPLOYEE_UPDATE_SCREEN:
      return {
        ...state,
        isUpdateEmployeeScreen: !state.isUpdateEmployeeScreen,
      };
    case EMPLOYEE_DROPDOWN:
      return {
        ...state,
        employeeDropdown: action.payload,
      };
    case TEMP_EMPLOYEES:
      return {
        ...state,
        tempEmployees: action.payload,
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
        pagination: action.pagination,
        employeesLoading: false,
      };
    case SELECT_EMPLOYEE:
      return {
        ...state,
        selectedEmployee: action.payload,
      };
    case ADD_EMPLOYEE:
      return {
        ...state,
        employees: [...state.employees, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        employeesLoading: false,
      };
    case GET_LEAVE_CREDITS:
      return {
        ...state,
        selectedEmployeeLeaveCredits: action.payload,
      };
    default:
      return state;
  }
}
