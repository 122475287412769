import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_EQUIPMENTS,
  GET_EQUIPMENTS,
  GET_EQUIPMENT_FILTERS,
  SELECT_EQUIPMENT,
  APPROVE_EQUIPMENT_REQUEST,
  DENY_EQUIPMENT_REQUEST,
  SELECT_EQUIPMENT_REQUEST,
  SELECT_SERVICED_EQUIPMENT,
  GET_EQUIPMENT_REQUESTS,
  GET_SCRAPPED_EQUIPMENTS,
  GET_SERVICED_EQUIPMENTS,
  GET_EQUIPMENT_CATEGORIES,
  ADD_EQUIPMENT_CATEGORY,
  UPDATE_EQUIPMENT_CATEGORY,
  DELETE_EQUIPMENT_CATEGORY,
  SELECT_EQUIPMENT_CATEGORY,
  ADD_EQUIPMENT,
  UPDATE_EQUIPMENT,
  DELETE_EQUIPMENT,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET EQUIPMENTS
export const getEquipments = (
  search_term,
  page,
  company = "",
  project = "",
  condition = "",
  category = ""
) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .get(
      BASE_URL +
        `/equipments?search_term=${search_term}&company=${company}&project=${project}&condition=${condition}&category=${category}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_EQUIPMENTS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// GET EQUIPMENT FILTERS
export const getEquipmentFilters = () => (dispatch, getState) => {
  axios
    .get(
      BASE_URL +
        `/equipments/get?companies=true&projects=true&employees=true&categories=true&drivers=true`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_EQUIPMENT_FILTERS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// SELECT EQUIPMENT
export const selectEquipment = (equipment) => (dispatch) => {
  dispatch({
    type: SELECT_EQUIPMENT,
    payload: equipment,
  });
};

// GET EQUIPMENT REQUESTS
export const getEquipmentRequests = (status, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .get(
      BASE_URL + `/equipments/requests?status=${status}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_EQUIPMENT_REQUESTS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT EQUIPMENT REQUEST
export const selectEquipmentRequest = (equipmentRequest) => (dispatch) => {
  dispatch({
    type: SELECT_EQUIPMENT_REQUEST,
    payload: equipmentRequest,
  });
};

// APPROVE EQUIPMENT REQUEST
export const approveEquipmentRequest = (requestId, comments) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_EQUIPMENTS });

  const payload = new FormData();
  payload.append("request_id", requestId);
  payload.append("comments", comments);

  axios
    .post(
      BASE_URL + `/equipments/approve-request`,
      payload,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: APPROVE_EQUIPMENT_REQUEST,
        payload: {
          id: requestId,
          comments: comments,
        },
      });
      swal({
        title: "Tool Request Approved",
        text: "Request can now be finally issued.",
        icon: "success",
      });
    });
};

// DENY TOOL REQUEST
export const denyEquipmentRequest = (requestId, comments) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_EQUIPMENTS });

  const payload = new FormData();
  payload.append("request_id", requestId);
  payload.append("comments", comments);

  axios
    .put(
      BASE_URL + `/equipment/request/${requestId}/deny`,
      payload,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: DENY_EQUIPMENT_REQUEST,
        payload: {
          id: requestId,
          comments: comments,
        },
      });
      swal({
        title: "Equipment Request Denied",
        text: "This will notify the requester.",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GET SCRAPPED EQUIPMENTS
export const getScrappedEquipments = () => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .get(BASE_URL + `/equipments/scrapped`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SCRAPPED_EQUIPMENTS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// GET SERVICED EQUIPMENTS
export const getServicedEquipments = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .get(BASE_URL + `/equipments/serviced?page=${page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SERVICED_EQUIPMENTS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT SERVICED EQUIPMENT
export const selectServicedEquipment = (servicedEquipment) => (dispatch) => {
  dispatch({
    type: SELECT_SERVICED_EQUIPMENT,
    payload: servicedEquipment,
  });
};

// GET EQUIPMENT CATEGORIES
export const getEquipmentCategories = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .get(BASE_URL + `/equipment_categories?page=${page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_EQUIPMENT_CATEGORIES,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// ADD EQUIPMENT CATEGORY
export const addEquipmentCategory = (equipmentCategory) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .post(
      BASE_URL + `/equipment_category`,
      equipmentCategory,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: ADD_EQUIPMENT_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Equipment Category Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE EQUIPMENT CATEGORY
export const updateEquipmentCategory = (id, equipmentCategory) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .put(
      BASE_URL + `/equipment_category/${id}`,
      equipmentCategory,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: UPDATE_EQUIPMENT_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Equipment Category Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// DELETE EQUIPMENT CATEGORY
export const deleteEquipmentCategory = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .delete(BASE_URL + `/equipment_category/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_EQUIPMENT_CATEGORY,
        payload: id,
      });
      swal({
        title: "Equipment Category Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// SELECT EQUIPMENT CATEGORY
export const selectEquipmentCategory = (equipmentCategory) => (
  dispatch,
  getState
) => {
  dispatch({
    type: SELECT_EQUIPMENT_CATEGORY,
    payload: equipmentCategory,
  });
};

// ADD EQUIPMENT
export const addEquipment = (equipment) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .post(BASE_URL + `/equipment`, equipment, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_EQUIPMENT,
        payload: res.data,
      });
      swal({
        title: "Equipment Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE EQUIPMENT
export const updateEquipment = (id, equipment) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .put(BASE_URL + `/equipment/${id}`, equipment, tokenConfig(getState))
    .then((res) => {
      dispatch({ type: UPDATE_EQUIPMENT, payload: res.data });
      swal({
        title: "Equipment Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// DELETE EQUIPMENT
export const deleteEquipment = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_EQUIPMENTS });
  axios
    .delete(BASE_URL + `/tool/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_EQUIPMENT,
        payload: id,
      });
      swal({
        title: "Equipment Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
