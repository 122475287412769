import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_AVAILABLE_COMMON_MATERIALS,
  GET_LIST_COMMON_MATERIALS,
  LOADING_COMMON_MATERIAL_REQUESTS,
  GET_COMMON_MATERIAL_REQUESTS,
  SELECT_COMMON_MATERIAL_REQUEST,
  ADD_COMMON_MATERIAL_REQUEST,
  UPDATE_COMMON_MATERIAL_REQUEST,
  DELETE_COMMON_MATERIAL_REQUEST,
} from "./types";

import moment from "moment";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// get available common materials
export const getAvailableCommonMaterials =
  (page, search_term) => (dispatch, getState) => {
    dispatch({ type: LOADING_COMMON_MATERIAL_REQUESTS });

    let endpoint = `/common-material-requests/available-materials?page=${page}`;
    if (search_term) endpoint += `&search_term=${search_term}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_AVAILABLE_COMMON_MATERIALS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

export const getListCommonMaterials =
  (page, search_term, project_id = null, task_id = null) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_COMMON_MATERIAL_REQUESTS });

    let endpoint = `/common-material-requests/list?page=${page}`;
    if (search_term) endpoint += `&search_term=${search_term}`;
    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_LIST_COMMON_MATERIALS,
          payload: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

export const exportListCommonMaterials = () => (dispatch, getState) => {
  let endpoint = `/common-material-requests/export`;

  axios
    .get(BASE_URL + endpoint, {
      ...tokenConfig(getState),
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Common Materials - ${moment().format("Y-MM-DD").toString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => console.error(err));
};

// get common material requests
export const getCommonMaterialRequests =
  (page, project_id, task_id, status) => (dispatch, getState) => {
    dispatch({ type: LOADING_COMMON_MATERIAL_REQUESTS });

    let endpoint = `/common-material-requests?page=${page}`;

    if (status) endpoint += `&status=${status}`;
    if (project_id) endpoint += `&project_id=${project_id}`;
    if (task_id) endpoint += `&task_id=${task_id}`;

    axios
      .get(BASE_URL + endpoint, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_COMMON_MATERIAL_REQUESTS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// select common material request
export const selectCommonMaterialRequest =
  (commonMaterialRequest) => (dispatch) => {
    dispatch({
      type: SELECT_COMMON_MATERIAL_REQUEST,
      payload: commonMaterialRequest,
    });
  };

// add common material request
export const addCommonMaterialRequest = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/common-material-requests/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Material Request Added!",
        icon: "success",
      });
      dispatch({ type: ADD_COMMON_MATERIAL_REQUEST, payload: res.data });
    })
    .catch((err) => console.error(err));
};

// update common material request
export const updateCommonMaterialRequest =
  (payload) => (dispatch, getState) => {
    let endpoint = BASE_URL + `/common-material-requests/update-status`;

    axios
      .post(endpoint, payload, tokenConfig(getState))
      .then((res) => {
        swal({
          title: `Material Request ${payload.status}!`,
          icon: "success",
        });
        dispatch({ type: UPDATE_COMMON_MATERIAL_REQUEST, payload: res.data });
      })
      .catch((err) => console.error(err));
  };

// DELETE COMMON MATERIAL REQUEST
export const deleteCommonMaterialRequest = (id) => (dispatch, getState) => {
  axios
    .delete(BASE_URL + `/common-material-requests/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_COMMON_MATERIAL_REQUEST,
        payload: id,
      });
      swal({
        title: "Material Request Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
