import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Range from "react-range-progress";
import moment from "moment";
import swal from "@sweetalert/with-react";
import MyTasksUploadInspection from "./MyTasksUploadInspection";
import { roles } from "../../helpers/userRoles";

import { updateTask, deleteTask, deleteMapTask } from "../../actions/tasks";
import {
  updateProject,
  deleteProject,
  deleteMapProject,
} from "../../actions/projects";
import { addDocumentation } from "../../actions/documentations";
import ImageUtils from "../../helpers/ImageUtils";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser from "react-html-parser";

import { Label, Button } from "../common";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function MyTasksInformation(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const descriptionRef = useRef();
  const newUpdatesRef = useRef();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [status, setStatus] = useState({
    label: "In Progress",
    value: "In Progress",
  });
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const selectedMyTask = useSelector((state) => state.tasks.selectedMyTask);
  const user = useSelector((state) => state.auth.user);
  const [enableEditing, setEnableEditing] = useState(false);

  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();
  const fileInputRef4 = useRef();
  const fileInputRef5 = useRef();
  const [selectedWorkingDrawing, setSelectedWorkingDrawing] = useState(null);
  const [selectedWorkingDrawing2, setSelectedWorkingDrawing2] = useState(null);
  const [selectedWorkingDrawing3, setSelectedWorkingDrawing3] = useState(null);
  const [selectedWorkingDrawing4, setSelectedWorkingDrawing4] = useState(null);
  const [selectedWorkingDrawing5, setSelectedWorkingDrawing5] = useState(null);

  const handleFileChange = (e) => {
    setSelectedWorkingDrawing(fileInputRef.current.files[0]);
  };

  const handleFileChange2 = (e) => {
    setSelectedWorkingDrawing2(fileInputRef2.current.files[0]);
  };

  const handleFileChange3 = (e) => {
    setSelectedWorkingDrawing3(fileInputRef3.current.files[0]);
  };

  const handleFileChange4 = (e) => {
    setSelectedWorkingDrawing4(fileInputRef4.current.files[0]);
  };

  const handleFileChange5 = (e) => {
    setSelectedWorkingDrawing5(fileInputRef5.current.files[0]);
  };

  // const codeDefaultValue = selectedTask.parent_id === null ?
  //   selectedTask.title : selectedTask.heirarchies.length > 1 ? selectedTask.heirarchies[1].title : '';

  useEffect(() => {
    register("start_date");
    register("end_date");
    register("current_percentage");
    register("percentage");
    register("status");
    register("project_id");
    register("is_inspected");
    register("description");
    register("new_updates");

    setValue("project_id", selectedTask.project_id);
    setValue("status", selectedTask.status);
    setValue("description", selectedTask.description);
    setValue("new_updates", selectedTask.new_updates);
    setStatus({
      label: selectedTask.status,
      value: selectedTask.status,
    });

    setStartDate(
      selectedTask && selectedTask.start_date
        ? new Date(selectedTask.start_date)
        : null
    );
    selectedTask && selectedTask.start_date
      ? setValue(
          "start_date",
          moment(new Date(selectedTask.start_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("start_date", null);

    setEndDate(
      selectedTask && selectedTask.end_date
        ? new Date(selectedTask.end_date)
        : null
    );
    selectedTask && selectedTask.end_date
      ? setValue(
          "end_date",
          moment(new Date(selectedTask.end_date))
            .format("YYYY-MM-DD")
            .toString()
        )
      : setValue("end_date", null);

    setValue("current_percentage", selectedTask.current_percentage);
    setValue("percentage", selectedTask.current_percentage);
    setCurrentPercentage(selectedTask.current_percentage);
    setValue("is_inspected", 0);
    setEnableEditing(false);
  }, [dispatch, register, setValue, selectedTask]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setValue("start_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setValue("end_date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleStatusChange = (option) => {
    setStatus(option);
    setValue("status", option.value);
  };

  const handleProgressBarChange = (value) => {
    setCurrentPercentage(value);
    setValue("current_percentage", value);
    setValue("percentage", value);
  };

  const handleDescriptionChange = (content) => {
    setValue("description", content);
  };

  const handleNewUpdatesChange = (content) => {
    setValue("new_updates", content);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure you want to update this task?",
      text: `This will and update overall progress of the project.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file,
          file2,
          file3,
          file4,
          file5 = null;
        if (selectedWorkingDrawing) {
          file = await ImageUtils.fileToBase64(selectedWorkingDrawing);
        }
        if (selectedWorkingDrawing2) {
          file2 = await ImageUtils.fileToBase64(selectedWorkingDrawing2);
        }
        if (selectedWorkingDrawing3) {
          file3 = await ImageUtils.fileToBase64(selectedWorkingDrawing3);
        }
        if (selectedWorkingDrawing4) {
          file4 = await ImageUtils.fileToBase64(selectedWorkingDrawing4);
        }
        if (selectedWorkingDrawing5) {
          file5 = await ImageUtils.fileToBase64(selectedWorkingDrawing5);
        }

        if (file) {
          data.base64image = file;
        }
        if (file2) {
          data.base64image2 = file2;
        }
        if (file3) {
          data.base64image3 = file3;
        }
        if (file4) {
          data.base64image4 = file4;
        }
        if (file5) {
          data.base64image5 = file5;
        }

        if (selectedTask.type === "projects") {
          dispatch(updateProject(selectedMyTask.id, data));
        } else {
          if (selectedTask.is_parent) {
            if (selectedTask.calculated_percentage >= 80) {
              askIfInspected(data);
            } else {
              dispatch(updateTask(selectedTask.id, data, selectedMyTask.type));
              setEnableEditing(false);
            }
          } else {
            if (data.current_percentage >= 80) {
              askIfInspected(data);
            } else {
              dispatch(updateTask(selectedTask.id, data, selectedMyTask.type));
              setEnableEditing(false);
            }
          }
        }
      }
    });
  };

  const onDelete = () => {
    swal({
      icon: "warning",
      title: `Delete this ${selectedTask.type.slice(0, -1)}?`,
      text: `Are you sure you want to delete this ${selectedTask.type.slice(
        0,
        -1
      )}? This cannot be undone.`,
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        if (selectedTask.type === "projects") {
          dispatch(deleteProject(selectedTask.id));
          history.push("/");
        } else {
          dispatch(
            deleteTask(selectedTask.id, selectedMyTask, selectedMyTask.type)
          );
        }
      }
    });
  };

  const askIfInspected = (data) => {
    swal({
      icon: "warning",
      title: "Is this task inspected?",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        swal({
          icon: "warning",
          text: "Upload Proof of Inspection",
          buttons: ["Rather, not inspected yet."],
          content: (
            <MyTasksUploadInspection
              onInspectionSubmit={(payload) => {
                data.is_inspected = 1;
                dispatch(
                  addDocumentation(
                    selectedTask.id,
                    "tasks",
                    payload,
                    selectedTask
                  )
                );
                dispatch(
                  updateTask(selectedTask.id, data, selectedMyTask.type)
                );
                swal.close();
              }}
            />
          ),
        });
      } else {
        data.is_inspected = 0;
        dispatch(updateTask(selectedTask.id, data, selectedMyTask.type));
      }
    });
  };

  const handleMapDelete = (key) => {
    swal({
      icon: "warning",
      title: "Delete this file?",
      text: "This cannot be undone.",
      buttons: ["No", "Yes"],
    }).then((confirmed) => {
      if (confirmed) {
        if (selectedTask.type === "projects") {
          dispatch(deleteMapProject(selectedTask.id, key));
        } else if (selectedTask.type === "tasks") {
          dispatch(deleteMapTask(selectedTask.id, key));
        }
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {user.employee.role_id !== roles.drftr && (
        <div className="div mb-4 flex">
          <div className="w-1/2">
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                name="enable_editing"
                id="toggle"
                checked={enableEditing}
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                onChange={(event) => {
                  setEnableEditing(event.currentTarget.checked);
                }}
              />
              <label
                htmlFor="toggle"
                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>
            <label htmlFor="toggle" className="text-xs text-gray-700">
              Enable Editing
            </label>
          </div>
        </div>
      )}
      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Title" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedTask.title}
            defaultValue={selectedTask.title}
            name="title"
            type="text"
            disabled={!enableEditing}
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="Code" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedTask.code}
            defaultValue={selectedTask.code}
            name="code"
            type="text"
            disabled={!enableEditing}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Status" />
          <Select
            options={[
              { value: "In Progress", label: "In Progress" },
              { value: "On Hold", label: "On Hold" },
              (user.employee.role_id === roles.pm ||
                user.employee.role_id === roles.coord ||
                user.employee.role_id === roles.su) && {
                value: "Completed",
                label: "Completed",
              },
            ]}
            value={status}
            onChange={handleStatusChange}
            className="text-xs"
            isDisabled={!enableEditing}
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="w-1/2">
          <Label name="Location" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            ref={register}
            key={selectedTask.location}
            defaultValue={selectedTask.location}
            name="location"
            type="text"
            disabled={!enableEditing}
          />
        </div>

        {selectedTask.type === "tasks" && (
          <div className="w-1/2 px-4">
            <Label name={`Current Progress - ${currentPercentage}`} />
            <Range
              value={currentPercentage}
              fillColor={{
                r: 20,
                g: 150,
                b: 100,
                a: 0.75,
              }}
              trackColor={{
                r: 10,
                g: 10,
                b: 0,
                a: 0.5,
              }}
              height={14}
              width="100%"
              onChange={handleProgressBarChange}
              readOnly={selectedTask.is_parent}
            />
          </div>
        )}
      </div>

      <div className="flex mb-4">
        <div className="w-1/3">
          <Label name="Created by" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            key={`${
              selectedTask.created_by
                ? selectedTask.created_by.employee.first_name
                : ""
            } ${
              selectedTask.created_by
                ? selectedTask.created_by.employee.last_name
                : ""
            }`}
            defaultValue={`${
              selectedTask.created_by
                ? selectedTask.created_by.employee.first_name
                : ""
            } ${
              selectedTask.created_by
                ? selectedTask.created_by.employee.last_name
                : ""
            }`}
            name="created_by"
            type="text"
            readOnly={true}
          />
        </div>
        {selectedTask.type === "tasks" && (
          <div className="w-1/3 ml-4">
            <Label name="Percentage Distribution (0 if 1:1)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedTask.set_percentage}
              defaultValue={selectedTask.set_percentage}
              name="set_percentage"
              type="number"
              step="any"
              disabled={!enableEditing}
            />
          </div>
        )}
      </div>

      {selectedTask.type === "projects" && (
        <>
          <div className="flex mb-4">
            <div className="w-1/2 mr-2">
              <Label name="Client Name" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                key={selectedTask.client_name}
                defaultValue={selectedTask.client_name}
                name="client_name"
                type="text"
                disabled={!enableEditing}
              />
            </div>
            <div className="w-1/2 ml-2">
              <Label name="Client Address" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                ref={register}
                key={selectedTask.client_address}
                defaultValue={selectedTask.client_address}
                name="client_address"
                type="text"
                disabled={!enableEditing}
              />
            </div>
          </div>
        </>
      )}

      <div className="flex mb-4">
        <div className="w-1/2" style={{ zIndex: 20 }}>
          <Label name="Start Date" />
          <DatePicker
            showYearDropdown
            selected={startDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleStartDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            disabled={!enableEditing}
          />
        </div>
        <div className="w-1/2 ml-4" style={{ zIndex: 20 }}>
          <Label name="End Date" />
          <DatePicker
            showYearDropdown
            selected={endDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleEndDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
            disabled={!enableEditing}
          />
        </div>
      </div>

      <div className="box border rounded flex flex-col shadow bg-white mb-4">
        <div className="bg-gray-400 px-3 py-2 border-b">
          <h3 className="text-sm text-gray-900 font-medium">New Updates</h3>
        </div>
        {enableEditing ? (
          <SunEditor
            defaultValue={selectedTask.new_updates}
            key={selectedTask.new_updates}
            height="100"
            name="new_updates"
            onChange={handleNewUpdatesChange}
            ref={newUpdatesRef}
            disable={!enableEditing}
          />
        ) : (
          <div className="m-4">
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              truncatedEndingComponent={"... "}
              className="text-xs text-gray-700"
              anchorClass="text-blue-600 font-bold"
            >
              {ReactHtmlParser(selectedTask.new_updates)}
            </ShowMoreText>
          </div>
        )}
      </div>

      <div className="box border rounded flex flex-col shadow bg-white">
        <div className="bg-gray-400 px-3 py-2 border-b">
          <h3 className="text-sm text-gray-900 font-medium">Description</h3>
        </div>
        {enableEditing ? (
          <SunEditor
            defaultValue={selectedTask.description}
            key={selectedTask.description}
            height="100"
            name="description"
            onChange={handleDescriptionChange}
            ref={descriptionRef}
            disable={!enableEditing}
          />
        ) : (
          <div className="m-4">
            <ShowMoreText
              lines={3}
              more="Show more"
              less="Show less"
              truncatedEndingComponent={"... "}
              className="text-xs text-gray-700"
              anchorClass="text-blue-600 font-bold"
            >
              {ReactHtmlParser(selectedTask.description)}
            </ShowMoreText>
          </div>
        )}
      </div>

      <div className="flex mb-4">
        <div className="w-100 mt-4">
          <Label name="Files" />
          <div className="flex">
            {selectedTask.map_full_url && (
              <>
                <Button
                  text="View File"
                  primary="true"
                  onClick={() => {
                    window.open(selectedTask.map_full_url, "_blank");
                  }}
                />
                {enableEditing && (
                  <Button
                    style={{ marginLeft: -8 }}
                    text="x"
                    danger="true"
                    onClick={() => {
                      handleMapDelete("map");
                    }}
                  />
                )}
              </>
            )}
            {selectedTask.map2_full_url && (
              <>
                <Button
                  text="View File 2"
                  primary="true"
                  onClick={() => {
                    window.open(selectedTask.map2_full_url, "_blank");
                  }}
                />
                {enableEditing && (
                  <Button
                    style={{ marginLeft: -8 }}
                    text="x"
                    danger="true"
                    onClick={() => {
                      handleMapDelete("map2");
                    }}
                  />
                )}
              </>
            )}
            {selectedTask.map3_full_url && (
              <>
                <Button
                  text="View File 3"
                  primary="true"
                  onClick={() => {
                    window.open(selectedTask.map3_full_url, "_blank");
                  }}
                />
                {enableEditing && (
                  <Button
                    style={{ marginLeft: -8 }}
                    text="x"
                    danger="true"
                    onClick={() => {
                      handleMapDelete("map3");
                    }}
                  />
                )}
              </>
            )}
            {selectedTask.map4_full_url && (
              <>
                <Button
                  text="View File 4"
                  primary="true"
                  onClick={() => {
                    window.open(selectedTask.map4_full_url, "_blank");
                  }}
                />
                {enableEditing && (
                  <Button
                    style={{ marginLeft: -8 }}
                    text="x"
                    danger="true"
                    onClick={() => {
                      handleMapDelete("map4");
                    }}
                  />
                )}
              </>
            )}
            {selectedTask.map5_full_url && (
              <>
                <Button
                  text="View File 5"
                  primary="true"
                  onClick={() => {
                    window.open(selectedTask.map5_full_url, "_blank");
                  }}
                />
                {enableEditing && (
                  <Button
                    style={{ marginLeft: -8 }}
                    text="x"
                    danger="true"
                    onClick={() => {
                      handleMapDelete("map5");
                    }}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex mb-4 mt-4">
        <div className="flex">
          <div>
            <Label name="Update File 1" />
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
              disabled={!enableEditing}
            />
          </div>
          <div>
            <Label name="Update File 2" />
            <input
              ref={fileInputRef2}
              type="file"
              onChange={(e) => {
                handleFileChange2(e);
              }}
              disabled={!enableEditing}
            />
          </div>
          <div>
            <Label name="Update File 3" />
            <input
              ref={fileInputRef3}
              type="file"
              onChange={(e) => {
                handleFileChange3(e);
              }}
              disabled={!enableEditing}
            />
          </div>
        </div>
      </div>

      <div className="flex mb-4">
        <div>
          <Label name="Update File 4" />
          <input
            ref={fileInputRef4}
            type="file"
            onChange={(e) => {
              handleFileChange4(e);
            }}
            disabled={!enableEditing}
          />
        </div>
        <div>
          <Label name="Update File 5" />
          <input
            ref={fileInputRef5}
            type="file"
            onChange={(e) => {
              handleFileChange5(e);
            }}
            disabled={!enableEditing}
          />
        </div>
      </div>

      <div className="grid grid-cols-6 gap-4">
        <div className="col-start-1 col-end-3">
          <Button
            text="Update"
            success="true"
            type="submit"
            disabled={!enableEditing}
          />
        </div>
        <div className="col-end-7 col-span-2">
          {user.employee.role_id === roles.su && (
            <Button
              text={`Remove this ${selectedTask.type.slice(0, -1)}`}
              danger="true"
              onClick={() => {
                props.onTaskDelete && props.onTaskDelete();
                onDelete();
                setEnableEditing(false);
              }}
              disabled={!enableEditing}
            />
          )}
        </div>
      </div>
    </form>
  );
}
