import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button, Avatar } from "../../common";
import moment from "moment";
import swal from "sweetalert";
import { updateIssuePoMaterial } from "../../../actions/commonIssuePoMaterials";
import { updateMaterial, deleteMaterial } from "../../../actions/materials";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function CommonMaterialIssuePOEdit(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedIssuePO = useSelector(
    (state) => state.commonIssuePoMaterials.selected
  );
  const [date, setDate] = useState(new Date());
  const [materials, setMaterials] = useState(
    selectedIssuePO.materials ? selectedIssuePO.materials : []
  );
  const handleDateChange = (date) => {
    setDate(date);
  };

  const handleMaterialChange = (index, event) => {
    const values = [...materials];
    values[index][event.target.name] = event.target.value;

    setMaterials(values);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the Issue Purchase Order.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        data.date = moment(date).format("YYYY-MM-DD").toString();
        dispatch(updateIssuePoMaterial(selectedIssuePO.id, data));
        props.onFormClose && props.onFormClose();
        e.target.reset();
      }
    });
  };

  const updateMaterialEntry = (material) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update the material for this Issue PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(updateMaterial(material.id, material));
      }
    });
  };

  const removeMaterialEntry = (index, material) => {
    swal({
      icon: "error",
      title: "Are you sure?",
      text: `This will delete the material for this Issue PO as well as its master entry.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        dispatch(deleteMaterial(material.id));
        let values = [...materials];
        values.splice(index, 1);
        setMaterials(values);
      }
    });
  };

  //Register fiels that can'tt be captured by react-hook-form
  useEffect(() => {
    register("date");
    setValue(
      "date",
      moment(selectedIssuePO.date).format("YYYY-MM-DD").toString()
    );
  }, [register, setValue, selectedIssuePO, dispatch]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">
        Update Issue Purchase Order (Common Materials)
      </h1>
      <p className="text-xs text-gray-600 mb-4">
        Details here will be generated into PDF for issuance to supplier.
      </p>

      <div className="grid">
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Company" />
            <p className="text-xs">
              {selectedIssuePO.company ? selectedIssuePO.company.name : "-"}
            </p>
          </div>
        </div>
        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="PO No." />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="po_no"
              type="text"
              key={selectedIssuePO.po_no}
              defaultValue={selectedIssuePO.po_no}
              required
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Issue Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Document Reference" />
            <p className="text-xs">{selectedIssuePO.ref}</p>
          </div>

          <div className="w-1/2 px-4">
            <Label name="Ref #" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ref_no"
              type="text"
              key={selectedIssuePO.ref_no}
              defaultValue={selectedIssuePO.ref_no}
              required
            />
          </div>
        </div>

        <div className="flex mt-4 mb-4">
          <div className="w-1/2">
            <Label name="Supplier" />
            <p className="text-xs">
              {" "}
              {selectedIssuePO.common_supplier ? (
                <Avatar
                  name={selectedIssuePO.common_supplier.name}
                  image={selectedIssuePO.common_supplier.photo_full_url}
                />
              ) : (
                " - "
              )}
            </p>
          </div>

          <div className="w-1/2 px-4">
            <Label name="Ship to" />
            <p className="text-xs">
              {selectedIssuePO.ship_to ? (
                <>
                  <div className="flex items-center">
                    <span className="w-8 h-8 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full mb-4">
                      {selectedIssuePO.ship_to.avatar && (
                        <img
                          alt="Supplier"
                          className="rounded-full border border-gray-100 object-cover"
                          src={selectedIssuePO.ship_to.avatar.image}
                        />
                      )}
                    </span>
                    <span className="text-xs ml-2">
                      {selectedIssuePO.ship_to.first_name}{" "}
                      {selectedIssuePO.ship_to.last_name}
                      <br />
                      {selectedIssuePO.ship_to.contact_number}
                      <br />
                      {selectedIssuePO.ship_to_address}
                      <br />
                    </span>
                  </div>
                </>
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="w-1/2">
            <Label name="Payment Term" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="payment_term"
              type="text"
              key={selectedIssuePO.payment_term}
              defaultValue={selectedIssuePO.payment_term}
              required
            />
          </div>

          <div className="w-1/2 px-4">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="comments"
              type="text"
              key={selectedIssuePO.comments}
              defaultValue={selectedIssuePO.comments}
            />
          </div>
        </div>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Update Issue Purchase Order"
          success="true"
          type="submit"
        />
      </div>

      <div className="grid bg-gray-200 p-4 mt-4">
        <p className="text-sm font-bold text-gray-600 mb-4">
          Update Materials to Issue Purchase Order
        </p>

        <table className="table-fixed w-100">
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">Category</th>
              <th className="text-xs px-4 py-2">Code</th>
              <th className="text-xs px-4 py-2">Description</th>
              <th className="text-xs px-4 py-2">Unit</th>
              <th className="text-xs px-4 py-2">
                Price
                <br />
                (per unit)
              </th>
              <th className="text-xs px-4 py-2">Qty</th>
              <th className="text-xs px-4 py-2">Warehouse</th>
              <th className="text-xs px-4 py-2">
                <i className="far fa-trash text-gray-500 text-base"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {materials.map((material, index) => (
              <tr key={index} className="hover:bg-blue-200">
                <td className="text-xs border px-2 py-2">
                  {material.common_category.name}
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="code"
                    type="text"
                    value={material.code}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="description"
                    type="text"
                    value={material.description}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="unit"
                    type="text"
                    value={material.unit}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <p className="m-4">$</p>
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="price"
                    type="text"
                    value={material.price}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  <input
                    className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    autoComplete="off"
                    name="common_issue_po_qty"
                    type="text"
                    value={material.common_issue_po_qty}
                    onChange={(event) => handleMaterialChange(index, event)}
                  />
                </td>
                <td className="text-xs border px-2 py-2">
                  {material.warehouse ? material.warehouse.name : ""}
                </td>
                <td>
                  <div className="flex">
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        removeMaterialEntry(index, material);
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    <button
                      type="button"
                      className="bg-orange-500 hover:bg-orange-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => {
                        updateMaterialEntry(material);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose && props.onFormClose();
          }}
        />
      </div>
    </form>
  );
}
