import React from "react";
import UserDropdown from "./UserDropdown";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../../actions/general";
import classNames from "classnames";

export default function Navbar(props) {
  // const user = useSelector((state) => state.auth.user);
  const activeSidebarNav = useSelector(
    (state) => state.general.activeSidebarNav
  );
  const dispatch = useDispatch();
  const sidebarVisible = useSelector((state) => state.general.sidebarVisible);

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4">
          {/* Brand - Breadcrumbs go here */}
          <button
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            className="bg-blue-500 hover:bg-blue-700 py-2 px-2 rounded focus:outline-none focus:shadow-outline"
          >
            <span
              className={classNames("fas fa-arrow-left text-white", {
                "fa-arrow-left": sidebarVisible,
                "fa-arrow-right": !sidebarVisible,
              })}
            ></span>
          </button>
          <a
            className="text-sm uppercase hidden lg:inline-block font-semibold"
            href="#sbhe"
            onClick={(e) => e.preventDefault()}
          >
            {activeSidebarNav}
          </a>

          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
