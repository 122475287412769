import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Pagination,
  Tab,
  EmptyPlaceholder,
} from "../../common";
import {
  getForAllocateMaterials,
  getAllocatedMaterials,
  selectAllocatedMaterial,
  selectForAllocateMaterial,
} from "../../../actions/commonAllocatedMaterials";
import { deleteCommonMaterialRequest } from "../../../actions/commonMaterialRequests";
import { roles } from "../../../helpers/userRoles";
import CommonMaterialAllocateForm from "./CommonMaterialAllocateForm";
import CommonMaterialAllocateDetail from "./CommonMaterialAllocateDetail";
import swal from "sweetalert";

export default function CommonMaterialAllocatePage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const forAllocateMaterials = useSelector(
    (state) => state.commonAllocatedMaterials.forAllocateMaterials
  );
  const allocatedMaterials = useSelector(
    (state) => state.commonAllocatedMaterials.allocatedMaterials
  );

  const allocatedPagination = useSelector(
    (state) => state.commonAllocatedMaterials.allocatedPagination
  );
  const forAllocatePagination = useSelector(
    (state) => state.commonAllocatedMaterials.forAllocatePagination
  );

  const isLoading = useSelector(
    (state) => state.commonAllocatedMaterials.isLoading
  );
  const [status, setStatus] = useState("For Allocation");
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [allocatedMaterialsPageNumber, setAllocatedMaterialsPageNumber] =
    useState(1);
  const [forAllocateMaterialsPageNumber, setForAllocateMaterialsPageNumber] =
    useState(1);

  useEffect(() => {
    if (status === "For Allocation") {
      dispatch(getForAllocateMaterials(forAllocateMaterialsPageNumber));
    } else {
      dispatch(getAllocatedMaterials(allocatedMaterialsPageNumber));
    }
  }, [
    status,
    dispatch,
    forAllocateMaterialsPageNumber,
    allocatedMaterialsPageNumber,
  ]);

  return (
    <div className="mt-4">
      {addModal && (
        <Modal>
          <CommonMaterialAllocateForm
            onFormClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      <h1 className="font-bold mb-4">Select Allocation Status</h1>
      <div className="mb-4">
        <Tab>
          <Tab.Item
            name="For Allocation"
            isActive={status === "For Allocation"}
            onTabClick={() => {
              setStatus("For Allocation");
            }}
          />
          <Tab.Item
            name="Allocated"
            isActive={status === "Allocated"}
            onTabClick={() => {
              setStatus("Allocated");
            }}
          />
        </Tab>
      </div>

      {status === "For Allocation" && (
        <>
          <table className="table-fixed w-100">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date Requested</th>
                <th className="text-xs px-4 py-2">Date Approved</th>
                <th className="text-xs px-4 py-2">Requested by</th>
                <th className="text-xs px-4 py-2">Items</th>
                <th className="text-xs px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {forAllocateMaterials.map((forAllocateMaterial) => (
                <tr key={forAllocateMaterial.id} className="hover:bg-gray-200">
                  <td className="text-xs border px-2 py-2">
                    {forAllocateMaterial.date}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forAllocateMaterial.updated_at}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forAllocateMaterial.requested_by
                      ? `${forAllocateMaterial.requested_by.first_name} ${forAllocateMaterial.requested_by.last_name}`
                      : ""}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {forAllocateMaterial.materials
                      ? forAllocateMaterial.materials.length
                      : 0}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <div className="flex">
                      <div className="flex-initial">
                        <Button
                          text={"Allocate"}
                          primary="true"
                          onClick={() => {
                            dispatch(
                              selectForAllocateMaterial(forAllocateMaterial)
                            );
                            setAddModal(true);
                          }}
                        />
                      </div>
                      {user.employee.role_id === roles.su && (
                        <div className="flex-initial">
                          <Button
                            text={`Delete`}
                            danger="true"
                            onClick={() => {
                              swal({
                                icon: "error",
                                title: "Are you sure?",
                                text: `This will delete this Request Purchase Order.`,
                                buttons: true,
                              }).then(async (willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    deleteCommonMaterialRequest(
                                      forAllocateMaterial.id
                                    )
                                  );
                                }
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {forAllocateMaterials < 1 && (
            <EmptyPlaceholder text="Nothing For Allocation" />
          )}

          {forAllocatePagination && !isLoading && (
            <Pagination
              from={forAllocatePagination.from}
              to={forAllocatePagination.to}
              total={forAllocatePagination.total}
              lastPage={forAllocatePagination.last_page}
              currentPage={forAllocatePagination.current_page}
              onPageClick={(page) => {
                setForAllocateMaterialsPageNumber(page);
              }}
            />
          )}

          {isLoading && <Spinner />}
        </>
      )}

      {status === "Allocated" && (
        <>
          <table className="table-fixed w-100">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date Allocated</th>
                <th className="text-xs px-4 py-2">Date Requested</th>
                <th className="text-xs px-4 py-2">Requested by</th>
                <th className="text-xs px-4 py-2">Items</th>
                <th className="text-xs px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allocatedMaterials.map((allocatedMaterial) => (
                <tr key={allocatedMaterial.id} className="hover:bg-gray-200">
                  <td className="text-xs border px-2 py-2">
                    {allocatedMaterial.updated_at}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {allocatedMaterial.date}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {allocatedMaterial.requested_by
                      ? `${allocatedMaterial.requested_by.first_name} ${allocatedMaterial.requested_by.last_name}`
                      : ""}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    {allocatedMaterial.materials
                      ? allocatedMaterial.materials.length
                      : 0}
                  </td>
                  <td className="text-xs border px-2 py-2">
                    <Button
                      text={"Details"}
                      primary="true"
                      onClick={() => {
                        dispatch(selectAllocatedMaterial(allocatedMaterial));
                        setViewModal(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {allocatedMaterials < 1 && (
            <EmptyPlaceholder text="Nothing Allocated" />
          )}

          {allocatedPagination && !isLoading && (
            <Pagination
              from={allocatedPagination.from}
              to={allocatedPagination.to}
              total={allocatedPagination.total}
              lastPage={allocatedPagination.last_page}
              currentPage={allocatedPagination.current_page}
              onPageClick={(page) => {
                setAllocatedMaterialsPageNumber(page);
              }}
            />
          )}

          {isLoading && <Spinner />}
        </>
      )}

      {viewModal && (
        <Modal>
          <CommonMaterialAllocateDetail
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
