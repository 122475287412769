export const employeeListColumns = [
  {
    name: "Name",
  },
  {
    name: "Status",
  },
  {
    name: "Position",
  },
  {
    name: "Company",
  },
  {
    name: "Nationality",
  },
  {
    name: "Years of Service",
  },
];
