import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import { removeURLParameter } from "../../helpers/useTransformData";

export default function Attachment(props) {
  const path = props.path;
  const showFileName = props.showFileName;
  const [extension] = path.split(".").reverse();
  const fileName = removeURLParameter(path);
  const extensions = ["jpg", "png", "jpeg", "bmp", "gif", "tiff"];

  return extensions.indexOf(extension) >= 0 ? (
    <SRLWrapper>
      <img alt={`attachment`} className="border-none" src={props.path} />
    </SRLWrapper>
  ) : (
    <a
      href={props.path}
      target="_blank"
      rel="noopener noreferrer"
      className="underline text-blue-600 text-xs"
    >
      {showFileName ? fileName : "View File"}
    </a>
  );
}
