import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Redirect } from "react-router-dom";

import { MDCTextField } from "@material/textfield";
import LoginLogo from "../../assets/img/login_logo.png";
import LoginLogo1 from "../../assets/img/login_logo1.png";
export class Login extends Component {
  state = {
    username: "",
    password: "",
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    loginFailed: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = (e) => {
    this.props.login(this.state.username, this.state.password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    new MDCTextField(document.querySelector(".User"));
    new MDCTextField(document.querySelector(".Pass"));
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />;
    }

    const { username, password } = this.state;

    return (
      <main className="Login_Background absolute">
        <section className="flex h-screen">
          <div className="login-container m-auto">
            <div className="flex w-full h-full">
              <div className="relative flex flex-col w-full h-full">
                <div className="login-left-container">
                  <div className="mb-0 px-6 py-6">
                    <div className="flex justify-center">
                      <img
                        src={LoginLogo}
                        alt="SBHE PM Logo"
                        style={{ paddingBottom: "20px" }}
                      />
                      <div className="login-right-subtitle-left-1">
                        <p>SBHE PTE LTD</p>
                      </div>
                    </div>
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>

                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    {this.props.loginFailed === true && (
                      <div
                        className="bg-red-100 border border-red-400 text-red-700 px-2 py-2 mb-2 rounded relative"
                        role="alert"
                      >
                        <strong className="font-bold">Login Failed.</strong>
                        <span className="block sm:inline">
                          Credentials was invalid.
                        </span>
                      </div>
                    )}
                    <form>
                      <div className="w-full mb-3">
                        <label className="Login_MDC User mdc-text-field mdc-text-field--filled">
                          <span className="mdc-text-field__ripple"></span>
                          <input
                            type="text"
                            autoComplete="off"
                            className="mdc-text-field__input"
                            name="username"
                            onChange={this.onChange}
                            value={username}
                            style={{ transition: "all .15s ease" }}
                          />
                          <span className="mdc-floating-label" id="my-label">
                            Username
                          </span>
                          <span className="mdc-line-ripple"></span>
                        </label>
                      </div>

                      <div className="w-full mb-3">
                        <label className="Login_MDC Pass mdc-text-field mdc-text-field--filled">
                          <span className="mdc-text-field__ripple"></span>
                          <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.onChange}
                            className="mdc-text-field__input"
                            style={{ transition: "all .15s ease" }}
                          />
                          <span className="mdc-floating-label" id="my-label">
                            Password
                          </span>
                          <span className="mdc-line-ripple"></span>
                        </label>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="Login_Button text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                          onClick={this.onSubmit}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                    <div className="text-center mb-3">
                      <small>
                        Sign in with your SBHE PM account credentials.
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-full">
                <div className="login-right-background">
                  <div className="login-right-background-image-overlay">
                    <img
                      className="login-right-background-image"
                      src={LoginLogo1}
                      alt="SBHE"
                    />
                    <div className="login-right-subtitle">
                      <p style={{ fontSize: "1.3em", marginRight: "35px" }}>
                        SBHE PTE LTD
                      </p>
                      <p style={{ letterSpacing: "7px" }}>Project Management</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginFailed: state.auth.loginFailed,
});

export default connect(mapStateToProps, { login })(Login);
