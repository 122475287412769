import {
  GET_MATERIAL_CATEGORIES,
  ADD_MATERIAL_CATEGORY,
  UPDATE_MATERIAL_CATEGORY,
  DELETE_MATERIAL_CATEGORY,
  SELECT_MATERIAL_CATEGORY,
} from "../actions/types";

const initialState = {
  materialCategories: [],
  selectedMaterialCategory: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MATERIAL_CATEGORIES:
      return {
        ...state,
        materialCategories: payload,
      };
    case ADD_MATERIAL_CATEGORY:
      return {
        ...state,
        materialCategories: [...state.materialCategories, payload],
      };
    case UPDATE_MATERIAL_CATEGORY:
      return {
        ...state,
        materialCategories: state.materialCategories.map((category) => {
          if (payload.id !== category.id) {
            return category;
          }

          return {
            ...category,
            ...payload,
          };
        }),
      };
    case SELECT_MATERIAL_CATEGORY:
      return {
        ...state,
        selectedMaterialCategory: payload,
      };
    case DELETE_MATERIAL_CATEGORY:
      return {
        ...state,
        materialCategories: state.materialCategories.filter(
          (category) => category.id !== payload
        ),
      };
    default:
      return state;
  }
}
