import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  Fieldset,
  Typography,
  Table,
  Attachment,
  Modal,
  Pagination,
  Spinner,
} from "../common";

import { getScrappedTools, selectScrappedTool } from "../../actions/tools";
import { toolListScrappedColumns } from "./ToolTableColumns";
import ToolUnscrapForm from "./ToolUnscrapForm";
import { CutButton } from "../common/icons/HeroIcons";

export default function ToolScrappedPage(props) {
  const dispatch = useDispatch();
  const scrappedTools = useSelector((state) => state.tools.scrappedTools);
  const toolsLoading = useSelector((state) => state.tools.toolsLoading);
  const paginationScrappedTools = useSelector(
    (state) => state.tools.paginationScrappedTools
  );
  const [unscrapModal, setUnscrapModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getScrappedTools(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className="flex">
      <div className="w-full">
        <Fieldset>
          <Typography type="h1">Scrapped Tools</Typography>
          <Table
            columns={toolListScrappedColumns}
            data={
              <>
                {!toolsLoading &&
                  scrappedTools.map((scrappedTool) => (
                    <tr
                      key={scrappedTool.id}
                      className={classNames("hover:bg-gray-200 cursor-pointer")}
                    >
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {`${scrappedTool.brand} (${scrappedTool.description})`}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {scrappedTool.serial_no}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {scrappedTool.purchase_date}
                        </div>
                      </td>

                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {scrappedTool.company
                            ? scrappedTool.company.name
                            : " -- "}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {scrappedTool.latest_movement &&
                            scrappedTool.latest_movement.movement.date}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        <div className="text-xs text-gray-900">
                          {scrappedTool.latest_movement &&
                            scrappedTool.latest_movement.movement.extra}
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap">
                        {scrappedTool.latest_movement &&
                          scrappedTool.latest_movement.movement.files.length >
                            0 &&
                          scrappedTool.latest_movement.movement.files.map(
                            (file, index) => (
                              <div key={index}>
                                <Attachment path={file.full_path} />
                              </div>
                            )
                          )}

                        {!scrappedTool.latest_movement ||
                          (scrappedTool.latest_movement.movement.files
                            .length === 0 && (
                            <div className="text-xs text-gray-900">
                              No attachments.
                            </div>
                          ))}
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-col text-gray-600 hover:text-gray-900"
                            onClick={() => {
                              dispatch(selectScrappedTool(scrappedTool));
                              setUnscrapModal(true);
                            }}
                          >
                            <CutButton title={"Unscrap"} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </>
            }
          />

          {toolsLoading && <Spinner />}

          {paginationScrappedTools && !toolsLoading && (
            <Pagination
              from={paginationScrappedTools.from}
              to={paginationScrappedTools.to}
              total={paginationScrappedTools.total}
              lastPage={paginationScrappedTools.last_page}
              currentPage={paginationScrappedTools.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      {unscrapModal && (
        <Modal>
          <ToolUnscrapForm
            onViewClose={() => {
              setUnscrapModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
