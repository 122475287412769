export const paymentTermOptions = [
  {
    value: "7 Days",
    label: "7 Days",
  },
  {
    value: "14 Days",
    label: "14 Days",
  },
  {
    value: "30 Days",
    label: "30 Days",
  },
  {
    value: "60 Days",
    label: "60 Days",
  },
  {
    value: "90 Days",
    label: "90 Days",
  },
  {
    value: "Cash on Delivery",
    label: "Cash on Delivery",
  },
  {
    value: "Cheque",
    label: "Cheque",
  },
];
