import React, { Component } from "react";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";

import Footer from "./Footer";

import MyTasksPage from "../my-tasks/MyTasksPage";
import AttendancePage from "../attendance/AttendancePage";
import EmployeePage from "../employee/EmployeePage";
import UserPage from "../users/UserPage";
import ToolPage from "../tools/ToolPage";
import EquipmentPage from "../equipments/EquipmentPage";
import MyTasksDetail from "../my-tasks/MyTasksDetail";
import MaterialPage from "../materials/MaterialPage";
import ProjectPage from "../projects/ProjectPage";
import AnnouncementPage from "components/announcements/AnnouncementPage";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import classNames from "classnames";
export class MainHeader extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    sidebarVisible: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Router>
        {this.props.sidebarVisible && <Sidebar />}
        <div
          className={classNames(
            "relative bg-gray-200 flex flex-col min-h-screen",
            {
              "md:ml-64": this.props.sidebarVisible,
            }
          )}
        >
          <div className="mb-8">
            <Navbar />
          </div>
          <div className="flex-grow">
            {/* Content here */}
            {window.location.pathname === "/home" ? (
              <Redirect to="/home" />
            ) : (
              ""
            )}
            <Switch>
              <Route exact path="/" component={MyTasksPage} />
              <Route path="/home" component={MyTasksPage} />
              <Route path="/projects/:id" component={MyTasksDetail} />
              <Route path="/employees" component={EmployeePage} />
              <Route path="/users" component={UserPage} />
              <Route path="/attendance" component={AttendancePage} />
              <Route path="/tools" component={ToolPage} />
              <Route path="/equipments" component={EquipmentPage} />
              <Route path="/materials" component={MaterialPage} />
              <Route path="/projects" component={ProjectPage} />
              <Route path="/announcements" component={AnnouncementPage} />
            </Switch>
          </div>

          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sidebarVisible: state.general.sidebarVisible,
});
export default connect(mapStateToProps, { logout })(MainHeader);
