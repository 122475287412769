import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Spinner, Label } from "../../common";
import {
  getListCommonMaterials,
  exportListCommonMaterials,
} from "../../../actions/commonMaterialRequests";
import { commonMaterialListColumns } from "../MaterialTableColumns";
import useDebounce from "../../../helpers/useDebounce";
import classNames from "classnames";

export default function CommonMaterialList(props) {
  const dispatch = useDispatch();
  const materials = useSelector(
    (state) => state.commonMaterialRequests.listMaterials
  );
  const isLoading = useSelector(
    (state) => state.commonMaterialRequests.isLoading
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    dispatch(getListCommonMaterials(pageNumber, debouncedSearchTerm));
  }, [dispatch, pageNumber, debouncedSearchTerm]);

  return (
    <div className="mt-4">
      <div className="flex mb-4">
        <div className="w-2/4">
          <Label name="Search" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNumber(1);
            }}
            type="text"
            placeholder="Search Code or Description..."
          />
        </div>
        <div className="w-1/4 m-4 px-4">
          <Button
            primary="true"
            text="Export to Excel"
            onClick={() => {
              dispatch(exportListCommonMaterials());
            }}
          />
        </div>
      </div>

      <Table
        columns={commonMaterialListColumns}
        data={
          <>
            {!isLoading &&
              Object.keys(materials).map((key, index) => (
                <tr
                  key={materials[key][0].id}
                  className={classNames("hover:bg-gray-200", {
                    "bg-red-200 hover:bg-red-400":
                      materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_in_stock,
                        0
                      ) <= 0,
                  })}
                >
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key][0].common_category.name}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key][0].code}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key][0].description}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key][0].unit}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      $ {materials[key][0].price}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key][0].warehouse
                        ? materials[key][0].warehouse.name
                        : "-"}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_issue_po_qty,
                        0
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_delivered_qty,
                        0
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_requested_qty,
                        0
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_allocated_qty,
                        0
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) =>
                          acc + curVal.common_stocktake_adjustments,
                        0
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {materials[key].reduce(
                        (acc, curVal) => acc + curVal.common_in_stock,
                        0
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </>
        }
      />

      {isLoading && <Spinner />}
    </div>
  );
}
