import React from "react";
import { useSelector } from "react-redux";
import { Button, Label, Table } from "components/common";
import moment from "moment";
import { toolServiceServicedToolColumns } from "./ToolTableColumns";

export default function ToolServiceDetail(props) {
  const selectedServicedTool = useSelector(
    (state) => state.tools.selectedServicedTool
  );

  const tools = selectedServicedTool.tools ? selectedServicedTool.tools : [];

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Serviced Tool Details
      </h1>

      <div
        className="grid grid-cols-2 gap-4"
        style={{
          paddingTop: 30,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 30,
        }}
      >
        <div>
          <Label name="Performed by" />
          <p className="text-sm mb-4">
            {selectedServicedTool.performed_by
              ? `${selectedServicedTool.performed_by.first_name} ${selectedServicedTool.performed_by.last_name}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Service Date" />
          <p className="text-sm mb-4">
            {selectedServicedTool.date
              ? `${moment(selectedServicedTool.date)
                  .format("MMM D, Y")
                  .toString()}`
              : "-- None specified --"}
          </p>
        </div>

        <div>
          <Label name="Serviced To" />
          <p className="text-sm mb-4">{selectedServicedTool.extra}</p>
        </div>

        <div>
          <Label name="Issue" />
          <p className="text-sm mb-4">{selectedServicedTool.comments}</p>
        </div>
      </div>

      <Table
        columns={toolServiceServicedToolColumns}
        data={
          <>
            {tools.length > 0 ? (
              tools.map((toolService, index) => (
                <tr key={index} className="hover:bg-gray-200 cursor-pointer">
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {`${toolService.tool.brand} - ${toolService.tool.description}`}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {`${toolService.tool.serial_no}`}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolService.tool.purchase_date
                        ? `${moment(toolService.tool.purchase_date)
                            .format("MMM D, Y")
                            .toString()}`
                        : "-- None specified --"}
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap">
                    <div className="text-xs text-gray-900">
                      {toolService.tool.warranty_expiry_date
                        ? `${moment(toolService.tool.warranty_expiry_date)
                            .format("MMM D, Y")
                            .toString()}`
                        : "-- None specified --"}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <div className="align-self-center">
                <p className="text-xs text-gray-600 text-center mt-4">
                  <i>No tools specified.</i>
                </p>
              </div>
            )}
          </>
        }
      />

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
