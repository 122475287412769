import axios from "axios";
import { tokenConfig } from "./auth";

import {
  SELECT_ATTENDANCE,
  GET_ATTENDANCES,
  LOADING_ATTENDANCES,
  LOADING_ATTENDANCE_DETAILS,
  GET_ATTENDANCE_DETAILS,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const AWS_URL = process.env.REACT_APP_AWS_URL;

export const getAttendances =
  (date, project = 0, search_term = "", page = 1) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_ATTENDANCES });
    if (project === 0) {
      //Get attendance from all projects
      axios
        .get(
          BASE_URL +
            `/attendance/all?date=${date}&search_term=${search_term}&page=${page}`,
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch({
            type: GET_ATTENDANCES,
            payload: res.data.data,
            pagination: {
              from: res.data.from,
              to: res.data.to,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            },
          });
        });
    } else {
      //Get attendance from selected project
      axios
        .get(
          BASE_URL +
            `/attendance/employee/project/web/${project}?date=${date}&search_term=${search_term}&page=${page}`,
          tokenConfig(getState)
        )
        .then((res) => {
          dispatch({
            type: GET_ATTENDANCES,
            payload: res.data.data,
            pagination: {
              from: res.data.from,
              to: res.data.to,
              total: res.data.total,
              last_page: res.data.last_page,
              current_page: res.data.current_page,
            },
          });
        });
    }
  };

// GET ATTENDANCE DETAILS
export const getAttendanceDetails =
  (employeeId, range, date) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTENDANCE_DETAILS });
    axios
      .get(
        BASE_URL +
          `/attendance/employee/${employeeId}?range=${range}&date=${date}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_ATTENDANCE_DETAILS,
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

// EXPORT ATTENDANCE
export const exportAttendance = (employeeId, date) => (dispatch, getState) => {
  axios
    .get(
      BASE_URL + `/attendance/attendance_export/${employeeId}?date=${date}`,
      tokenConfig(getState)
    )
    .then((res) => {
      var forceRefresh = new Date().getTime();
      var win = window.open(
        AWS_URL + "/" + res.data.data + "?" + forceRefresh,
        "_blank"
      );
      win.focus();
    })
    .catch((err) => {
      console.error(err);
    });
};

// SELECT ATTENDANCE
export const selectAttendance = (attendance) => (dispatch) => {
  dispatch({
    type: SELECT_ATTENDANCE,
    payload: attendance,
  });
};
