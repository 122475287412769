import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Label, Spinner, Attachment } from "../common";
import swal from "sweetalert";
import {
  generatePDF,
  selectDocumentation,
  deleteDocumentation,
} from "actions/documentations";

export default function ViewElectInspection(props) {
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const isFormLoading = useSelector(
    (state) => state.documentations.isFormLoading
  );

  const elect_ref = React.createRef();

  return (
    <div>
      {isFormLoading ? (
        <div className="items-center">
          <Spinner />
          <p className="text-center text-sm m-4">
            Please wait while we generate the PDF...
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 mb-4">
          <div>
            {/* <button
              onClick={() => {
                dispatch(generatePDF(selectedDocument, "elect"));
              }}
              className="bg-blue-500 hover:bg-blue-700 text-xs text-white m-2 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              Generate PDF
            </button> */}
            <Button
              text="Edit"
              primary="true"
              onClick={() => {
                props.onEditClick && props.onEditClick();
              }}
            />
          </div>
        </div>
      )}
      <hr />
      <div className="shadow-md">
        <div
          className="grid grid-cols-2 gap-4"
          ref={elect_ref}
          style={{
            paddingTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
          }}
        >
          <div>
            <Label name="Elect No." />
            <p className="text-sm mb-4">{selectedDocument.ref_no}</p>
          </div>
          <div>
            <Label name="Date" />
            <p className="text-sm mb-4">{selectedDocument.date}</p>
          </div>
          <div>
            <Label name="Location" />
            <p className="text-sm mb-4">{selectedDocument.location}</p>
          </div>
          <div>
            <Label name="Acceptable" />
            <p className="text-sm mb-4">{selectedDocument.acceptable}</p>
          </div>
          <div>
            <Label name="Description" />
            <p className="text-sm mb-4">{selectedDocument.description}</p>
          </div>
          <div style={{ display: "none" }}>
            <Label name="Comments" />
            <p className="text-sm mb-4">{selectedDocument.comments}</p>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="shadow-md">
        <div
          style={{
            paddingTop: 30,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 30,
          }}
        >
          <div>
            <Label name="Comments" />
            <p className="text-sm mb-4">{selectedDocument.comments}</p>
          </div>
        </div>
      </div>
      <br />
      <Label name="Attachments" />
      <div className="flex">
        {selectedDocument && selectedDocument.files ? (
          selectedDocument.files.map((file, index) => (
            <div key={index}>
              <Attachment path={file.full_path} />
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-xs">No attachments.</p>
        )}
      </div>
      <div className="float-right">
        <Button
          text="Delete"
          danger="true"
          onClick={() => {
            swal({
              icon: "warning",
              title: "Are you sure?",
              text: `Once deleted, selected elect inspection will be removed.`,
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                dispatch(deleteDocumentation(selectedDocument.id));
                props.onViewClose && props.onViewClose();
                selectDocumentation(null);
              }
            });
          }}
        />
      </div>
      <div className="flex mt-4">
        <Button
          text="Close"
          primary="true"
          disabled={isFormLoading}
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
        {selectedDocument.is_submitted === 1 && (
          <p className="text-gray-600 text-xs mt-4">
            Report already approved..
          </p>
        )}
      </div>
    </div>
  );
}
