import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { updateDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";

export default function EditElectInspection(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const selectedDocument = useSelector(
    (state) => state.documentations.selectedDocument
  );
  const [date, setDate] = useState(
    selectedDocument.date ? new Date(selectedDocument.date) : new Date()
  );

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    register("date");
    setValue(
      "date",
      moment(selectedDocument.date).format("YYYY-MM-DD").toString()
    );
  }, [register, setValue, selectedDocument]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onUpdate = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will update Elect Inspection.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        if (file) {
          data.base64image = [file];
        }

        dispatch(updateDocumentation(selectedDocument.id, data));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onUpdate)}>
      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Ref No" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ref_no"
              key={selectedDocument.ref_no}
              defaultValue={selectedDocument.ref_no}
              type="text"
              required
            />
          </div>
        </div>

        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="Location" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="location"
              type="text"
              key={selectedDocument.location}
              defaultValue={selectedDocument.location}
              readOnly={true}
              required
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Acceptable?" />
            <div className="mb-4">
              <input
                type="radio"
                ref={register}
                name="acceptable"
                value="Yes"
                className="h-5 w-5 text-gray-600"
                defaultChecked={selectedDocument.acceptable === "Yes"}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">Yes</span>

              <input
                type="radio"
                ref={register}
                name="acceptable"
                value="No"
                className="h-5 w-5 text-gray-600"
                defaultChecked={selectedDocument.acceptable === "No"}
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">No</span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Description" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="description"
              key={selectedDocument.description}
              defaultValue={selectedDocument.description}
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
              key={selectedDocument.comments}
              defaultValue={selectedDocument.comments}
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="File" />
          {selectedDocument.files.length > 0 && (
            <p>{`${selectedDocument.files.length} file uploaded. Re-attaching file would overwrite current uploaded file`}</p>
          )}
          <input
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>
      </div>
      <Button text="Update" success="true" type="submit" />
      <Button
        text="Close"
        primary="true"
        // disabled={isFormLoading}
        onClick={() => {
          props.onViewClose && props.onViewClose();
        }}
      />
    </form>
  );
}
