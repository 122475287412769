import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Spinner,
  Pagination,
  Attachment,
  EmptyPlaceholder,
} from "../../common";
import {
  getStocktakes,
  selectStocktake,
  deleteStocktake,
} from "../../../actions/commonStocktakes";
import { roles } from "../../../helpers/userRoles";
import swal from "sweetalert";

import CommonMaterialStocktakeAdd from "./CommonMaterialStocktakeAdd";
import CommonMaterialStocktakeView from "./CommonMaterialStocktakeView";

export default function CommonMaterialStocktakePage(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const stocktakes = useSelector((state) => state.commonStocktakes.stocktakes);
  const pagination = useSelector(
    (state) => state.commonStocktakes.stocktakesPagination
  );
  const isLoading = useSelector((state) => state.commonStocktakes.isLoading);
  const [pageNumber, setPageNumber] = useState(1);
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {
    dispatch(getStocktakes(pageNumber));
  }, [dispatch, pageNumber]);

  const onDelete = (stocktake) => {
    swal({
      title: "Are you sure?",
      icon: "error",
      text: "This will delete the stocktake record and this cannot be undone. Records adjusted will be reverted.",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteStocktake(stocktake.id));
      }
    });
  };

  return (
    <div className="mt-4">
      <div className="mb-4">
        <div className="flex justify-end">
          <Button
            text={`+ Create Stocktake`}
            primary="true"
            onClick={() => {
              setAddModal(true);
            }}
          />
        </div>
      </div>

      <table className="table-fixed w-100">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Date</th>
            <th className="text-xs px-4 py-2">Prepared by</th>
            <th className="text-xs px-4 py-2">Checked by</th>
            <th className="text-xs px-4 py-2">Operation</th>
            <th className="text-xs px-4 py-2">Attachment</th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {stocktakes.map((stocktake) => (
            <tr
              key={stocktake.id}
              className="hover:bg-gray-200"
              onClick={() => {
                dispatch(selectStocktake(stocktake));
              }}
            >
              <td className="text-xs border px-2 py-2">{stocktake.date}</td>
              <td className="text-xs border px-2 py-2">
                {stocktake.prepared_by &&
                  `${stocktake.prepared_by.first_name} ${stocktake.prepared_by.last_name}`}
              </td>
              <td className="text-xs border px-2 py-2">
                {stocktake.checked_by &&
                  `${stocktake.checked_by.first_name} ${stocktake.checked_by.last_name}`}
              </td>
              <td className="text-xs border px-2 py-2">{stocktake.action}</td>
              <td className="text-xs border px-2 py-2">
                {stocktake.attachment ? (
                  <Attachment path={stocktake.attachment_full_url} />
                ) : (
                  <p className="text-gray-600 text-xs">No attachments.</p>
                )}
              </td>
              <td className="text-xs border px-2 py-2">
                <Button
                  text={`View`}
                  primary="true"
                  onClick={() => {
                    dispatch(selectStocktake(stocktake));
                    setViewModal(true);
                  }}
                />
                {user.employee.role_id === roles.su && (
                  <>
                    <Button
                      danger="true"
                      text="Delete"
                      onClick={() => {
                        onDelete(stocktake);
                      }}
                    />
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {stocktakes < 1 && <EmptyPlaceholder text="No Stocktakes Found" />}

      {isLoading && <Spinner />}

      {pagination && !isLoading && (
        <Pagination
          from={pagination.from}
          to={pagination.to}
          total={pagination.total}
          lastPage={pagination.last_page}
          currentPage={pagination.current_page}
          onPageClick={(page) => {
            setPageNumber(page);
          }}
        />
      )}

      {addModal && (
        <Modal>
          <CommonMaterialStocktakeAdd
            onViewClose={() => {
              setAddModal(false);
            }}
          />
        </Modal>
      )}

      {viewModal && (
        <Modal>
          <CommonMaterialStocktakeView
            onViewClose={() => {
              setViewModal(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
}
