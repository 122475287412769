import * as yup from "yup";

export const EmployeeValidationSchema = yup.object().shape({
  company: yup
    .object()
    .required("Company is required. Please select a company")
    .nullable(true),
  role: yup
    .object()
    .required("Role is required. Please select a role")
    .nullable(true),
  unique_id: yup
    .string()
    .required("Employee ID is required. Please type an Employee ID")
    .nullable(true),
  first_name: yup
    .string()
    .required("First Name is required. Please type a first name")
    .nullable(true),
  last_name: yup
    .string()
    .required("Last Name is required. Please type a last name")
    .nullable(true),
  birthdate: yup
    .date()
    .required("Birth Date is required. Please pick a birth date")
    .nullable(true),
  nationality: yup
    .string()
    .required("Nationality is required. Please type a nationality")
    .nullable(true),
  contact_number: yup
    .string()
    .required("Contact Number is required. Please type a contact number")
    .nullable(true),
  start_date: yup
    .date()
    .required("Start Date is required. Please pick a start date.")
    .nullable(true),
  end_date: yup
    .date()
    .required("End Date is required. Please pick an end date.")
    .nullable(true),
});
