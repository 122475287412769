import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import { addDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";

export default function MyTasksAddSDICVI(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    register("date");
  }, [register]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const handleFileChange = (e) => {
    setSelectedFile(fileInputRef.current.files[0]);
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add SDI/CVI to the Task.`,
      buttons: true,
    }).then(async (willAdd) => {
      if (willAdd) {
        let file = null;
        if (selectedFile) {
          file = await ImageUtils.fileToBase64(selectedFile);
        }
        data.base64image = [file];
        data.type = "sdi_cvi";

        dispatch(
          addDocumentation(selectedTask.id, "tasks", data, selectedTask)
        );
        props.onTaskSubmit && props.onTaskSubmit();
        e.target.reset();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Add SDI/CVI</h1>
      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChange={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="Ref No" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="ref_no"
              type="text"
              required
            />
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-1/3">
            <Label name="Subject" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subject"
              type="text"
              required
            />
          </div>
          <div className="w-1/3 px-4">
            <Label name="SDI/CVI" />
            <div className="mb-4">
              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="si"
                className="h-5 w-5 text-gray-600"
                defaultChecked
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">SI</span>

              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="sd"
                className="h-5 w-5 text-gray-600"
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">SD</span>

              <input
                type="radio"
                ref={register}
                name="si_sd"
                value="cvi"
                className="h-5 w-5 text-gray-600"
              />
              <span className="ml-2 mr-2 text-gray-700 text-xs">CVI</span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="File" />
          <input
            ref={fileInputRef}
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
          />
        </div>
      </div>
      <Button text="Save" success="true" type="submit" />
    </form>
  );
}
