import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Label, Button } from "../common";
import moment from "moment";
import ImageUploader from "react-images-upload";
import swal from "sweetalert";
import { addDocumentation } from "../../actions/documentations";
import DatePicker from "react-datepicker";
import ImageUtils from "../../helpers/ImageUtils";
import "react-datepicker/dist/react-datepicker.css";

export default function MyTasksAddSiteSurvey(props) {
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [photos, setPhotos] = useState([]);
  const imageUploadRef = useRef(null);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);

  const onDrop = (photo) => {
    setPhotos(photo);
  };

  useEffect(() => {
    register("date");
  }, [register]);

  const handleDateChange = (date) => {
    setDate(date);
    setValue("date", moment(date).format("YYYY-MM-DD").toString());
  };

  const onSubmit = async (data, e) => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: `This will add Site Survey to the Task.`,
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let tempImg = [];
        for (let i = 0; i < photos.length; i++) {
          tempImg.push(await ImageUtils.resizeImage(photos[i]));
        }
        if (tempImg.length > 0) {
          data.base64image = tempImg;
        }
        data.type = "site_survey";

        dispatch(
          addDocumentation(selectedTask.id, "tasks", data, selectedTask)
        );
        props.onTaskSubmit && props.onTaskSubmit();
        e.target.reset();
      }
    });
  };

  setValue("date", moment(new Date()).format("YYYY-MM-DD").toString());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className="font-bold">Add New Site Survey</h1>
      <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>

      <div className="grid">
        <div className="flex">
          <div className="w-1/3">
            <Label name="Date" />
            <DatePicker
              showYearDropdown
              selected={date}
              dateFormat="yyyy-MM-dd"
              onChnge={handleDateChange}
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              style={{ zIndex: 10 }}
            />
          </div>

          <div className="w-1/3 px-4">
            <Label name="Subject" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              name="subject"
              type="text"
              required
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Description" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="description"
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="w-1/2">
            <Label name="Comments" />
            <textarea
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              ref={register}
              name="comments"
            />
          </div>
        </div>

        <div className="mt-4">
          <Label name="Photos" />
          <ImageUploader
            ref={imageUploadRef}
            withPreview={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
            fileTypeError={" - image not supported"}
            fileSizeError={" - image size too big"}
          />
        </div>
      </div>
      <Button text="Save" success="true" type="submit" />
    </form>
  );
}
