import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { getCompanies } from "../../actions/companies";
import { EmployeeValidationSchema } from "./EmployeeFormValidationSchema";
import { useYupValidationResolver } from "../../helpers/useYupValidationResolver";

import { Button, Label, CropPicUpload, CustomDatePicker, Tab } from "../common";
import moment from "moment";

import { addEmployee } from "../../actions/employees";
import {
  initialValueEmployee,
  civilStatusOptions,
} from "../../constants/employees";
import Select from "react-select";
import {
  useTransformOption,
  transformDate,
} from "../../helpers/useTransformData";
import swal from "sweetalert";

export default function EmployeeForm(props) {
  const companies = useSelector((state) => state.companies.companies);
  const roles = useSelector((state) => state.roles.roles);
  const dispatch = useDispatch();

  const companiesOption = useTransformOption(companies);
  const rolesOption = useTransformOption(roles);

  const [birthDate, setBirthDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [passportExpiryDate, setPassportExpiryDate] = useState(null);
  const [airportPassExpiryDate, setAirportPassExpiryDate] = useState(null);
  const [wpApplicationDate, setWpApplicationDate] = useState(null);
  const [wpExpiryDate, setWpExpiryDate] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [activeTab, setActiveTab] = useState("Information");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValueEmployee,
    resolver: useYupValidationResolver(EmployeeValidationSchema),
  });

  const onSubmit = (data, e) => {
    if (profilePicture) {
      data.avatar = profilePicture;
    } else {
      data.avatar = null;
    }
    data.start_date = transformDate(data.start_date);
    data.birthdate = transformDate(data.birthdate);
    data.end_date = transformDate(data.end_date);
    data.role_id = data.role.value;
    data.company_id = data.company.value;
    data.civil_status = data.civil_status.value;
    data.designation = "owner";
    data.employment_status = "employed";

    delete data.company;
    delete data.role;

    setSubmitting(true);
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "Confirm to add this Employee?",
      buttons: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(addEmployee(data));
      }
      // resetFunc();
    });
    setSubmitting(false);
  };

  const resetFunc = async () => {
    await reset();
    setBirthDate(null);
    setStartDate(null);
    setEndDate(null);
    setPassportExpiryDate(null);
    setAirportPassExpiryDate(null);
    setWpApplicationDate(null);
    setWpExpiryDate(null);
    setProfilePicture(null);
    setActiveTab("Information");
  };

  return (
    <div>
      <h1 className="text-sm font-extrabold text-gray-600 mb-4">
        Add Employee
      </h1>

      <Tab>
        <Tab.Item
          name="Information"
          isActive={activeTab === "Information"}
          onTabClick={() => setActiveTab("Information")}
        />
        <Tab.Item
          name="Identification"
          isActive={activeTab === "Identification"}
          onTabClick={() => setActiveTab("Identification")}
        />
        <Tab.Item
          name="Salary Details"
          isActive={activeTab === "Salary Details"}
          onTabClick={() => setActiveTab("Salary Details")}
        />
      </Tab>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: activeTab === "Information" ? "block" : "none",
          }}
        >
          <div className="flex">
            <div className="mb-2 p-2 w-100">
              <Label name="Upload Profile Picture" />
              <CropPicUpload
                onSaveCroppedImage={(image) => {
                  setProfilePicture(image);
                }}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Company" />
              <Controller
                control={control}
                name="company"
                rules={{ required: true }}
                defaultValue={initialValueEmployee.company_id}
                render={(props) => (
                  <Select
                    {...props}
                    className="text-xs shadow-sm"
                    options={companiesOption}
                  />
                )}
              />
              {errors?.company && (
                <span className="text-xs text-red-600">
                  {errors?.company?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Role" />
              <Controller
                as={Select}
                options={rolesOption}
                defaultValue={initialValueEmployee.role_id}
                name="role"
                control={control}
                className="text-xs"
              />
              {errors?.role && (
                <span className="text-xs text-red-600">
                  {errors?.role?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Employee Code" />
              <Controller
                control={control}
                name="unique_id"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.unique_id && (
                <span className="text-xs text-red-600">
                  {errors?.unique_id?.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="First Name" />
              <Controller
                control={control}
                name="first_name"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.first_name && (
                <span className="text-xs text-red-600">
                  {errors?.first_name?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Last Name" />
              <Controller
                control={control}
                name="last_name"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.last_name && (
                <span className="text-xs text-red-600">
                  {errors?.last_name?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Gender" />
              <div className="flex">
                <div className="flex">
                  <Controller
                    control={control}
                    name="gender"
                    render={(props) => (
                      <input
                        {...props}
                        type="radio"
                        className="h-5 w-5 text-gray-600"
                        value="male"
                        name="gender"
                        defaultChecked
                      />
                    )}
                  />
                  <span className="ml-2 mr-2 text-gray-700 text-xs">Male</span>
                </div>

                <div className="flex">
                  <Controller
                    control={control}
                    name="gender"
                    render={(props) => (
                      <input
                        {...props}
                        type="radio"
                        className="h-5 w-5 text-gray-600"
                        value="female"
                        name="gender"
                      />
                    )}
                  />
                  <span className="ml-2 mr-2 text-gray-700 text-xs">
                    Female
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Birth Date" />
              <Controller
                control={control}
                name="birthdate"
                defaultValue={initialValueEmployee.birthdate}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={birthDate}
                    value={birthDate}
                    onChange={(date) => {
                      setBirthDate(date);
                      setValue("birthdate", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
              {errors?.birthdate && (
                <span className="text-xs text-red-600">
                  {errors?.birthdate?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Civil Status" />
              <Controller
                as={Select}
                options={civilStatusOptions}
                defaultValue={{ value: "Single", label: "Single" }}
                name="civil_status"
                control={control}
                className="text-xs"
              />
              {errors?.civil_status && (
                <span className="text-xs text-red-600">
                  {errors?.civil_status?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Nationality" />
              <Controller
                control={control}
                name="nationality"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.nationality && (
                <span className="text-xs text-red-600">
                  {errors?.nationality?.message}
                </span>
              )}
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Contact Number" />
              <Controller
                control={control}
                name="contact_number"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
              {errors?.contact_number && (
                <span className="text-xs text-red-600">
                  {errors?.contact_number?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Start Date" />
              <Controller
                control={control}
                name="start_date"
                defaultValue={initialValueEmployee.start_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={startDate}
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setValue("start_date", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
              {errors?.start_date && (
                <span className="text-xs text-red-600">
                  {errors?.start_date?.message}
                </span>
              )}
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="End Date" />
              <Controller
                control={control}
                name="end_date"
                defaultValue={initialValueEmployee.end_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={endDate}
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setValue("end_date", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
              {errors?.end_date && (
                <span className="text-xs text-red-600">
                  {errors?.end_date?.message}
                </span>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: activeTab === "Identification" ? "block" : "none",
          }}
        >
          <div style={{ height: 200 }}></div>
          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Pass Type" />
              <Controller
                control={control}
                name="pass_type"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Passport No." />
              <Controller
                control={control}
                name="passport_no"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Passport Expiry Date" />
              <Controller
                control={control}
                name="passport_expiry_date"
                defaultValue={initialValueEmployee.passport_expiry_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={passportExpiryDate}
                    value={passportExpiryDate}
                    onChange={(date) => {
                      setPassportExpiryDate(date);
                      setValue("passport_expiry_date", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Airport Pass Expiry Date" />
              <Controller
                control={control}
                name="airport_pass_expiry_date"
                defaultValue={initialValueEmployee.airport_pass_expiry_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={airportPassExpiryDate}
                    value={airportPassExpiryDate}
                    onChange={(date) => {
                      setAirportPassExpiryDate(date);
                      setValue(
                        "airport_pass_expiry_date",
                        transformDate(date),
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="MYE" />
              <Controller
                control={control}
                name="mye"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="NRIC/FIN" />
              <Controller
                control={control}
                name="nric"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="WP No." />
              <Controller
                control={control}
                name="wp_no"
                render={(props) => (
                  <input
                    {...props}
                    type="text"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="WP Application Date" />
              <Controller
                control={control}
                name="wp_application_date"
                defaultValue={initialValueEmployee.wp_application_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={wpApplicationDate}
                    value={wpApplicationDate}
                    onChange={(date) => {
                      setWpApplicationDate(date);
                      setValue("wp_application_date", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="WP Expiry Date" />
              <Controller
                control={control}
                name="wp_expiry_date"
                defaultValue={initialValueEmployee.wp_expiry_date}
                render={(props) => (
                  <CustomDatePicker
                    {...props}
                    selected={wpExpiryDate}
                    value={wpExpiryDate}
                    onChange={(date) => {
                      setWpExpiryDate(date);
                      setValue("wp_expiry_date", transformDate(date), {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div
          style={{ display: activeTab === "Salary Details" ? "block" : "none" }}
        >
          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Basic Pay" />
              <Controller
                control={control}
                name="basic_pay"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-2 p-2 w-1/3">
              <Label name="Daily Rate" />
              <Controller
                control={control}
                name="daily_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-2 p-2 w-1/3">
              <Label name="Sunday Rate" />
              <Controller
                control={control}
                name="sunday_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Holiday Rate" />
              <Controller
                control={control}
                name="holiday_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-2 p-2 w-1/3">
              <Label name="OT Rate (Hourly)" />
              <Controller
                control={control}
                name="ot_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>

            <div className="mb-2 p-2 w-1/3">
              <Label name="Night Shift Allowance" />
              <Controller
                control={control}
                name="night_shift_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 p-2 w-1/3">
              <Label name="Food Allowance" />
              <Controller
                control={control}
                name="food_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-2 p-2 w-1/3">
              <Label name="Dinner Allowance" />
              <Controller
                control={control}
                name="dinner_rate"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
            <div className="mb-2 p-2 w-1/3">
              <Label name="Levy Payment" />
              <Controller
                control={control}
                name="levy_payment"
                render={(props) => (
                  <input
                    {...props}
                    type="number"
                    step="any"
                    className="text-xs shadow-sm rounded w-full py-2 px-3 mr-2 text-gray-700 focus:ring-2 focus:ring-blue-600"
                    style={{ border: "1px solid #ccc" }}
                    autoComplete="off"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="justify-center flex mt-4 px-4">
          <Button
            text="Save"
            success="true"
            type="submit"
            disabled={submitting}
          />

          <Button
            text="Close"
            primary="true"
            onClick={() => {
              props.onViewClose && props.onViewClose();
            }}
          />
        </div>
      </form>
    </div>
  );
}
