import { SET_ACTIVE_SIDEBAR_NAV, TOGGLE_SIDEBAR } from "./types";

//SET ACTIVE SIDEBAR NAV
export const setActiveSidebarNav = (activeNav) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_SIDEBAR_NAV,
    payload: activeNav,
  });
};

//TOGGLE SIDEBAR
export const toggleSidebar = () => (dispatch, getState) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: !getState().general.sidebarVisible,
  });
};
