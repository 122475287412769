import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getLeaveCredits, updateLeaveCredits } from "../../actions/employees";
import generateYears from "../../helpers/generateYears";
import { Label, Button } from "components/common";
import swal from "sweetalert";

export default function LeaveDetailsForm(props) {
  const dispatch = useDispatch();
  const selectedEmployee = useSelector(
    (state) => state.employees.selectedEmployee
  );
  const selectedEmployeeLeaveCredits = useSelector(
    (state) => state.employees.selectedEmployeeLeaveCredits
  );
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState({
    value: currentYear,
    label: currentYear,
  });
  const [annualLeave, setAnnualLeave] = useState(0);
  const [sickLeave, setSickLeave] = useState(0);
  const [hospitalizationLeave, setHospitalizationLeave] = useState(0);
  const [marriageLeave, setMarriageLeave] = useState(0);
  const [compassionateLeave, setCompassionateLeave] = useState(0);
  const [childcareLeave, setChildcareLeave] = useState(0);
  const [extendedChildcareLeave, setExtendedChildcareLeave] = useState(0);
  const [maternityLeave, setMaternityLeave] = useState(0);
  const [paternityLeave, setPaternityLeave] = useState(0);
  const [unpaidLeave, setUnpaidLeave] = useState(0);
  const [enableEditing, setEnableEditing] = useState(false);

  // const editDisabled = false;
  const { active, register } = props;

  let dropdownYears = generateYears();

  useEffect(() => {
    dispatch(getLeaveCredits(selectedEmployee.id, selectedYear.value));
  }, [dispatch, selectedEmployee, selectedYear]);

  useEffect(() => {
    setAnnualLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.annual : 0
    );
    setSickLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.sick : 0
    );
    setHospitalizationLeave(
      selectedEmployeeLeaveCredits
        ? selectedEmployeeLeaveCredits.hospitalization
        : 0
    );
    setMarriageLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.marriage : 0
    );
    setCompassionateLeave(
      selectedEmployeeLeaveCredits
        ? selectedEmployeeLeaveCredits.compassionate
        : 0
    );

    setChildcareLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.childcare : 0
    );

    setExtendedChildcareLeave(
      selectedEmployeeLeaveCredits
        ? selectedEmployeeLeaveCredits.extended_childcare
        : 0
    );

    setMaternityLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.maternity : 0
    );

    setPaternityLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.paternity : 0
    );

    setUnpaidLeave(
      selectedEmployeeLeaveCredits ? selectedEmployeeLeaveCredits.unpaid : 0
    );
  }, [selectedEmployeeLeaveCredits]);

  const onSubmit = () => {
    swal({
      icon: "warning",
      title: `Are you sure?`,
      text: `Just making sure that you want to update leave credits for ${selectedYear.value}.`,
      buttons: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        const data = {
          annual_leave: annualLeave,
          sick_leave: sickLeave,
          hospitalization_leave: hospitalizationLeave,
          marriage_leave: marriageLeave,
          compassionate_leave: compassionateLeave,
          childcare_leave: childcareLeave,
          extended_childcare_leave: extendedChildcareLeave,
          maternity_leave: maternityLeave,
          paternity_leave: paternityLeave,
          employee_id: selectedEmployee.id,
          year: selectedYear.value,
        };

        dispatch(updateLeaveCredits(data));
      }
    });
  };

  if (active) {
    return (
      <div>
        {console.log("unpaidLeave", unpaidLeave)}
        <div className="flex mt-4 mb-4">
          <div className="w-1/2 px-2">
            <Label name="Select Year" />
            <Select
              options={dropdownYears}
              value={selectedYear}
              onChange={setSelectedYear}
              className="text-xs"
            />
          </div>

          <div className="w-1/2 px-2 mt-4 ml-4">
            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                name="enable_editing"
                id="toggle"
                checked={enableEditing}
                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                onChange={(event) => {
                  setEnableEditing(event.currentTarget.checked);
                }}
              />
              <label
                htmlFor="toggle"
                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>
            <label htmlFor="toggle" className="text-xs text-gray-700">
              Enable Editing
            </label>
          </div>
        </div>
        <table className="table-fixed" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="text-xs px-4 py-2">
                Leave
                <br />
                Type
              </th>
              <th className="text-xs px-4 py-2">
                Available
                <br />
                Credits
              </th>
              <th className="text-xs px-4 py-2">
                Used
                <br />
                Credits
              </th>
              <th className="text-xs px-4 py-2">Remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Annual Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="annual_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setAnnualLeave(e.target.value)}
                  value={annualLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_annual
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(annualLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_annual
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Sick Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="sick_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setSickLeave(e.target.value)}
                  value={sickLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_sick
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(sickLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_sick
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">
                Hospitalization Leave
              </td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="hospitalization_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setHospitalizationLeave(e.target.value)}
                  value={hospitalizationLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_hospitalization
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(hospitalizationLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_hospitalization
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Marriage Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="marriage_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setMarriageLeave(e.target.value)}
                  value={marriageLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_marriage
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(marriageLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_marriage
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Compassionate Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="compassionate_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setCompassionateLeave(e.target.value)}
                  value={compassionateLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_compassionate
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(compassionateLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_compassionate
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Childcare Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="childcare_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setChildcareLeave(e.target.value)}
                  value={childcareLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_childcare
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(childcareLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_childcare
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">
                Extended Childcare Leave
              </td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="extended_childcare_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setExtendedChildcareLeave(e.target.value)}
                  value={extendedChildcareLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_extended_childcare
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(extendedChildcareLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_extended_childcare
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Maternity Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="maternity_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setMaternityLeave(e.target.value)}
                  value={maternityLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_maternity
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(maternityLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_maternity
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Paternity Leave</td>
              <td className="border px-2 py-2 text-xs">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  ref={register}
                  name="paternity_leave"
                  type="number"
                  step="any"
                  onChange={(e) => setPaternityLeave(e.target.value)}
                  value={paternityLeave}
                  disabled={!enableEditing}
                />
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.used_paternity
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {(
                  parseFloat(paternityLeave || 0) -
                  parseFloat(
                    selectedEmployeeLeaveCredits
                      ? selectedEmployeeLeaveCredits.used_paternity
                      : 0
                  )
                ).toFixed(1)}
              </td>
            </tr>

            <tr className="hover:bg-gray-200">
              <td className="border px-2 py-2 text-xs">Unpaid Leave</td>
              <td className="border px-2 py-2 text-xs">-</td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.unpaid
                  : 0}
              </td>
              <td className="border px-2 py-2 text-xs">
                {selectedEmployeeLeaveCredits
                  ? selectedEmployeeLeaveCredits.unpaid
                  : 0}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="justify-center flex mt-4 px-4">
          <Button
            text="Save Changes"
            success="true"
            type="button"
            disabled={!enableEditing}
            onClick={() => onSubmit()}
          />
          <Button
            text="Close"
            primary="true"
            onClick={() => {
              props.onViewClose && props.onViewClose();
            }}
          />
        </div>
      </div>
    );
  }
  return <div />;
}
