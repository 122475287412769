import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  LOADING_TOOLS,
  GET_TOOLS,
  GET_TOOL_MOVEMENTS,
  GET_UNISSUED_TOOLS,
  ADD_TOOL,
  UPDATE_TOOL,
  DELETE_TOOL,
  GET_TOOL_FILTERS,
  GET_SCRAPPED_TOOLS,
  UNSCRAP_TOOL,
  SELECT_SCRAPPED_TOOL,
  GET_SERVICED_TOOLS,
  SELECT_SERVICED_TOOL,
  SELECT_TOOL,
  GET_TOOL_REQUESTS,
  GET_PROJECT_TOOL_REQUESTS,
  SELECT_TOOL_REQUEST,
  APPROVE_TOOL_REQUEST,
  DENY_TOOL_REQUEST,
  ISSUE_TOOL,
  GET_TOOL_DELIVERIES,
  SELECT_TOOL_DELIVERY,
  ADD_TOOL_CATEGORY,
  UPDATE_TOOL_CATEGORY,
  DELETE_TOOL_CATEGORY,
  GET_TOOL_CATEGORIES,
  SELECT_TOOL_CATEGORY,
  ADD_REQUEST_TOOL,
  ADD_SCRAP_TOOL,
  ADD_SERVICE_TOOL,
  RETURN_TOOL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET TOOLS
export const getTools =
  (
    search_term,
    page,
    company = "",
    project = "",
    condition = "",
    category = ""
  ) =>
  (dispatch, getState) => {
    dispatch({ type: LOADING_TOOLS });
    axios
      .get(
        BASE_URL +
          `/tools?search_term=${search_term}&company=${company}&project=${project}&condition=${condition}&category=${category}&page=${page}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_TOOLS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// GET TOOL MOVEMENTS
export const getToolMovements = (id) => (dispatch, getState) => {
  axios
    .get(BASE_URL + `/tool/${id}/movements`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TOOL_MOVEMENTS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

export const getUnissuedTools = () => (dispatch, getState) => {
  axios
    .get(
      BASE_URL + `/tools?unissued_only=true&is_mobile=true`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_UNISSUED_TOOLS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// GET TOOL FILTERS
export const getToolFilters = () => (dispatch, getState) => {
  axios
    .get(
      BASE_URL +
        `/tools/get?companies=true&projects=true&employees=true&categories=true&drivers=true`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_TOOL_FILTERS,
        payload: res.data,
      });
    })
    .catch((err) => console.error(err));
};

// SELECT TOOL
export const selectTool = (tool) => (dispatch) => {
  dispatch({
    type: SELECT_TOOL,
    payload: tool,
  });
};

// GET SCRAPPED TOOLS
export const getScrappedTools = (pageNumber) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .get(
      BASE_URL + `/tools/scrapped?is_web=true&page=${pageNumber}`,
      tokenConfig(getState)
    )
    .then((res) => {
      console.log(res, "SCRAPPED");
      dispatch({
        type: GET_SCRAPPED_TOOLS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT SCRAPPED TOOL
export const selectScrappedTool = (scrappedTool) => (dispatch) => {
  dispatch({
    type: SELECT_SCRAPPED_TOOL,
    payload: scrappedTool,
  });
};

// UNSCRAP TOOL
export const unscrapTool = (data) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/tool-movement/unscrap`, data, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UNSCRAP_TOOL,
        payload: data.tool_movement_id,
      });
      swal({
        title: "Unscrapped Tool",
        text: "Tool now back on the main list.",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// GET SERVICED TOOLS
export const getServicedTools = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .get(BASE_URL + `/tools/serviced?page=${page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SERVICED_TOOLS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT SERVICED TOOL
export const selectServicedTool = (servicedTool) => (dispatch) => {
  dispatch({
    type: SELECT_SERVICED_TOOL,
    payload: servicedTool,
  });
};

// GET TOOL DELIVERIES
export const getToolDeliveries = (status, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .get(
      BASE_URL + `/tools/issued?status=${status}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_TOOL_DELIVERIES,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// SELECT TOOL DELIVERY
export const selectToolDelivery = (toolDelivery) => (dispatch) => {
  dispatch({
    type: SELECT_TOOL_DELIVERY,
    payload: toolDelivery,
  });
};

// GET TOOL REQUESTS
export const getToolRequests = (status, page) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .get(
      BASE_URL + `/tools/requests?status=${status}&page=${page}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_TOOL_REQUESTS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// GET PROJECT TOOL REQUESTS
export const getProjectToolRequests =
  (projectId, page) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOOLS });
    axios
      .get(
        BASE_URL + `/tools/requests?project_id=${projectId}&page=${page}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PROJECT_TOOL_REQUESTS,
          payload: res.data.data,
          pagination: {
            from: res.data.from,
            to: res.data.to,
            total: res.data.total,
            last_page: res.data.last_page,
            current_page: res.data.current_page,
          },
        });
      })
      .catch((err) => console.error(err));
  };

// SELECT TOOL REQUEST
export const selectToolRequest = (toolRequest) => (dispatch) => {
  dispatch({
    type: SELECT_TOOL_REQUEST,
    payload: toolRequest,
  });
};

// APPROVE TOOL REQUEST
export const approveToolRequest =
  (requestId, comments) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOOLS });

    let data = {
      request_id: requestId,
      comments,
    };

    axios
      .post(BASE_URL + `/tools/approve-request`, data, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: APPROVE_TOOL_REQUEST,
          payload: {
            id: requestId,
            comments: comments,
          },
        });
        swal({
          title: "Tool Request Approved",
          text: "Request can now be finally issued.",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// DENY TOOL REQUEST
export const denyToolRequest =
  (requestId, comments) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOOLS });

    let data = {
      request_id: requestId,
      comments,
    };

    // const payload = new FormData();

    // payload.append("request_id", requestId);
    // payload.append("comments", comments);

    axios
      .put(
        BASE_URL + `/tool/request/${requestId}/deny`,
        data,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: DENY_TOOL_REQUEST,
          payload: {
            id: requestId,
            denied_comments: comments,
          },
        });
        swal({
          title: "Tool Request Denied",
          text: "This will notify the requester.",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// GET TOOL CATEGORIES
export const getToolCategories = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .get(BASE_URL + `/tool_categories?page=${page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TOOL_CATEGORIES,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// ADD TOOL CATEGORY
export const addToolCategory = (toolCategory) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .post(BASE_URL + `/tool_category`, toolCategory, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_TOOL_CATEGORY,
        payload: res.data,
      });
      swal({
        title: "Tool Category Added",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE TOOL CATEGORY
export const updateToolCategory =
  (id, toolCategory) => (dispatch, getState) => {
    dispatch({ type: LOADING_TOOLS });
    axios
      .put(
        BASE_URL + `/tool_category/${id}`,
        toolCategory,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: UPDATE_TOOL_CATEGORY,
          payload: res.data,
        });
        swal({
          title: "Tool Category Updated!",
          icon: "success",
        });
      })
      .catch((err) => console.error(err));
  };

// DELETE TOOL CATEGORY
export const deleteToolCategory = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .delete(BASE_URL + `/tool_category/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_TOOL_CATEGORY,
        payload: id,
      });
      swal({
        title: "Tool Category Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// SELECT TOOL CATEGORY
export const selectToolCategory = (toolCategory) => (dispatch) => {
  dispatch({
    type: SELECT_TOOL_CATEGORY,
    payload: toolCategory,
  });
};

// ADD TOOL
export const addTool = (tool) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .post(BASE_URL + `/tool`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_TOOL,
        payload: res.data,
      });
      swal({
        title: "Tool Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// UPDATE TOOL
export const updateTool = (id, tool) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .put(BASE_URL + `/tool/${id}`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_TOOL,
        payload: res.data,
      });
      swal({
        title: "Tool Updated!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// DELETE TOOL
export const deleteTool = (id) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .delete(BASE_URL + `/tool/${id}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: DELETE_TOOL,
        payload: id,
      });
      swal({
        title: "Tool Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

// REQUEST TOOL
export const addRequestTool = (tool) => (dispatch, getState) => {
  dispatch({ type: LOADING_TOOLS });
  axios
    .post(BASE_URL + `/tool/request`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_REQUEST_TOOL,
        payload: res.data,
      });
      swal({
        title: "Request Tool Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const addScrapTool = (tool) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/tools/scrap`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_SCRAP_TOOL,
        payload: tool,
      });
      swal({
        title: "Scrap Tool Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const addServiceTool = (tool) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/tools/repair`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_SERVICE_TOOL,
        payload: tool,
      });
      swal({
        title: "Service Tool Added!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const issueTool = (tool) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/tool/issue`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ISSUE_TOOL,
        payload: res.data,
      });
      swal({
        title: "Tool Issued!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};

export const returnTool = (tool, id) => (dispatch, getState) => {
  axios
    .post(BASE_URL + `/tools/return`, tool, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: RETURN_TOOL,
        payload: id,
      });
      swal({
        title: "Tool Returned!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
