import React, { useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Button, Fieldset, Typography, Label } from "../common";
import Select from "react-select";

import { sendAnnouncement } from "actions/announcements";
import { roleOptions } from "helpers/userRoles";
import swal from "sweetalert";

export default function AnnouncementPage(props) {
  const dispatch = useDispatch();
  const [sendTo, setSendTo] = useState("management");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);

  const handleRolesChange = (e) => {
    setSelectedRoles(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const onSubmit = () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "Confirm sending notifications to mobile users? Please ensure that the information are free from errors. This cannot be reverted.",
      buttons: true,
    }).then((willAdd) => {
      let data = {
        send_to: sendTo,
        title: title,
        description: description,
        role_ids: selectedRoles,
      };
      if (willAdd) {
        dispatch(sendAnnouncement(data));
        onReset();
      }
    });
  };

  const onReset = () => {
    setSendTo("management");
    setTitle("");
    setDescription("");
    setSelectedRoles([]);
  };

  return (
    <div className="m-12">
      <Fieldset>
        <Typography type="h1">Announcements</Typography>
        <Typography type="muted">
          Send Announcements to mobile app users via push notifications.
        </Typography>

        <div class="mt-4 mb-2">
          <Typography type="p">Make an Announcement</Typography>
        </div>
        <hr />

        <div className="flex mt-2">
          <div className="mb-4 w-full mr-2">
            <Label name="Send to:" />
            <div className="flex mt-4">
              <div className="flex">
                <input
                  type="radio"
                  name="send_to"
                  value="management"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={sendTo === "management"}
                  onClick={() => {
                    setSendTo("management");
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  Management
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="send_to"
                  value="role"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={sendTo === "role"}
                  onClick={() => {
                    setSendTo("role");
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  Specified Role/s
                </span>
              </div>

              <div className="flex">
                <input
                  type="radio"
                  name="send_to"
                  value="role"
                  className="h-5 w-5 text-gray-600"
                  defaultChecked={sendTo === "general"}
                  onClick={() => {
                    setSendTo("general");
                  }}
                />
                <span className="ml-2 mr-2 text-gray-700 text-xs">
                  General (all users)
                </span>
              </div>
            </div>
          </div>

          {sendTo === "role" && (
            <div className="mb-4 w-full ml-2">
              <Label name="Select role/s:" />
              <Select
                isMulti
                isClearable
                options={roleOptions}
                onChange={handleRolesChange}
                className="text-xs"
              />
            </div>
          )}
        </div>

        <div className="mt-2">
          <Label name="Title" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="mt-2 mb-4">
          <Label name="Description" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-non focus:shadow-outline"
            autoComplete="off"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div class="mt-2">
          <Button
            success="true"
            text="Send Announcement"
            disabled={!title || !description}
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </Fieldset>
    </div>
  );
}
