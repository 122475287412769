import {
  GET_MY_TASKS,
  GET_TASKS_DROPDOWN,
  GET_UNACCEPTED_TASKS,
  ACCEPT_TASK,
  UPDATE_TASK,
  SELECT_TASK,
  SELECT_MAIN_TASK,
  SELECT_MY_TASK,
  GET_SUBTASKS,
  LOADING_TASKS,
  ADD_TASK,
  ASSIGN_TO_TASK,
  UNASSIGN_TO_TASK,
} from "../actions/types";

const initialState = {
  tasks: [],
  tasksDropdown: [],
  unacceptedTasks: [],
  subtasks: [],
  selectedTask: null,
  selectedMainTask: null,
  selectedMyTask: null,
  tasksLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TASKS:
      return {
        ...state,
        tasksLoading: true,
      };
    case GET_MY_TASKS:
      return {
        ...state,
        tasksLoading: false,
        tasks: action.payload,
      };
    case GET_TASKS_DROPDOWN:
      let result = [];
      if (action.payload) {
        const tasks = action.payload;
        for (let i in tasks) {
          result.push({
            value: tasks[i].id,
            label: tasks[i].title,
          });
        }
      }
      return {
        ...state,
        tasksDropdown: result,
      };
    case ADD_TASK:
      return {
        ...state,
        tasksLoading: false,
        tasks: [...state.tasks, action.payload],
      };
    case UPDATE_TASK:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          start_date: action.payload.response.start_date,
          end_date: action.payload.response.end_date,
          description: action.payload.response.description,
          new_updates: action.payload.response.new_updates,
          is_inspected: action.payload.response.is_inspected,
          current_percentage: action.payload.input.current_percentage,
          percentage: action.payload.input.current_percentage,
          map_full_url: action.payload.response.map_full_url,
          map2_full_url: action.payload.response.map2_full_url,
          map3_full_url: action.payload.response.map3_full_url,
          map4_full_url: action.payload.response.map4_full_url,
          map5_full_url: action.payload.response.map5_full_url,
        },
      };
    case ASSIGN_TO_TASK:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          workers: [...state.selectedTask.workers, action.payload],
        },
      };
    case UNASSIGN_TO_TASK:
      return {
        ...state,
        selectedTask: {
          ...state.selectedTask,
          workers: state.selectedTask.workers.filter(
            (worker) => worker.id !== action.payload
          ),
        },
      };
    case GET_UNACCEPTED_TASKS:
      return {
        ...state,
        tasksLoading: false,
        unacceptedTasks: action.payload,
      };
    case ACCEPT_TASK:
      return {
        ...state,
        tasksLoading: false,
      };
    case SELECT_TASK:
      return {
        ...state,
        selectedTask: action.payload,
      };
    case SELECT_MAIN_TASK:
      return {
        ...state,
        selectedMainTask: action.payload,
      };
    case SELECT_MY_TASK:
      return {
        ...state,
        selectedMyTask: action.payload,
      };
    case GET_SUBTASKS:
      return {
        ...state,
        subtasks: action.payload,
        tasksLoading: false,
      };
    default:
      return state;
  }
}
