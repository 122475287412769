import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployeeScreen } from "../../actions/employees";
import { Label } from "components/common";

const Form = (props) => {
  const dispatch = useDispatch();
  const { active, register, selectedEmployee } = props;
  const isUpdateEmployeeScreen = useSelector(
    (state) => state.employees.isUpdateEmployeeScreen
  );
  const [enableEditing, setEnableEditing] = useState(false);

  useEffect(() => {
    if (isUpdateEmployeeScreen === true) {
      setEnableEditing(false);
      dispatch(updateEmployeeScreen());
    }
  }, [dispatch, isUpdateEmployeeScreen]);

  const [error, setError] = useState({ type: "", message: "" });

  if (active) {
    return (
      <div>
        <div className="w-1/2" style={{ marginLeft: 18, marginTop: 10 }}>
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              type="checkbox"
              name="enable_editing"
              id="toggle"
              checked={enableEditing}
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              onChange={(event) => {
                setEnableEditing(event.currentTarget.checked);
              }}
            />
            <label
              htmlFor="toggle"
              className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
          <label htmlFor="toggle" className="text-xs text-gray-700">
            Enable Editing
          </label>
        </div>
        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Basic Pay" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.basic_pay}
              defaultValue={selectedEmployee.basic_pay}
              name="basic_pay"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "BasicPay",
                      message: "Basic Pay must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "BasicPay" && error ? error.message : ""}
            </p>
          </div>

          <div className="w-1/2 px-2">
            <Label name="Daily Rate" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.daily_rate}
              defaultValue={selectedEmployee.daily_rate}
              name="daily_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "DailyRate",
                      message: "Daily Rate must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "DailyRate" && error ? error.message : ""}
            </p>
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Sunday Rate" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.sunday_rate}
              defaultValue={selectedEmployee.sunday_rate}
              name="sunday_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "SundayRate",
                      message: "Sunday Rate must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "SundayRate" && error ? error.message : ""}
            </p>
          </div>

          <div className="w-1/2 px-2">
            <Label name="Holiday Rate" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.holiday_rate}
              defaultValue={selectedEmployee.holiday_rate}
              name="holiday_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "HolidayRate",
                      message: "Holiday Rate must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "HolidayRate" && error ? error.message : ""}
            </p>
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="OT Rate (Hourly)" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.ot_rate}
              defaultValue={selectedEmployee.ot_rate}
              name="ot_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "OtRate",
                      message: "OT Rate must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "OtRate" && error ? error.message : ""}
            </p>
          </div>

          <div className="w-1/2 px-2">
            <Label name="Night Shift Allowance" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.night_shift_rate}
              defaultValue={selectedEmployee.night_shift_rate}
              name="night_shift_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "NightShift",
                      message: "Night Shift Allowance must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "NightShift" && error ? error.message : ""}
            </p>
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Food Allowance" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.food_rate}
              defaultValue={selectedEmployee.food_rate}
              name="food_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "FoodAllowance",
                      message: "Food Allowance must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "FoodAllowance" && error ? error.message : ""}
            </p>
          </div>

          <div className="w-1/2 px-2">
            <Label name="Dinner Allowance" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.dinner_rate}
              defaultValue={selectedEmployee.dinner_rate}
              name="dinner_rate"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "DinnerAllowance",
                      message: "Dinner Allowance must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "DinnerAllowance" && error ? error.message : ""}
            </p>
          </div>
        </div>

        <div className="flex mt-4 px-4">
          <div className="w-1/2 px-2">
            <Label name="Levy Payment" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.levy_payment}
              defaultValue={selectedEmployee.levy_payment}
              name="levy_payment"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "LevyPay",
                      message: "Levy Payment must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "LevyPay" && error ? error.message : ""}
            </p>
          </div>

          <div className="w-1/2 px-2">
            <Label name="Sat Allowance" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              ref={register}
              key={selectedEmployee.sat_allowance}
              defaultValue={selectedEmployee.sat_allowance}
              name="sat_allowance"
              step="any"
              disabled={!enableEditing}
              onChange={(event) =>
                isNaN(event.target.value)
                  ? setError({
                      type: "SatAllowance",
                      message: "Sat Allowance must be numeric.",
                    })
                  : setError("")
              }
            />
            <p style={{ color: "red", fontSize: 12 }}>
              {error.type === "SatAllowance" && error ? error.message : ""}
            </p>
          </div>

          {/* <div className="w-1/2 px-2">
                        <Label name="D.O.A." />
                        <DatePicker showYearDropdown 
                          selected={new Date(selectedEmployee.doa)}
                          name="doa"
                          ref={register}
                          dateFormat="yyyy-MM-dd"
                          // onChange={(date) => setAttendanceDate(date)}
                          className="text-xs shadow appearance-none border rounded w-full py-2 px-3"
                        />
                      </div> */}
        </div>
      </div>
    );
  }
  return <div />;
};

export default Form;
