import {
  GET_COMMON_MATERIAL_CATEGORIES,
  ADD_COMMON_MATERIAL_CATEGORY,
  UPDATE_COMMON_MATERIAL_CATEGORY,
  DELETE_COMMON_MATERIAL_CATEGORY,
  SELECT_COMMON_MATERIAL_CATEGORY,
} from "../actions/types";

const initialState = {
  commonMaterialCategories: [],
  selectedCommonMaterialCategory: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMON_MATERIAL_CATEGORIES:
      return {
        ...state,
        commonMaterialCategories: payload,
      };
    case ADD_COMMON_MATERIAL_CATEGORY:
      return {
        ...state,
        commonMaterialCategories: [...state.commonMaterialCategories, payload],
      };
    case UPDATE_COMMON_MATERIAL_CATEGORY:
      return {
        ...state,
        commonMaterialCategories: state.commonMaterialCategories.map(
          (category) => {
            if (payload.id !== category.id) {
              return category;
            }

            return {
              ...category,
              ...payload,
            };
          }
        ),
      };
    case SELECT_COMMON_MATERIAL_CATEGORY:
      return {
        ...state,
        selectedCommonMaterialCategory: payload,
      };
    case DELETE_COMMON_MATERIAL_CATEGORY:
      return {
        ...state,
        commonMaterialCategories: state.commonMaterialCategories.filter(
          (category) => category.id !== payload
        ),
      };
    default:
      return state;
  }
}
