import moment from "moment";

export const useTransformOption = (
  options,
  valueKey = "id",
  labelKey = "name"
) => {
  let arr = [];

  for (let i in options) {
    arr.push({
      value: options[i][valueKey],
      label: options[i][labelKey],
    });
  }

  return arr;
};

export const filterUnissuedTools = (options) => {
  let tools = [];
  if (!options) {
    return null;
  }

  options.forEach((item, index) => {
    let tool = tools.find((tItem) => tItem.serial_no === item.serial_no);
    if (!tool) {
      tools.push({
        value: item.id,
        description: item.description,
        label: `${item.description} (available: 1)`,
        quantity: 1,
      });
    } else {
      let toolIndex = tools.indexOf(tool);
      if (toolIndex > -1) {
        tools[toolIndex].quantity++;
        tools[
          toolIndex
        ].label = `${tools[toolIndex].description} (available: ${tools[toolIndex].quantity})`;
      }
    }
  });

  return tools;
};

export const filterUnissuedToolsBySerialNo = (options) => {
  let tools = [];
  if (!options) {
    return null;
  }

  options.forEach((item, index) => {
    tools.push({
      value: item.id,
      description: item.description,
      label: `${item.description} (S/N: ${item.serial_no})`,
      serial_no: item.serial_no,
      quantity: 1,
    });
  });

  return tools;
};

// export const filterUnissuedTools = (options) => {
//   let tools = [];
//   if (!options) {
//     return null;
//   }

//   options.forEach((item, index) => {
//     tools.push({
//       value: item.id,
//       description: item.description,
//       label: `${item.description} (S/N: ${item.serial_no})`,
//       quantity: 1,
//     });
//   });

//   return tools;
// };

export const transformUnissuedTools = (options) => {
  let tools = [];

  if (!options) {
    return null;
  }

  options.forEach((item) => {
    tools.push({
      id: item.id,
      value: item.id,
      description: item.description,
      serial_no: item.serial_no,
      condition: item.condition,
      brand: item.brand,
      purchase_date: item.purchase_date,
      warranty_expiry_date: item.warranty_expiry_date,
      label: `${item.description} (${item.condition}) (S/N: ${item.serial_no})`,
    });
  });

  return tools;
};

export const transformDate = (date, format) =>
  moment(date)
    .format(format || "YYYY-MM-DD")
    .toString();

export const setValidDate = (date) => {
  if (date) {
    let jsDate = new Date(date);
    if (Object.prototype.toString.call(jsDate) === "[object Date]") {
      if (!isNaN(jsDate.getTime())) {
        return jsDate;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const groupBy = (array, group) => {
  var hash = Object.create(null),
    result = [];

  array.forEach(function (a) {
    if (!hash[a[group]]) {
      hash[a[group]] = [];
      result.push(hash[a[group]]);
    }
    hash[a[group]].push(a);
  });
  return result;
};

export const getDistinctSuppliers = (materials) => {
  let suppliers = [];

  groupBy(materials, "supplier_id").forEach((material) => {
    material.forEach((m) => {
      if (m.supplier && m.type === "Specialised") {
        let supplierExists = false;
        suppliers.forEach((s) => {
          if (s?.id === m.supplier?.id) {
            supplierExists = true;
          }
        });
        if (!supplierExists) {
          suppliers.push(m.supplier);
        }
      }
    });
  });

  return suppliers;
};

export const removeURLParameter = (url) => {
  const fileNameWithParam = url.split("/").reverse();
  return fileNameWithParam[0].split("?")[0];
};
