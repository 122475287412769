export const specialisedMaterialListColumns = [
  {
    name: "Photo",
  },
  {
    name: "Type",
  },
  {
    name: "Category",
  },
  {
    name: "Supplier",
  },
  {
    name: "Code",
  },
  {
    name: "Description",
  },
  {
    name: "Unit",
  },
  {
    name: "Price",
  },
  // {
  //   name: "Project",
  // },
  // {
  //   name: "Warehouse",
  // },
  {
    name: "Bulk Qty",
  },
  {
    name: "Requested Qty",
  },
  {
    name: "Delivered Qty",
  },
  {
    name: "Delivered Amount Total",
  },
  {
    name: "Adjusted Qty",
  },
  {
    name: "Used Qty",
  },
  {
    name: "In Stock",
  },
];

export const commonMaterialListColumns = [
  {
    name: "Category",
  },
  {
    name: "Code",
  },
  {
    name: "Description",
  },
  {
    name: "Unit",
  },
  {
    name: "Price",
  },
  {
    name: "Warehouse",
  },
  {
    name: "Issue Qty",
  },
  {
    name: "Delivered Qty",
  },
  {
    name: "Requested for Allocation Qty",
  },
  {
    name: "Allocated Qty",
  },
  {
    name: "Adjusted Qty",
  },
  {
    name: "In Stock Qty",
  },
];

export const commonMaterialCategoryColumns = [
  {
    name: "Category Name",
  },
];

export const warehouseColumns = [
  {
    name: "Warehouse Name",
  },
];

export const supplierColumns = [
  {
    name: "Photo",
  },
  {
    name: "Name",
  },
  {
    name: "Address",
  },
  {
    name: "Contact Person",
  },
];
