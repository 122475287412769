import {
  GET_TASK_CATEGORIES,
  UPDATE_TASK_CATEGORY,
  DELETE_TASK_CATEGORY,
  ADD_TASK_CATEGORY,
  SELECT_TASK_CATEGORY,
  LOADING_TASK_CATEGORIES,
} from "../actions/types";

const initialState = {
  isLoading: false,
  taskCategories: [],
  selectedTaskCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_TASK_CATEGORIES:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TASK_CATEGORIES:
      return {
        ...state,
        taskCategories: action.payload,
        isLoading: false,
      };
    case SELECT_TASK_CATEGORY:
      return {
        ...state,
        selectedTaskCategory: action.payload,
      };
    case ADD_TASK_CATEGORY:
      return {
        ...state,
        taskCategories: [...state.taskCategories, action.payload],
        isLoading: false,
      };
    case UPDATE_TASK_CATEGORY:
      return {
        ...state,
        taskCategories: state.taskCategories.map((taskCategory) => {
          if (action.payload.id !== taskCategory.id) {
            return taskCategory;
          }

          return {
            ...taskCategory,
            ...action.payload,
          };
        }),
        isLoading: false,
      };
    case DELETE_TASK_CATEGORY:
      return {
        ...state,
        taskCategories: state.taskCategories.filter(
          (taskCategory) => taskCategory.id !== action.payload
        ),
        isLoading: false,
      };
    default:
      return state;
  }
}
