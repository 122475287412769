import axios from "axios";
import { tokenConfig } from "./auth";
import swal from "sweetalert";

import {
  GET_COMMON_DELIVERED_MATERIALS,
  GET_COMMON_FOR_DELIVERY_MATERIALS,
  LOADING_COMMON_DELIVERY_MATERIALS,
  SELECT_COMMON_DELIVERED_MATERIAL,
  DELIVER_COMMON_MATERIAL,
  SELECT_COMMON_FOR_DELIVERY_MATERIAL,
  GET_COMMON_PARTIAL_DELIVERED_MATERIALS,
  SELECT_COMMON_PARTIAL_DELIVERED_MATERIAL,
  DELETE_COMMON_DELIVERY_MATERIAL,
} from "./types";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// GET DELIVERY MATERIALS
export const getDeliveredMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_COMMON_DELIVERY_MATERIALS });

  let endpoint = `/common-delivery-materials?page=${page}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMMON_DELIVERED_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

// get for delivery materials (issue POs that haven't delivered yet.)
export const getForDeliveryMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_COMMON_DELIVERY_MATERIALS });

  let endpoint = `/common-delivery-materials/for-delivery?page=${page}`;

  axios
    .get(BASE_URL + endpoint, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_COMMON_FOR_DELIVERY_MATERIALS,
        payload: res.data.data,
        pagination: {
          from: res.data.from,
          to: res.data.to,
          total: res.data.total,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        },
      });
    })
    .catch((err) => console.error(err));
};

export const getPartialDeliveredMaterials = (page) => (dispatch, getState) => {
  dispatch({ type: LOADING_COMMON_DELIVERY_MATERIALS });

  let endpoint = `/common-delivery-materials/partial?page=${page}`;

  axios.get(BASE_URL + endpoint, tokenConfig(getState)).then((res) => {
    dispatch({
      type: GET_COMMON_PARTIAL_DELIVERED_MATERIALS,
      payload: res.data.data,
      pagination: {
        from: res.data.from,
        to: res.data.to,
        total: res.data.total,
        last_page: res.data.last_page,
        current_page: res.data.current_page,
      },
    });
  });
};

// select delivery material
export const selectForDeliveryMaterial =
  (forDeliveryMaterial) => (dispatch) => {
    dispatch({
      type: SELECT_COMMON_FOR_DELIVERY_MATERIAL,
      payload: forDeliveryMaterial,
    });
  };

export const selectDeliveredMaterial = (deliveredMaterial) => (dispatch) => {
  dispatch({
    type: SELECT_COMMON_DELIVERED_MATERIAL,
    payload: deliveredMaterial,
  });
};

export const selectPartialDeliveredMaterial =
  (partialDeliveredMaterial) => (dispatch) => {
    dispatch({
      type: SELECT_COMMON_PARTIAL_DELIVERED_MATERIAL,
      payload: partialDeliveredMaterial,
    });
  };

// DELIVER MATERIAL
export const deliverMaterial = (payload) => (dispatch, getState) => {
  let endpoint = BASE_URL + `/common-delivery-materials/`;

  axios
    .post(endpoint, payload, tokenConfig(getState))
    .then((res) => {
      swal({
        title: "Materials marked as delivered!",
        icon: "success",
      });
      dispatch({ type: DELIVER_COMMON_MATERIAL, payload: res.data });
    })
    .catch((err) => console.error(err));
};

export const deleteDeliveryMaterial = (id) => (dispatch, getState) => {
  axios
    .delete(
      BASE_URL + `/common-delivery-materials/${id}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: DELETE_COMMON_DELIVERY_MATERIAL,
        payload: id,
      });
      swal({
        title: "Delivery PO Deleted!",
        icon: "success",
      });
    })
    .catch((err) => console.error(err));
};
