import React from "react";
import classNames from "classnames";

function Button(props) {
  const {
    primary,
    success,
    danger,
    info,
    warning,
    disabled,
    onClick,
    text,
    className = "",
    type = "button",
  } = props;

  let bg = "bg-blue-500";
  let hover = "hover:bg-blue-700";

  if (primary) {
    bg = "bg-blue-500";
    hover = "hover:bg-blue-700";
  } else if (success) {
    bg = "bg-green-500";
    hover = "hover:bg-green-700";
  } else if (danger) {
    bg = "bg-red-500";
    hover = "hover:bg-red-700";
  } else if (info) {
    bg = "bg-indigo-500";
    hover = "hover:bg-indigo-700";
  } else if (warning) {
    bg = "bg-orange-500";
    hover = "hover:bg-orange-700";
  }

  if (disabled) {
    bg = "bg-gray-200";
    hover = "";
  }

  return (
    <button
      onClick={() => {
        onClick && onClick();
      }}
      className={
        className ||
        classNames(
          `${bg} ${hover} text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline`
        )
      }
      type={type}
      disabled={disabled ?? false}
      {...props}
    >
      {text}
    </button>
  );
}

export default Button;
