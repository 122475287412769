import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import {
  Fieldset,
  Label,
  Pagination,
  Spinner,
  EmptyPlaceholder,
} from "../common";

import {
  getServicedEquipments,
  selectServicedEquipment,
} from "../../actions/equipments";

export default function EquipmentServicePage(props) {
  const dispatch = useDispatch();
  const servicedEquipments = useSelector(
    (state) => state.equipments.servicedEquipments
  );
  const selectedServicedEquipment = useSelector(
    (state) => state.equipments.selectedServicedEquipment
  );

  const pagination = useSelector(
    (state) => state.equipments.paginationServicedEquipments
  );
  const equipmentsLoading = useSelector(
    (state) => state.equipments.equipmentsLoading
  );
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getServicedEquipments(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <div className="flex">
      <div className="w-2/3">
        <Fieldset>
          <h1 className="text-sm font-extrabold text-gray-600 mb-4 px-4">
            Serviced Equipments
          </h1>

          <table className="table-auto ml-6 mt-4">
            <thead>
              <tr>
                <th className="text-xs px-4 py-2">Date</th>
                <th className="text-xs px-4 py-2">Sent By</th>
                <th className="text-xs px-4 py-2">Serviced To</th>
                <th className="text-xs px-4 py-2">Items</th>
              </tr>
            </thead>

            <tbody>
              {!equipmentsLoading &&
                servicedEquipments.map((servicedEquipment) => (
                  <tr
                    key={servicedEquipment.id}
                    className={classNames("hover:bg-gray-200 cursor-pointer", {
                      "text-blue-600 bg-teal-200":
                        (selectedServicedEquipment
                          ? selectedServicedEquipment.id
                          : 0) === servicedEquipment.id,
                    })}
                    onClick={() => {
                      dispatch(selectServicedEquipment(servicedEquipment));
                    }}
                  >
                    <td className="text-xs border px-2 py-2">
                      {servicedEquipment.date &&
                        moment(servicedEquipment.date)
                          .format("MMM DD Y")
                          .toString()}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {servicedEquipment.performed_by &&
                        `${servicedEquipment.performed_by.first_name} ${servicedEquipment.performed_by.last_name}`}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {servicedEquipment.extra}
                    </td>
                    <td className="text-xs border px-2 py-2">
                      {servicedEquipment.equipments
                        ? servicedEquipment.equipments.length
                        : 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {equipmentsLoading && <Spinner />}

          {pagination && !equipmentsLoading && (
            <Pagination
              from={pagination.from}
              to={pagination.to}
              total={pagination.total}
              lastPage={pagination.last_page}
              currentPage={pagination.current_page}
              onPageClick={(page) => {
                setPageNumber(page);
              }}
            />
          )}
        </Fieldset>
      </div>

      <div className="w-1/3 px-4">
        {!equipmentsLoading && (
          <Fieldset>
            {!selectedServicedEquipment ? (
              <EmptyPlaceholder
                text="Select a Serviced Equipment"
                subText="then we will show the rest of the details here."
              />
            ) : (
              <div>
                <h1 className="text-sm font-extrabold text-gray-600 mb-4">
                  {`In Service - ${
                    selectedServicedEquipment.date &&
                    moment(selectedServicedEquipment.date)
                      .format("MMM DD Y")
                      .toString()
                  }`}
                </h1>
                <Label name="To" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedServicedEquipment.extra ? (
                    selectedServicedEquipment
                  ) : (
                    <i className="text-sm">None specified.</i>
                  )}
                </p>
                <Label name="Issue" />
                <p className="text-sm text-gray-700 mb-2">
                  {selectedServicedEquipment.comments}
                </p>

                <Label name="Equipments in service" />
                <table className="table-auto mb-4">
                  <thead>
                    <tr>
                      <th className="text-xs px-4 py-2">Description</th>
                      <th className="text-xs px-4 py-2">S/N</th>
                      <th className="text-xs px-4 py-2">Condition</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedServicedEquipment.equipments &&
                      selectedServicedEquipment.equipments.map((equipment) => (
                        <tr key={equipment.equipment.id}>
                          <td className="text-xs border px-2 py-2">
                            {equipment.equipment.description}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {equipment.equipment.serial_no}
                          </td>
                          <td className="text-xs border px-2 py-2">
                            {equipment.equipment.condition}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Fieldset>
        )}
      </div>
    </div>
  );
}
