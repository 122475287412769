import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Label, Button, Typography } from "../common";
import { addUser, getAvailableEmployees } from "../../actions/users";
import swal from "sweetalert";
import AsyncSelect from "react-select/async";

export default function UserForm(props) {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [employee, setEmployee] = useState(null);

  const loadEmployeeOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(getAvailableEmployees(inputValue)).then((res) => {
        resolve(res);
      });
    });

    let employeeOptions = [];

    for (let i in search) {
      if (search) {
        employeeOptions.push({
          value: search[i].id,
          label: `${search[i].first_name} ${search[i].last_name}`,
        });
      }
    }

    callback(employeeOptions);
  };

  const handleEmployeeChange = (option) => {
    setEmployee(option);
  };

  const onSubmit = async () => {
    const body = {
      employee_id: employee.value,
      username,
      password,
    };

    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "Create credentials for this employee?",
      buttons: true,
    }).then((willAdd) => {
      if (willAdd) {
        dispatch(addUser(body));
        props.onViewClose && props.onViewClose();
      }
    });
  };

  return (
    <div>
      <Typography type="h1">Add User Credentials</Typography>

      <div className="flex mt-4 px-4 justify-center">
        <div className="w-1/2 px-2">
          <Label name="Select Employee" />
          <AsyncSelect
            loadOptions={loadEmployeeOptions}
            value={employee}
            onChange={handleEmployeeChange}
            className="text-xs shadow-sm"
          />
        </div>
      </div>

      <div className="flex mt-4 px-4 justify-center">
        <div className="w-1/2 px-2">
          <Label name="Username" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            name="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </div>

      <div className="flex mt-4 px-4 justify-center">
        <div className="w-1/2 px-2">
          <Label name="Password" />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>

      <div className="justify-center flex mt-4 px-4">
        <Button
          text="Save"
          success="true"
          onClick={() => {
            onSubmit();
          }}
        />

        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onViewClose && props.onViewClose();
          }}
        />
      </div>
    </div>
  );
}
