import * as yup from "yup";

export const ToolValidationSchema = yup.object().shape({
  company: yup.object().required("Please select a company").nullable(true),
  category: yup.object().required("Please select a category").nullable(true),
  condition: yup.object().required("Please select a condition").nullable(true),
  brand: yup.string().required("Please type a brand").nullable(true),
  description: yup
    .string()
    .required("Please type a description")
    .nullable(true),
  serialNumber: yup
    .string()
    .required("Please type a serial number")
    .nullable(true),
  purchaseDate: yup
    .date()
    .required("Please select a purchase date")
    .nullable(true),
  expiryDate: yup
    .date()
    .min(yup.ref("purchaseDate"), "Expiry date can't be before purchase date")
    .required("Please select an expiry date")
    .nullable(true),
});
