import {
  GET_MATERIALS,
  LOADING_MATERIALS,
  SELECT_MATERIAL,
  GET_MATERIAL_CATEGORIES,
  ADD_MATERIAL,
  UPDATE_MATERIAL,
  DELETE_MATERIAL,
  GET_PURCHASED_MATERIALS_TOTAL,
  LOADING_PURCHASED_MATERIALS_TOTAL,
} from "../actions/types";

const initialState = {
  materials: [],
  paginationMaterials: null,
  selectedMaterial: null,
  materialCategories: [],
  materialsLoading: false,
  purchasedMaterialsTotal: {
    total_purchased: 0,
    months: {
      "01": 0,
      "02": 0,
      "03": 0,
      "04": 0,
      "05": 0,
      "06": 0,
      "07": 0,
      "08": 0,
      "09": 0,
      "10": 0,
      "11": 0,
      "12": 0,
    },
  },
  purchasedMaterialsTotalLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_MATERIALS:
      return {
        ...state,
        materialsLoading: true,
      };
    case LOADING_PURCHASED_MATERIALS_TOTAL:
      return {
        ...state,
        purchasedMaterialsTotalLoading: true,
      };
    case GET_MATERIALS:
      return {
        ...state,
        materials: action.payload,
        paginationMaterials: action.pagination,
        materialsLoading: false,
      };
    case GET_PURCHASED_MATERIALS_TOTAL:
      return {
        ...state,
        purchasedMaterialsTotal: action.payload,
        purchasedMaterialsTotalLoading: false,
      };
    case SELECT_MATERIAL:
      return {
        ...state,
        selectedMaterial: action.payload,
      };
    case GET_MATERIAL_CATEGORIES:
      return {
        ...state,
        materialCategories: action.payload,
      };
    case ADD_MATERIAL:
      return {
        ...state,
        materials: [...state.materials, action.payload],
        // pagination: {
        //   ...state.pagination,
        //   total: state.pagination.total + 1,
        // },
        materialsLoading: false,
      };
    case UPDATE_MATERIAL:
      return {
        ...state,
        materials: state.materials.map((material) => {
          if (action.payload.id !== material.id) {
            return material;
          }

          return {
            ...material,
            ...action.payload,
          };
        }),
      };
    case DELETE_MATERIAL:
      return {
        ...state,
        materials: state.materials.filter(
          (material) => material.id !== action.payload
        ),
        materialsLoading: false,
      };
    default:
      return state;
  }
}
