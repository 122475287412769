import {
  GET_EQUIPMENTS,
  GET_EQUIPMENT_FILTERS,
  LOADING_EQUIPMENTS,
  SELECT_EQUIPMENT,
  APPROVE_EQUIPMENT_REQUEST,
  DENY_EQUIPMENT_REQUEST,
  SELECT_EQUIPMENT_REQUEST,
  GET_EQUIPMENT_REQUESTS,
  GET_SCRAPPED_EQUIPMENTS,
  GET_SERVICED_EQUIPMENTS,
  SELECT_SERVICED_EQUIPMENT,
  GET_EQUIPMENT_CATEGORIES,
  UPDATE_EQUIPMENT_CATEGORY,
  DELETE_EQUIPMENT_CATEGORY,
  ADD_EQUIPMENT_CATEGORY,
  SELECT_EQUIPMENT_CATEGORY,
  ADD_EQUIPMENT,
  DELETE_EQUIPMENT,
  UPDATE_EQUIPMENT,
} from "../actions/types";

const initialState = {
  equipmentsLoading: false,
  equipments: [],
  equipmentCategories: [],
  equipmentRequests: [],
  scrappedEquipments: [],
  servicedEquipments: [],
  pagination: null,
  paginationEquipmentRequests: null,
  paginationServicedEquipments: null,
  paginationEquipmentCategories: null,
  selectedEquipment: null,
  selectedEquipmentRequest: null,
  selectedServicedEquipment: null,
  selectedEquipmentCategory: null,
  filters: {
    companies: [],
    projects: [],
    employees: [],
    drivers: [],
    categories: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_EQUIPMENTS:
      return {
        ...state,
        equipmentsLoading: true,
      };
    case GET_EQUIPMENTS:
      return {
        ...state,
        equipments: action.payload,
        pagination: action.pagination,
        equipmentsLoading: false,
      };
    case SELECT_EQUIPMENT:
      return {
        ...state,
        selectedEquipment: action.payload,
      };
    case GET_EQUIPMENT_FILTERS:
      return {
        ...state,
        filters: {
          companies: action.payload.companies,
          projects: action.payload.projects,
          employees: action.payload.employees,
          drivers: action.payload.drivers,
          categories: action.payload.categories,
        },
      };
    case GET_EQUIPMENT_REQUESTS:
      return {
        ...state,
        equipmentsLoading: false,
        equipmentRequests: action.payload,
        paginationEquipmentRequests: action.pagination,
      };
    case APPROVE_EQUIPMENT_REQUEST:
      return {
        ...state,
        equipmentsLoading: false,
        equipmentRequests: state.equipmentRequests.map((equipmentRequest) => {
          if (action.payload.id !== equipmentRequest.id) {
            return equipmentRequest;
          }

          return {
            ...equipmentRequest,
            status: "Approved",
            approved_comments: action.payload.comments,
          };
        }),
      };
    case DENY_EQUIPMENT_REQUEST:
      return {
        ...state,
        equipmentsLoading: false,
        equipmentRequests: state.equipmentRequests.map((equipmentRequest) => {
          if (action.payload.id !== equipmentRequest.id) {
            return equipmentRequest;
          }

          return {
            ...equipmentRequest,
            status: "Denied",
            denied_comments: action.payload.comments,
          };
        }),
      };
    case SELECT_EQUIPMENT_REQUEST:
      return {
        ...state,
        selectedEquipmentRequest: action.payload,
      };
    case GET_SCRAPPED_EQUIPMENTS:
      return {
        ...state,
        toolsLoading: false,
        scrappedEquipments: action.payload,
      };
    case GET_SERVICED_EQUIPMENTS:
      return {
        ...state,
        equipmentsLoading: false,
        servicedEquipments: action.payload,
        paginationServicedEquipments: action.pagination,
      };
    case SELECT_SERVICED_EQUIPMENT:
      return {
        ...state,
        selectedServicedEquipment: action.payload,
      };
    case GET_EQUIPMENT_CATEGORIES:
      return {
        ...state,
        equipmentCategories: action.payload,
        paginationEquipmentCategories: action.pagination,
        equipmentsLoading: false,
      };
    case ADD_EQUIPMENT_CATEGORY:
      return {
        ...state,
        equipmentCategories: [...state.equipmentCategories, action.payload],
        paginationEquipmentCategories: {
          ...state.paginationEquipmentCategories,
          total: state.paginationEquipmentCategories.total + 1,
        },
        equipmentsLoading: false,
      };
    case UPDATE_EQUIPMENT_CATEGORY:
      return {
        ...state,
        equipmentCategories: state.equipmentCategories.map(
          (equipmentCategory) => {
            if (action.payload.id !== equipmentCategory.id) {
              return equipmentCategory;
            }

            return {
              ...equipmentCategory,
              ...action.payload,
            };
          }
        ),
        equipmentsLoading: false,
      };
    case DELETE_EQUIPMENT_CATEGORY:
      return {
        ...state,
        equipmentCategories: state.equipmentCategories.filter(
          (equipmentCategory) => equipmentCategory.id !== action.payload
        ),
        equipmentsLoading: false,
      };
    case SELECT_EQUIPMENT_CATEGORY:
      return {
        ...state,
        selectedEquipmentCategory: action.payload,
      };
    case ADD_EQUIPMENT:
      return {
        ...state,
        equipments: [...state.equipments, action.payload],
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        equipmentsLoading: false,
      };
    case UPDATE_EQUIPMENT:
      return {
        ...state,
        equipments: state.equipments.map((equipment) => {
          if (action.payload.id !== equipment.id) {
            return equipment;
          }

          return {
            ...equipment,
            ...action.payload,
          };
        }),
        equipmentsLoading: false,
      };
    case DELETE_EQUIPMENT:
      return {
        ...state,
        equipments: state.equipments.filter(
          (equipment) => equipment.id !== action.payload
        ),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
        equipmentsLoading: false,
      };
    default:
      return state;
  }
}
