export const roles = {
  su: 1,
  dir: 2,
  pm: 3,
  apm: 4,
  engr: 5,
  sup: 6,
  wrkr: 7,
  sfty: 8,
  qs: 9,
  coord: 10,
  log: 11,
  drvr: 12,
  proc: 13,
  drftr: 14,
  hr: 15,
};
export const role_names = {
  1: "Superadmin",
  2: "Director",
  3: "Project Manager",
  4: "Assistant Project Manager",
  5: "Engineer",
  6: "Supervisor",
  7: "Worker",
  8: "Safety",
  9: "Quantity Surveyor",
  10: "Coordinator",
  11: "Logistic",
  12: "Driver",
  13: "Procurement",
  14: "Drafter",
  15: "Human Resource",
};
export const roleOptions = [
  {
    value: 1,
    label: "Superadmin",
  },
  {
    value: 2,
    label: "Director",
  },
  {
    value: 3,
    label: "Project Manager",
  },
  {
    value: 4,
    label: "Assistant Project Manager",
  },
  {
    value: 5,
    label: "Engineer",
  },
  {
    value: 6,
    label: "Supervisor",
  },
  {
    value: 7,
    label: "Worker",
  },
  {
    value: 8,
    label: "Safety",
  },
  {
    value: 9,
    label: "Quantity Surveyor",
  },
  {
    value: 10,
    label: "Coordinator",
  },
  {
    value: 11,
    label: "Logistic",
  },
  {
    value: 12,
    label: "Driver",
  },
  {
    value: 13,
    label: "Procurement",
  },
  {
    value: 14,
    label: "Drafter",
  },
  {
    value: 15,
    label: "Human Resource",
  },
];
export const role_abbr = {
  1: "SU",
  2: "DIR",
  3: "PM",
  4: "APM",
  5: "ENGR",
  6: "SUP",
  7: "WRKR",
  8: "SFTY",
  9: "QS",
  10: "COORD",
  11: "LOG",
  12: "DRVR",
  13: "PROC",
  14: "DRFTR",
  15: "HR",
};
