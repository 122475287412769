import React from "react";

const Typography = ({ type, children }) => {
  switch (type) {
    case "h1":
      return (
        <h1 className="text-lg font-extrabold text-gray-600 mb-2">
          {children}
        </h1>
      );
    case "p":
      return (
        <p className="text-sm text-gray-700 text-xs font-bold">{children}</p>
      );
    case "muted":
      return <p className="text-xs text-gray-600 mb-2">{children}</p>;
    default:
      return children;
  }
};

export default Typography;
