import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button } from "../common";
import moment from "moment";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useTransformOption } from "../../helpers/useTransformData";
import { getSuppliers } from "../../actions/suppliers";
import { getMaterialCategories } from "../../actions/materialCategories";
import { searchMaterials } from "../../actions/materials";
import { updateRequestPoMaterial } from "../../actions/requestPoMaterials";
import MaterialIcon from "../../assets/img/material_icon.png";
import ImageUtils from "../../helpers/ImageUtils";
import ImageUploader from "react-images-upload";

export default function MaterialRequestOrderUpdate(props) {
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers.suppliers);
  const suppliersDropdown = useTransformOption(suppliers);
  const selectedTask = useSelector((state) => state.tasks.selectedTask);
  const selectedRequestOrder = useSelector(
    (state) => state.requestPoMaterials.selected
  );
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [comments, setComments] = useState(selectedRequestOrder.comments ?? "");
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useSelector(
    (state) => state.materialCategories.materialCategories
  );
  const categoriesDropdown = useTransformOption([
    { id: null, name: "All" },
    ...categories,
  ]);

  // Material Fields
  const [isNew, setIsNew] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [orderQty, setOrderQty] = useState(0);
  const [photo, setPhoto] = useState(null);
  const [materialComment, setMaterialComment] = useState("");
  const [unit, setUnit] = useState("");

  const [deliveryDate, setDeliveryDate] = useState(
    selectedRequestOrder.delivery_date
      ? Date.parse(selectedRequestOrder.delivery_date)
      : new Date()
  );

  const imageUploadRef = useRef(null);
  const onDrop = (photo) => {
    setPhoto(photo);
  };

  const handleDeliveryDateChange = (date) => {
    setDeliveryDate(date);
  };

  const handleSelectedMaterialChange = (option) => {
    if (!option.__isNew__) {
      setSelectedMaterial(option);
      setIsNew(false);
    } else {
      setIsNew(true);
      let material = {
        id: null,
        type: "Common",
        category: null,
        code: "CST-" + Math.random().toString(36).substr(2, 6),
        description: option.label,
        unit: null,
        material_id: null,
        order_qty: orderQty,
        price: null,
        warehouse: null,
        photo: null,
        photo_full_url: null,
        delivery_date: deliveryDate
          ? moment(deliveryDate).format("YYYY-MM-DD").toString()
          : null,
      };
      const newOption = {
        ...option,
        material,
      };
      setSelectedMaterial(newOption);
    }
  };

  const handleSupplierChange = (option) => {
    setSelectedSupplier(option);
  };

  useEffect(() => {
    dispatch(getSuppliers());
    dispatch(getMaterialCategories());
    //Move material.pivot.order_qty to material.order_qty for update
    if (selectedRequestOrder.materials) {
      let newMaterials = [];
      selectedRequestOrder.materials.forEach((m) => {
        let material = {
          ...m,
          order_qty: m.pivot.order_qty,
        };
        newMaterials.push(material);
      });
      setSelectedMaterials(newMaterials);
    }
  }, [dispatch, selectedRequestOrder]);

  const loadMaterialOptions = async (inputValue, callback) => {
    let search = await new Promise((resolve, reject) => {
      dispatch(
        searchMaterials(
          inputValue,
          selectedCategory?.value,
          selectedSupplier?.value
        )
      ).then((res) => {
        resolve(res);
      });
    });

    let options = [];

    for (let i in search) {
      options.push({
        value: search[i].id,
        label: `${search[i].code} - ${search[i].description}`,
        material: search[i],
      });
    }
    callback(options);
  };

  const addMaterial = async () => {
    let material = {
      id: selectedMaterial.material.id,
      type: selectedMaterial.material.type,
      category: selectedMaterial.material.category?.name,
      code: selectedMaterial.material.code,
      description: selectedMaterial.material.description,
      unit: isNew ? unit : selectedMaterial.material.unit,
      material_id: selectedMaterial.material.id,
      order_qty: orderQty,
      photo: null,
      photo_full_url: selectedMaterial.material.photo_full_url,
      price: selectedMaterial.material.price,
      warehouse: selectedMaterial.material.warehouse?.name,
      delivery_date: deliveryDate
        ? moment(deliveryDate).format("YYYY-MM-DD").toString()
        : null,
    };

    if (photo) {
      let tempImg = null;

      for (let i = 0; i < photo.length; i++) {
        tempImg = await ImageUtils.resizeImage(photo[i]);
      }

      if (tempImg) {
        material.photo = tempImg;
      }
    }
    material.comment = materialComment;

    setSelectedMaterials([...selectedMaterials, material]);
    setSelectedMaterial(null);
    setSelectedSupplier(null);
    setPhoto(null);
    setUnit("");
    setIsNew(false);
    setOrderQty(0);
    setMaterialComment("");
  };

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
  };

  const handleMaterialChange = (index, event) => {
    const values = [...selectedMaterials];
    values[index][event.target.name] = event.target.value;

    setSelectedMaterials(values);
  };

  const onSubmit = () => {
    swal({
      icon: "warning",
      title: "Are you sure?",
      text: "This will update this Request Order.",
      buttons: true,
    }).then(async (willUpdate) => {
      if (willUpdate) {
        let data = {
          materials: selectedMaterials,
          delivery_date: deliveryDate
            ? moment(deliveryDate).format("YYYY-MM-DD").toString()
            : null,
          id: selectedRequestOrder.id,
          comments: comments,
        };

        dispatch(updateRequestPoMaterial(data));
        props.onFormClose && props.onFormClose();
      }
    });
  };

  const removeMaterialEntry = (index) => {
    let values = [...selectedMaterials];
    values.splice(index, 1);
    setSelectedMaterials(values);
  };

  return (
    <div>
      <h1 className="font-bold">Update Request Order</h1>
      {selectedTask && (
        <p className="text-xs text-gray-600 mb-4">under {selectedTask.title}</p>
      )}

      <div className="flex mt-4 mb-4">
        <div className="w-1/2">
          <Label name="Expected Delivery Date" />
          <DatePicker
            showYearDropdown
            selected={deliveryDate}
            dateFormat="yyyy-MM-dd"
            onChange={handleDeliveryDateChange}
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            style={{ zIndex: 10 }}
          />
        </div>

        <div className="w-1/2 px-4">
          <Label name="RO No." />
          <p className="text-xs text-gray-900">
            {selectedRequestOrder.series_no}
          </p>
        </div>
      </div>

      <p className="text-sm font-bold mt-4">Materials</p>
      <p className="text-xs text-gray-600 mb-4">
        you can add/remove materials requested as preferred.
      </p>

      <p className="text-xs text-gray-600 mb-4">Add a material</p>
      <div className="flex mb-4">
        <div className="w-1/4">
          <Label name="Supplier" />
          <Select
            className="text-xs shadow-sm"
            options={suppliersDropdown}
            value={selectedSupplier}
            onChange={handleSupplierChange}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Category" />
          <Select
            className="text-xs"
            options={categoriesDropdown}
            value={selectedCategory}
            onChange={handleCategoryChange}
          />
        </div>
      </div>
      <div className="flex mb-4">
        <div className="w-2/4">
          <Label name="Search Code/Description" />
          <AsyncCreatableSelect
            defaultOptions
            loadOptions={loadMaterialOptions}
            onChange={handleSelectedMaterialChange}
            value={selectedMaterial}
            allowCreateWhileLoading={false}
            className="text-xs shadow-sm"
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 1000 }) }}
          />
        </div>

        <div className="w-1/4 px-4">
          <Label name="Order Qty." />
          <input
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            name="order_qty"
            type="number"
            value={orderQty}
            onChange={(e) => setOrderQty(e.target.value)}
            required
          />
        </div>

        {isNew && (
          <div className="w-1/4 px-4">
            <Label name="Unit" />
            <input
              className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              autoComplete="off"
              name="unit"
              type="text"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              required
            />
          </div>
        )}

        {isNew === false && (
          <div className="w-1/4 m-4 px-4">
            <Button
              text={`+ Add Material`}
              primary="true"
              disabled={!selectedMaterial}
              onClick={async () => {
                await addMaterial();
              }}
            />
          </div>
        )}
      </div>

      {isNew && (
        <>
          <p className="text-xs text-gray-600 mb-4">
            Please provide more information to the material requested.
          </p>

          <div className="flex mb-4">
            <div className="w-1/2">
              <Label name="Photo" />
              <ImageUploader
                ref={imageUploadRef}
                withPreview={true}
                singleImage={true}
                onChange={onDrop}
                imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                fileTypeError={" - image not supported"}
                fileSizeError={" - image size too big"}
              />
            </div>

            <div className="w-1/2 px-4">
              <Label name="Comment" />
              <input
                className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                autoComplete="off"
                name="comment"
                type="text"
                value={materialComment}
                onChange={(e) => setMaterialComment(e.target.value)}
              />
            </div>
          </div>

          <div className="flex mb-4">
            <div className="w-1/4 m-4 px-4">
              <Button
                text={`+ Add Material`}
                primary="true"
                disabled={!selectedMaterial}
                onClick={async () => {
                  await addMaterial();
                }}
              />
            </div>
          </div>
        </>
      )}

      <p className="text-xs text-gray-600">Update existing materials.</p>
      <table className="table-fixed w-100 mb-4">
        <thead>
          <tr>
            <th className="text-xs px-4 py-2">Photo</th>
            <th className="text-xs px-4 py-2">Type</th>
            <th className="text-xs px-4 py-2">Category</th>
            <th className="text-xs px-4 py-2">Code</th>
            <th className="text-xs px-4 py-2">Description</th>
            <th className="text-xs px-4 py-2">Unit</th>
            {/* <th className="text-xs px-4 py-2">Warehouse</th> */}
            <th className="text-xs px-4 py-2">Order Qty</th>
            <th className="text-xs px-4 py-2">Comment</th>
            <th className="text-xs px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {selectedMaterials.map((material, index) => (
            <tr key={index} className="hover:bg-gray-200">
              <td className="text-xs border px-2 py-2">
                <span className="w-16 h-16 text-sm text-white inline-flex items-center justify-center">
                  {material.photo_full_url ? (
                    <a
                      href={material.photo_full_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        alt="..."
                        className="w-full align-middle border-none"
                        src={material.photo_full_url}
                      />
                    </a>
                  ) : material.photo ? (
                    <img
                      alt="..."
                      className="w-full align-middle border-none"
                      src={material.photo}
                    />
                  ) : (
                    <img
                      alt="..."
                      className="w-full align-middle border-none"
                      src={MaterialIcon}
                    />
                  )}
                </span>
              </td>
              <td className="text-xs border px-2 py-2">{material.type}</td>
              <td className="text-xs border px-2 py-2">
                {material.category?.name}
              </td>
              <td className="text-xs border px-2 py-2">{material.code}</td>
              <td className="text-xs border px-2 py-2">
                {material.description}
              </td>
              <td className="text-xs border px-2 py-2">{material.unit}</td>
              {/* <td className="text-xs border px-2 py-2">
                {material.warehouse?.name}
              </td> */}
              <td className="text-xs border px-2 py-2">
                <input
                  className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  name="order_qty"
                  type="text"
                  value={material.order_qty}
                  onChange={(event) => handleMaterialChange(index, event)}
                />
              </td>
              <td className="text-xs border px-2 py-2">{material.comment}</td>
              <td className="text-xs border px-2 py-2">
                <div className="flex">
                  <button
                    type="button"
                    className="bg-red-500 hover:bg-red-700 text-xs text-white font-bold py-2 px-4 mr-2 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => {
                      removeMaterialEntry(index);
                    }}
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4">
        <div className="w-1/2">
          <Label name="Request Order Comments" />
          <textarea
            className="text-xs shadow appearance-none border rounded w-full py-2 px-3 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            autoComplete="off"
            name="comments"
            type="text"
            value={comments}
            onChange={(event) => setComments(event.target.value)}
          />
        </div>
      </div>

      <div className="justify-center flex mt-4 px-4 mb-4">
        <Button
          text="Submit Request Order"
          success="true"
          onClick={() => {
            onSubmit();
          }}
          disabled={!selectedMaterials}
        />
        <Button
          text="Close"
          primary="true"
          onClick={() => {
            props.onFormClose && props.onFormClose();
          }}
        />
      </div>
    </div>
  );
}
